import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { showAlert, SW_ICON } from "../../../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";
import { getToken, removeToken } from "../../../../utils/TokenLS";
import { getStaticArcReception } from "../../../../utils/staticArc";
import { 
    FormControlActionTwo,
    FormControlInputSearch,
    FormControlSeccionOne,
    Label,
    PropertyTypesContainerColum,
    PropertyTypesContainerRow,
    ReceptionDetailDescriptionWrapper,
    ReceptionDetailSubTitle,
    ReceptionDetailValueWrapper,
    ReceptionDetailWrapper,
            ReceptionDetailWrapper_Global, 
            ReceptionDetailWrapper_Global_DOS,
            ReceptionFormControlAction,
            ReceptionFormControlActionOne,
            ReceptionImageWrapper,
            ReceptionInfoWrapper, 
            } from "./styles";
import { FileInput, FormControl, FormControlAction, FormControlInput, FormControlInputFile, FormControlInputRadio, PageTitle, SubTitle } from "../../../../globalStyles";
import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";
import { PropertyTypeLabel } from "../../../../components/PropertyTypeLabel";
import { TraslateDetailTitle } from "../../../Traslates/Components/TraslateDetail/styles";
import { Button } from "../../../../components/Button";
import { ButtonIcon } from "../../../../components/ButtonIcon";
import { IoArrowBack, IoTrashOutline } from "react-icons/io5";
import { Page } from "../../../../components/Page";
import swal from 'sweetalert';
import { LogoImageWrapper } from "../../../../components/Logo/styles";
import Alert from "../../../../components/Alert/Alert";

export const ReceptionProductDetail = () => {
  
  const { auth, closeSeccionAuth } = useAuth();
  const {id} = useParams()
  const navigate = useNavigate();

  const [ detailProduct, setDetailProduct ] = useState([]);
  const [ selectedFileName, setSelectedFileName ] = useState('');
  const [ cargando, setCargando ] = useState(false);
  const [ alert, setAlert ] = useState({})    

  const [ cantidad, setCantidad ] = useState(0);    
  const [ remision, setRemision ] = useState('');
  const [ entrega, setEntrega ] = useState('');
  const [ recibe, setRecibe ] = useState('');
  const [ observacion, setObservacion ] = useState('');


  const { 
      register, 
      handleSubmit,
      formState:{
          errors,
          isValid
      }  
  } = useForm({ mode: 'onChange'});   

  useEffect(() => {  
      const getData = async ()=>{
          try {
              const token = getToken();
              if(!token) return   
              await getReceptionProduct()            
          } catch (error) {
              console.log(error)
          }             
      }    
      getData();   
        
  },[auth])
  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
      closeSeccionAuth()
      removeToken()
      showAlert(
          'Inicie Seccion Nuevamente', 
          "Su token puede estar vencido", 
          SW_ICON.INFO,
          () => { navigate('/Login')}    
      );
  }
  //ReceptionById
  const getReceptionProduct = async ()=>{        
    try {                      
        const response = await requestHttp(
            {
                    method: HTTP_VERBS.GET,
                    endpoint: `/supplies/${id}`                                                                       
            }
        );             
        const {data} = response;                
        await setDetailProduct(data);
        //console.log({dataDetail: data})                   
        setCargando(true); 
                
        data?setCantidad(data.cantidad):console.log('')  
        data?setRemision(data.remision):console.log('')  
        data?setEntrega(data.entrega):console.log('')  
        data?setRecibe(data.recibe):console.log('')  
        data?setObservacion(data.Observacion):console.log('')  
        
    } catch (error) {
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        }    
    }
  }  
  //Delete Reception
  const showAlertYN = () =>{
    //console.log({id: id})
    //Vallido que si este dentro de un rango permitido para eliminar
    //Validaciones
    //Vallido que si este dentro de un rango permitido para registrar
    //valido si dia es viernes o sabado                          
    if(numeroDia >= 5){
        //rango para el viernes de 4 dias.
        if(numeroDia == 5){                
            if(rango > 363599999){
                showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);      
                return
            }
        }else{
            //rango para el sabado de 3 dias.                     
                if(rango > 276999999){
                    showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);             
                    return
                }
        }
    }else{
        //Rango de 2 dias para registrar
        if(rango > 189999999){                
            showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);  
            return
        }
    }
    swal({
        title: "Esta usted seguro?",
        text: "Una vez eliminado, no podras recuperar este registro!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
        if (willDelete) {   
            receptionDelete(id)                
        } else {
        //swal("Su registro esta a salvo!");
        return
        }
        //console.log(willDelete)
    });
  }
  const receptionDelete = async () =>{             
      try {           
          const response = await requestHttp(
              {
                  method: HTTP_VERBS.DELETE,
                  endpoint: `/supplies/${id}`,               
                  token: getToken()               
              }
          );          
          showAlert(
              'Recepción', 
              "Eliminada Correctamente", 
              SW_ICON.SUCCESS,   
              () => { navigate('/AddReceptionProducts')}                 
          );                              
              
          } catch (error) { 
              if(error.response.status === 401){
                  //hendleCloseSeccion();
              }else{
                  showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
              }            
          }        
  }
  //FileUpload
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];        
      if (selectedFile) {            
          setFile(selectedFile);        
          setSelectedFileName(selectedFile.name);
      }

      // Realiza aquí alguna acción con el archivo seleccionado si es necesario
      console.log('Archivo seleccionado:', file);
  };
  const uploadFileHandler = () =>{
      if(file){
          requestUploadFile(file)           
      }else{
        onSubmitsave()   
      }        
  }
  const requestUploadFile = async (file) =>{
      try {
          const formData = new FormData();
          formData.append("propertyAdjunto", file);
          const response = await requestHttp({
              endpoint: '/supplies/upload',
              contentType: CONTENT_TYPES.MULTIPART_FORM_DTA,
              body: formData
          })                                             
          onSubmitsave(response.data.newFileName)       
         
      } catch (error) {
          if(error.response.status === 401){
              hendleCloseSeccion();
          }else{
              console.log(error)
          }            
      }
  }
  //Actualizar Recepcion
  const onSubmitsave = async (name) =>{
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede realizar modificaciones para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                    //if(rango > 363599999){ //Festivo dia lunes
                        setAlert({
                            msg: 'Usted ya no puede realizar modificaciones para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){
            //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede realizar modificaciones para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }    
    //Valido Fecha
    if(fecha > hoy){
        setAlert({
            msg: 'Fecha no puede ser "mayor" a la fecha actual',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return     
    }
    const dataReception = {}    
    dataReception['cantidad'] = cantidad?cantidad:detailProduct.cantidad
    dataReception['remision'] = remision?remision:detailProduct.remision
    dataReception['mainAdjunto'] =  name?name:detailProduct.mainAdjunto          
    dataReception['entrega'] = entrega?entrega:detailProduct.entrega
    dataReception['recibe'] = recibe?recibe:detailProduct.recibe
    dataReception['Observacion'] = observacion?observacion:detailProduct.Observacion
    dataReception['employeesId'] =  auth.data._id
    
    console.log({dataReupdate: dataReception})

    //Enviar a grabar
    receptionRequest(dataReception); 

  }
  const receptionRequest = async (dataReception) =>{             

    try {           
        const response = await requestHttp(
            {
                method: HTTP_VERBS.PUT,
                endpoint: `/supplies/${id}`,
                body: dataReception,
                token: getToken()
            }
        );                                                
        showAlert(
            'Recepción', 
            "Actualizada Correctamente", 
            SW_ICON.SUCCESS                
        );
        setFile('');
        getReceptionProduct()            
    } catch (error) {  
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            showAlert('Error', "Update Incorrecta", SW_ICON.ERROR);
        }            
    }       
   
  }
  //Validar dia de la semana
  const fechaComoCadena = cargando?detailProduct.dtmFecha.split('T')[0]:''; // día semana
  const dias = [
      'LUNES',
      'MARTES',
      'MIERCOLES',
      'JUEVES',
      'VIERNES',
      'SABADO',
      'DOMINGO',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  const nombreDia = dias[numeroDia];

  const fecha = new Date(fechaComoCadena);
  //hoy
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);

  const rango = new Date(hoy - fecha);

  const { msg } = alert;

  return (
    <Page>
        {cargando?
        <ReceptionDetailWrapper_Global>
            <ReceptionDetailWrapper_Global_DOS>
                <LogoImageWrapper>
                  <PageTitle>
                    EDITAR RECEPCIÓN
                  </PageTitle>
                  <img src={"../Logodos.jpg"} alt="imgLogo"/>
                </LogoImageWrapper>
                
                <ReceptionDetailWrapper>
                  <ReceptionImageWrapper>
                      <img alt='Documento' 
                          //src={`Imagenes/${retired.mainImage}`}
                          src={getStaticArcReception(detailProduct.mainAdjunto)}
                      />
                  </ReceptionImageWrapper>
                  <ReceptionInfoWrapper>                    
                      <h2>CICLO {detailProduct.ciclo}</h2>
                      <ReceptionDetailSubTitle>
                        
                      </ReceptionDetailSubTitle>
                      <ReceptionDetailDescriptionWrapper>
                        <PropertyTypesContainerRow>
                          <PropertyTypesContainerColum>
                              <ReceptionDetailValueWrapper>
                                  Recibió: 
                                  <h2>FINCA {detailProduct.strFinca}</h2>
                                  <h3>Ibm: {detailProduct.Ibm} </h3>
                                  <PropertyBusinessType
                                    busineesType={`Fecha de Recibido: ${detailProduct.dtmFecha?detailProduct.dtmFecha.split('T')[0]:''} (${nombreDia})`}
                                  />
                                  <PropertyBusinessType
                                    busineesType={`Responsable: ${detailProduct.recibe}`}                                    
                                  />
                              </ReceptionDetailValueWrapper>
                          </PropertyTypesContainerColum>
                          <PropertyTypesContainerColum>
                              <ReceptionDetailValueWrapper>
                                  Producto: 
                                  <h2>{detailProduct.IntCodigo_CONC} - {detailProduct.strConcepto}</h2>
                                  <h3>Cant: {detailProduct.cantidad} </h3>
                                  <PropertyBusinessType
                                    busineesType={`#Remisión: ${detailProduct.remision}`}                                    
                                  />
                                  <PropertyBusinessType
                                    busineesType={`Entrega: ${detailProduct.entrega}`}                                    
                                  />                                  
                              </ReceptionDetailValueWrapper>
                          </PropertyTypesContainerColum>
                        </PropertyTypesContainerRow>
                      </ReceptionDetailDescriptionWrapper>
                      <ReceptionFormControlAction>
                        <ReceptionFormControlActionOne>
                            <PropertyTypeLabel 
                                typeId= {`${detailProduct.employeesId?detailProduct.employeesId.name:''}`}
                            />&nbsp;&nbsp;               
                            <PropertyTypeLabel 
                                    typeId= {`FC: ${detailProduct.dtmFechaModifica?detailProduct.dtmFechaModifica:''}`}                                                      
                            />
                        </ReceptionFormControlActionOne>
                        <ReceptionFormControlActionOne>
                            <PropertyTypesContainerRow>
                            <PropertyTypesContainerColum>
                                <ReceptionDetailValueWrapper>                                                                  
                                    <PropertyBusinessType
                                        busineesType={`Observación: ${detailProduct.Observacion}`}                                    
                                    />
                                </ReceptionDetailValueWrapper>
                            </PropertyTypesContainerColum>
                            </PropertyTypesContainerRow>
                        </ReceptionFormControlActionOne>
                      </ReceptionFormControlAction>
                  </ReceptionInfoWrapper>
                  <FormControlAction>
                    <ButtonIcon icon={IoTrashOutline} onPress={showAlertYN} />
                  </FormControlAction>
                </ReceptionDetailWrapper> 
                { msg && <Alert alert = {alert} />}                         
                <form>
                <FormControlSeccionOne>
                            <FormControl>
                                <FormControlInputSearch>
                                        <label>CANTIDAD</label>
                                        <input  type="number"                                    
                                                {...register("cantidad")}
                                                value={cantidad}
                                                onChange={e => setCantidad(e.target.value)}     
                                                autoFocus                                                                                                                                        
                                                tabIndex="5"  
                                                id="input5"                                                     
                                        />                                        
                                </FormControlInputSearch>
                                <FormControlInputSearch>
                                            <label>#REMISIÓN</label>
                                            <input  type="text"                                    
                                                    {...register("remision")}
                                                    value={remision}
                                                    onChange={e => setRemision(e.target.value)}                                                                                                                                             
                                                    tabIndex="6"  
                                                    id="input6"                                                     
                                            />                                              
                                </FormControlInputSearch>
                                <FormControlInputFile> 
                                    <Label
                                        backcolor={selectedFileName?'#23774d':'#f0a808'}
                                        id="input7" 
                                        tabIndex="7"   
                                    >
                                        {selectedFileName?selectedFileName:'Seleccionar remisión'}
                                        <FileInput
                                            type="file"
                                            id="file"                                                                                      
                                            onChange={handleFileChange}                                              
                                        />
                                    </Label>                                                                                                                        
                                </FormControlInputFile>
                            </FormControl>                                                                                                                                                                                       
                           
                            <FormControl>
                                <FormControlInputSearch>
                                            <label>ENTREGA (Nombre Proveedor - Nombre Persona)</label>
                                            <input  type="text"                                    
                                                    {...register("entrega")}
                                                    value={entrega}
                                                    onChange={e => setEntrega(e.target.value)}                                                                                                    
                                                    tabIndex="8"
                                                    id="input8"
                                                                                                                                                                                                
                                            />                                                                   
                                </FormControlInputSearch>                                
                            </FormControl>
                        </FormControlSeccionOne>
                        <FormControlSeccionOne>                                                                                                                                                                                       
                            <FormControl>
                                <FormControlInputSearch>
                                        <label>OBSERVACIÓN</label>
                                        <input  type="text"                                    
                                                {...register("observacion")}
                                                value={observacion}
                                                onChange={e => setObservacion(e.target.value)}                                            
                                                tabIndex="9"
                                                id="input9"                                                                                                                                                                                            
                                        />                                    
                                </FormControlInputSearch>                                
                            </FormControl>
                            <FormControl>
                                <FormControlInputSearch>
                                        <label>RECIBE (Nombre del Empleado - Cargo)</label>
                                        <input  type="text"                                    
                                                {...register("recibe")}
                                                value={recibe}
                                                onChange={e => setRecibe(e.target.value)}                                                                                                
                                                tabIndex="10"
                                                id="input10"
                                                                                                                                                                                            
                                        />                                                                                                          
                                </FormControlInputSearch>
                            </FormControl>
                        </FormControlSeccionOne>
                        <FormControl>
                            <Button label="Actualizar" onPress={handleSubmit(uploadFileHandler)} />                               
                        </FormControl>
                        <FormControlActionTwo>
                                <ButtonIcon icon={IoArrowBack} onPress={() => { navigate('/addReceptionProducts')}} />
                        </FormControlActionTwo>
                </form>
            </ReceptionDetailWrapper_Global_DOS>
        </ReceptionDetailWrapper_Global>
        :'cargando...'}
    </Page>
  )
}