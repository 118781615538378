import styled from 'styled-components';

export const PropertyTypesContainer = styled.section`
    //border: 1px solid orange;
    display: flex;
    overflow-x: scroll; 
        
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 1366px) {
        font-size: 10px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        font-size: 14px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    
`;

export const PrecipitationWrapper_Global = styled.div`
    //border: 1px solid blue;   
    background-color: #F2F2F2;     
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan cuando no quepan en el ancho */
    justify-content: space-between; /* Distribuye los elementos horizontalmente */   
    //align-items: center;
    margin: 0 auto; /* Centrar el contenedor */
    max-width: 100%; /* Establecer un ancho máximo */
    max-height: 100%;

    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 820px) {
        //font-size: 16px;
        flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
        //border: 1px solid blue;  
        
    }
    
`;


/* Establecer el ancho de las tablas y el gráfico para ocupar todo el espacio disponible */
export const PrecipitationWrapper_Table = styled.div`
    //border: 1px solid blue;   
    flex: 1;
    /* Hace que los elementos ocupen el espacio disponible de manera equitativa */
    margin: 0px; /* Agrega un pequeño margen entre los elementos */
    box-sizing: border-box; /* Incluye el margen en el ancho total */
    //border: 1px solid #ccc; /* Opcional: Agrega un borde a los elementos */

    display: flex;
    justify-content: center; /* Centra horizontalmente la tabla */
    align-items: center; /* Centra verticalmente la tabla */   
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        //border: 1px solid blue;  
        display: flex;        
    
    }

`;

export const PrecipitationWrapper_Chart = styled.div`
    //border: 1px solid blue;     
    flex: 1; /* Ocupa dos tercios del espacio disponible (2/3) */
    margin: 10px; /* Agrega un pequeño margen entre los elementos */
    margin-right: 30px;
    box-sizing: border-box; /* Incluye el margen en el ancho total */
    //border: 1px solid #ccc; /* Opcional: Agrega un borde a los elementos */

`;