import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import {Page} from "../../components/Page"
import { FormControl, 
         FormControlAction,         
         FormControlInput,          
         FormControlInputFile, 
         PageTitle, 
         FormControlInputSearch,
         FormControlInputRadio} from "../../globalStyles";
import {Button} from "../../components/Button"
import { ButtonIcon } from "../../components/ButtonIcon";
import { IoSearchCircleSharp } from "react-icons/io5";
import { RetiredWrapper_Global, 
         RetiredWrapper_Global_DOS } from "./styles";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { getToken, removeToken } from "../../utils/TokenLS";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";

export const Retired = () =>{
    
    const { auth, closeSeccionAuth } = useAuth();
    const [ employe, setEmploye ] = useState([]);

    const [motivoSeleted, setMotivoSelected] = useState('');
    const [employeSeleted, setEmployeSelected] = useState();
    const [codigoSeleted, setCodigoSelected] = useState();

    const navigate = useNavigate();
    
    const { 
            register, 
            handleSubmit,
            formState:{
                errors,
                isValid
            }  
    } = useForm({ mode: 'onChange'});   

    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }

    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                //await getSucursalesAll(); 
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                 
    },[auth])
    
    //Motivo
    const handleChange = event => {
        setMotivoSelected(event.target.value)      
        //console.log(motivoSeleted)                 
     }
    
    //EmployeesDoc
    const handleEmployeDoc = () => { 
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                await  getEmployeesDoc();   
                setCodigoSelected('');
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                
     } 

    const getEmployeesDoc = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',                         
                    params: makeEmployeesFiltersDoc()                                     
                }
            );             
            const {data} = response;              
            setEmploye(data); 
            //Valido si existe 
            if(!data.length){
                showAlert('Error', "Identificación no existe", SW_ICON.ERROR);   
            }
            //Validar si el trabajador esta vinculado a la finca del usuario que realiza retiro.            
            if((auth.data.role === 3) && (data[0].Ibm !== auth.data.ibm)){
                showAlert(
                    'No Autorizado', 
                    `Trabajador CC.${employeSeleted} no pertenece a su finca`, 
                    SW_ICON.INFO);   
                    setEmploye('');
            }
            setEmployeSelected('');           
            setCodigoSelected('');             
                                   
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }    
        }
    }
        
    const makeEmployeesFiltersDoc = ()=>{
        const filters = {};  
        filters['strDocumento'] = employeSeleted;
        return filters;       
    }

    //EmployeesCod
    const handleEmployeCod = () => {   
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                await   getEmployeesCod();       
                setEmployeSelected(''); 
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                                
     } 

    const getEmployeesCod = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',                         
                    params: makeEmployeesFiltersCod()                                     
                }
            );             
            const {data} = response;              
            setEmploye(data); 
            //Valido si existe 
            if(!data.length){
                showAlert('Error', "Identificación no existe", SW_ICON.ERROR);   
            }
            //Validar si el trabajador esta vinculado a la finca del usuario que realiza el retiro.            
            if((auth.data.role === 3) && (data[0].Ibm !== auth.data.ibm)){
                showAlert(
                    'No Autorizado', 
                    `Trabajador con Codigo: ${codigoSeleted} no pertenece a su finca`, 
                    SW_ICON.INFO);   
                    setEmploye('');
            }
            setEmployeSelected('');           
            setCodigoSelected('');              
                      
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
    }

    const makeEmployeesFiltersCod = ()=>{
        const filters = {};         
            filters['IntCodigo'] = codigoSeleted;     
        return filters;       
    }

    //Retired
    const onSubmitRetired = async (data, name) =>{   

        const dataRetired = {}               
        dataRetired['strDocumento'] =  employe[0].strDocumento
        dataRetired['IntCodigo'] =  employe[0].IntCodigo        
        dataRetired['strNombreTrabajador'] =  employe[0].strNombreTrabajador        
        dataRetired['dtFRetiro'] =  data.fecharetiro
        dataRetired['strEstadoTrabajador'] =  'Retiro'
        dataRetired['strCausa'] =  motivoSeleted
        dataRetired['strCodigoCentro'] =  employe[0].strCodigoCentro
        dataRetired['Ibm'] =  employe[0].strCodigoActividad
        dataRetired['strObservacion'] =  data.observacion
        //dataRetired['mainAdjunto'] =  name  
        dataRetired['employeesId'] =  employe[0]._id

        retiredRequest(dataRetired); 
    }

    
    const retiredRequest = async (dataRetired) =>{             

        try {           
            const response = await requestHttp(
                {
                    endpoint: '/retiros',
                    body: dataRetired,
                    token: getToken()
                }
            );             
            setEmployeSelected('');           
            setCodigoSelected('');   
            setEmploye('');                                  
            showAlert(
                'Retired', 
                "Creado Correctamente", 
                SW_ICON.SUCCESS                
            );
            setFile('');            
        } catch (error) {            
            showAlert('Error', "Retired Incorrecto", SW_ICON.ERROR);
        }       
       
    }

    //FileUpload
    const [file, setFile] = useState(null);

    const fileSelectedHandler = (event) =>{
        const fileSelected = event.target.files[0];
        setFile(fileSelected);        
    };

    const uploadFileHandler = (data) =>{
        if(file){
            requestUploadFile(file, data)           
        }else{
            showAlert("Error", "Selecciona un archivo", SW_ICON.ERROR);
        }        
    }

    const requestUploadFile = async (file, data) =>{
        try {
            const formData = new FormData();
            formData.append("propertyAdjunto", file);
            const response = await requestHttp({
                endpoint: '/retiros/upload',
                contentType: CONTENT_TYPES.MULTIPART_FORM_DTA,
                body: formData
            })                                             
            onSubmitRetired(data, response.data.newFileName)       
           
        } catch (error) {
            console.log('error', error)
        }
    }
    /*
        <FormControl>
            <FormControlInputFile>                            
                <label>Adjuntar Archivo</label>
                    <input  type='file' 
                            accept="image/png, image/jpeg, image/jpg, PDF/pdf"
                            onChange={fileSelectedHandler}                                    
                            />
            </FormControlInputFile>                                       
        </FormControl>

        <form onSubmit={handleSubmit(uploadFileHandler)}>
    */
   
    return(
    <Page >
        <RetiredWrapper_Global>
            <RetiredWrapper_Global_DOS>
                <LogoImageWrapper> 
                    <PageTitle>                   
                        RETIROS            
                    </PageTitle>         
                    <img src={"Logo.jpg"} alt="imgLogo"/>            
                </LogoImageWrapper>
                
                <br />
                <form onSubmit={handleSubmit(onSubmitRetired)}>
                    <FormControl>
                        <FormControlInputSearch>
                            <label>IDENTIFICACIÓN</label>
                            <input  type="number"                                    
                                     {...register("identificacionSearch")}
                                      value={employeSeleted} 
                                      onChange={e => setEmployeSelected(e.target.value)}                                                                     
                            />
                            
                        </FormControlInputSearch> 
                        <FormControlAction>
                            <ButtonIcon icon={IoSearchCircleSharp} onPress={handleEmployeDoc}/>                     
                        </FormControlAction>
                        
                        <FormControlInputSearch>
                            <label>CODIGO</label>
                            <input  type="number"                                    
                                     {...register("codigoSearch")}
                                      value={codigoSeleted} 
                                      onChange={e => setCodigoSelected(e.target.value)}                                                                     
                            />
                            
                        </FormControlInputSearch> 
                        <FormControlAction>
                            <ButtonIcon icon={IoSearchCircleSharp} onPress={handleEmployeCod}/>                     
                        </FormControlAction>                                         
                    </FormControl>
                    <br/>
                    <FormControl>
                        <FormControlInput>
                            <label>NOMBRE DEL TRABAJADOR</label>
                            <input  type="text"                                    
                                     {...register("name")}
                                     value={employe[0]?employe[0].strNombreTrabajador:''}                                                                        
                            />                            
                        </FormControlInput>                                          
                    </FormControl>                              
                    <FormControl>
                        <FormControlInput>
                            <label>IDENTIFICACIÓN</label>
                            <input  type="number"                                    
                                     {...register("identificacion")}    
                                     value={employe[0]?employe[0].strDocumento:''}                                                                     
                            />                            
                        </FormControlInput>                                          
                    </FormControl>            
                    <FormControl>
                    <FormControlInput>
                            <label>CODIGO</label>
                            <input  type="number"                                    
                                     {...register("codigo")}  
                                     value={employe[0]?employe[0].IntCodigo:''}                                                                      
                            />                            
                        </FormControlInput>                                                        
                    </FormControl>
                    <FormControl>
                        <FormControlInput>
                            <label>FINCA A LA QUE PERTENECE</label>
                            <input  type="text"                                    
                                     {...register("origen")}    
                                     value={employe[0]?employe[0].strCodigoCentro:''}                                                                    
                            />                            
                        </FormControlInput>                                          
                    </FormControl>    

                    <FormControl
                        color='#86b49d'       
                    >
                        <FormControlInput
                            colortext='white' 
                        >
                            <label>MOTIVO DE RETIRO:</label>                        
                            <FormControlInputRadio
                                colortext='white' 
                            >                           
                                <label>Voluntario</label>
                                <input {...register("motivo")}  onChange={handleChange} type="radio" value="Voluntario" />
                                <label>Otro</label>
                                <input {...register("motivo")} onChange={handleChange} type="radio" value="Otro" />
                            </FormControlInputRadio>                                        
                        </FormControlInput>                        
                    </FormControl> 

                    <FormControl>
                        <FormControlInput>
                            <label>FECHA DE RETIRO</label>
                            <input  type="date"
                                    {...register("fecharetiro", { required: true })} 
                            />
                            {errors.fecharetiro?.type === 'required' && <span>Este Campo es requerido</span>}
                        </FormControlInput>                                       
                    </FormControl>                    
                    
                    <FormControl>
                        <FormControlInput>
                            <label>OBSERVACIÓN</label>
                            <input  type='text'                                    
                                     {...register("observacion")}                                                                        
                            />                            
                        </FormControlInput>                                          
                    </FormControl>
                    
                    <br />
                    <Button disabled={!isValid} label="Registrar Retiro" type="submit" onPress={()=>{}} />
                    <br />
                                  
                </form>
                
            </RetiredWrapper_Global_DOS>
        </RetiredWrapper_Global>        
    </Page>
);};