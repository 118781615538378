import styled from 'styled-components'

export const LogoImageWrapper = styled.div`
        
//border: 1px solid blue;
display: flex;
margin: 0;
align-items: center;
justify-content: space-between;

img{  
    flex: 1 ;
        //border: 1px solid blue;    
        border-radius: 20px;
        width: 10%;
        
    }    

`