import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import { getToken, removeToken } from "../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { PropertyCardRdts } from "./PropertyCardRdts";
import { PropertyButtonWrapper, PropertyCardWrapper, 
         PropertyCardWrapperTr, 
         PropertyDescriptionWrapper, 
         PropertyImageWrapper, 
         PropertyInfoWrapper, 
         PropertyValueWrapper } from "./PropertyCardRdts/styles";
import { FormControl, FormControlInput, FormControlInputDuplicate, SubTitle } from "../../globalStyles";
import { Page } from "../../components/Page";
import { PropertyTypesContainerColum, 
         PropertyTypesContainerHead, 
         PropertyTypesContainerRow, 
         RdtsDetailTitle, 
         RdtsWrapper, 
         RdtExportButton,
        RdtsDetailInfo } from "./styles";
import { PropertyProfile } from "../Home/components/PropertyProfile";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { ButtonIconRemove } from "../../components/ButtonIconRemove";
import { IoTrashOutline, 
  IoRemoveCircleOutline,
  IoRadioButtonOffOutline,
  IoArrowBackCircleOutline,
  IoArrowBack,
  IoDuplicate,
  IoAddCircleOutline,
  IoSave, 
  IoSend} from "react-icons/io5";
import swal from 'sweetalert';
import Alert from "../../components/Alert/Alert"
import ExportExcel from "react-export-excel";
import { Button } from "../../components/Button";
import { getCurrencyFormat } from "../../utils/CurrencyFormat";

const ALL_SUCURSALES = 0;
const fecha = Date.now();
const suma = 0; 

export const Rdds = () => {

  
  const ExcelFile = ExportExcel.ExcelFile;
  const ExcelSheet = ExportExcel.ExcelSheet;
  const ExcelColumn = ExportExcel.ExcelColumn;

  const { auth, closeSeccionAuth } = useAuth();
  const { rdtprov, setRdtprov } = useRdt();
  const [ rdts, setRdts ] = useState([]);
  const [ sucursal, setSucursal ] = useState([]);
  const [ sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);
  
  const [ codtrabajador, setCodtrabajador ] = useState('');
  const [ codlabor, setCodlabor ] = useState('');  
  const [ fechaini, setFechaIni ] = useState('');
  const [ fechafin, setFechafin ] = useState('');

  const [ sumtotal, setSumtotal ] = useState(suma);
  
  const [duplicar, setDuplicar] = useState('B')
  const [check, setCheck] = useState(false)
  const [checkSelected, setCheckSelected] = useState([])
  
  const [alert, setAlert] = useState({}) 
  
  const navigate = useNavigate();

  const { 
    register,
    handleSubmit,     
    formState:{
        errors,
        isValid
    }  
 } = useForm({ 
      mode: 'onChange',
      defaultValues: {
        checkbox: []
      }      
  }); 

 //Validar dia de la semana
 const fechaComoCadena = fechaini.split('T')[0] +  " 00:01:59"; // día semana
 const fechaComoCadenaf = fechafin.split('T')[0] +  " 00:01:59"; // día semana
 const dias = [
     'DOMINGO',
     'LUNES',
     'MARTES',
     'MIERCOLES',
     'JUEVES',
     'VIERNES',
     'SABADO',
 ];
 const numeroDia = new Date(fechaComoCadena).getDay();
 const nombreDia = dias[numeroDia];

 //hoy
 const tiempoTranscurrido = Date.now(); 
 const fechai = new Date(fechaComoCadena);
 const fechaf = new Date(fechaComoCadenaf);
 const hoy = new Date(tiempoTranscurrido);
 const rango = new Date(hoy - fechai);
 const tiempo = new Date(189999999);

    //console.log({rango: rango})
    //console.log({cadena: fechaComoCadena})
    //console.log({tiempo: tiempo})
    //console.log({tiempoViernes: tiempoViernes})
    //console.log({tiempoSabado: tiempoSabado})
    //console.log({numeroDiaRDT: numeroDia})
    //console.log({fecha: fecha})
    //console.log({tiempoTranscurrido: tiempoTranscurrido})   
    //console.log("Fecha actual: ", hoy); 
    //console.log("Calendario: ", fecha);   
    //console.log("nombre dia: ", nombreDia); 

  
 useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return   
            await getSucursalesAll();                                          
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[auth])

  //Si estoy editando
  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return            
            await rdtprov.ibm?setSucursalSelected(rdtprov.ibm):console.log(''); 
            await rdtprov.dateRdt?setFechaIni((rdtprov.dateRdt).split('T')[0]):console.log('');                                
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
},[rdtprov])

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                                         
            await getRdts(); 
            setCheck(false);           
           setDuplicar('B');
           setCheckSelected([]);                
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[sucursalSeleted])

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
          const token = getToken();
            if(!token) return                               
           await getRdts();  
           setCheck(false);           
           setDuplicar('B');
           setCheckSelected([]);            
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[fechaini])

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                               
           await getRdts();                     
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[codtrabajador])

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                               
           await getRdts();                                                                 
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[codlabor])  

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                               
           await onSubmitCantidad();                                                                  
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[rdts])

  //Sucursales
  const handleChange = event => {
    setSucursalSelected(event.target.value)             
  }
  const handleChangeDate = event => {
    setFechaIni(event.target.value)             
  }
  const handleChangeDateduplicate = event => {
    setFechafin(event.target.value)             
  }
  const handleChangeCodtrabajador = event => {
    setCodtrabajador(event.target.value)             
  }
  const handleChangeCodlabor = event => {
    setCodlabor(event.target.value)             
  }
  //habilitar duplicado
  const handleChangeCheckduplicate = event => {
    
    if(event.target.value === 'A'){
      setDuplicar('B')
      setCheck(false)      
    }else{
      setDuplicar('A')  
      setCheck(true)
    }       
  }
  //Seleccionar novedades a duplicar
  const handleChangeCheckSelected = event => {   
   /* var listado;   
    if(checkSelected.includes(event.target.value)){
      listado = checkSelected.filter(elemento=>elemento!==event.target.value)
    }else{
      listado = checkSelected.concat(event.target.value)
    }
    setCheckSelected(listado)      */
  }
  //Sucursals
  const getSucursalesAll = async ()=>{
    try {            
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/sucursal',                         
                params: makeSucursalesFilters()                                     
            }
        );             
        const {data} = response;                
        setSucursal(data);                                   
    } catch (error) {
        console.log(error)
    }
  }
  const makeSucursalesFilters = ()=>{
    const filters = {};           
    return filters;       
  }
  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
      closeSeccionAuth()
      removeToken()
      showAlert(
          'Inicie Seccion Nuevamente', 
          "Su token puede estar vencido", 
          SW_ICON.INFO,
          () => { navigate('/Login')}    
      );
  }
  //getRdts
  const getRdts = async ()=>{
      //console.log({sucursal: sucursalSeleted})
      if(!fechaini){
        setRdts([])
        return
        //console.log('Fecha vacia')
      }
      
        try {                      
          const response = await requestHttp(
              {
                  method: HTTP_VERBS.GET,
                  endpoint: '/rdds',                         
                  params: makePropertiesFilters()                                     
              }
          );             
          const {data} = response;                
          data?setRdts(data):console.log('');        
          } catch (error) {
              if(error.response.status === 401){
                  hendleCloseSeccion();
              }else{
                  console.log(error)
              }
          }
          
            //console.log({hacerConsulta: sucursalSeleted})        
                 
  }
  
  //Sumar cantidad
  const onSubmitCantidad = async (data) =>{    
    if(Object.keys(rdts).length > 0 ){
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;        
      for (let i = 0; i < rdts.length ; i++){          
        a = a + rdts[i].intTotal_rdd;
        b = b + rdts[i].intTotal_rdd;
        c = c + rdts[i].intTotal_rdd;
        d = d + rdts[i].intTotal_rdd;
        e = e + rdts[i].intHora_rdd;    
      }
      setSumtotal(a)
  
    }else if(Object.keys(rdts).length > 0 && Object.keys(codtrabajador).length > 0){
        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;
        let e = 0;        
        for (let i = 0; i < rdts.length ; i++){          
            a = a + rdts[i].intTotal_rdd;
            b = b + rdts[i].intTotal_rdd;
            c = c + rdts[i].intTotal_rdd;
            d = d + rdts[i].intTotal_rdd;
            e = e + rdts[i].intHora_rdd;          
        }
        setSumtotal(a)
     
    }else{
      setSumtotal(0);

    }        
                            
  }
  const makePropertiesFilters = ()=>{
      const filters = {};                
          filters['Fecha_INI'] = fechaini; 
          filters['Fecha_FIN'] = fechaini; 
          filters['IbmLaboro'] = sucursalSeleted;   
          filters['IntCodigo_EMPL'] = codtrabajador;    
          filters['IntCodigo_CONC'] = codlabor;
         
        //filters['employeesId'] = auth.data._id;     
        //console.log(fechaini);
      return filters;       
  }
  //Delete Novedad RDT
  const showAlertYN = (id) =>{
      //Validar dia de la semana
      const fechaComoCadena = fechaini?fechaini.split('T')[0] +  " 00:01:59":console.log(''); // día semana  
      const fecha = new Date(fechaComoCadena);
      const numeroDia = new Date(fechaComoCadena).getDay();
      //hoy
      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);
      const rango = new Date(hoy - fecha);
      const tiempo = new Date(189999999);

      //console.log({rango: rango})
      //console.log({tiempo: tiempo})

      //Vallido que si este dentro de un rango permitido para duplicar
      //valido si dia es viernes o sabado                          
      if(numeroDia >= 5){
        //rango para el viernes de 4 dias.
        if(numeroDia == 5){                
            if(rango > 363599999){
                setAlert({
                    msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }else{
            //rango para el sabado de 3 dias.                     
                if(rango > 276999999){
                    setAlert({
                        msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
        }
      }else{
        if(rango > 189999999){
          setAlert({
              msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
              error: true
          })
          //console.log({mensaje: alert.msg}); 
          setTimeout(()=>{
              setAlert({})
          }, 7000)            
          return
        }
      }
      //console.log({id: id})
      swal({
          title: "Esta usted seguro?",
          text: "Una vez eliminado, no podras recuperar este registro!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
          if (willDelete) {   
              rdtDelete(id)                
          } else {
          //swal("Su registro esta a salvo!");
          return
          }
          //console.log(willDelete)
      });
  }
  const rdtDelete = async (id) =>{             
      try {           
          const response = await requestHttp(
              {
                  method: HTTP_VERBS.DELETE,
                  endpoint: `/rdds/${id}`,               
                  token: getToken()               
              }
          );
          getRdts()
          showAlert(
              'RDD', 
              "Eliminado Correctamente", 
              SW_ICON.SUCCESS              
          );                              
              
          } catch (error) { 
              if(error.response.status === 401){
                  //hendleCloseSeccion();
              }else{
                  showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
              }            
          }        
  }
 
  const { msg } = alert;
  
  return (
    <Page>       
        <RdtsDetailTitle>
          CONSULTA DE CASINOS
        </RdtsDetailTitle> 
        { msg && <Alert alert = {alert} />}
      <form>
          <PropertyTypesContainerRow>            
            <PropertyTypesContainerColum>
                  <LogoImageWrapper>                                
                    <img src={"Logodos.jpg"} alt="imgLogo"/>                               
                  </LogoImageWrapper>                   
              </PropertyTypesContainerColum>
              <PropertyTypesContainerColum>
              <FormControl>
                <FormControlInput>
                        <label>FINCA REPORTADA</label>                    
                        <select id="sucursal"
                                    {...register("sucursal")}
                                    onChange={handleChange}
                                    value={sucursalSeleted}
                                    disabled={ auth.data.role != 1 && rdtprov.ibm != 90021?true:false}>
                                <option value={""}>--Seleccione--</option>
                                {sucursal.map(item=>(                                    
                                        <option key={item.strCodigo} 
                                                value={item.strCodigo}                                                                                               
                                                id={item.strCodigo}
                                    >{item.strNombre}</option>
                                    )
                                    )
                                }  
                                                                                                                                                                            
                        </select>
                </FormControlInput>
              </FormControl>  
              <FormControl>
                <FormControlInput>
                            <label>FECHA DEL RDT</label>
                            <input  type="date"
                                    {...register("fechaini")} 
                                    onChange={handleChangeDate}
                                    value={fechaini}
                            />                            
                            {errors.fechaini?.type === 'required' && <span>Este Campo es requerido</span>}
                </FormControlInput>
              </FormControl> 
            </PropertyTypesContainerColum>
            <PropertyTypesContainerColum>
              <FormControl>
                <FormControlInput>
                            <label>COD.TRABAJDOR:</label>
                            <input  type='text'                                    
                                     {...register("codtrabajador")}                                                                        
                                     onChange={handleChangeCodtrabajador}
                            />
                  </FormControlInput>
              </FormControl>
              
                 
            </PropertyTypesContainerColum>
            <PropertyTypesContainerColum>
              <RdtsDetailInfo>
                <p> 
                      USU:<span>{auth.data ? auth.data.email : 'Usuario no registrado'}</span><br/>
                      IBM:<span>{sucursalSeleted}</span><br/>
                      FEC:<span>{fechaini}</span><br/><br/>
                      DIA:<span>"{nombreDia}"</span>                                                                                                                             
                </p> 
                </RdtsDetailInfo>
            </PropertyTypesContainerColum>
            <PropertyTypesContainerColum>
                <LogoImageWrapper>                                
                                                
                </LogoImageWrapper> 
            </PropertyTypesContainerColum>
            
        </PropertyTypesContainerRow>  
        
      </form>
      {auth.data.role === 1?
        <RdtExportButton>
          <ExcelFile
                      element={<button>Exportar Casinos</button>}
                      filename="RdtCasinosexportacion" >
                      <ExcelSheet data={rdts} name={fechaini}>
                        <ExcelColumn label="TIPO" value="" />
                        <ExcelColumn label="CONCEPTO" value="IntCodigo_CONC" />
                        <ExcelColumn label="COD.TRABAJADOR" value="IntCodigo_EMPL" />
                        <ExcelColumn label="DOC.TRABAJADOR" value="" />
                        <ExcelColumn label="VR.DESCUENTO" value="intTotal_rdd" />
                        <ExcelColumn label="DOC.TERCERO" value="strDocumentocasino" />
                        <ExcelColumn label="COD CONCEPTO" value="" />
                        <ExcelColumn label="SALDO" value="" /> 
                        <ExcelColumn label="FILA" value="" />  
                        <ExcelColumn label="OBSERVACIONES" value="strObservacion_rdd" />                                                               
                        <ExcelColumn label="FINCA_REPORTO" value="strFincaLaboro" />
                        <ExcelColumn label="RESPONSABLE_CASINO" value="strNombrecasino" />                                                
                        <ExcelColumn label="NAMEEMPL" value="strNombreTrabajador" />
                        <ExcelColumn label="IBM_PERTENECE" value="strCodigo_SUCU" />                                             
                        <ExcelColumn label="FECHA_REPORTO" value="dtmFecha_rdd" />    
                        <ExcelColumn label="USUARIO_REPORTO" value="email_USUA" />                    
                      </ExcelSheet>
                    </ExcelFile>
      </RdtExportButton>:''}           
      <br/> 
      <table>
       <thead>      
          <tr>
            <th>Datos Trabajador</th>            
            <th>Cod</th>
            <th>Descuento</th>
            <th>Total</th>                       
            <th>Observación</th>           
            <th>Ibm</th>   
            <th>Reporto</th>
            {duplicar === 'A'? 
            <th>Seleccione Novedades</th>:''}
          </tr>          
        </thead>
        <tbody>
          {rdts.map((item, key) => (                     
            <tr>              
              <th>
                
                <PropertyCardWrapperTr>
                    <PropertyButtonWrapper>
                    {key}&nbsp;
                    <ButtonIconRemove icon={IoTrashOutline} onPress={()=>showAlertYN(item._id)} />
                    </PropertyButtonWrapper>
                    <PropertyCardWrapper to={`/RddsDetail/${item._id}`}>                  
                      <PropertyInfoWrapper>
                        {item.strNombreTrabajador} 
                        <SubTitle>
                            <span>{item.IntCodigo_EMPL} </span> - {item.strNombre_SUCU}
                        </SubTitle>
                      </PropertyInfoWrapper>               
                    </PropertyCardWrapper>
                </PropertyCardWrapperTr>
              </th>                         
              <th>
                <PropertyDescriptionWrapper>
                 <p>{item.IntCodigo_CONC}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.strConcepto}
                </PropertyDescriptionWrapper>
              </th>
              <th>
              <PropertyDescriptionWrapper>
                  <p>{getCurrencyFormat(item.intTotal_rdd)}</p>
                </PropertyDescriptionWrapper>
              </th>              
              <th>
                <PropertyDescriptionWrapper>
                  {item.strObservacion_rdd}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.IbmLaboro === item.strCodigo_SUCU ?"":item.IbmLaboro}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.email_USUA}
                </PropertyDescriptionWrapper>
                
              </th>
              {duplicar === 'A'? 
                <th>
                  <input {...register("checkbox")} 
                          type="checkbox"                          
                          onChange={handleChangeCheckSelected}                          
                          value={key} /> 
                </th>:''}                                                                                            
            </tr>                    
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>T.REG: {rdts.length}</th>            
            <th></th>
            <th></th>
            <th>{getCurrencyFormat(sumtotal)}</th>
            <th></th>
            <th></th>
            <th></th>
                        
          </tr>
        </tfoot>
      </table>
    </Page>
  )
}
