import styled from "styled-components";

export const AddPrecipitationWrapper_Global = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    justify-content: center;
    
`;

export const AddPrecipitationWrapper_Global_DOS = styled.div`
    //border: 1px solid blue;  
    flex-basis: ${props => props.basis};    
    @media (max-width: 1024px){
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    } 
    @media (min-width: 1024px){
            margin-top: 20px;
            padding:0 5px;            
            justify-content: center;
            width: 50%;
    }

    label{
        display: block;
        font-size: 0.70em;
        color: blue;
        font-weight: bold;
        text-align: center ;
        p{
            color: black;              
        }                    
    }
    
`;

export const FormControl_Global = styled.div`
    //border: 1px solid black;  
    background-color: #F2F2F2; 
    display:flex;
    margin-top: ${props => props.margintop}; 
    @media (max-width: 520px){
            flex-direction:column;
    }
        
`;

export const PropertyTypesContainerColum = styled.section`
    //border: 1px solid yellow;
    padding-right: 40px;
    margin-top: ${props => props.margintop}; 
    margin-left: ${props => props.marginleft}; 
    margin-bottom: ${props => props.marginbotton};
    
    label{
        display: block;
        font-size: 0.70em;
        color: blue;
        font-weight: bold;          
        p{
            color: black;              
        }                    
    } 
`;

export const PropertyTypesContainerRow = styled.section`

    //border: 1px solid red;
    border: 1px solid #dfdfdf;
    background-color: #F2F2F2;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
`;

export const FormControl = styled.div`
    //border: 1px solid red;
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;        

    
`;

export const FormControlTitle = styled.div`
    //border: 1px solid yellow;
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;  
    justify-content: center;
    label{        
        font-size: 1.4em;
        color: blue;
        font-weight: bold;         
        text-align: center;                           
    }
    span{ 
        font-size: 1em;
        color: red;
        font-weight: bold;         
        text-align: center;                           
    }      
`;



export const FormControlInputSearch = styled.div`
    //border: 1px solid orange;    
    flex: 1;  
    align-items: center;
    margin: 0 10px 0 10px;

    label{
        display: block;
        font-size: 0.80em;
        color: #26794f;
        font-weight: 500;
        padding-left: 10px ;
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"],
    input[type="float"],input[type="date"]{
        //display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 1.1em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border-radius: 15px;
        outline: none;    

    }
    input[type="number"]{
        width: 50%;
        font-size: 1.1em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border-radius: 15px;
        outline: none;    
    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
    
`;

