import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from '../../../../hooks/useAuth';
import { useRdt } from '../../../../hooks/useRdt';
import { IoCloseCircleOutline } from "react-icons/io5";
import { getToken, removeToken } from '../../../../utils/TokenLS';
import { showAlert, SW_ICON } from '../../../../utils/swAlert';
import { requestHttp } from '../../../../utils/HttpRequest';
import Alert from '../../../../components/Alert/Alert' 
import { Button } from '../../../../components/Button';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { PropertyBusinessType } from '../../../../components/PropertyBusinessType';
import { LogoImageWrapper } from '../../../../components/Logo/styles';
import { FormControl, FormControlInput } from '../../../../globalStyles';
import { TittleAuthorizationsRdt, 
         CerrarAuthorizationsRdt, 
         FormControlAuthorizationsRdt, 
         FormularioAuthorizationsRdt, 
         AuthorizationsRdtDetailWrapper,
         AuthorizationsRdtDetailInfoWrapper,
         AuthorizationsRdtDetailSubTitle,
         AuthorizationsRdtDetailValueWrapper,
         AuthorizationsRdtDetailDescriptionWrapper,
         AuthorizationsPropertyTypesContainerRow,
         AuthorizationsPropertyTypesContainerColum} from './styles'

export const AuthorizationsRdts = ({setModal, animarModal, setAnimarModal}) => {

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, reload, setReload, rdtEmploye  } = useRdt();
    const navigate = useNavigate();

    const [ observacion, setObservacion ] = useState('');

    const [ alert, setAlert] = useState({})    
    
    const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ mode: 'onChange'});   

    //Token valido o no expirado
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token){
                    hendleCloseSeccion()
                } return                  
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[auth])
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }
    //Boton de cerrar
    const ocultarAuthorizationsRdt = ()=>{      
        //setAnimarModal(false)
        setModal(false)
              
    }
    //Registrar Autorizacion
    const onSubmitSolicitud = async (e) =>{   
        e.preventDefault();
        if([observacion].includes('')){
            setAlert({
                msg: 'Todos los campos son requeridos',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return
        }
        setAlert({});       
        try {
            const dataRdtAuth = {};
            dataRdtAuth['IntCodigo_EMPL'] = rdtEmploye[0]?rdtEmploye[0].IntCodigo:'';
            dataRdtAuth['strDocumento'] = rdtEmploye[0]?rdtEmploye[0].strDocumento:'';
            dataRdtAuth['strNombreTrabajador'] = rdtEmploye[0]?rdtEmploye[0].strNombreTrabajador:'';
            dataRdtAuth['strCodigo_SUCU'] = rdtEmploye[0]?rdtEmploye[0].Ibm:'';
            dataRdtAuth['strNombre_SUCU'] = rdtEmploye[0]?rdtEmploye[0].strCodigoCentro:'';
            dataRdtAuth['strNombre_EMPR'] = rdtEmploye[0]?rdtEmploye[0].Contratista:'';
            dataRdtAuth['dtmFecha_RDT'] = rdtprov.dateRdt;
            dataRdtAuth['strNombredia'] = rdtprov.nombreDia;
            dataRdtAuth['IbmLaboro'] = rdtprov.ibm;
            dataRdtAuth['strFincaLaboro'] = rdtprov.nombreFinca;
            dataRdtAuth['strObservacion'] = observacion;
            dataRdtAuth['email_USUA'] = auth.data.email;
            dataRdtAuth['strRequestby'] = rdtEmploye[0]?'TRABAJADOR':'FINCA';
            dataRdtAuth['employeesId'] = auth.data._id;
            dataRdtAuth['email_AUTH'] = '';
            dataRdtAuth['authorizedId'] = auth.data._id;
            dataRdtAuth['strEstado'] = "Pendiente";
            const response = await requestHttp(
                {
                    endpoint: '/rdtsauth',
                    body: dataRdtAuth
                }
            ); 
            showAlert(
                'Solicitud Enviada', 
                "Correctamente", 
                SW_ICON.SUCCESS,
                () => { ocultarAuthorizationsRdt()}                       
            );
            
        } catch (error) {
            setAlert({
                msg: error.response.data,
                error: true
            })
            setTimeout(()=>{
                setAlert({})                
            }, 10000)
        }
    }
    //Mensaje de error
    const { msg } = alert;  

  return (
    <FormControlAuthorizationsRdt>
        <CerrarAuthorizationsRdt>
            <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarAuthorizationsRdt} />
        </CerrarAuthorizationsRdt>
        {animarModal?
            <FormularioAuthorizationsRdt>             
                <TittleAuthorizationsRdt>                   
                    SOLICITAR AUTORIZACIÓN RDT {rdtEmploye[0]?'"TRABAJADOR"':'"FINCA"'}
                </TittleAuthorizationsRdt>      
                <br />            
                { msg && <Alert alert = {alert} />}
            
                <form >
                    <AuthorizationsRdtDetailWrapper>
                        <AuthorizationsRdtDetailInfoWrapper>
                            <h2>{rdtEmploye[0]?rdtEmploye[0].strNombreTrabajador:''}</h2>
                            <AuthorizationsRdtDetailSubTitle>
                                {rdtEmploye[0]?rdtEmploye[0].strCodigoCentro:''}
                            </AuthorizationsRdtDetailSubTitle>
                            <h3>{rdtEmploye[0]?'Cod Trabajador: ' + rdtEmploye[0].IntCodigo:'Solicito Autorización para todos los trabajadores que realizaron labores en la finca: '} </h3> 
                        </AuthorizationsRdtDetailInfoWrapper>                   
                    </AuthorizationsRdtDetailWrapper>
                    <AuthorizationsRdtDetailDescriptionWrapper>
                        <AuthorizationsPropertyTypesContainerRow>
                            <AuthorizationsPropertyTypesContainerColum>
                                <AuthorizationsRdtDetailValueWrapper>
                                    {rdtEmploye[0]?'Laboró en:':''}
                                    <h2>{rdtprov.nombreFinca}</h2>
                                    <h3>Ibm: {rdtprov.ibm} </h3>
                                    <PropertyBusinessType
                                        busineesType={`Fecha del RDT: ${rdtprov.dateRdt?rdtprov.dateRdt:''} (${rdtprov.nombreDia})`}
                                    />                                
                                </AuthorizationsRdtDetailValueWrapper>
                                
                            </AuthorizationsPropertyTypesContainerColum>
                        </AuthorizationsPropertyTypesContainerRow>                        
                    </AuthorizationsRdtDetailDescriptionWrapper>                
                    <FormControl>                    
                        <FormControlInput>
                            <label>Motivo:</label>
                            <input  type='text' 
                                    placeholder={rdtEmploye[0]?'Cuéntanos el motivo por el cual el trabajador no logro registrarse...':
                                                            'Cuéntanos el motivo por el cual la finca no tenía los dispositivos faciales activos...'}                                   
                                    {...register("observacion")}
                                    value={observacion}
                                    onChange={e => setObservacion(e.target.value)}                                                                                                       
                            /> 
                            {errors.observacion?.type === 'required' && <span>Este Campo es requerido</span>}                           
                        </FormControlInput>                                          
                    </FormControl>                
                    <br />
                    <Button disabled={!isValid} label="Registrar Solicitud" onPress={onSubmitSolicitud} />
                    <br />
                </form>           
            </FormularioAuthorizationsRdt> 
        :''}         
    </FormControlAuthorizationsRdt>
  )
}


