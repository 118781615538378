import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import swal from 'sweetalert';
import { IoTrashOutline } from "react-icons/io5";
import { getToken, removeToken } from "../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import Alert from "../../components/Alert/Alert"
import { Button } from "../../components/Button";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { Page } from "../../components/Page";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { ButtonIconRemove } from "../../components/ButtonIconRemove";
import { FormControl, FormControlInput, SubTitle } from "../../globalStyles";
import { PropertyTypesContainerColum,  
         PropertyTypesContainerRow,         
         RdtsauthorizationDetailInfo,         
         RdtsauthorizationDetailTitle} from "./styles";
import { PropertyButtonWrapper, PropertyCardWrapper, PropertyCardWrapperTr, PropertyDescriptionWrapper, PropertyInfoWrapper } from "./PropertyCardRdts/styles";

const ALL_SUCURSALES = 0;
const fecha = Date.now();
const suma = 0; 
const usersAuth = [
                    'sistemasgc@grupocentral.co',
                    'analistasistemas@grupocentral.co',
                    'auxiliarsistemas@grupocentral.co',
                    'directoragricultura@grupocentral.co',
                    'asistenteagricultura@grupocentral.co',
                    'gcauditor@grupocentral.co',
                    'nomina@grupocentral.co',                    
                    'auxiliarnomina1@grupocentral.co',
                    'auxiliarnomina2@grupocentral.co',
                    'auxiliarnomina3@grupocentral.co',
                    'directoradministrativo@grupocentral.co'                    
                  ];

export const Rdtauthorizations = () => {

  const { auth, closeSeccionAuth } = useAuth();
  const { rdtprov, setRdtprov } = useRdt();  
  const [ sucursal, setSucursal ] = useState([]);
  const [ rdtsauth, setRdtsauth ] = useState([]);
  const [ sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);

  const [ codtrabajador, setCodtrabajador ] = useState('');  
  const [ fechaini, setFechaIni ] = useState('');
  const [ fechafin, setFechafin ] = useState('');

  const [duplicar, setDuplicar] = useState('B')
  const [check, setCheck] = useState(false)
  const [checkSelected, setCheckSelected] = useState([])
  
  const [alert, setAlert] = useState({}) 
  
  const navigate = useNavigate();

  const { 
    register,
    handleSubmit,     
    formState:{
        errors,
        isValid
    }  
  } = useForm({ 
        mode: 'onChange',
        defaultValues: {
          checkbox: []
        }      
    }); 

  //Validar dia de la semana
  const fechaComoCadena = fechaini.split('T')[0] +  " 00:01:59"; // día semana
  const fechaComoCadenaf = fechafin.split('T')[0] +  " 00:01:59"; // día semana
  const dias = [
      'DOMINGO',
      'LUNES',
      'MARTES',
      'MIERCOLES',
      'JUEVES',
      'VIERNES',
      'SABADO',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  const nombreDia = dias[numeroDia];

  //hoy
  const tiempoTranscurrido = Date.now(); 
  const fechai = new Date(fechaComoCadena);
  const fechaf = new Date(fechaComoCadenaf);
  const hoy = new Date(tiempoTranscurrido);
  const rango = new Date(hoy - fechai);
  const tiempo = new Date(189999999);
  //Verifico que sea un usuario permitido para autorizar
  const verUsersAuth = usersAuth.filter((item)=>item === auth.data.email)
    
  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return   
            await getSucursalesAll();                                          
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[auth])
  //Si estoy editando
  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return            
            await rdtprov.ibm?setSucursalSelected(rdtprov.ibm):console.log(''); 
            await rdtprov.dateRdt?setFechaIni((rdtprov.dateRdt).split('T')[0]):console.log('');                                
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
  },[rdtprov])
  //Selecciono sucursal
  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return 
            await getRdtsauth();                                               
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[sucursalSeleted])
  //Selecciono Fecha
  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
          const token = getToken();
            if(!token) return                               
            await getRdtsauth();  
            setCheck(false);           
            verUsersAuth.length > 0?setDuplicar('A'):setDuplicar('B');
            setCheckSelected([]);              
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[fechaini])
  //Busco por trabajador
  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                               
                       
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[codtrabajador])

  //Sucursales
  const handleChange = event => {
    setSucursalSelected(event.target.value)             
  }
  const handleChangeDate = event => {
    setFechaIni(event.target.value)             
  }
  //Sucursals
  const getSucursalesAll = async ()=>{
    try {            
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/sucursal',                         
                params: makeSucursalesFilters()                                     
            }
        );             
        const {data} = response;                
        setSucursal(data);                                   
    } catch (error) {
        console.log(error)
    }
  }
  const makeSucursalesFilters = ()=>{
    const filters = {};           
    return filters;       
  }
  //getRdtsauth
  const getRdtsauth = async ()=>{
    //console.log({sucursal: sucursalSeleted})
    if(!fechaini){
      setRdtsauth([])
      return
      //console.log('Fecha vacia')
    }
    
      try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/rdtsauth',                         
                params: makePropertiesFilters()                                     
            }
        );             
        const {data} = response;                
        data?setRdtsauth(data):console.log(''); 
        
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
        
          //console.log({hacerConsulta: sucursalSeleted})        
               
  }
  const makePropertiesFilters = ()=>{
    const filters = {};                
        filters['Fecha_INI'] = fechaini; 
        filters['Fecha_FIN'] = fechaini; 
        if(sucursalSeleted==='90021'){
          filters['IbmLaboro'] = '';   
        }else{
          filters['IbmLaboro'] = sucursalSeleted;   
        }       
        filters['IntCodigo_EMPL'] = codtrabajador;    
      //filters['employeesId'] = auth.data._id;     
      //console.log(fechaini);
    return filters;       
  }
  //Delete Autorizacion para registro de RDT
  const showAlertYN = (id, estado) =>{
    //Validar dia de la semana
    const fechaComoCadena = fechaini?fechaini.split('T')[0] +  " 00:01:59":console.log(''); // día semana  
    const fecha = new Date(fechaComoCadena);
    const numeroDia = new Date(fechaComoCadena).getDay();
    //hoy
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const rango = new Date(hoy - fecha);
    const tiempo = new Date(189999999);

    //console.log({rango: rango})
    //console.log({tiempo: tiempo})

    //Vallido que si este dentro de un rango permitido para duplicar
    //valido si dia es viernes o sabado                          
    if(numeroDia >= 5){
      //rango para el viernes de 4 dias.
      if(numeroDia == 5){                
          if(rango > 363599999){
              setAlert({
                  msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
                  error: true
              })
              //console.log({mensaje: alert.msg}); 
              setTimeout(()=>{
                  setAlert({})
              }, 7000)            
              return
          }
      }else{
          //rango para el sabado de 3 dias.                     
              if(rango > 276999999){
                  setAlert({
                      msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
                      error: true
                  })
                  //console.log({mensaje: alert.msg}); 
                  setTimeout(()=>{
                      setAlert({})
                  }, 7000)            
                  return
              }
      }
    }else{
      if(rango > 189999999){
      //if(rango > 276999999){
        setAlert({
            msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return
      }
    }
    //Valido si registro no esta autorizado
    if(estado === 'Autorizado'){
        setAlert({
            msg: 'Usted ya no puede eliminar este registro, porque ya fue autorizado',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return
    }
    //console.log({id: id})
    swal({
        title: "Esta usted seguro?",
        text: "Una vez eliminado, no podras recuperar este registro!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
        if (willDelete) {   
            rdtauthDelete(id)                
        } else {
        //swal("Su registro esta a salvo!");
        return
        }
        //console.log(willDelete)
    });
  }
  const rdtauthDelete = async (id) =>{             
    try {           
        const response = await requestHttp(
            {
                method: HTTP_VERBS.DELETE,
                endpoint: `/rdtsauth/${id}`,               
                token: getToken()               
            }
        );
        getRdtsauth()
        showAlert(
            'Autorización RDT', 
            "Eliminado Correctamente", 
            SW_ICON.SUCCESS              
        );                              
            
        } catch (error) { 
            if(error.response.status === 401){
                //hendleCloseSeccion();
            }else{
                showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
            }            
        }        
  } 
  //Auth request addRdts
  const handleAddAuth = async (data) => {
    //valido si dia es viernes o sabado                          
    if(numeroDia >= 5){
      //rango para el viernes de 4 dias.
      if(numeroDia == 5){                
          if(rango > 363599999){
              setAlert({
                  msg: 'Usted ya no puede realizar registros de Autorizaciones RDT para este dia',
                  error: true
              })
              //console.log({mensaje: alert.msg}); 
              setTimeout(()=>{
                  setAlert({})
              }, 7000)            
              return
          }
      }else{
          //rango para el sabado de 3 dias.                     
              if(rango > 276999999){
                  setAlert({
                      msg: 'Usted ya no puede realizar registros de Autorizaciones RDT para este dia',
                      error: true
                  })
                  //console.log({mensaje: alert.msg}); 
                  setTimeout(()=>{
                      setAlert({})
                  }, 7000)            
                  return
              }
      }
    }else{
      //Rango de 2 dias para registrar
      if(rango > 189999999){
      //if(rango > 276999999){
          setAlert({
              msg: 'Usted ya no puede realizar registros de Autorizaciones RDT para este dia',
              error: true
          })
          //console.log({mensaje: alert.msg}); 
          setTimeout(()=>{
              setAlert({})
          }, 7000)            
          return
      }
    } 
    //Valido si Fecha es mayor a la actual
    if(fechaf > hoy){
      setAlert({
          msg: 'Fecha no puede ser "mayor" a la fecha actual',
          error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return     
    }
    //console.log(data.checkbox.length)
    //console.log(data.checkbox)
    //Valido que este seleccionado una labor
    //console.log({size: checkSelected.length})
    if(!data.checkbox.length){
        setAlert({
            msg: 'Seleccione registros de los trabajadores para autorizar RDT',
            error: true
        })       
        setTimeout(()=>{            
            setAlert({})
        }, 7000)
        return           
    }           
        for (let i = 0; i < data.checkbox.length ; i++){                                                  
                const Id = rdtsauth[data.checkbox[i]]._id
                const dataRdt = {};                            
                dataRdt['email_AUTH'] = auth.data.email;                
                dataRdt['strEstado'] = 'Autorizado';
                
                //console.log({dataRdt: dataRdt}, Id)
                sendDataRdtauth(Id, dataRdt)                                                          
        }    
       
    
    showAlert(
        'Autorizado', 
        "Correctamente", 
        SW_ICON.SUCCESS,
        () => {navigate(getRdtsauth())}                       
    );
    
  }
  const sendDataRdtauth = async (id, dataRdt) =>{             
    try {           
      const response = await requestHttp(
        {
          method: HTTP_VERBS.PUT,
          endpoint: `/rdtsauth/${id}`,
          body: dataRdt,
          token: getToken()
        }
    );            
    } catch (error) {    
        //setIterations(false)            
        /*console.log(dataRdt.IntCodigo_EMPL,dataRdt.IntCodigo_CONC) 
        await setEmplInconsistencia({Empl: dataRdt.IntCodigo_EMPL, CONC: dataRdt.IntCodigo_CONC})       */
        showAlert('Error', `"Duplicado Incorrecto"`, SW_ICON.ERROR);
    }     
  }
  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
      closeSeccionAuth()
      removeToken()
      showAlert(
          'Inicie Seccion Nuevamente', 
          "Su token puede estar vencido", 
          SW_ICON.INFO,
          () => { navigate('/Login')}    
      );
  }
  //Mensajes 
  const { msg } = alert;
  return (
    <Page>       
        <RdtsauthorizationDetailTitle>
          CONSULTA DE AUTORIZACIONES RDTS
        </RdtsauthorizationDetailTitle> 
        { msg && <Alert alert = {alert} />}
        <form>
          <PropertyTypesContainerRow>            
            <PropertyTypesContainerColum>
                  <LogoImageWrapper>                                
                    <img src={"Logodos.jpg"} alt="imgLogo"/>                               
                  </LogoImageWrapper>                   
              </PropertyTypesContainerColum>            
              <PropertyTypesContainerColum>
                <FormControl>
                  <FormControlInput>
                          <label>FINCA REPORTADA</label>                    
                          <select id="sucursal"
                                      {...register("sucursal")}
                                      onChange={handleChange}
                                      value={sucursalSeleted}
                                      disabled={ auth.data.role != 1 && rdtprov.ibm != 90021?true:false}>
                                  <option value={""}>--Seleccione--</option>
                                  {sucursal.map(item=>(                                    
                                          <option key={item.strCodigo} 
                                                  value={item.strCodigo}                                                                                               
                                                  id={item.strCodigo}
                                      >{item.strNombre}</option>
                                      )
                                      )
                                  }  
                                                                                                                                                                              
                          </select>
                  </FormControlInput>
                </FormControl>  
                <FormControl>
                  <FormControlInput>
                              <label>FECHA DEL RDT</label>
                              <input  type="date"
                                      {...register("fechaini")} 
                                      onChange={handleChangeDate}
                                      value={fechaini}
                              />                            
                              {errors.fechaini?.type === 'required' && <span>Este Campo es requerido</span>}
                  </FormControlInput>
                </FormControl> 
              </PropertyTypesContainerColum>
              <PropertyTypesContainerColum>
              <RdtsauthorizationDetailInfo>
                <p> 
                      USU:<span>{auth.data ? auth.data.email : 'Usuario no registrado'}</span><br/>
                      IBM:<span>{sucursalSeleted}</span><br/>
                      FEC:<span>{fechaini}</span><br/><br/>
                      DIA:<span>"{nombreDia}"</span>                                                                                                                             
                </p> 
                </RdtsauthorizationDetailInfo>
            </PropertyTypesContainerColum>
            </PropertyTypesContainerRow>
        </form>
        <br/> 
        <table>
          <thead>      
            <tr>
              <th>Datos Trabajador</th>          
              <th>Solicitud por</th>                       
              <th>Observación</th>           
              <th>Ibm Laboro</th>   
              <th>Reporto</th>
              <th>Autorizo</th>              
              <th>Estado</th>
              {duplicar === 'A'? 
              <th>Seleccione</th>:''}
            </tr>          
          </thead>
          <tbody>
            {rdtsauth.map((item, key) => (                     
              <tr>              
                <th>                  
                  <PropertyCardWrapperTr>
                      <PropertyButtonWrapper>
                      {key}&nbsp;
                      <ButtonIconRemove icon={IoTrashOutline} onPress={()=>showAlertYN(item._id, item.strEstado)} />
                      </PropertyButtonWrapper>
                      <PropertyCardWrapper to={`/RdtauthDetail/${item._id}`}>                  
                        <PropertyInfoWrapper>
                          {item.strNombreTrabajador === ''?item.strFincaLaboro:item.strNombreTrabajador} 
                          <SubTitle>
                              <span>{item.strNombreTrabajador === ''?item.IbmLaboro:item.IntCodigo_EMPL}</span> - {item.strNombre_SUCU}
                          </SubTitle>
                        </PropertyInfoWrapper>               
                      </PropertyCardWrapper>
                  </PropertyCardWrapperTr>
                </th>                         
                
                <th>
                <PropertyDescriptionWrapper>
                    <p>{item.strRequestby}</p>
                  </PropertyDescriptionWrapper>
                </th>              
                <th>
                  <PropertyDescriptionWrapper>
                    {item.strObservacion}
                  </PropertyDescriptionWrapper>
                </th>
                <th>
                  <PropertyDescriptionWrapper>
                    {item.IbmLaboro === item.strCodigo_SUCU ?"":item.IbmLaboro}
                  </PropertyDescriptionWrapper>
                </th>
                <th>
                  <PropertyDescriptionWrapper>
                    {item.email_USUA}
                  </PropertyDescriptionWrapper>                  
                </th>
                <th>
                  <PropertyDescriptionWrapper>
                    {item.email_AUTH}
                  </PropertyDescriptionWrapper>                  
                </th>
                <th>
                  <PropertyDescriptionWrapper>
                  <p>{item.strEstado}</p>
                  </PropertyDescriptionWrapper>
                </th>                
                {item.strEstado === 'Pendiente' && duplicar === 'A'? 
                  <th>
                    <input {...register("checkbox")} 
                            type="checkbox"                        
                                         
                            value={key} /> 
                  </th>:''}                                                                                            
              </tr>                    
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>T.REG: {rdtsauth.length}</th>            
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>              
              {duplicar === 'A'? 
                <th>
                  <FormControl>
                    <Button label="AUTORIZAR" onPress={handleSubmit(handleAddAuth)} />                 
                  </FormControl>
                </th>:''}            
            </tr>
          </tfoot>
        </table>
    </Page>
  )
}