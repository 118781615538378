import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SignatureCanvas from 'react-signature-canvas';
import { useRdt } from "../../../../hooks/useRdt";
import { SubTitle } from "../../../../globalStyles"
import { PropertyButtonWrapper, PropertyCardWrapper, 
    PropertyCardWrapperTr, 
    PropertyDescriptionWrapper, 
    PropertyImageWrapper, 
    PropertyInfoWrapper, 
    PropertyValueWrapper } from "./styles"
import { ButtonIconRemove } from "../../../../components/ButtonIconRemove"
import { IoTrashOutline, 
        IoRemoveCircleOutline,
        IoRadioButtonOffOutline,
        IoArrowBackCircleOutline,
        IoArrowBack,
        IoDuplicate,
        IoAddCircleOutline,
        IoSave, 
        IoSend} from "react-icons/io5";
import swal from 'sweetalert';
import { HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";
import { getToken } from "../../../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../../../utils/swAlert";

export const PropertyTransports = (item) => {
    //Para firmar doc PDF
    const signatureRef = useRef(null);

    const { setReload } = useRdt();

    const navigate = useNavigate();

    //Validar dia de la semana
    const fechaComoCadena =  item.dtmFecha.split('T')[0]; // día semana
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];

    const fecha = new Date(fechaComoCadena);
    //hoy
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    const rango = new Date(hoy - fecha);
    
    //Delete transport
    const showAlertYN = (id) =>{
        //console.log({id: id})
        //Vallido que si este dentro de un rango permitido para eliminar
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);      
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                        showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);             
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){                
                showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);  
                return
            }
        }
        swal({
            title: "Esta usted seguro?",
            text: "Una vez eliminado, no podras recuperar este registro!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {   
                transportDelete(id)
                setReload(true)                 
            } else {
            //swal("Su registro esta a salvo!");
            return
            }
            //console.log(willDelete)
        });
    }
    const transportDelete = async (id) =>{             
        try {           
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.DELETE,
                    endpoint: `/transports/${id}`,               
                    token: getToken()               
                }
            );
            setReload(true)   
            showAlert(
                'Transporte', 
                "Eliminado Correctamente", 
                SW_ICON.SUCCESS,   
                () => { navigate('/AddTransport')}                 
            );                              
                
            } catch (error) { 
                if(error.response.status === 401){
                    //hendleCloseSeccion();
                }else{
                    showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
                }            
            }        
    } 
    //Generar PDF Transporte
    const handlePrintPDF = () => {
        //const { data } = item; // Asume que tienes los datos de la remisión en 'data'
    
        // Crea un nuevo documento PDF
        const doc = new jsPDF();                
        // Agrega el logo al PDF (reemplaza 'url_del_logo' con la URL o el recurso de tu logo)
        const logoData = 'Logodos.jpg'; // Reemplaza con la URL o recurso de tu logo
        doc.addImage(logoData, 'JPG', 160, 10, 20, 20);

    
        // Agrega contenido al PDF
        doc.setFontSize(12);        
        doc.text('Transporte No: ' + item.idTransport, 155, 35);        
        
        // Mueve la posición Y hacia abajo para evitar que la tabla cubra el texto
        const tableOptions = {
            startY: 30,
            head: [['Transportadora','Fecha']],
            body: [[item.conductor, fechaComoCadena]],
            tableWidth: 80, // Ajusta el alto de la tabla según sea necesario
            headStyles: { fillColor: [255, 0, 0], textColor: 255, fontStyle: 'bold', cellWidth: 'wrap', halign: 'center', valign: 'middle', angle: 90 },
            bodyStyles: { fontStyle: 'bold', cellWidth: 'wrap', halign: 'center', valign: 'middle', angle: 90 },
        };        
        const tableOptionsdos = {
            startY: 35,
            head: [['Placa']],
            body: [[item.placa]],
            tableWidth: 50, // Ajusta el alto de la tabla según sea necesario
            headStyles: { fillColor: [255, 0, 0], textColor: 255, fontStyle: 'bold', cellWidth: 'wrap', halign: 'center', valign: 'middle', angle: 90 },
            bodyStyles: { fontStyle: 'bold', cellWidth: 'wrap', halign: 'center', valign: 'middle', angle: 90 },
        };

        doc.autoTable(tableOptions);
        //doc.autoTable(tableOptionsdos);
        //doc.autoTable(tableOptionstres);        
       
        doc.autoTable({ head: [['Origen', 'Destino', 'Ciclo','Vehiculo', 'Cant Personas']], body: [[item.origen, item.strFinca, item.ciclo, item.placa, item.cantidad]], startY: 50 });
        
        //Observación
        doc.text('Observación:', 20, 75);
        // Agregar el campo de observación
        const maxTextWidth = 160; // Ancho máximo del área de texto
        const maxTextHeight = 50; // Altura máxima del área de texto
        const lines = doc.splitTextToSize(item.Observacion, maxTextWidth);
        //doc.text('', 20, 82); // Iniciar un nuevo bloque de texto
        //doc.setFontStyle('justify'); // Establecer justificación de texto
        if (lines.length * 12 > maxTextHeight) {
            // Si el texto es demasiado largo para el área, mostrar un mensaje o realizar alguna acción.
            console.error('El texto es demasiado largo para el área especificada.');
        } else {
            // Agregar el texto línea por línea
            lines.forEach((line, index) => {
            doc.text(line, 20, 82 + index * 6);
            });
        }
        //doc.setFontStyle('normal'); // Restaurar estilo de texto normal
        //doc.text('Observación: ' + item.Observacion, 20, 80);
        // Agrega alguna firma escaneada al PDF
        //const firma = 'FirmaJ.jpg'; // Reemplaza con la URL o recurso de tu logo
        //doc.addImage(firma, 'JPG', 50, 128, 20, 20);
        //Firma de PDF
        // Puedes acceder a la firma capturada a través de signatureRef.current.toDataURL()
        const signatureData = signatureRef.current.toDataURL();
        doc.addImage(signatureData, 'PNG', 45, 128, 50, 20);
        //console.log('Firma capturada:', signatureData);      
        
        doc.text('Responsable: ' + item.employeesId.name, 20, 150);        
        //Fecha impresión
        doc.text(`F.Creación: ${new Date(item.createdAt).toLocaleDateString()}`, 140, 150);
        //Fecha impresión
        doc.text(`F.Impresión: ${new Date().toLocaleDateString()}`, 140, 170);
        
        
        // Descarga el PDF
        doc.save(`transporte_${item.idTransport}.pdf`);
    }
    const handleClear = () =>{
      signatureRef.current.clear();
       //console.log(signatureRef)
    }
    /*
        <button onClick={handlePrintPDF}>PDF</button>
        <button onClick={handleClear}>Limpiar Firma</button>
        <SignatureCanvas
            ref={signatureRef}
            canvasProps={{ width: 300, height: 200, className: 'signatureCanvas' }}            
        />
    */

  return (
    <PropertyCardWrapperTr>
            <PropertyCardWrapper to={`/TransportDetail/${item._id}`}>               
                <PropertyInfoWrapper>
                    Transporte:
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.origen} - {item.strFinca}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fecha:&nbsp; {fechaComoCadena}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Personas:&nbsp;<span>{item.cantidad}</span><br/>
                    <SubTitle>                            
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ciclo:&nbsp;<span>{item.ciclo}</span>                                                        
                    </SubTitle>
                    <br/>
                    Empresa:
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.conductor}
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Placa:&nbsp;{item.placa}
                    <br/>
                    <br/>Observación:&nbsp;{item.Observacion}
                </PropertyInfoWrapper>               
            </PropertyCardWrapper>
            <PropertyButtonWrapper>                    
                <ButtonIconRemove icon={IoTrashOutline} onPress={()=>showAlertYN(item._id)} />
            </PropertyButtonWrapper>
                     
        </PropertyCardWrapperTr>
  )
}