import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Page } from "../../components/Page";
import { PropertyProfile } from "./components/PropertyProfile";
import { PropertyTypeButton } from "./components/PropertyTypeButton";
import { Home_Global_DOS, Home_Global_TRES, PropertyTypesContainer, 
         PropertyTypesContainerHead, 
         PropertyTypesContainerWelcome } from "./styles";
import { IoBusiness, IoExit, IoPersonCircleOutline, 
         IoLocation, IoRainy } from "react-icons/io5";
import { PropertyWelcome } from "./components/PropertyWelcome";
import { HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { PropertyCard } from "./components/PropertyCard";
import { PropertyCardRetired } from "./components/PropertyCardRetired";
import { PropertyCardNobiometric } from "./components/PropertyCardNobiometric";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { removeToken } from "../../utils/TokenLS";
import { PropertyGrafDonaChartanio } from "../AddPrecipitation/components/PropertyGrafDonaChartanio";

const ALL_PROPERTIES_TYPE = 0;

const PropertiesTypes = [
    { id: 5, icon: IoRainy, label:'Precipitación'},
    { id: 1, icon: IoBusiness, label:'Traslados'},
    { id: 4, icon: IoLocation, label:'Prestamos'},  
    { id: 2, icon: IoExit, label:'Retiros'},
    { id: 3, icon: IoPersonCircleOutline, label:'Sin Biometria'},
    
    
];

export const Home = () => {
    const [propertyTypeSeleted, setPropertyTypeSelected] = useState(ALL_PROPERTIES_TYPE);
    const [ traslates, setTraslates ] = useState([]);
    const [ retireds, setRetireds ] = useState([]);
    const [ nobiometrics, setNobiometrics ] = useState([]);
    const { auth, closeSeccionAuth } = useAuth();       
    const [ estado, setEstado ] = useState(false)  

    const navigate = useNavigate();
    
    const Cargar = ()=>{
        setTimeout(()=>{                                  
             setEstado(true)                        
        }, 300)           
    }

    const propertyTypeHandler = (id)=>{
        setPropertyTypeSelected(propertyTypeSeleted === id 
            ? ALL_PROPERTIES_TYPE : id);        
    }

    useEffect(() => {     
        if(!estado){
            Cargar()
        }else if(auth.data){
            if(propertyTypeSeleted === 1){
                getTraslatesAll()
                setRetireds([])
                setNobiometrics([])                
            }else if(propertyTypeSeleted === 2){
                getRetiredsAll()
                setTraslates([])
                setNobiometrics([])
            }else if(propertyTypeSeleted === 3){
                getNobiometricsAll()
                setTraslates([])
                setRetireds([])
            }else if(propertyTypeSeleted === 4){
                getTraslatesAll()
                setRetireds([])
                setNobiometrics([])  
            }else if(propertyTypeSeleted === 5){                
                setRetireds([])
                setTraslates([])
                setNobiometrics([]) 
                { navigate('/Precipitations')}                
            }else{
                setRetireds([])
                setTraslates([])
                setNobiometrics([])                    
            }                       
        }

    },[propertyTypeSeleted])
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }
    //getTraslates
    const getTraslatesAll = async ()=>{
        
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/traslates',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;
            //console.log({respuesta: response})                
            setTraslates(data);                   
        } catch (error) {            
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }            
        }        
    }
    //getRetireds
    const getRetiredsAll = async ()=>{
        
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/retiros',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;                
            setRetireds(data);                   
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
        
    }
    //getNobiometrics
    const getNobiometricsAll = async ()=>{
        
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/nobiometrics',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;                
            setNobiometrics(data);                   
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
        
    }
    const makePropertiesFilters = ()=>{
        const filters = {};         
            const fecha = '2022-07-23';
            let Motivo;
            if(propertyTypeSeleted === 1){
                Motivo = 'Traslado'
            }else if( propertyTypeSeleted === 4){
                Motivo = 'Prestamo'
            }
                        
            filters['strMotivo'] = Motivo;
            if(auth.data.role === 1){
                filters['dtLastMonth'] = fecha;                
            }else{
                filters['dtLastMonth'] = fecha;       
                filters['employeesId'] = auth.data._id;     
            }
            
           
           // console.log(filters);
        return filters;       
    }
    
    
    
    return(
    <Page>               
        <PropertyTypesContainerHead>
            <PropertyProfile
                lblhrc={"Logo.jpg"}
                lblNameClient = {auth.data ? auth.data.name : 'Usuario no registrado'}
            />                    
        </PropertyTypesContainerHead>
                
        <PropertyTypesContainerWelcome>
            <PropertyWelcome />            
        </PropertyTypesContainerWelcome>
                         
        <PropertyTypesContainer>             
            {          
                PropertiesTypes.map((item, key) => 
                    <PropertyTypeButton 
                        selected={propertyTypeSeleted === item.id}
                        icon={item.icon} 
                        label={item.label}
                        id={item.id}
                        key={key}
                        onPress={propertyTypeHandler}

                    /> )
            }
        </PropertyTypesContainer>
        <Home_Global_TRES>
            {traslates.map((item, key) => 
                
                    <Home_Global_DOS
                        basis='25%'
                    >
                        <PropertyCard 
                            key={key}                   
                            {...item}                
                        />
                    </Home_Global_DOS>
                )
            }

            {retireds.map((item, key) => 
                <Home_Global_DOS
                basis='25%'
                >   
                    <PropertyCardRetired
                        key={key}                   
                        {...item} 
                        propertyTypeSeleted               
                    />
                </Home_Global_DOS>
                )
            }

            {nobiometrics.map((item, key) => 
                <Home_Global_DOS
                basis='25%'
                >
                    <PropertyCardNobiometric
                        key={key}                   
                        {...item} 
                        propertyTypeSeleted               
                    />
                </Home_Global_DOS>
                )
            }
            
        </Home_Global_TRES>
    </Page>
    
)};