import { SubTitle } from "../../../../globalStyles"
import { PropertyCardWrapper, 
    PropertyCardWrapperTr, 
    PropertyDescriptionWrapper, 
    PropertyImageWrapper, 
    PropertyInfoWrapper, 
    PropertyValueWrapper } from "./styles"

export const PropertyCardRdtsDuplicate = (item) =>{    

    return (
    
      
        <PropertyCardWrapper to={`/RdtDetail/${item._id}`}>                         
            <PropertyInfoWrapper>
                {item.email_USUA}&nbsp;&nbsp;                
                      <SubTitle>                                                                                               
                        <span color="blanchedalmond">
                            Labor:&nbsp;{item.IntCodigo_CONC}
                        </span>&nbsp;&nbsp;&nbsp;
                        <span>{item.strConcepto}</span>&nbsp;&nbsp;                         
                        ({item.strNombre_UDP}:&nbsp;<span>{item.intCantidad_UDP}</span>&nbsp;
                         {item.strNombre_UDPdos}:&nbsp;<span>{item.intCantidad_UDPdos}</span>&nbsp;
                         {item.strNombre_UDPtres}:&nbsp;<span>{item.intCantidad_UDPtres}</span>&nbsp;
                         {item.strNombre_UDPcuatro}:&nbsp;<span>{item.intCantidad_UDPcuatro}</span>&nbsp;)
                         {item.strObservacion_RDT}                        
                    </SubTitle>
                    Horas:&nbsp;{item.intHora_RDT}&nbsp;&nbsp; 
                    Cant:&nbsp;{(item.intTotal_RDT).toFixed(2)}
            </PropertyInfoWrapper>               
        </PropertyCardWrapper>
            
          
          
    )
}