import { Link } from "react-router-dom";
import styled, {css} from "styled-components";

export const FormControlAnalysesRdt = styled.div` 
    //border: 1px solid orange;   
    display: flex;
    justify-content: center;
    position: absolute;
    background-color: rgb(0 0 0 / 0.92);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
`;

export const CerrarAnalysesRdt = styled.div`
    position: absolute;
    right: 3rem;
    top: 3rem;
    width: 3rem;
    height: 3rem;
`;

export const FormularioAnalysesRdt = styled.div`
    //border: 1px solid blue;      
    max-width: 1920px;
    width: auto;
    height: auto;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in;     
    opacity: 1;

 
    
`;

export const AnimarAnalysesRdt = styled.div` 
    opacity: 1;
`;

export const TittleAnalysesRdt = styled.h1`
    font-size: 2.2em;
    //color: #222;
    color: #fff;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;

    @media (max-width: 820px){
            font-size: 1.6em;
            margin-top: 100px;
            margin-bottom: 0px;
            padding: 0 2px;            
            justify-content: center;
            width: 100%;
    }    
`;

export const AnalysesRdtDetailWrapper = styled.div`
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px;    
    display: flex;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    margin: 10px 0;
    background-color:white;
    text-decoration: none;    
`;

export const AnalysesRdtDetailInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin-left: 10px;
    font-size: 1em;
    h2{
        font-size: 1.5em;
        margin: 0;
        color:#6262A0;
    }
    h3{
        
        margin: 0;
        color:#6262A0;
    }        
`;

export const AnalysesRdtDetailSubTitle = styled.div`
    //border: 1px solid red;
    color: #C0C2D2;
    font-size: 1.2em;
    text-transform: capitalize;
    margin: 0;
    margin-top: 1px;
`;

export const AnalysesRdtDetailDescriptionWrapper = styled.div` 
    border-radius: 20px;
    color: #6262A0;
    font-weight: bold; 
    font-size: 1.0em;   
    padding-bottom: 10px;    
    p{
        color: #6262A0;
        font-weight: bold; 
        font-size: 1em;   
    }
`;

export const AnalysesPropertyTypesContainerRow = styled.section`
    //border: 1px solid red;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin: 10px 0;
    background-color: #F2F2F2;
    display: flex;
    flex-direction: row;   
    //width: auto;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);   
`;

export const AnalysesPropertyTypesContainerColum = styled.section`
    //border: 1px solid yellow;
    display: flex;
    border-radius: 20px;
    background-color: white;
    margin-right: 20px;
    padding: 15px;
    width: auto;
    label{
        display: block;
        font-size: 0.70em;
        color: blue;
        font-weight: bold;          
        p{
            color: black;              
        }                    
    }
`;

export const AnalysesRdtDetailValueWrapper = styled.div`
    color: #222;
    font-size: 1em;
    h2{
        font-size: 1.2em;
        margin: 0;
        color:#6262A0;
    }
    h3{        
        margin: 0;
        color:#6262A0;
    }
`;

//
export const PropertyTableContainer = styled.div`
  //max-height: 920px; /* Establece la altura máxima para la tabla y habilita el desplazamiento vertical */
  overflow-y: auto; /* Habilita el desplazamiento vertical si el contenido supera la altura máxima */
  position: relative; /* Necesario para el posicionamiento relativo del encabezado */
  margin-left: 10px;
  width: 100%;
  //height: 900px;
`;

export const PropertyTable = styled.table`
  //border: 1px solid yellow;
  //padding:10px;
  //margin-top: 10px;
  width: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const PropertyTableHeader = styled.th`
  
  background-color: #26794f;
  background-color: ${props => props.backg};
  color: white;
  text-align: center;
  padding: 4px;
  size:10px;
  //position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
  &:first-child {
    left: 0; /* Fija el primer encabezado de columna en la parte izquierda */
  }
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 3px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    span{
      padding: 3px;
      background-color: #a0d995;
      color: black;
      //text-decoration-line: underline;
      //font-weight: bold;
    }
`;

export const PropertyTableHeaderRow = styled.tr`
  background-color: #b0c2f2;
  position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
  font-size: 14px;
`;

export const PropertyTableRow = styled.tr`
  &:nth-child(even) {
    background-color: white;
  }  
  font-size: 11px;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        font-size: 10px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        font-size: 10px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableCell = styled.td`
  border: 1px solid #ddd;
  padding: 0;
  text-align: center;
  font-size: 12px;
  color: black;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 0;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 0;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    span{
      color: blue;
      font-weight: bold;
    }
    P{
      font-weight: bold;
    }
`;

export const PropertyTableCellBH = styled.td`
  border: 1px solid #ddd;
  padding: 0;
  text-align: center;
  font-size: 9px;
  color: black;
  background-color: #f2f2f2;
  font-weight: bold;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 0;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 0;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    span{
      color: blue;
      font-weight: bold;
    }
    P{
      font-weight: bold;
    }
`;

export const PropertyTableCellTotal = styled.td`
  border: 1px solid #ddd;
  padding: 4px;
  text-align: right;
  color: black;
  font-weight: bold;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 3px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

const BaseButtonStyle = css`
    width: 100%;
    font-size: 1em;
    color: blue;
    //text-decoration-line: underline;
    font-weight: bold;
    //background-color: #4A148C;
    background-color: transparent;//verde osc
    //background-color: #ff8c00;//Naranja
    text-align: right;
    border: none;
    //border-radius: 6px;
    //padding: 5px 5px;
    //opacity: 0.9;
    cursor: pointer;    
    
    &hover{        
        opacity: 1;
    }      
`;

export const ButtonAplicate= styled.button`
     ${BaseButtonStyle}
    
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }      
`;