
import styled from "styled-components";

export const PropertyTypeLabelWrapper = styled.div`
    background-color: #126EFD;
    color:#fff;
    border-radius: 3px;
    margin: 8px 0;
    display: inline-block;
    padding: 3px;
    p{
        color: #fff;
        margin: 0px;
        font-size: 0.78em;
        font-weight: bold;
        
    }
`