import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRdt } from "../../../hooks/useRdt";
import { useAuth } from "../../../hooks/useAuth";
import { HTTP_VERBS, requestHttp } from "../../../utils/HttpRequest";
import { showAlert, SW_ICON } from "../../../utils/swAlert";
import { getToken, removeToken } from "../../../utils/TokenLS";
import { Page } from "../../../components/Page";
import { FormControl, 
         FormControlInput,
         FormControlAction, 
         FormControlInputSearch, 
         FormControlInputSearchudp, 
         FormControlInputudp, 
         PropertyTypesContainerColum, 
         PropertyTypesContainerRow, 
         RdtDetailDescriptionWrapper, 
         RdtDetailInfoWrapper, 
         RdtDetailSubTitle, 
         RdtDetailTitle, 
         RdtDetailValueWrapper, 
         RdtDetailWrapper, 
         RdtDetailWrapper_Global, 
         RdtDetailWrapper_Global_DOS, 
         LogoImageWrapper,
         FormControlActionOne,
         FormControlActionTwo} from "./styles";
import { PropertyBusinessType } from "../../../components/PropertyBusinessType";
import { PropertyTypeLabel } from "../../../components/PropertyTypeLabel";
import { ButtonIcon } from "../../../components/ButtonIcon";
import { IoTrashOutline, 
         IoRemoveCircleOutline,
         IoRadioButtonOffOutline,
         IoArrowBackCircleOutline,
         IoArrowBack,
         IoDuplicate,
         IoSendSharp,
         IoSave } from "react-icons/io5";
import swal from 'sweetalert';
import { ButtonIconRemove } from "../../../components/ButtonIconRemove";
import { PageTitle } from "../../../globalStyles";
import Alert from "../../../components/Alert/Alert";

import { ButtonIconUpdateAll } from "../../../components/ButtonIconUpdateAll";

const ALL_SUCURSALES = 0;
const HORA = 10;

export const RdtnewsDetail = () => {

  const { auth, closeSeccionAuth } = useAuth();
  const { setRdtprov } = useRdt();
  const { id } = useParams();

  const [ rdt, setRdt ] = useState([]);    
  const [ conceptoSeleted, setConceptoSelected] = useState('');
  const [ sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);   
  const [ sumcantidad, setSumcantidad ] = useState(0);    
  const [ horas, setHoras ] = useState(0);
  const [ observacion, setObservacion ] = useState('');
  const [ date, setDate ] = useState('');

  const [ rdtstrabajador, setRdtstrabajador ] = useState([]);
  const [ concepto, setConcepto ] = useState([]);  
  const [ conceptos, setConceptos ] = useState([]);

  const [alert, setAlert] = useState({})    

  const navigate = useNavigate();

  const { 
    register, 
    handleSubmit,
    formState:{
        errors,
        isValid
    }  
  } = useForm({ mode: 'onChange'});    

  useEffect(() => {  
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return   
            await getRdt()            
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();              
},[auth])
//Conceptos       
useEffect(() => {
  const getData = async ()=>{
      try {
          const token = getToken();
          if(!token) return           
          conceptoSeleted? getConcepto() : console.log('');                              
      } catch (error) {
          console.log(error)
      }             
  }    
  getData();                     
},[conceptoSeleted])
//Concepts
const getConcepto = async ()=>{
    //console.log({concepto: makeConceptoFilters()})
    if(rdt){
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/conceptossucursal/allconceptos',                         
                    params: makeConceptoFilters()                                     
                }
            );             
            const {data} = response; 
            //console.log({datos: data})   
            data ? setConceptos(data):console.log('');                                                                                                    
        } catch (error) {
            console.log(error)
        }
        
    }else{
      console.log('');
    } 
}        
const makeConceptoFilters = ()=>{
    const filters = {};  
    filters['IDGrupo'] = 203;                  
    //console.log(filters);         
    return filters;                       
}
//Close session if token expired
const hendleCloseSeccion = async ()=>{
  closeSeccionAuth()
  removeToken()
  showAlert(
      'Inicie Seccion Nuevamente', 
      "Su token puede estar vencido", 
      SW_ICON.INFO,
      () => { navigate('/Login')}    
  );
}
//Cod Labor
const handleChangeCodLabor = event => {
    setConceptoSelected(event.target.value)                    
  }
//RdtById
const getRdt = async ()=>{
     
  try {                      
     const response = await requestHttp(
        {
              method: HTTP_VERBS.GET,
              endpoint: `/rdtsnews/${id}`                                                                       
        }
     );             
     const {data} = response;                
     data?setRdt(data):console.log(''); 
     data?setConceptoSelected(data.IntCodigo_CONC):console.log('')  
     data?setSucursalSelected(data.IbmLaboro):console.log('')  
     data?setSumcantidad(data.intTotal_RDT):console.log('')  
     data?setHoras(data.intHora_RDT):console.log('')  
     data?setObservacion(data.strObservacion_RDT):console.log('')  
     data?setDate(data.dtmFecha_RDT):console.log('')  

     if(data.IbmLaboro === auth.data.ibm && auth.data.role != 1){
        const rdtData = {
            ibm: data.IbmLaboro,
            dateRdt: data.dtmFecha_RDT
        };
        data?setRdtprov(rdtData):console.log('')  
        //console.log({rdtData: rdtData})
     }else{
        const rdtData = {
            ibm: auth.data.ibm,
            dateRdt: data.dtmFecha_RDT
        };
        data?setRdtprov(rdtData):console.log('')  
        //console.log({rdtData: rdtData})
     }
     
     
          
  } catch (error) {
       if(error.response.status === 401){
           hendleCloseSeccion();
       }else{
           console.log(error)
       } 
  }

}
//Update
const onSubmitsave = async (data) =>{
  //Validaciones
        //Vallido que si este dentro de un rango permitido para actualizar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de novedades RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                        setAlert({
                            msg: 'Usted ya no puede realizar registros de novedades RDT para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){
                setAlert({
                    msg: 'Usted ya no puede realizar registros de RDT novedades para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }    
  //Validaciones
  let sumaH = 0;
  //Valido Fecha
  if(fecha > hoy){
      setAlert({
          msg: 'Fecha no puede ser "mayor" a la fecha actual',
          error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return     
  }
  //Empleado
  if(!rdt.IntCodigo_EMPL){
      setAlert({
          msg: 'Se requiere codigo del trabajador',
          error: true
      }) 
      //console.log({mensajeincludes: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return    
  }
  //Sumar horas reportadas al trabajador
  if(rdtstrabajador[0]){            
      const lista = rdtstrabajador.map(item => ({
          sumaHoras: Math.floor(item.intHora_RDT)
         }));                                   
         sumaH = lista.reduce((prev, next) => prev + next.sumaHoras, 0);

         //console.log({lista: lista});   
         //console.log({horas: sumaH})
  } 
  //Cod Labor      
  if(!rdt.IntCodigo_CONC){
      setAlert({
          msg: 'Se requiere codigo de la labor realizada',
          error: true
      }) 
      //console.log({mensajeincludes: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return    
  }       
  //Horas
  //Viernes
  if(dias[numeroDia] === 'VIERNES'){  
      const acumHoras = (Number(sumaH) - Number(rdt.intHora_RDT)) + Number(horas);
      //console.log({acumuladoHoras: acumHoras})
      if(Number(acumHoras) > Number(8)){
          setAlert({
              msg: 'Horas no pueden superar el max de "8 horas" para el trabajador en el día',
              error: true
          })
          console.log({mensaje: alert.msg}); 
          setTimeout(()=>{
              setAlert({})
          }, 7000)            
          return   
      }           
  } 
  //Sabado y Domingo
  if(dias[numeroDia] === 'SABADO' || dias[numeroDia] === 'DOMINGO'){
      if(Number(horas) > Number(0)){
          setAlert({
              msg: 'Hora debe ser "0 horas" para el trabajador los dias sabado ó domingo',
              error: true
          })
          //console.log({mensaje: alert.msg}); 
          setTimeout(()=>{
              setAlert({})
          }, 7000)            
          return   
      }
  }
  //Lunes-Jueves       
  const acumHoras = (Number(sumaH) - Number(rdt.intHora_RDT)) + Number(horas); 
  
  setAlert({});                 
  try {
      const fecha = Date.now();            
      const dataRdt = {};      
      dataRdt['IntCodigo_CONC'] = conceptoSeleted;
      //Nombre Ausencia
      let ausencia = '';
      for (let i = 0; i < conceptos.length ; i++){            
           if(conceptoSeleted === conceptos[i].IntCodigo){
            ausencia = conceptos[i].strConcepto
           }                             
      }      
      dataRdt['strConcepto'] = ausencia;      
      dataRdt['strObservacion_RDT'] = observacion;            
      dataRdt['dtmFechaModifica'] = fecha;
      
      const response = await requestHttp(
          {
            method: HTTP_VERBS.PUT,
            endpoint: `/rdtsnews/${id}`,
            body: dataRdt,
            token: getToken()
          }
      );            
      /*setAlert({
          msg: response.data,
          error: false
      })*/
      await getRdt();
      showAlert(
          'Actualizado', 
          "Correctamente", 
          SW_ICON.SUCCESS,   
          () => { navigate('/addrdtsnews')}               
      );           
                                 
  } catch (error) {
      //console.log(error)
      setAlert({
          msg: error.response.data,
          error: true
      })
      setTimeout(()=>{
          setAlert({})                
      }, 10000) 
  }     
      
}
//Delete RDT
const showAlertYN = () =>{
  //Vallido que si este dentro de un rango permitido para eliminar
  if(numeroDia >= 5){
    //rango para el viernes de 4 dias.
    if(numeroDia == 5){                
            if(rango > 363599999){
                setAlert({
                    msg: 'Usted ya no puede eliminar registros de novedades RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }else{
            //rango para el sabado de 3 dias.                     
                if(rango > 276999999){
                    setAlert({
                        msg: 'Usted ya no puede eliminar registros de novedades RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
        }
    }else{
        //Rango de 2 dias para registrar
        if(rango > 189999999){
            setAlert({
                msg: 'Usted ya no puede eliminar registros de RDT novedades para este dia',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return
        }
    }  
  swal({
      title: "Esta usted seguro?",
      text: "Una vez eliminado, no podras recuperar este registro!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {   
          rdtDelete()         
       /*   swal("El registro ha sido eliminado correctamente!", {
          icon: "success",
                      
        });*/           

      } else {
        //swal("Su registro esta a salvo!");
        return
      }
      //console.log(willDelete)
    });
}
const rdtDelete = async () =>{             
  try {           
      const response = await requestHttp(
          {
              method: HTTP_VERBS.DELETE,
              endpoint: `/rdtsnews/${id}`,               
              token: getToken()               
          }
      );   
      showAlert(
          'NOVEDAD RDT', 
          "Eliminado Correctamente", 
          SW_ICON.SUCCESS,   
          () => { navigate('/addrdtsnews')}                 
      );                              
          
      } catch (error) { 
          if(error.response.status === 401){
              hendleCloseSeccion();
          }else{
              showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
          }            
      }      
 
}
//focus
function FocusOnInput(){
    document.getElementById("input1").focus();
}
  // Colección de inputs con índices de (0 a 3)
      // 0 es la posición de #input1, 3 es la posición de #input4
      let inputs = document.querySelectorAll('[id^="input"]');
      // Asignar evento para ejecutar función
      inputs.forEach(input => input.addEventListener('keypress', nextFocus));
  
// Recibe evento como parámetro
      function nextFocus(e) {
          if(e.keyCode == 13){
              // e.target es el elemento que lanzó el evento
              // Obtener número desde ID, eliminando la palabra input (1 a 4)
              let actual = parseInt(e.target.id.replace('input', ''));
              // Solo si no es el último
              if(actual < inputs.length) {
                  // Evitar comportamiento normal del evento
                  e.preventDefault();
                  // Activar siguiente campo
                  inputs[actual].focus();
                  //console.log({actual: actual})                
              }
          }
      }
  //Validar dia de la semana
  const fechaComoCadena = date.split('T')[0] +  " 00:01:59"; // día semana
  const dias = [
      'DOMINGO',
      'LUNES',
      'MARTES',
      'MIERCOLES',
      'JUEVES',
      'VIERNES',
      'SABADO',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  const nombreDia = dias[numeroDia];
  
  const fecha = new Date(fechaComoCadena);
  //hoy
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  
  const rango = new Date(hoy - fecha);
  const tiempo = new Date(189999999);
  
  //console.log({rango: rango})
  //console.log({tiempo: tiempo})
  //console.log("Fecha actual: ", hoy); 
  //console.log("Calendario: ", fecha); 
  //console.log("NoDia: ", numeroDia);    
  //console.log("nombre dia: ", nombreDia); 
  

const { msg } = alert;

  return (
   <Page>
      <RdtDetailWrapper_Global>
          <RdtDetailWrapper_Global_DOS>
              <LogoImageWrapper>
                <PageTitle>
                  EDITAR NOVEDAD
                </PageTitle>
                <img src={"../Logodos.jpg"} alt="imgLogo"/>
              </LogoImageWrapper>
              { msg && <Alert alert = {alert} />}
            <RdtDetailWrapper>            
              <RdtDetailInfoWrapper>
                <h2>{rdt.strNombreTrabajador}</h2>
                <RdtDetailSubTitle>
                  {rdt.strNombre_SUCU}
                </RdtDetailSubTitle>                
                <h3>Cod Trabajador: {rdt.IntCodigo_EMPL} </h3>                      
                <RdtDetailDescriptionWrapper>
                  <PropertyTypesContainerRow>
                    <PropertyTypesContainerColum>
                        <RdtDetailValueWrapper>                          
                          Finca Reporta: 
                          <h2>{rdt.strFincaLaboro}</h2>
                          <h3>Ibm: {rdt.IbmLaboro} </h3>
                          <PropertyBusinessType
                            busineesType={`Fecha de la Novedad: ${rdt.dtmFecha_RDT?rdt.dtmFecha_RDT.split('T')[0]:''} (${nombreDia})`}
                          />
                        </RdtDetailValueWrapper>                        
                      </PropertyTypesContainerColum>
                      <PropertyTypesContainerColum>
                        <RdtDetailValueWrapper>
                          Novedad Reportada:
                          <h3>Cod: {rdt.IntCodigo_CONC} </h3>
                          <h2>{rdt.strConcepto}</h2>
                                                    
                        </RdtDetailValueWrapper>
                      </PropertyTypesContainerColum>
                  </PropertyTypesContainerRow>                  
                </RdtDetailDescriptionWrapper>               
                
                <FormControlAction>
                  <FormControlActionOne>                  
                    <ButtonIcon icon={IoSave} onPress={handleSubmit(onSubmitsave)} />                    
                    <ButtonIcon icon={IoTrashOutline} onPress={showAlertYN} />                   
                  </FormControlActionOne>                  
                  <FormControlActionOne>                   
                    <PropertyTypeLabel 
                        typeId= {`${rdt.employeesId?rdt.employeesId.name:''}`}
                    />&nbsp;&nbsp;               
                    <PropertyTypeLabel 
                            typeId= {`FC: ${rdt.updatedAt?rdt.updatedAt:''}`}                                                      
                    />                    
                </FormControlActionOne>
                
              </FormControlAction>                                               
              </RdtDetailInfoWrapper>              
              
            </RdtDetailWrapper>
            
            <form>                            
              <FormControl>
                <FormControl>                                     
                        <FormControl>                                                                                  
                            <FormControlInputSearch>
                                <label>NOVEDAD</label>                                 
                                <select id="conceptos"
                                            {...register("conceptos")}
                                            onChange={handleChangeCodLabor}
                                            tabIndex="4"
                                            value={conceptoSeleted}>
                                        <option value={""}>--Seleccione--</option>
                                        {conceptos.map(item=>(                                    
                                                <option key={item.IntCodigo} 
                                                        value={item.IntCodigo}                                                                                               
                                                        id={item.IntCodigo}                                                                                                      
                                                >{item.strConcepto}</option>
                                            )
                                            )
                                        }                                                                                                                                               
                                    </select>                                  
                            </FormControlInputSearch>                                                                     
                        </FormControl>                                                                  
                          
                          <FormControl>
                              <FormControlInputSearch>
                                      <label>OBSERVACIÓN</label>
                                      <input  type="text"                                    
                                              {...register("observacion")}
                                              value={observacion}
                                              onChange={e => setObservacion(e.target.value)}   
                                              tabIndex="15"                                             
                                              id="input5"
                                                                                                                                                                                          
                                      />                                    
                              </FormControlInputSearch>
                          </FormControl>                                                                                                                                                                                         
                </FormControl>      
              </FormControl>
              <FormControlActionTwo>
                    <ButtonIcon icon={IoArrowBack} onPress={() => { navigate('/addrdtsnews')}} />
              </FormControlActionTwo>
            </form>
          </RdtDetailWrapper_Global_DOS>
      </RdtDetailWrapper_Global>
   </Page>
  )
}