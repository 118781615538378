import styled from "styled-components";

export const NewsWrapper_Global = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    justify-content: center;
    
`;

export const NewsWrapper_Global_DOS = styled.div`
    //border: 1px solid blue;   
    
    @media (max-width: 1024px){        
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    } 
    @media (min-width: 1024px){
            margin-top: 20px;
            padding: 0 20px;            
            justify-content: center;
            width: 50%;
    }
    
`;

export const NewsWrapper = styled.div`
    //border: 1px solid red;
    display: flex;
    flex-wrap: wrap;
    //flex-flow: column wrap;  
    justify-content: left;
    padding: 10px;
    margin: 40px;    

    a{      
       //border: 1px solid red;                      
       margin-bottom: 10px;       
    }  
    //Tablets
    @media (max-width: 1024px){  
            //border: 1px solid red;                            
            margin: 0px;
            //padding: 5px;            
            justify-content: center;
            width: 100%;            
    } 
`;
