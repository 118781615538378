import { Button } from "../../components/Button";
import {Page} from "../../components/Page"
import { FooterFixed, PageTitle } from "../../globalStyles";
import { AccountWrapper_Global, AccountWrapper_Global_DOS } from "./styles";
import { useAuth } from "../../hooks/useAuth";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { removeToken } from "../../utils/TokenLS";
import { useNavigate } from "react-router-dom";
import { showAlert, SW_ICON } from "../../utils/swAlert";

export const Account = () =>{

    const { auth, closeSeccionAuth } = useAuth();     

    const navigate = useNavigate();

    const hendleCloseSeccion = ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Seccion cerrada Correctamente', 
            "Gracias por hacer parte de la familia ¡Grupo Central!", 
            SW_ICON.SUCCESS,
            () => { navigate('/Login')}    
        );
    }
    
    const UserInfo = () =>(
        <div>
            <h3>Nombre: {auth.data?auth.data.name:''}</h3>
            <h5>Role:   {auth.data?auth.data.role:''}</h5>
            <p>Email:   {auth.data?auth.data.email:''}</p>
            <h5>Ibm:     {auth.data?auth.data.ibm:''}</h5>
            <h5>Ver: 1.2.3</h5>
            <hr />
            <FooterFixed>
                <Button 
                    label="Cerrar Sección" 
                    onPress={hendleCloseSeccion}                    
                    
                />
            </FooterFixed>                       
        </div>
    )

    const UserUnauthorized = () => (
        <div>
            <h2>Usuario No Autenticado</h2>
            <p>Para acceder a tu perfil de usuario inicia Sección</p>                        
            <Button label="Iniciar Sección" linkTo={"/login"} />
        </div>
    )

    return(
    <Page>
        <AccountWrapper_Global>
            <AccountWrapper_Global_DOS>
                <LogoImageWrapper> 
                        <PageTitle>                   
                        MI CUENTA              
                        </PageTitle>         
                        <img src={"Logo.jpg"} alt="imgLogo"/>                                  
                </LogoImageWrapper>                
                {
                 auth.data ? <UserInfo />:<UserUnauthorized/>
                }
            </AccountWrapper_Global_DOS>
        </AccountWrapper_Global>                          
    </Page>
    
)};