
import styled from 'styled-components';

export const PropertyTypesContainerHead = styled.section`
    
    //border: 1px solid red;
    display: flex;  
    margin: 10px 0 ;    
    
`
export const PropertyTypesContainerColum = styled.section`

    //border: 1px solid yellow;
    padding-right: 20px;
    

`
export const PropertyTypesContainerRow = styled.section`

    //border: 1px solid red;
    display: flex;

`
export const PropertyTypesContainer = styled.section`
    //border: 1px solid orange;
    display: flex;
    overflow-x: scroll; 
    
`

export const RdtsDetailTitle = styled.h1`
    font-size: 1.5em;
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;
    
`

export const RdtsWrapper = styled.section`
    border: 1px solid red;
    display: flex;    
    //justify-content: center; 

    a{                            
       margin-bottom: 10px;       
    }   
`;

export const RdtExportButton = styled.div`
    
    button{
        //
        //width: 30%;
        font-size: 1em;
        color: #fff;
        //background-color: #4A148C;
        background-color: #0d6a3b;//verde osc
        //background-color: #ff8c00;//Naranja
        text-align: center;        
        border: none;
        border-radius: 6px;
        padding: 9px;
        opacity: 0.9;
        cursor: pointer;    
        
        &hover{        
            opacity: 1;
        } 
    }
`;