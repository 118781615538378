import { Link } from "react-router-dom";
import styled from "styled-components"

export const PropertyCardWrapper = styled.div`
    //border: 1px solid red;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px 0 5px 0;    
    display: flex;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    margin: 1px 0;
    font-size: 0.7em;
    text-decoration: none;
    background-color: #24784e ;
    width:100%;

`;


export const PropertyCardWrapperTr = styled.tr`
    border: 1px solid #ccc;  

`;

export const PropertyImageWrapper = styled.div`
    flex: 30;
    border-radius: 20px;
    img{
        width: 100%;
        border-radius: inherit;
    }

`;

export const PropertyInfoWrapper = styled.div`
    flex: 70;    
    //border: 1px solid red;
    font-size: 1em;
    margin: 0 10px 0 10px;
    text-align: left;
    color:#fff;
    
    span{
        color:#fff;
    }
        
`;

export const PropertyDescriptionWrapper = styled.div`    
    color: #6262A0;
    font-weight: bold; 
    font-size: 0.8em;   
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    p{
        color: #6262A0;
        font-weight: bold; 
        font-size: 1em;
        text-align: right;
    }
`;

export const PropertyValueWrapper = styled.p`
    color: #222;
`;

export const PropertyCardSubTitle = styled.p`

    color: #C0C2D2;
    font-size: 1em;
    text-transform: capitalize;
    margin: 0;
    margin-top: 3px;
    span{
        //color: #6262A0;
        //color: blanchedalmond;
        color: ${props => props.color};
    }

`;
