import { Link } from "react-router-dom";
import styled from "styled-components"

export const ReceptionDetailWrapper_Global = styled.div`
    //border: 1px solid blue; 
    display: flex;
    justify-content: center;
`;

export const ReceptionDetailWrapper_Global_DOS = styled.div`
    //border: 1px solid blue;     
    @media (max-width: 1024px){
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    } 
    @media (min-width: 1024px){
            margin-top: 80px;
            padding: 0 20px;            
            justify-content: center;
            width: 60%;
    }    
`;

export const ReceptionDetailWrapper = styled.div`

    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px;    
    display: flex;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    margin: 10px 0;

    text-decoration: none;

       //Para moviles
       @media (max-width: 512px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
    }      
    

`;

export const ReceptionImageWrapper = styled.div`
    flex: 30;
    border-radius: 20px;
    img{
        width: 100%;
        border-radius: inherit;
    }

       //Para moviles
       @media (max-width: 512px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        //flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 300px;
        //margin-top: 10px;
    }      

`;

export const ReceptionInfoWrapper = styled.div`
   flex: 70;
    //border: 1px solid red;
    margin-left: 10px;
    font-size: 1em;
    h2{
        font-size: 1.5em;
        margin: 0;
        color:#6262A0;
    }
    h3{
        
        margin: 0;
        color:#6262A0;
    }       
`;

export const ReceptionDetailSubTitle = styled.p`

    color: #C0C2D2;
    font-size: 1.2em;
    text-transform: capitalize;
    margin: 0;
    margin-top: 1px;

`;

export const ReceptionDetailDescriptionWrapper = styled.div`    
    color: #6262A0;
    font-weight: bold; 
    font-size: 1.0em;   
    
    p{
        color: #6262A0;
        font-weight: bold; 
        font-size: 1em;   
    }
`;

export const PropertyTypesContainerColum = styled.section`
    //border: 1px solid yellow;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    background-color: white;
    padding: 5px;
    width: 100%;
    label{
        display: block;
        font-size: 0.70em;
        color: blue;
        font-weight: bold;          
        p{
            color: black;              
        }                    
    }
`;

export const PropertyTypesContainerRow = styled.section`

    //border: 1px solid red;
    border: 1px solid #dfdfdf;
    margin: 10px 5px 5px 0px;
    background-color: #F2F2F2;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    

`;


export const ReceptionDetailValueWrapper = styled.div`
    color: #222;
    font-size: 1em;
    h2{
        font-size: 1.2em;
        margin: 0;
        color:#6262A0;
    }
    h3{
        
        margin: 0;
        color:#6262A0;
    }
`;


export const ReceptionFormControlAction = styled.div`
    //border: 1px solid red;
    display: flex;
    //justify-content: space-between;
    //justify-content: flex-start;
    align-items: center;
    //padding-left: 5px;
    //flex-direction: column;
    //flex-wrap: wrap;
    //margin-top: 50px;
    //margin-bottom: 20px;
    button{
        margin-right: 30px;
    }
    
`;

export const ReceptionFormControlActionOne = styled.div`
    display: flex;        
    width: 100%;
`;

export const FormControlInputSearch = styled.div`
    //border: 1px solid red;
    flex: 1;    
    margin: 0 10px 0 10px;

    label{
        display: block;
        font-size: 0.80em;
        color: #26794f;
        font-weight: 500;
        padding-left: 10px ;
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],
    input[type="float"],input[type="date"]{
        //display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 1.1em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border-radius: 15px;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;

export const Label = styled.label`

  background-color: ${props => props.backcolor};
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block;
  position: relative;

  &:hover {
    background-color: #2980b9;
  }
`;

export const FormControlActionTwo = styled.div`
    display: flex; 
    flex-direction: row-reverse;       
    width: 100%;
    
    label{
        color: #26794f;
        padding: 3px;
        font-size: 0.8em;
    }
`;

export const FormControlSeccionOne = styled.div`
    
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;  
        
    //Para moviles
    @media (max-width: 512px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
    }      
`;