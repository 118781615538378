
import { Page } from "../../components/Page";
import { PageTitle } from "../../globalStyles";


export const NotFound =()=>{
      
    return(
        <Page>
            <PageTitle>Pagina No Encontrada - 404</PageTitle>        
        </Page>
    )
}