import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export const PropertyGrafDonaChartanio = (item) => {
  const [ precipitationUltsem, setPrecipitationUltsem ] = useState([]);
  const [ sucursals, setSucursals ]=useState([]);
  const [ anio, setAnio ]=useState([]);
  const [ precipitacion, setPrecipitation ]=useState([]); 

  //console.log({item: item})             
    
  //anio       
  useEffect(() => {
    const getData = async ()=>{
        try {                                                    
          item.anio ? getPrecipitationUltSemanas():console.log('');            
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();             
  },[item.anio])
  //getPrecipitation
  const getPrecipitationUltSemanas = async ()=>{                
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/precipitations/anio',                         
                params: makePropertiesFiltersUltSemanas()                                     
            }
        );             
        const {data} = response;                
        setPrecipitationUltsem(data);    
        //console.log({semana: precipitationUltsem})  
        let auxSucursal=[]
        let auxAnio=[]
        let auxLluvia=[]
        data.map(elemento=>{
          auxSucursal.push(elemento.strFinca)
          auxAnio.push('Año ' + elemento.anio)
          auxLluvia.push(elemento.totalLLuvia)
        });
        setAnio(auxAnio)
        setPrecipitation(auxLluvia)
        setSucursals(auxSucursal)
        //console.log({arreglo: data})               
    } catch (error) {
        if(error.response.status === 401){
          console.log('')
        }else{
            console.log(error)
        }
    }    
  }
  const makePropertiesFiltersUltSemanas = ()=>{
      const filters = {};                                    
          //filters['Ibm'] = item.sucursal?item.sucursal:console.log(''); 
          filters['anio'] = item.anio?item.anio:console.log('');         
          //filters['employeesId'] = auth.data._id;              
          //console.log(fechaini);
          //console.log({semana: item.sucursal})
      return filters;       
  }  
  //const Dias=["Lunes","Martes","Miercoles","Jueves","Viernes","Sabado","Domingo"]
  //const Milimetros=[5,0,12,15,20,11,30]

  const data = {
    labels: sucursals,
    datasets:[
      {
       label: "mm", 
       data: precipitacion, 
       borderColor: ['orange','green','yellow'],       
       borderWidth: 2,
       backgroundColor: ['orange','green','yellow'],       
     }
   ],
  };

  const options = {
    maintainAspectRatio:false,
    responsive: true,
    cutout: 20,

    plugins:{
      legend:{
        display: true,
        position: 'top',
      },
    },

    /*scales:{
      y:{
        min: 0,
      },
    },

    plugins:{
      legend:{
        display: true,
        position: 'top',
      },
    },
    tooltip:{
      intersect: false,
      mode: 'index',
      callbacks:{
        label: function (context){
          const label = context.dataset.label || '';
          const value = context.formattedValue;
          return `${label}: ${value} mm`
        },
      },
    },  */
  };
  //<Line data={data} options={options}/>
  return (
    <div>
      <Doughnut data={data} options={options}/>
    </div>
  )
}


