import styled, {css} from 'styled-components';

export const PropertyTypesContainerColum = styled.section`

    //border: 1px solid yellow;
    padding-right: 20px;
    

`;
export const PropertyTypesContainerRow = styled.section`

    //border: 1px solid red;
    display: flex;

`;
export const PropertyTypesContainer = styled.section`
    //border: 1px solid orange;
    display: flex;
    overflow-x: scroll; 
    
`;
export const ReceptionProductsDetailTitle = styled.h1`
    font-size: 1.5em;
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;
    
`;


export const ReceptionProductsWrapper = styled.section`
    //border: 1px solid red;
    display: flex;    
    //justify-content: center; 

    a{                            
       margin-bottom: 10px;       
    }   
`;

export const ReceptionProductsDetailInfo = styled.div`
    span{
        font-size: 1.5em;
        font-weight: bold;        
    }
    p{
        text-align: left; 
        span{
            font-size: 1.1em;
            font-weight: bold;
        }
    }
    
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    
    //border: 1px solid blue;
    width: 100%;
    
`;

export const PropertyContainerDasboardRow = styled.section`
    
    //border: 1px solid red;
    display: flex;
    margin: 10px 0; 
    //width: 100%;
    //height: auto;
    justify-content: space-around;
    
    //display:inline-flex; 
   
    background-color    : #a0d995;
    color: white;

    //Para Tablets
    @media (max-width: 1024px){
        //border: 1px solid red; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
    }
    
`;

export const PropertyContainerDasboardColum = styled.section`
    
    //border: 1px solid yellow;
    display: flex-col;
    margin: 5px 0;  
    background-color    : #a0d995;
    color: white;
    height: auto;
    //align-content: center;
    
`;

export const PropertyContainerDasboard = styled.section`
    
    //border: 1px solid black;
    display: flex;    
    margin: 10px 0;  
    background-color    : #a0d995;
    color: white;
    align-content: center;
    justify-content:center;
`;

export const PropertyReceptionDasboard = styled.section`
    
    border:2px solid white;
    display: flex-col;
    width: 100%;
    margin: 10px;
    padding  : 5px;
    background-color    : #a0d995;
    color: white;
    justify-content: center;
    border-radius: 15px;
    h2{
        text-align:center;
        color:black;
        margin: 0;
    }
    
`;

export const PropertyTotalDasboard = styled.div`
    
    //border: 1px solid yellow;
    display: flex-col;
    margin: 0 5px;
    padding  : 10px ;
    font-size: 1.2em;
    background-color    : white;
    color: black;
    border-radius: 15px;
    align-content: center;
    text-align:center;

    h2{
        //border: 1px solid red;
        margin:0;       
    }

    span{        
        //border: 1px solid red;
        margin:0;       
        font-size: 0.8em;       
    }
    
`;

export const PropertyTitleDasboard = styled.div`
    font-size: 1.5em;
    //color: #222;
    color: white;
    background-color: #26794f;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    border-radius:15px;
    padding:10px;
    margin-left: 10px;    
    //border: 1px solid blue;
    //width: 100%;    
    
`;

export const PropertySectorone = styled.div`
    //border:2px solid blue;
    display: flex;
    justify-content: space-around;

    //Para movil
    @media (max-width: 512px){
        //border: 1px solid red; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //height: auto;
        //margin-top: 10px;
    }
    
`;

export const PropertySectortwo = styled.div`
    //border:2px solid orange;
    display: flex;
    justify-content: space-around;

    //Para movil
    @media (max-width: 512px){
        //border: 1px solid red; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: auto;
        //margin-top: 10px;
    }
`;



const BaseButtonStyle = css`
    width: 100%;
    font-size: 1em;
    color: white;
    //font-weight: bold;
    //background-color: #4A148C;
    background-color: #a0d995;//verde osc
    //background-color: #ff8c00;//Naranja
    text-align: center;
    border: none;
    //border-radius: 6px;
    padding: 8px 8px;
    //opacity: 0.9;
    cursor: pointer;    
    
    &hover{        
        opacity: 1;
    } 
     
    
`

export const ButtonTransport= styled.button`
    ${BaseButtonStyle}
    
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
`;