import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import Alert from "../../components/Alert/Alert";
import { PropertyRdts } from "./components/PropertyRdts";
import { PropertyRdtstrabajador } from "./components/PropertyRdtstrabajador";
import { Page } from "../../components/Page";
import { PageTitle } from "../../globalStyles";
import { Button } from "../../components/Button"
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { getToken, removeToken } from "../../utils/TokenLS";
import { AddRdtsWrapper_Global, 
         AddRdtsWrapper_Global_DOS, 
         PropertyTypesContainerColum, 
         PropertyTypesContainerRow,
         FormControl,           
         FormControlInput,
         FormControlInputSearch,          
         FormControlTitle,
         FormControlSeccionOne} from "./styles";

const ALL_SUCURSALES = 0;
const HORA = 10;

export const AddRdtsnews = () => {

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ sucursal, setSucursal ] = useState([]);
    const [ employe, setEmploye ] = useState([]);
    const [ concepto, setConcepto ] = useState([]);
    const [ conceptos, setConceptos ] = useState([]);

    const [ horas, setHoras ] = useState(ALL_SUCURSALES);
    const [ observacion, setObservacion ] = useState('');
    const [ date, setDate ] = useState('');
    const [ sumcantidad, setSumcantidad ] = useState(0);
    const [ rdts, setRdts ] = useState([]);
    const [ rdtstrabajador, setRdtstrabajador ] = useState([]);

    const [sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);    
    const [sucursalname, setSucursalname] = useState('');    
    const [codigoSeleted, setCodigoSelected] = useState('');
    const [conceptoSeleted, setConceptoSelected] = useState('');
    
    const [multilabor, setMultilabor] = useState(0)  

    const [alert, setAlert] = useState({})    

    const navigate = useNavigate();

   const { 
            register, 
            handleSubmit,
            formState:{
                errors,
                isValid
            }  
   } = useForm({ 
        mode: 'onChange',
        defaultValues: {
            checkbox: []
          }
       });     

  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
  }
  //Token valido o no expirado
  useEffect(() => {  
      const getData = async ()=>{            
          try {
              const token = getToken();
              if(!token) return
              await getSucursalesAll();   
              await getConceptos();               
          } catch (error) {
              console.log(error)
          }             
      }    
      getData();               
  },[auth])
  //Recargar RDTs
  useEffect(() => {  
      const getData = async ()=>{            
          try {
              const token = getToken();
              if(!token) return
              await reload ? getRdts():console.log('')    
              await reload ? getRdtstrabajador():console.log('')                             
          } catch (error) {
              console.log(error)
          }             
      }    
      getData();               
  },[reload])
  //Si estoy editando
  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return
            await rdtprov.ibm?setSucursalSelected(rdtprov.ibm):console.log(''); 
            await rdtprov.dateRdt?setDate((rdtprov.dateRdt).split('T')[0]):console.log('');                                
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
  },[rdtprov])
  //Sucursals       
  useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return
            await  cleanStates();                              
            sucursalSeleted && date ? getRdts():cleanStates();                                          
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
          
  },[sucursalSeleted])
  //date       
  useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return 
            await  cleanStates();                
            sucursalSeleted && date ? getRdts():cleanStates();
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
          
  },[date])
  //Employees
  useEffect(() => {  
    const getDataEmpl = async ()=>{            
        try {
            const token = getToken();
            if(!token) return    
            cleanStatesConcepto();            
            codigoSeleted && sucursal && date ? handleEmployeCod() : cleanStatesEmployees();                                                                                                  
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataEmpl();                 
  },[codigoSeleted])
  //Employees Rdtstrabajador
  useEffect(() => {  
    const getDataEmpl = async ()=>{            
        try {
            const token = getToken();
            if(!token) return    
            cleanStatesConcepto();            
            getRdtstrabajador();                                                                                                  
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataEmpl();                 
  },[employe])
  //Conceptos       
  useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return                
            conceptoSeleted && employe && date ? getConcepto() : cleanStatesConcepto();                               
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                     
  },[conceptoSeleted])

  //Sucursales
  const handleChange = event => {
    setRdtprov({});
    setSucursalSelected(event.target.value)                 
  }        
  const handleChangedate = event => {
    setDate(event.target.value) 
                  
  }    
  //Cod Labor
  const handleChangeCodLabor = event => {
    setConceptoSelected(event.target.value)                    
  }
  //Employees
  const handleEmployeCod = () => {   
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return
            if((auth.data.role !== 1) && (auth.data.role !== 2) && (auth.data.role !== 3)){
                showAlert(
                    'No Autorizado', 
                    "Usuario no permitido para realizar esta operación", 
                    SW_ICON.INFO                
                ); 
                return 
            }
            await   getEmployeesCod();                                     
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }             
    }    
    getData();                                
  }
  const getEmployeesCod = async ()=>{
    try {            
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/employees',                         
                params: makeEmployeesFiltersCod()                                     
            }
        );
        setMultilabor(0);             
        const {data} = response;              
        data ? setEmploye(data):cleanStatesConcepto();
        //await data ? getRdtstrabajador():cleanStatesConcepto();
    } catch (error) {
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        }  
    }
  }
  const makeEmployeesFiltersCod = ()=>{
    const filters = {};  
    if(codigoSeleted !== ALL_SUCURSALES){
        filters['IntCodigo'] = codigoSeleted;
    }
    //console.log(filters);         
    return filters;       
  }
  //Sucursals
  const getSucursalesAll = async ()=>{
      try {            
          const response = await requestHttp(
              {
                  method: HTTP_VERBS.GET,
                  endpoint: '/sucursal',                         
                  params: makeSucursalesFilters()                                     
              }
          );             
          const {data} = response;                
          setSucursal(data);                                     
      } catch (error) {
          console.log(error)
      }
  }
  const makeSucursalesFilters = ()=>{
      const filters = {};           
      return filters;       
  }
  //Concept
  const getConcepto = async ()=>{        
    if(employe){
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/conceptossucursal',                         
                    params: makeConceptoFilters()                                     
                }
            );             
            const {data} = response; 
            //console.log({datos: data})   
            data ? setConcepto(data):cleanStatesudps(); 
            cleanStatesudps();                                                                                              
        } catch (error) {
            console.log(error)
        }
        
    }else{
        cleanStatesConcepto();
    } 
  }
  const makeConceptoFilters = ()=>{
    const filters = {};  
    if(sucursalSeleted !== ALL_SUCURSALES && codigoSeleted !== ALL_SUCURSALES){
        filters['Ibm'] = sucursalSeleted;            
        filters['IntCodigo'] = conceptoSeleted;
    }
    //console.log(filters);         
    return filters;            
  }
  //Concepts
  const getConceptos = async ()=>{        
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/conceptossucursal/allconceptos',                         
                    params: makeConceptosFilters()                                     
                }
            );             
            const {data} = response; 
            //console.log({datos: data})   
            data ? setConceptos(data):cleanStatesudps(); 
            cleanStatesudps();                                                                                              
        } catch (error) {
            console.log(error)
        }
  }        
  const makeConceptosFilters = ()=>{
    const filters = {};  
    filters['IDGrupo'] = 203;                    
    
    //console.log(filters);         
    return filters;            
  }
  //getRdts
  const getRdts = async ()=>{
            
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/rdtsnews',                         
                params: makePropertiesFilters()                                     
            }
        );             
        const {data} = response;                
        setRdts(data); 
        setReload(false);
        
    } catch (error) {
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        }
    }    
  }
  const makePropertiesFilters = ()=>{
    const filters = {};                
          filters['Fecha_INI'] = date; 
          filters['Fecha_FIN'] = date; 
          filters['IbmLaboro'] = sucursalSeleted;   
          filters['employeesId'] = auth.data._id;   
          filters['intLimit'] = 6;   
          //console.log(fechaini);
    return filters;       
  }
  //getRdtstrabajador
  const getRdtstrabajador = async ()=>{
    if(employe[0]){
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/rdtsnews',                         
                    params: makePropertiesFilterstrabajador()                                     
                }
            );             
            const {data} = response;  
            data ? setRdtstrabajador(data):console.log('esperando');            

        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
    }else{
        setRdtstrabajador([])
    }    
        
  }
  const makePropertiesFilterstrabajador = ()=>{
    const filters = {};                
          filters['Fecha_INI'] = date; 
          filters['Fecha_FIN'] = date; 
          filters['IntCodigo_EMPL'] = employe[0]? employe[0].IntCodigo:'';
          filters['strCodigo_SUCU'] = employe[0]?employe[0].Ibm:'';                    
          //console.log({filtrotrab: filters});
    return filters;       
  }  
  //Clean states
  const cleanStates = ()=>{
    setCodigoSelected('');
    setConceptoSelected('');
    setHoras(0);
    setObservacion('');

    setEmploye([]);
    setConcepto([]);
    setRdtstrabajador([]);
    setEmploye([]);
    
    if(sucursalSeleted === '20128'){
        setSucursalname('EDEN')
    }else if(sucursalSeleted === '90032'){
        setSucursalname('TULE')
    }else if(sucursalSeleted === '20065'){
        setSucursalname('JUANCA')
    }else if(sucursalSeleted === '80108'){
        setSucursalname('LA MONICA')
    }else if(sucursalSeleted === '23270'){
        setSucursalname('TIKAL')
    }else if(sucursalSeleted === '20947'){
        setSucursalname('BUENOS AIRES')
    }else if(sucursalSeleted === '80132'){
        setSucursalname('AGUA VERDE')
    }else if(sucursalSeleted === '20180'){
        setSucursalname('TOSCANA')
    }else if(sucursalSeleted === '20175'){
        setSucursalname('ASTURIAS')
    }else if(sucursalSeleted === '20185'){
        setSucursalname('LA CEJA')
    }else if(sucursalSeleted === '20186'){
        setSucursalname('ARIZONA')
    }else if(sucursalSeleted === '20188'){
        setSucursalname('ALCATRAZ')
    }else if(sucursalSeleted === '20194'){
        setSucursalname('MANAURE')
    }else if(sucursalSeleted === '90008'){
        setSucursalname('LA AGUADA')
    }else{
        setSucursalname('')
    }
  }
  //Clean states concepto y udps
  const cleanStatesConcepto = ()=>{ 
    setConcepto([]);
    setHoras(0);   
    if(multilabor != 1){
        //console.log('estoy en clean conceptos')
          setObservacion('');
    }     
  }
  //Clean states employees
  const cleanStatesEmployees = ()=>{ 
    setEmploye([]);
    setConceptoSelected('');
    setConcepto([]); 
    setRdtstrabajador([]);
    setHoras(0);
    setObservacion('');    
  } 
  //Clean states uds
  const cleanStatesudps = ()=>{ 
    if(multilabor != 1){
       
    }     
  }
  //Grabar
  const onSubmitsave = async (data) =>{
    //console.log('')
    //Validaciones
    //Vallido que si este dentro de un rango permitido para registrar
    //valido si dia es viernes o sabado                          
    if(numeroDia >= 5){
        //rango para el viernes de 4 dias.
        if(numeroDia == 5){                
            if(rango > 363599999){
                setAlert({
                    msg: 'Usted ya no puede realizar registros de novedades de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }else{
            //rango para el sabado de 3 dias.                     
                if(rango > 276999999){
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de novedades de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
        }
    }else{
        //Rango de 2 dias para registrar
        if(rango > 189999999){
        //if(rango > 276999999){
            setAlert({
                msg: 'Usted ya no puede realizar registros de novedades de RDT para este dia',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return
        }
    }        
    //Valido si Fecha es mayor a la actual
    if(fecha > hoy){
        setAlert({
            msg: 'Fecha no puede ser "mayor" a la fecha actual',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return     
    }
    //console.log(data)              
    let sumaH = 0;
    //Empleado
    if(!employe[0]){
        setAlert({
            msg: 'Se requiere codigo del trabajador',
            error: true
        }) 
        //console.log({mensajeincludes: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return    
    }
    //Concepto           
    if(!conceptoSeleted){
        setAlert({
            msg: 'Se requiere que seleccione la ausencia',
            error: true
        }) 
        //console.log({mensajeincludes: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return    
    }       
    
    
    setAlert({});                 
    try {
        const fecha = Date.now();            
        const dataRdt = {};
        dataRdt['IntCodigo_EMPL'] = employe[0].IntCodigo;
        dataRdt['strDocumento'] = employe[0].strDocumento;
        dataRdt['strNombreTrabajador'] = employe[0].strNombreTrabajador;
        dataRdt['strCodigo_SUCU'] = employe[0].Ibm;
        dataRdt['strNombre_SUCU'] = employe[0].strCodigoCentro;
        dataRdt['strNombre_EMPR'] = employe[0].Contratista;
        dataRdt['IntCodigo_CONC'] = conceptoSeleted;
        //Nombre Ausencia
        let ausencia = '';
        for (let i = 0; i < conceptos.length ; i++){            
             if(conceptoSeleted === conceptos[i].IntCodigo){
              ausencia = conceptos[i].strConcepto
             }                             
        }        
        dataRdt['strConcepto'] = ausencia;
        dataRdt['intHora_RDT'] = horas;
        dataRdt['intTotal_RDT'] = 0;
        dataRdt['dtmFecha_RDT'] = data.fechardt;
        dataRdt['IbmLaboro'] = sucursalSeleted;
        //Finca Laboro
        let nombreFincareporta = '';
        for (let j = 0; j < sucursal.length ; j++){            
             if(sucursalSeleted === sucursal[j].strCodigo){
              nombreFincareporta = sucursal[j].strNombre
             }                             
        }
        dataRdt['strFincaLaboro'] = nombreFincareporta;
        dataRdt['strObservacion_RDT'] = observacion;
        dataRdt['email_USUA'] = auth.data.email;
        dataRdt['dtmFechaCrea'] = fecha;
        dataRdt['dtmFechaModifica'] = fecha;
        dataRdt['employeesId'] = auth.data._id;
        dataRdt['intTotal_RDT'] = sumcantidad;       
                
        //console.log({datosRDT: dataRdt})
        //console.log({Fecha: fecha})

        const response = await requestHttp(
            {
                endpoint: '/rdtsnews',
                body: dataRdt
            }
        );            
        /*setAlert({
            msg: response.data,
            error: false
        })*/
        await getRdts();
        setCodigoSelected('');                
        setEmploye([]);
        setConceptoSelected('');
        showAlert(
            'Grabado', 
            "Correctamente", 
            SW_ICON.SUCCESS,
            () => { FocusOnInput()}                       
        );
        
        
        /*setTimeout(()=>{
            setAlert({})                
        }, 7000)*/  
        
        
                     
                                   
    } catch (error) {
        console.log(error)
        setAlert({
            msg: error.response.data,
            error: true
        })
        setTimeout(()=>{
            setAlert({})                
        }, 10000) 
    }     
        
  }

  function FocusOnInput(){
      document.getElementById("input1").focus();
  }

  /*
  // Colección de inputs con índices de (0 a 3)
  // 0 es la posición de #input1, 3 es la posición de #input4
  let inputs = document.querySelectorAll('[id^="input"]');
  // Asignar evento para ejecutar función
  inputs.forEach(input => input.addEventListener('keypress', nextFocus));

  // Recibe evento como parámetro
  function nextFocus(e) {
      if(e.keyCode == 13){
          // e.target es el elemento que lanzó el evento
          // Obtener número desde ID, eliminando la palabra input (1 a 4)
          let actual = parseInt(e.target.id.replace('input', ''));
          // Solo si no es el último
          if(actual < inputs.length) {
              // Evitar comportamiento normal del evento
              e.preventDefault();
              // Activar siguiente campo
              inputs[actual].focus();
              //console.log({actual: actual})                
          }
      }
  }
  */
  //Validar dia de la semana
  const fechaComoCadena = date +  " 00:01:59"; // día semana
  const dias = [
      'DOMINGO',
      'LUNES',
      'MARTES',
      'MIERCOLES',
      'JUEVES',
      'VIERNES',
      'SABADO',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  const nombreDia = dias[numeroDia];

  const fecha = new Date(fechaComoCadena);
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  
  const rango = new Date(hoy - fecha);
  //Dos dias para registrar
  //const tiempo = new Date(189999999);
  //Cuatro dias para registrar
  //const tiempoViernes = new Date(363599999);
  //Tres dias para registrar
  //const tiempoSabado = new Date(276999999);

  
  //console.log({rango: rango})
  //console.log({tiempo: tiempo})
  //console.log({tiempoViernes: tiempoViernes})
  //console.log({tiempoSabado: tiempoSabado})
  //console.log({numeroDiaRDT: numeroDia})
  //console.log({fecha: fecha})
  //console.log({tiempoTranscurrido: tiempoTranscurrido})   
  //console.log("Fecha actual: ", hoy); 
  //console.log("Calendario: ", fecha);   
  //console.log("nombre dia: ", nombreDia); 

  const { msg } = alert;  

  return (
    <Page>
        <AddRdtsWrapper_Global>
            <AddRdtsWrapper_Global_DOS>                               
                    <LogoImageWrapper>                                                
                        <PageTitle>
                            NOVEDADES RDTs
                        </PageTitle>
                        <img src={"Logodos.jpg"} alt="imgLogo"/>
                    </LogoImageWrapper>                
                <br/>
                { msg && <Alert alert = {alert} />}    
                <FormControlSeccionOne>
                            <FormControl>                                                       
                                <FormControlInputSearch>
                                    <label>FINCA</label>                    
                                    <select id="sucursal"
                                            {...register("sucursal")}
                                            onChange={handleChange}
                                            tabIndex="1"
                                            value={sucursalSeleted}
                                            disabled={ auth.data.role != 1 && rdtprov.ibm != 90021?true:false}>
                                        <option value={""}>--Seleccione--</option>
                                        {sucursal.map(item=>(                                    
                                                <option key={item.strCodigo} 
                                                        value={item.strCodigo}                                                                                               
                                                        id={item.strCodigo}                                                                                                      
                                                >{item.strNombre}</option>
                                            )
                                            )
                                        }                                                                                                                                               
                                    </select>
                                </FormControlInputSearch>                            
                                <FormControlInputSearch>
                                    <label>FECHA DE RDT</label>
                                    <input  type="date"
                                            {...register("fechardt", { required: true })}
                                            value={date} 
                                            onChange={handleChangedate}
                                            tabIndex="2"
                                    />
                                    {errors.fechardt?.type === 'required' && <span>Este Campo es requerido</span>}
                                </FormControlInputSearch>
                            </FormControl>                          
                            <FormControl>
                               <FormControlTitle>
                                   <label>{sucursalname}</label>
                               </FormControlTitle>
                               <FormControlTitle>
                                    <span>
                                        {nombreDia}
                                        <br/>                                     
                                        {date}
                                    </span>
                               </FormControlTitle>                                                             
                            </FormControl>
                </FormControlSeccionOne>
                <form>                                                                                                     
                    <FormControlSeccionOne>                        
                        <FormControl>
                            <FormControlInputSearch>
                                <label>COD. TRABAJADOR</label>
                                <input  type="number"                                    
                                        {...register("codigoSearch")}   
                                        value={codigoSeleted}                                                                                   
                                        onChange={e => setCodigoSelected(e.target.value)} 
                                        autoFocus
                                        tabIndex="3"  
                                        id="input1"                                                     
                                />                                    
                            </FormControlInputSearch>
                            
                            <FormControlInput>
                                    <label>CODIGO</label>
                                    <p {...register("codigo")}>{employe[0]?employe[0].IntCodigo:''}</p>
                                                                
                            </FormControlInput>
                        </FormControl>
                        <FormControl>
                            <FormControlInput>
                                <label>NOMBRE DEL TRABAJADOR</label>                            
                                <p {...register("name")}>{employe[0]?employe[0].strNombreTrabajador:''}</p> 
                            </FormControlInput>
                        </FormControl>
                        <FormControl> 
                                
                                <FormControlInput>
                                    <label>FINCA A LA QUE PERTENECE</label>
                                    <p  {...register("origen")}>{employe[0]?employe[0].strCodigoCentro:''}</p>
                                </FormControlInput>                                                       
                        </FormControl>                                              
                    </FormControlSeccionOne>
                    <FormControl>                                                                                  
                            <FormControlInputSearch>
                                <label>NOVEDAD</label>                                 
                                <select id="conceptos"
                                            {...register("conceptos")}
                                            onChange={handleChangeCodLabor}
                                            tabIndex="4"
                                            value={conceptoSeleted}>
                                        <option value={""}>--Seleccione--</option>
                                        {conceptos.map(item=>(                                    
                                                <option key={item.IntCodigo} 
                                                        value={item.IntCodigo}                                                                                               
                                                        id={item.IntCodigo}                                                                                                      
                                                >{item.strConcepto}</option>
                                            )
                                            )
                                        }                                                                                                                                               
                                    </select>                                  
                            </FormControlInputSearch>                                                                     
                    </FormControl>                    
                                                                              
                    <FormControlSeccionOne>                                                                                                                                            
                        <FormControl>
                            <FormControlInputSearch>
                                    <label>OBSERVACIÓN</label>
                                    <input  type="text"                                    
                                            {...register("observacion")}
                                            value={observacion}
                                            onChange={e => setObservacion(e.target.value)}                                                
                                            id="input5"
                                                                                                                                                                                         
                                    />                                    
                             </FormControlInputSearch>
                        </FormControl>
                        
                        <FormControl>
                            <Button label="GRABAR" onPress={handleSubmit(onSubmitsave)} />                               
                        </FormControl>                                                                                                                                            
                    </FormControlSeccionOne>                                                      
                </form>                
                <PropertyTypesContainerRow>
                    <PropertyTypesContainerColum>
                        <label>Ultimos registros que he digitado...</label>
                        {rdts.map((item, key) => 
                            <PropertyRdts
                                key={key}                   
                                {...item} 
                                propertyTypeSeleted               
                            />
                            )
                        }
                    </PropertyTypesContainerColum>
                    <PropertyTypesContainerColum>
                        <label>Novedades digitadas para el trabajador:</label>  
                        <label>
                            <p>
                                {rdtstrabajador[0]?rdtstrabajador[0].strNombreTrabajador:''}&nbsp;
                                {rdtstrabajador[0]?'Cod:':''}
                                {rdtstrabajador[0]?rdtstrabajador[0].IntCodigo_EMPL:''}&nbsp;
                            </p>
                            
                        </label>
                        {rdtstrabajador.map((item, key) =>
                            <>                                                          
                                <PropertyRdtstrabajador
                                    key={key}                   
                                    {...item} 
                                    propertyTypeSeleted               
                                />
                            </>                            
                            )
                        }
                    </PropertyTypesContainerColum>
               </PropertyTypesContainerRow>
            </AddRdtsWrapper_Global_DOS>
        </AddRdtsWrapper_Global>
    </Page>
  )
}
