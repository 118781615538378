import { useEffect, useState } from "react";
import { useAuth } from "../../../../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";
import { TraslateDetailDescriptionWrapper, 
         TraslateDetailInfoWrapper, 
         TraslateDetailSubTitle, 
         TraslateDetailTitle, 
         TraslateDetailValueWrapper, 
         TraslateDetailWrapper, 
         TraslateDetailWrapper_Global, 
         TraslateDetailWrapper_Global_DOS } from "./styles";
import { HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";
import { getToken, removeToken } from "../../../../utils/TokenLS";
import { Page } from "../../../../components/Page";
import { FormControl, FormControlAction, FormControlInput, FormControlInputRadio, PageTitle, SubTitle } from "../../../../globalStyles";
import { Button } from "../../../../components/Button";
import { IoTrashOutline } from "react-icons/io5";
import { ButtonIcon } from "../../../../components/ButtonIcon";
import { showAlert, showAlertYN, SW_ICON } from "../../../../utils/swAlert";
import { PropertyTypeLabel } from "../../../../components/PropertyTypeLabel";
import swal from 'sweetalert';

const ALL_SUCURSALES = 0;

export const TraslateDetail = ()=>{

   const { auth, closeSeccionAuth } = useAuth();
   const {id} = useParams()

   const navigate = useNavigate();
   
   const [ traslate, setTraslate ] = useState([]);
   const [ sucursal, setSucursal ] = useState([]);
   const [ centros, setCentros ] = useState([]);

   const [sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);
   const [centrosSeleted, setCentrosSelected] = useState(ALL_SUCURSALES);
   const [ motivoSeleted, setMotivoSelected ] = useState('');
   const [ cargando, setCargando ] = useState(false);

   const { 
      register, 
      handleSubmit,
      formState:{
          errors,
          isValid
      }  
   } = useForm({ mode: 'onChange'});   
    
useEffect(() => {  
      const getData = async ()=>{
          try {
              const token = getToken();
              if(!token) return   
              await getTraslate();
              await getSucursalesAll(); 
          } catch (error) {
              console.log(error)
          }             
      }    
      getData();              
},[auth]) 
//Close session if token expired
const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
};
//Motivo
const handleChangeMotivo = event => {
    setMotivoSelected(event.target.value)      
    //console.log(motivoSeleted)                 
 }
//Sucursales
const handleChange = event => {
      setSucursalSelected(event.target.value)                  
};
const getSucursalesAll = async ()=>{
      try {            
          const response = await requestHttp(
              {
                  method: HTTP_VERBS.GET,
                  endpoint: '/sucursal',                         
                  params: makeSucursalesFilters()                                     
              }
          );             
          const {data} = response;                
          setSucursal(data);                                   
      } catch (error) {
          console.log(error)
      }
};
const makeSucursalesFilters = ()=>{
      const filters = {};           
      return filters;       
};
//Centros       
useEffect(() => {
   const getData = async ()=>{
       try {
           const token = getToken();
           if(!token) return
           await  getCentros();  
       } catch (error) {
           console.log(error)
       }             
   }    
   getData();         
         
},[sucursalSeleted])

const handleChangeC = event => {
   setCentrosSelected(event.target.value)                      
};
const getCentros = async ()=>{
   
   try {            
       const response = await requestHttp(
           {
               method: HTTP_VERBS.GET,
               endpoint: '/centros',                         
               params: makeCentrosFilters()                                     
           }
       );             
       const {data} = response;                
       setCentros(data);                                              
   } catch (error) {
       console.log(error)
   }
};
const makeCentrosFilters = ()=>{
    const filters = {};  
    if(sucursalSeleted !== ALL_SUCURSALES){
        filters['strCodigoSucursal'] = sucursalSeleted;
    }
    //console.log(filters);         
    return filters;            
};
//TraslateById
const getTraslate = async ()=>{
   try {                      
      const response = await requestHttp(
         {
               method: HTTP_VERBS.GET,
               endpoint: `/traslates/${id}`                                                                       
         }
      );             
      const {data} = response;                
      await setTraslate(data); 
      setCargando(true);                  
   } catch (error) {
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        } 
   }

};
//editTraslate
const onSubmitTraslate = (data) =>{                  
   const dataTraslate = {}
   dataTraslate['dtFIngreso'] =  traslate.dtFIngreso
   dataTraslate['strCodigoCentro'] =  data.seccion
   dataTraslate['intIBM_TRAS'] =  data.sucursal

   dataTraslate['strMotivo'] =  motivoSeleted
   dataTraslate['dtFInicial'] =  data.fechatraslado
   motivoSeleted === 'Prestamo'?dataTraslate['dtFFinal'] =  data.fechafin : dataTraslate['dtFFinal'] = ''
   //console.log(dataTraslate)
   traslateRequest(dataTraslate); 
};
const traslateRequest = async (dataTraslate) =>{           
   try {           
       const response = await requestHttp(
           {
               method: HTTP_VERBS.PUT,
               endpoint: `/traslates/${id}`,
               body: dataTraslate,
               token: getToken()
           }
       );                                   
       showAlert(
           'Traslate', 
           "Actualizado Correctamente", 
           SW_ICON.SUCCESS                
       );
       getTraslate()
   } catch (error) { 
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            showAlert('Error', "Actualización Incorrecta", SW_ICON.ERROR);
        }      
   }     
};
//Delete Traslate
const showAlertYN = () =>{
    swal({
        title: "Esta usted seguro?",
        text: "Una vez eliminado, no podras recuperar este registro!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {   
            traslateDelete()         
         /*   swal("El registro ha sido eliminado correctamente!", {
            icon: "success",
                        
          });*/           

        } else {
          //swal("Su registro esta a salvo!");
          return
        }
        //console.log(willDelete)
      });
};
const traslateDelete = async () =>{             
   try {           
       const response = await requestHttp(
           {
               method: HTTP_VERBS.DELETE,
               endpoint: `/traslates/${id}`,               
               token: getToken()               
           }
       );                                   
       showAlert(
           'Traslate', 
           "Eliminado Correctamente", 
           SW_ICON.WARNING,   
           () => { navigate('/')}                 
       );
       getTraslate()
   } catch (error) { 
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
        } 
   }  
};
//Busqueda por centro
const centrosFiltrados = motivoSeleted === 'Prestamo' ? centros.filter(centro => centro.strNombre.toLowerCase().includes(motivoSeleted.toLowerCase())) : [] 
//console.log(centrosFiltrados)
    return(
      <Page>
        {cargando?
         <TraslateDetailWrapper_Global>
            <TraslateDetailWrapper_Global_DOS>                  
               <TraslateDetailWrapper>                                                  
               <TraslateDetailInfoWrapper>                           
                     <h2>{traslate.strNombreTrabajador}</h2>           
                     <TraslateDetailSubTitle>
                        {traslate.strCodigoCentro_EMPL}
                     </TraslateDetailSubTitle>         
                     <h3>Identificacion: {traslate.strDocumento} </h3> 
                     <h3>Cod: {traslate.IntCodigo} </h3>   
                     <TraslateDetailDescriptionWrapper>
                        <TraslateDetailValueWrapper>
                           {traslate.strMotivo}: 
                           <h2>{traslate.strCodigoCentro}</h2>
                           <h3>Ibm: {traslate.intIBM_TRAS} </h3>
                        </TraslateDetailValueWrapper>
                        <PropertyBusinessType
                              busineesType = {`Fecha de ${traslate.strMotivo} : ${traslate.dtFInicial.split('T')[0]} ${traslate.dtFFinal === null?'':'//'} ${traslate.dtFFinal === null?'':traslate.dtFFinal.split('T')[0]}  `}
                        />                        
                     </TraslateDetailDescriptionWrapper>                     
                     <PropertyTypeLabel 
                        typeId= {`FC: ${traslate.updatedAt.split('T')[0]}`}                        
                     />
                     <br/>
                     <PropertyTypeLabel 
                        typeId= {`${traslate.employeesId?traslate.employeesId.name:''}`}
                     />
                  </TraslateDetailInfoWrapper> 
                  <FormControlAction>
                            <ButtonIcon icon={IoTrashOutline} onPress={showAlertYN} />                     
                  </FormControlAction>     
               </TraslateDetailWrapper> 
               <form onSubmit={handleSubmit(onSubmitTraslate)}>
                     <TraslateDetailTitle>
                          EDITAR PRESTAMO Ó TRASLADO                                                                                      
                     </TraslateDetailTitle> 
                     <FormControl
                        color='#86b49d'
                     >
                                <FormControlInput>                                               
                                    <FormControlInputRadio
                                        colortext='white'         
                                    >                           
                                        <label>Prestamo</label>
                                        <input {...register("tipo")}  onChange={handleChangeMotivo} type="radio" value="Prestamo" />
                                        <label>Traslado</label>
                                        <input {...register("tipo")} onChange={handleChangeMotivo} type="radio" value="Traslado" />
                                    </FormControlInputRadio>                                        
                                </FormControlInput>                        
                     </FormControl>
                     <FormControl>
                                <FormControlInput>
                                    <label>{motivoSeleted === 'Prestamo'?'FINCA DE PRESTAMO':'FINCA DE TRASLADO'}</label>                    
                                    <select id="sucursal"
                                            {...register("sucursal", { required: true })}
                                            onChange={handleChange}>
                                        <option value={""}>--Seleccione--</option>
                                        {sucursal.map(item=>(                                    
                                                <option key={item.strCodigo} 
                                                        value={item.strCodigo}                                                                                               
                                                        id={item.strCodigo}
                                            >{item.strNombre}</option>
                                            )
                                            )
                                        }                                                                                                                                               
                                    </select>
                                </FormControlInput>                                          
                            </FormControl>  
                            <FormControl>
                                <FormControlInput>
                                    <label>EQUIPO DE TRABAJO</label>                    
                                    <select id="seccion"
                                            {...register("seccion", { required: true })}
                                            onChange={handleChangeC}>                            
                                        <option value={""}>--Seleccione--</option>
                                        {motivoSeleted === 'Traslado'? 
                                            centros.map(item=>(                                    
                                                <option key={item.strCodigo} 
                                                        value={item.strNombre}                                                                                               
                                                        id={item.strCodigo}
                                                >{item.strNombre}</option>
                                                )
                                            ):
                                            centrosFiltrados.map(item=>(                                    
                                                <option key={item.strCodigo} 
                                                        value={item.strNombre}                                                                                               
                                                        id={item.strCodigo}
                                                >{item.strNombre}</option>
                                                )
                                                )

                                        }                                                                                                                                                              
                                    </select>
                                </FormControlInput>                                          
                            </FormControl>                             
                            <FormControl>
                                <FormControlInput>
                                    <label>{motivoSeleted === 'Prestamo'?'FECHA INI':'FECHA DE TRASLADO'}</label>
                                    <input  type="date"
                                            {...register("fechatraslado", { required: true })} 
                                    />
                                    {errors.fechatraslado?.type === 'required' && <span>Este Campo es requerido</span>}
                                </FormControlInput>
                                {motivoSeleted === 'Prestamo'?
                                    <FormControlInput>                                    
                                        <label>FECHA FIN</label>
                                        <input  type="date"
                                                {...register("fechafin")} 
                                        />                                    
                                    </FormControlInput>:''}                                       
                            </FormControl>                                        
                    <br />
                    <Button disabled={!isValid} label={motivoSeleted === 'Prestamo'?'Efectuar Cambio Prestamo':'Efectuar Cambio Traslado'} type="submit" onPress={()=>{}} />
                    <br />                          
               </form>
            </TraslateDetailWrapper_Global_DOS>
         </TraslateDetailWrapper_Global>
         :'cargando...'}
      </Page>
    )
};