import { Link } from "react-router-dom";
import styled from "styled-components"


export const RetiredDetailWrapper_Global = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    justify-content: center;
    
    
`;

export const RetiredDetailWrapper_Global_DOS = styled.div`
    //border: 1px solid blue;   
    
    @media (max-width: 1024px){
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    } 
    @media (min-width: 1024px){
            margin-top: 80px;
            padding: 0 20px;            
            justify-content: center;
            width: 50%;
    }
    
`;

export const RetiredCardWrapper = styled.div`
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px;    
    display: flex;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    margin: 10px 0;

    text-decoration: none;
    

`;

export const RetiredImageWrapper = styled.div`
    flex: 30;
    border-radius: 20px;
    img{
        width: 100%;
        border-radius: inherit;
    }

`;

export const RetiredInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin-left: 10px;
    h3{
        margin: 0;
        color:#6262A0;
    }    
`;

export const RetiredDescriptionWrapper = styled.div`    
    color: #6262A0;
    font-weight: bold; 
    font-size: 1em;   
    
    p{
        color: #6262A0;
        font-weight: bold; 
        font-size: 1em;   
    }
`;

export const RetiredValueWrapper = styled.p`
    color: #222;
`
export const RetiredDetailSubTitle = styled.p`

    color: #C0C2D2;
    font-size: 1.2em;
    text-transform: capitalize;
    margin: 0;
    margin-top: 1px;

`;


export const RetiredDetailTitle = styled.h1`
    font-size: 1.5em;
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;
    
`