import styled from "styled-components";

export const ConfirmAccountWrapper_Global = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    justify-content: center;
    
    
`;

export const ConfirmAccountWrapper_Global_DOS = styled.div`
    //border: 1px solid blue;   
    
    @media (max-width: 1024px){
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    } 
    @media (min-width: 1024px){
            margin-top: 80px;
            padding: 0 20px;            
            justify-content: center;
            width: 50%;
    }
    
`;


export const ConfirmAccountWrapper = styled.div`
    //border: 1px solid red;
         
    padding: 10px;
    margin: 10px;
    
    a{                            
       margin-bottom: 10px;
       
    }

    span{
        //color: blueviolet;
        color: #f0a808;
        font-weight: 700;
       
    }
   
`;
