import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../../hooks/useRdt";
import { showAlert, SW_ICON } from "../../../utils/swAlert";
import { HTTP_VERBS, requestHttp } from "../../../utils/HttpRequest";
import { getToken, removeToken } from "../../../utils/TokenLS";
import { PropertyTable, PropertyTableCell, PropertyTableContainer, PropertyTableHeader, PropertyTableHeaderRow, PropertyTableRow } from "./styles";
import Spinner from "../../../utils/Spinner";

export const PrecipitationDetailanio = (item) => {

    //console.log({PrecipitationDetailanio: item})

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ precipitationanio, setPrecipitationanio ] = useState([]);
    const [ iterations, setIterations ] = useState(false); 
    
    const [alert, setAlert] = useState({})    

    const navigate = useNavigate();

    const Cargar = ()=>{
        setTimeout(()=>{ 
            setIterations(true)                                                                                                    
        }, 200)           
    }

    const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ 
        mode: 'onChange',
        defaultValues: {
            checkbox: []
        }
   }); 

   //Close session if token expired
   const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
   }

   //Token valido o no expirado
   useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return
            await item.anio ? getPrecipitationanio():console.log('');
            //await getPrecipitationanio();                      
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
    },[auth])
    //Recargar RDTs
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await reload ? getPrecipitationanio():console.log('')                                              
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[reload])
    //cargar data
    useEffect(() => {  
        if(!iterations){
            Cargar()
        }               
    },[item.anio])
    //getPrecipitation
    const getPrecipitationanio = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations/anio',                         
                    params: makePropertiesFiltersanio()                                     
                }
            );             
            const {data} = response;                
            setPrecipitationanio(data);    
            //console.log({dataAnio: data})                          
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersanio = ()=>{
        const filters = {};                
            filters['anio'] = item.anio;                         
            //filters['employeesId'] = auth.data._id;              
            //console.log(anio);
        return filters;       
    }

    // Crear un objeto para almacenar los totales de precipitacion por año
    const precipitacionPorAnio = {};
    // Calcular los totales de precipitacion por año
    precipitationanio.forEach((itemPrec) => {
        if (!precipitacionPorAnio[itemPrec.anio]) {
            precipitacionPorAnio[itemPrec.anio] = {};
        }
        if (!precipitacionPorAnio[itemPrec.anio][itemPrec.Finca]) {
            precipitacionPorAnio[itemPrec.anio][itemPrec.Finca] = 0;
        }
        precipitacionPorAnio[itemPrec.anio][itemPrec.Finca] += itemPrec.totalLLuvia;
    });

    const años = Object.keys(precipitacionPorAnio);
    const fincas = [...new Set(precipitationanio.map((itemPrec) => itemPrec.Finca))];
    //console.log({años: años})
    //console.log({fincas: fincas})
    //console.log({fincasPrecipitacion: precipitacionPorAnio})
    //<h2>Milimetros de LLuvia x Año</h2>

  return (
    <PropertyTableContainer>
     {iterations?
     <>
     <br/>     
     <PropertyTable>
       <thead>      
            <PropertyTableHeaderRow>
                <PropertyTableHeader>Fincas</PropertyTableHeader>
                {años.map((año) => (
                    <PropertyTableHeader key={año}>{año}</PropertyTableHeader>
                ))}
            </PropertyTableHeaderRow>          
        </thead>
        <tbody>
            {fincas.map((finca) => (
                <PropertyTableRow key={finca}>
                    <PropertyTableHeader>                    
                            {finca}                   
                    </PropertyTableHeader>
                    {años.map((año) => (
                    <PropertyTableCell key={`${finca}-${año}`}>                    
                        {precipitacionPorAnio[año][finca] || 0}                    
                    </PropertyTableCell>
                    ))}
                </PropertyTableRow>
            ))}
        </tbody>
      </PropertyTable> 
      </>
      :<Spinner/>} 
    </PropertyTableContainer>
  )
}

