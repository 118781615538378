import { useState } from "react";
import { ModalReception } from "./Components/ModalReception";
import { ButtonTransport, PropertyTable, 
         PropertyTableCell, 
         PropertyTableCellTotal, 
         PropertyTableContainer, 
         PropertyTableHeader, 
         PropertyTableHeaderRow, 
         PropertyTableRow } from "./styles"


export const ReceptionFarms = (props) => {

    const [modal, setModal] = useState(false);
    const [animarModal, setAnimarModal] = useState(false);
    const [idReception, setIdReception] = useState(null);

    let receptions = []
    receptions = (props.receptions.map((item)=>
        item
    ))   
    //console.log({receptions: receptions})
    const handleModalReceptionFarm = (idDetail)=>{
        setModal(true)
        setIdReception(idDetail);
        //console.log({idDetail: idDetail})  
        setTimeout(() => {
          setAnimarModal(true)
        }, 300);
    }
    //Sumar totales
    let totalReccibido = 0;    
    if(Object.keys(receptions).length > 0 ){
        //console.log('en submitCant')
          let a = 0;
                     
          for (let i = 0; i < receptions.length ; i++){          
              a = a + receptions[i].cantidad;
                            
          }
          totalReccibido = a;
          
                
    }else{
        totalReccibido = 0;        
    }
    //console.log(totalReccibido)
  return (
    <PropertyTableContainer>
         <br/>
         <PropertyTable>
            <thead>
                { modal?'':
                    <PropertyTableHeaderRow>
                        <PropertyTableHeader>FINCA</PropertyTableHeader>
                        <PropertyTableHeader>PRODUCTO</PropertyTableHeader>
                        <PropertyTableHeader>CANTI</PropertyTableHeader>
                        <PropertyTableHeader>#REMIS</PropertyTableHeader>
                        <PropertyTableHeader>F.RECIBIDO</PropertyTableHeader>
                        <PropertyTableHeader>PROVEEDOR</PropertyTableHeader>                  
                    </PropertyTableHeaderRow>
                }
            </thead>
            <tbody>
                {receptions.map((item)=>
                    <PropertyTableRow key={item._id}>
                        <PropertyTableHeader>{item.strFinca}</PropertyTableHeader>
                        <PropertyTableCell>{item.strConcepto}</PropertyTableCell>
                        <PropertyTableCell>
                            <ButtonTransport onClick={()=>handleModalReceptionFarm(item)}>{item.cantidad}</ButtonTransport>
                        </PropertyTableCell>
                        <PropertyTableCell>{item.remision}</PropertyTableCell>
                        <PropertyTableCell>{item.dtmFecha.split('T')[0]}</PropertyTableCell>
                        <PropertyTableCell>{item.entrega}</PropertyTableCell>
                    </PropertyTableRow>
                
                )}       
            </tbody>
            <tfoot>
                <PropertyTableRow>
                    <PropertyTableHeader></PropertyTableHeader>                                 
                    <PropertyTableCellTotal>Total:</PropertyTableCellTotal>                                                                                                                                                                                
                    <PropertyTableCell>           
                    <span>{totalReccibido}</span>                 
                    </PropertyTableCell>
                </PropertyTableRow>
            </tfoot>
         </PropertyTable>
         {modal && <ModalReception
                    setModal={setModal}
                    animarModal={animarModal}
                    detail={idReception}/>                                            
         }
    </PropertyTableContainer>
  )
}

