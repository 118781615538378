import { useEffect, useState } from 'react'
import { useAuth } from '../../../../hooks/useAuth';
import { useRdt } from '../../../../hooks/useRdt';
import { getToken, removeToken } from '../../../../utils/TokenLS';
import { showAlert, SW_ICON } from '../../../../utils/swAlert';
import { IoCloseCircleOutline, IoBookmark, IoArrowBack } from "react-icons/io5";
import { AnalysesPropertyTypesContainerColum, AnalysesPropertyTypesContainerRow, CerrarAnalysesRdt, CerrarAnalysesRdtInt, FormControlAnalysesRdt, FormularioAnalysesRdt, PropertyTable, PropertyTableCell, PropertyTableContainer, PropertyTableHeader, PropertyTableRow, TittleAnalysesRdt } from './styles';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { HTTP_VERBS, requestHttp } from '../../../../utils/HttpRequest';
import { ButtonIconRibbon } from '../../../../components/ButtonIconRibbon';
import { DetailsAnalysesRdts } from '../DetailsAnalysesRdts';
import { DetailAnalysesRdtsInv } from '../DetailAnalysesRdtsInv';
import { DetailAnalysesRdtsInvGua } from '../DetailAnalysesRdtsInvGua';

const colors = [ 
    {id:1, cinta:'brown', name:'CAFE', nameCorto:'CA'},
    {id:2, cinta:'black', name:'NEGRA', nameCorto:'NE'}, 
    {id:3, cinta:'orange', name:'NARANJA', nameCorto:'NA'}, 
    {id:4, cinta:'green', name:'VERDE', nameCorto:'VE'}, 
    {id:5, cinta:'yellow', name:'AMARILLA', nameCorto:'AM'},   
    {id:6, cinta:'white', name:'BLANCA', nameCorto:'BL'},
    {id:7, cinta:'blue', name:'AZUL', nameCorto:'AZ'},
    {id:8, cinta:'gray', name:'GRIS', nameCorto:'GR'},   
    {id:9, cinta:'purple', name:'MORADA', nameCorto:'MO'},    
    {id:10, cinta:'red', name:'ROJA', nameCorto:'RO'},   
];

const codLborEmb = 118;

export const DetailsAnalysesRdtsGua = ({setModal, animarModal, setAnimarModal, ibm,fechaini,fechafin,codLabor,cintas, global, sucursalName}) => {
    //console.log({ibm: ibm})
    //console.log({fechaini: fechaini})
    //console.log({fechafin: fechafin})
    //console.log({codLabor: codLabor})
    //console.log({cintas: cintas})
    //console.log({global: global})

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, reload, setReload, rdtEmploye  } = useRdt();

    //Buscar en calendario según fecha que se esta trabajando
    const [ calendar, setCalendar ] = useState([]);
    //Buscar analisis de rdts por cinta,lote, si es Prema o Pres, cant
    const [ rdtsudps, setRdtsudps ] = useState([]);
    const [ rdtsudpslote, setRdtsudpslote ] = useState([]);        
    
    //Token valido o no expirado
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token){
                    hendleCloseSeccion()
                } return                  
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[auth])
    //date       
    useEffect(() => {
            const getData = async ()=>{
                //console.log('obtener calendar');
                try {
                    const token = getToken();
                    if(!token) return                
                    //await  cleanStates();                
                    await getCalendar();
                    await getRdtsUdp();
                } catch (error) {
                    console.log(error)
                }             
            }    
            getData();         
                  
    },[ibm,fechaini,fechafin,codLabor])    
    
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        
    }
    //Boton de cerrar
    const ocultarAuthorizationsRdt = ()=>{      
        //setAnimarModal(false)
        setModal(false)
              
    }
    //getCalendars para prematuro y presente
    const getCalendar = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',                         
                    params: makePropertiesFiltersCalendar()                                     
                }
            );             
            const {data} = response;                
            setCalendar(data); 
            //console.log({calendar: data});       
            //console.log(calendar[0].cintaPm);    
            //console.log(calendar[0].cintaPs);                 
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersCalendar = ()=>{
        const filters = {};                     
            filters['Fecha_FIN_INV'] = fechafin; 
            filters['tipo'] = 'udps';                          
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }
    //getRdts x udp x cinta x tipo Pm o Ps
    const getRdtsUdp = async ()=>{
            //console.log({sucursal: sucursalSeleted})
            if(!fechaini){
              setRdtsudps([])
              return
              //console.log('Fecha vacia')
            }
            if(!fechafin){
                setRdtsudps([])
                return
                //console.log('Fecha vacia')
              }
            if(ibm === '' || ibm === 0 || ibm === '90021' || ibm === '90020'){
              setRdtsudps([])
              return
              //console.log({nohacerConsulta: sucursalSeleted})        
            }else{
              try {                      
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts/analysesudps',                         
                        params: makePropertiesFilters()                                     
                    }
                );             
                const {data} = response;                
                data?setRdtsudps(data):console.log('');
                //console.log({rdtsUdps: data})        
                } catch (error) {
                    if(error.response.status === 401){
                        hendleCloseSeccion();
                    }else{
                        console.log(error)
                    }
                }
                  //console.log({hacerConsulta: sucursalSeleted})        
            }                   
    }
    const makePropertiesFilters = ()=>{
        const filters = {};                
            filters['Fecha_INI'] = fechaini; 
            filters['Fecha_FIN'] = fechafin; 
            filters['IbmLaboro'] = ibm;   
            filters['IntCodigo_CONC'] = codLabor;            
          // filters['employeesId'] = auth.data._id;     
          //console.log({fechafin: fechafin});
         
        return filters;       
    }
    //1. Unimos calendar que contiene el anio y semana con rdtsUdps
    //1.1 Función para verificar si una fecha está dentro de un rango
    const fechaDentroDelRango = (fecha, fechaInicio, fechaFin) => {
        return fecha >= fechaInicio && fecha <= fechaFin;
    };    
    //1.2 Iterar sobre rdtsudps y comprobar si las fechas coinciden con alguna semana en calendar
    rdtsudps.forEach(itemDos => {
        const { fmin, fmax } = itemDos;
        //Inicio con el calendario
        calendar.forEach(itemUno => {
            const { fini, ffin, semana, anio } = itemUno;
            if (
                fechaDentroDelRango(fmin, fini, ffin) ||
                fechaDentroDelRango(fmax, fini, ffin)
            ) {
                // Si la fmin o fmax está dentro del rango, agregar anio y semana a rdtsudps
                itemDos.semana = semana;
                itemDos.anio = anio;
            }
        }); 
        //Continuo agregando aneta y abruta de global
        global.forEach(itemTres =>{
            if(Number(itemTres.Lote) === itemDos.Lote){
                itemDos.A_Neta = itemTres.A_Neta
            }
        })       
    });
    //console.log({rdtsudps: rdtsudps});

    //1.3 Eliminamos los objetos con lote igual a cero
    const rdtsudpsuno = rdtsudps.filter(objeto => objeto.Lote !== 0 );    
    //console.log({rdtsudpsuno: rdtsudpsuno })

    //2. Le hago un distinc a global y saco todos los lotes de la finca
    const Lotes = [...new Set(global.map((itemLote) => itemLote.Lote))];
    //console.log({Lotes: Lotes})
    //2.0 Le hago un distinc a global a las semanas
    const Semanas = [...new Set(calendar.map((itemSem) => itemSem.semana))];
    //console.log({Semanas: Semanas})
    //4. Le hago un distinc a rdtsudpsuno a las cintaS
    const Cintas = [...new Set(rdtsudpsuno.map((itemCinta) => itemCinta.cinta))];
    //console.log({Cintas: Cintas})    
    //5. Agrego a las cintas el color de la cinta
    const cintasColors = [];
    //5. Iterar sobre Cintas y agregar el nombre de la cinta en el arreglo cintasColors
    Cintas.forEach(itemDos => {        
        //cintasColors = [{codCinta: itemDos, color:colors.id[itemDos].name}]    
        //console.log({itemDos:itemDos}) 
        //console.log({ColorsName: colors.name})
        const color = colors.find(color => color.name == itemDos)
        if(color){
          //cintaPs = color.cinta
          //nameCintaPs = color.name
          //console.log({ColorsName: color.name})
          const sem = calendar.find(cinta=>cinta.cintaPm === color.id)
          //console.log({semCal: sem.semana})
          cintasColors.push({
            id: color.id,
            cintaName: itemDos,
            nameCorto: color.nameCorto,
            cinta: color.cinta,
            semana: sem.semana,
            orden: cintasColors.length
          })
        }
                
    });
    //console.log({cintasColors: cintasColors}) 
    //Ordenar las cintas en forma descendente
    cintasColors.sort((a, b) => b.semana - a.semana);
    //Colocar index de semana a cintaColors
    const cintasColorsem = cintasColors.reduce((acc, curr) => {
        // Creamos la clave para la semana si no existe
        if (!acc[curr.cintaName]) {
            acc[curr.cintaName] = {orden:curr.semana};
        }        
        return acc;
    }, {});
    //console.log({cintasColorsem: cintasColorsem})
    //2.1 Organizar el calendario por index de sem
    const semCalendar = {}
    calendar.forEach(item =>{
        //si la sem no esta la inicializo
        if(!semCalendar[item.semana]){
            semCalendar[item.semana]={anio:0, cintaPm: 0, cintaPs: 0, fini: '', ffin: ''};
        }  
        //Agregar items
        semCalendar[item.semana].anio = item.anio;
        semCalendar[item.semana].cintaPm = colors.find(color => color.id == item.cintaPm);
        semCalendar[item.semana].cintaPs = colors.find(color => color.id == item.cintaPs);;
        semCalendar[item.semana].fini = item.fini;
        semCalendar[item.semana].ffin = item.ffin;

        //const color = colors.find(color => color.id == item.cintaPm)
        //Recuperar color de cinta para labor 119 guantelete
        //console.log({color:color})
            
    })    
    //console.log({semCalendar: semCalendar })
        
    //3. Crear un nuevo arreglo para almacenar los resultados de rdtsudpsuno
    const nuevoArreglo = [];
    //3.1 Crear un conjunto para rastrear las combinaciones únicas de año, semana
    const combinacionesUnicas = new Set();
    //3.2 Iterar sobre los datos de rdtsudpsuno originales y guardar un nuevoArreglo 
    for (const cantidadLotes of rdtsudpsuno) {
        const { anio, semana, Lote, cinta, total, fmin, fmax } = cantidadLotes; //Campos de rdtsudpsuno
        const combinacion = `${anio}-${semana}`;

        if (!combinacionesUnicas.has(combinacion)) {
            combinacionesUnicas.add(combinacion);//Guardo las combinaciones unicas de anio-semana
        }
        
        //Guardo los datos de rdtsudpsuno solo de las combinaciones encontradas por anio-semana en nuevoArreglo
        nuevoArreglo.push({            
            anio,
            semana,
            Lote,
            cinta,            
            total,            
            fmin,
            fmax         
        });
        
    }
    //console.log({combinacionesUnicas: combinacionesUnicas})
    //console.log({nuevoArreglo: nuevoArreglo})
        
    //4. Completar con los lotes faltantes por semana para luego ser visualizados 
    for (const combinacion of combinacionesUnicas) {
        const [anio, semana] = combinacion.split('-');
        const lotesRegistrados = new Set();

        //4.1 Identificamos los lotes que ya estan en nuevoArreglo
        for (const identificarLote of nuevoArreglo) {
            if (identificarLote.anio === parseInt(anio) && identificarLote.semana === parseInt(semana)) {
                
            }
        }
        //console.log({lotesRegistrados: lotesRegistrados})
       
        //4.2 Agregamos los lotes faltantes a nuevoArreglo
        for (const codLotes of Lotes) {           
            if (!lotesRegistrados.has(codLotes)) {
                nuevoArreglo.push({                    
                    anio: parseInt(anio),
                    semana: parseInt(semana),
                    Lote: Number(codLotes),
                    cinta: '',                    
                    total: 0,                    
                    fmin:'',
                    fmax:'' 
                });
            }            
        }            
    } 
    //console.log({nuevoArreglo: nuevoArreglo})

    //5. Iterar sobre nuevoArreglo y agregar A_Neta, A_Bruta de Global y orden de cintasColors
    nuevoArreglo.forEach(itemDos => {
        //Continuo agregando aneta y abruta de global
        global.forEach(itemTres =>{
            if(Number(itemTres.Lote) === itemDos.Lote){
                itemDos.A_Neta = itemTres.A_Neta
                itemDos.A_Bruta = itemTres.A_Bruta
                itemDos.Vueltas = itemTres.Vueltas
            }
        })
        //console.log({itemDoscinta: itemDos.cinta})
        //console.log({itemCintacolorssem: cintasColorsem[itemDos.cinta]})

        const filterColor = cintasColors.filter(item=>item.cintaName == itemDos.cinta)
        if(filterColor[0]){
            itemDos.orden = filterColor[0].semana
            //console.log({filterColororden: filterColor[0].semana})
            //console.log({filterColor: filterColor})
        }else{
            itemDos.orden = 0
            //console.log({filterColorsinOrden: filterColor})
        }
        //console.log({filterColor: filterColor})
        
    });
    //Ordenar nuevoArreglo en forma descendente por orden
    nuevoArreglo.sort((a, b) => b.orden - a.orden);
    //console.log({nuevoArreglo: nuevoArreglo})

    //Mostrar
    const analisisTotales = {}; 
    //const colorsSem = {};  
    let totalNetaG = 0;
    let totalBrutaG = 0;
    let totalGlobal = 0;
    //6. Función para organizar los elementos por semana y lote
    const organizarPorSemanaYLote = () => {
        const analisisOrganizado = {};

        nuevoArreglo.forEach(item => { 
                        
            // Si la semana no está en el objeto, inicializarla
            if (!analisisOrganizado[item.semana]) {
                analisisOrganizado[item.semana] = {};
            }
            // Si el lote no está en la semana, inicializarlo
            if (!analisisOrganizado[item.semana][item.Lote]) {
                analisisOrganizado[item.semana][item.Lote] = {suma: 0, A_Neta: 0, A_Bruta: 0, F_MIN:'', F_MAX:''};
            }
            // Si la cinta no esta en el lote, inicializarlo
            if (!analisisOrganizado[item.semana][item.Lote][item.cinta]) {
                analisisOrganizado[item.semana][item.Lote][item.cinta] = { };
            }
               
            // Agregar la A_Bruta del lote
            analisisOrganizado[item.semana][item.Lote].A_Bruta = item.A_Bruta;
            // Agregar la A_Neta del lote
            analisisOrganizado[item.semana][item.Lote].A_Neta = item.A_Neta;
            // Agregar la suma de cantidad PM + PS a la nueva columna suma
            analisisOrganizado[item.semana][item.Lote].suma += item.total;
            // Agregar otros campos
            analisisOrganizado[item.semana][item.Lote].F_MIN = item.fmin;
            analisisOrganizado[item.semana][item.Lote].F_MAX = item.fmax;
            // Agregar otros campos
            analisisOrganizado[item.semana][item.Lote][item.cinta] = item.total;

            //Sumar el total de todas las cintas realizados en el rango consultado por semana
            if (!analisisTotales[item.semana]) {
                analisisTotales[item.semana] = {totalSem: 0};
            }
            analisisTotales[item.semana].totalSem += item.total 
            //Sumar el total de todas las cintas realizados en el rango consultado
            totalGlobal += item.total;
            
            /*//Sacar el listado de colores por semana e inicializo la semana si no existe
            if(!colorsSem[item.semana]){
                colorsSem[item.semana] = {};
            }

            if(!colorsSem[item.semana][item.cinta]){
                colorsSem[item.semana][item.cinta] = {};
            }
            //colorsSem[item.semana][item.cinta].orden = */

        });
        //1.3 Eliminamos los objetos de colorsSem con index igual a ""
        // Iteramos sobre cada semana en colorSem
       /* Object.keys(colorsSem).forEach(semana => {
            // Filtramos las claves que no estén vacías
            colorsSem[semana] = Object.fromEntries(
                Object.entries(colorsSem[semana]).filter(([color, _]) => color !== '')
            );
        });*/
       
        //console.log({rdtsudpsuno: rdtsudpsuno })
        //console.log({colorsSem: colorsSem[0].length})//
        //Para la suma de areas netas y brutas totales        
        global.forEach(item=>{            
            totalNetaG += item.A_Neta
            totalBrutaG += item.A_Bruta            
        }) 
        //console.log({totalNeta: totalNetaG})
        //console.log({totalBruta: totalBrutaG})        
        //console.log({totalGlobal: totalGlobal})

        return analisisOrganizado;
    }; 
    //organizarPorSemanaYLote()   
    //7. Sacar los totales por cinta    
    // Creamos un objeto para almacenar la suma de la cantidad de cada color de cinta
    const sumaPorColor = nuevoArreglo.reduce((acc, curr) => {
        // Creamos la clave para la semana si no existe
        if (!acc[curr.semana]) {
            acc[curr.semana] = {};
        }
        // Creamos la clave para el color de cinta si no existe en la semana actual
        if (!acc[curr.semana][curr.cinta]) {
            acc[curr.semana][curr.cinta] = curr.total;
        } else {
            // Si el color de cinta ya existe en la semana actual, sumamos la cantidad
            acc[curr.semana][curr.cinta] += curr.total;
        }
        return acc;
    }, {});
    //console.log({sumaPorColor:sumaPorColor});
  
    // Función para renderizar las tablas por semana
    const renderTablasPorSemana = () => {
        const analisisPorSemanaYLote = organizarPorSemanaYLote(); 
        //console.log({analisisPorSemanaYLote:analisisPorSemanaYLote})               
        //const totalesAnalisis = totalesAnalisisorganizado();           
        //console.log({totalesAnalisis:totalesAnalisis})
       
        

        return Object.keys(analisisPorSemanaYLote).map(semana => (
        <div key={semana}>
            <h4>{semCalendar[semana].anio} Sem: {semana} ({global[0].Codigo}-{global[0].Labor})    <br/>  </h4>
            <PropertyTable>
            <thead>
                <PropertyTableRow>
                    <PropertyTableHeader>LOTE</PropertyTableHeader>                    
                    <PropertyTableHeader>A_BRUTA</PropertyTableHeader>
                    <PropertyTableHeader>A_NETA</PropertyTableHeader>                                                                                                                      
                    {cintasColors.map(cinta=>(                                                
                        <PropertyTableHeader> 
                            <PropertyTableHeader>SM {cinta.semana}</PropertyTableHeader>                           
                            {cinta.nameCorto}                                                       
                            <ButtonIconRibbon icon={IoBookmark} cinta={cinta.cinta} />                            
                        </PropertyTableHeader>
                    ))}
                    <PropertyTableHeader>TOTAL</PropertyTableHeader>
                    
                                                  
                </PropertyTableRow>
            </thead>
            <tbody>
                {Object.keys(analisisPorSemanaYLote[semana]).map(lote => (
                <PropertyTableRow key={`${semana}_${lote}`}>
                    <PropertyTableHeader>{lote}</PropertyTableHeader>                    
                    <PropertyTableCell>{analisisPorSemanaYLote[semana][lote].A_Bruta}</PropertyTableCell>
                    <PropertyTableCell>{analisisPorSemanaYLote[semana][lote].A_Neta}</PropertyTableCell>
                    {cintasColors.map(cinta=>(                         
                        <PropertyTableCell>                            
                            {analisisPorSemanaYLote[semana][lote][cinta.cintaName]}                                                                
                        </PropertyTableCell>
                    ))}                                                            
                    <PropertyTableCell>{analisisPorSemanaYLote[semana][lote].suma}</PropertyTableCell>                                        
                    
                </PropertyTableRow>
                ))}
            </tbody>
            <tfoot>
                <PropertyTableRow>
                    <PropertyTableHeader>TOTAL</PropertyTableHeader>
                    <PropertyTableHeader>{(totalBrutaG).toFixed(2)}</PropertyTableHeader>                    
                    <PropertyTableHeader>{(totalNetaG).toFixed(2)}</PropertyTableHeader>                    
                        {cintasColors.map(cinta=>(
                            <PropertyTableHeader>
                                {sumaPorColor[semana][cinta.cintaName]}
                            </PropertyTableHeader>
                        ))}  
                    <PropertyTableHeader>{(analisisTotales[semana].totalSem).toFixed(0)}</PropertyTableHeader>                              
                </PropertyTableRow>
            </tfoot>
            </PropertyTable>
        </div>
        ));
    };
    // {renderTablasPorSemana()} 

    return(
       <FormControlAnalysesRdt>
            <CerrarAnalysesRdt>
                <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarAuthorizationsRdt} />   
            </CerrarAnalysesRdt>
            {animarModal?
                <FormularioAnalysesRdt>                    
                    <PropertyTableContainer>
                        <AnalysesPropertyTypesContainerRow>                              
                            <AnalysesPropertyTypesContainerColum>
                                <CerrarAnalysesRdtInt>                           
                                    <ButtonIcon icon={IoArrowBack} onPress={ocultarAuthorizationsRdt} />        
                                </CerrarAnalysesRdtInt>                                
                                <AnalysesPropertyTypesContainerColum>                                                               
                                    {renderTablasPorSemana()}
                                </AnalysesPropertyTypesContainerColum>                                
                            </AnalysesPropertyTypesContainerColum> 
                            <AnalysesPropertyTypesContainerColum>
                                <>
                                <DetailAnalysesRdtsInvGua
                                    ibm = {ibm}                                    
                                    fechafin = {fechafin}
                                    codLabor = {codLborEmb}
                                    codLaborGua = {codLabor}
                                    global={global}
                                    sucursalName = {sucursalName}
                                />
                                </>                                
                            </AnalysesPropertyTypesContainerColum>                                                                                    
                        </AnalysesPropertyTypesContainerRow>                        
                    </PropertyTableContainer>
                </FormularioAnalysesRdt>
            :''}
       </FormControlAnalysesRdt>
    )
}