import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRdt } from "../../../hooks/useRdt";
import { useAuth } from "../../../hooks/useAuth";
import { HTTP_VERBS, requestHttp } from "../../../utils/HttpRequest";
import { showAlert, SW_ICON } from "../../../utils/swAlert";
import { getToken, removeToken } from "../../../utils/TokenLS";
import { Page } from "../../../components/Page";
import { FormControl, 
         FormControl_Global,
         FormControlInput,
         FormControlAction, 
         FormControlInputSearch, 
         FormControlInputSearchudp, 
         FormControlInputudp, 
         PropertyTypesContainerColum, 
         PropertyTypesContainerRow, 
         PrecipitationDetailDescriptionWrapper, 
         PrecipitationDetailInfoWrapper, 
         PrecipitationDetailSubTitle, 
         PrecipitationDetailTitle, 
         PrecipitationDetailValueWrapper, 
         PrecipitationDetailWrapper, 
         PrecipitationDetailWrapper_Global, 
         PrecipitationDetailWrapper_Global_DOS, 
         LogoImageWrapper,
         FormControlActionOne,
         FormControlActionTwo} from "./styles";
import { PropertyBusinessType } from "../../../components/PropertyBusinessType";
import { PropertyTypeLabel } from "../../../components/PropertyTypeLabel";
import { ButtonIcon } from "../../../components/ButtonIcon";
import { IoTrashOutline, 
         IoRemoveCircleOutline,
         IoRadioButtonOffOutline,
         IoArrowBackCircleOutline,
         IoArrowBack,
         IoDuplicate,
         IoSendSharp,
         IoSave } from "react-icons/io5";
import swal from 'sweetalert';
import { ButtonIconRemove } from "../../../components/ButtonIconRemove";
import { PageTitle } from "../../../globalStyles";
import Alert from "../../../components/Alert/Alert";

import { ButtonIconUpdateAll } from "../../../components/ButtonIconUpdateAll";

const ALL_SUCURSALES = 0;
const HORA = 10;


export const PrecipitationDetail = () => {

  const { auth, closeSeccionAuth } = useAuth();
  const { setRdtprov } = useRdt();
  const { id } = useParams();

  const [ precipitation, setPrecipitation ] = useState([]);    
  const [ cantidad, setCantidad ] = useState(0);  
  const [ date, setDate ] = useState('');
  
  const [alert, setAlert] = useState({})    

  const navigate = useNavigate();

  const { 
    register, 
    handleSubmit,
    formState:{
        errors,
        isValid
    }  
  } = useForm({ mode: 'onChange'});    

  useEffect(() => {  
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return   
            await getPrecipitation()            
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();              
  },[auth])

  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
  }
  //PrecipitationById
  const getPrecipitation = async ()=>{
     
    try {                      
       const response = await requestHttp(
          {
                method: HTTP_VERBS.GET,
                endpoint: `/precipitations/${id}`                                                                       
          }
       );             
       const {data} = response; 
       //console.log(data)               
       data?setPrecipitation(data):console.log(''); 
       data?setCantidad(data.intPrecipitation):console.log('')  
       data?setDate(data.dtmFecha):console.log('')  
  
       if(data.Ibm === auth.data.ibm && auth.data.role != 1){
          const PrecipitationData = {
              ibm: data.Ibm,
              datePrecipitation: data.dtmFecha
          };
          data?setRdtprov(PrecipitationData):console.log('')  
          //console.log({PrecipitationData: PrecipitationData})
       }else{
          const PrecipitationData = {
              ibm: auth.data.ibm,
              datePrecipitation: data.dtmFecha
          };
          data?setRdtprov(PrecipitationData):console.log('')  
          //console.log({PrecipitationData: PrecipitationData})
       }     
            
    } catch (error) {
         if(error.response.status === 401){
             hendleCloseSeccion();
         }else{
             console.log(error)
         } 
    }
  
  }
  //Update
  const onSubmitsave = async (data) =>{
    //Validaciones
          //Vallido que si este dentro de un rango permitido para actualizar
          //valido si dia es viernes o sabado                          
          if(numeroDia >= 5){
              //rango para el viernes de 4 dias.
              if(numeroDia == 5){                
                  if(rango > 363599999){
                      setAlert({
                          msg: 'Usted ya no puede realizar registros de precipitación para este dia',
                          error: true
                      })
                      //console.log({mensaje: alert.msg}); 
                      setTimeout(()=>{
                          setAlert({})
                      }, 7000)            
                      return
                  }
              }else{
                  //rango para el sabado de 3 dias.                     
                      if(rango > 276999999){
                          setAlert({
                              msg: 'Usted ya no puede realizar registros de precipitación para este dia',
                              error: true
                          })
                          //console.log({mensaje: alert.msg}); 
                          setTimeout(()=>{
                              setAlert({})
                          }, 7000)            
                          return
                      }
              }
          }else{
              //Rango de 2 dias para registrar
              if(rango > 189999999){
                  setAlert({
                      msg: 'Usted ya no puede realizar registros de precipitación para este dia',
                      error: true
                  })
                  //console.log({mensaje: alert.msg}); 
                  setTimeout(()=>{
                      setAlert({})
                  }, 7000)            
                  return
              }
          }    
    //Validaciones
    //Valido Fecha
    if(fecha > hoy){
        setAlert({
            msg: 'Fecha no puede ser "mayor" a la fecha actual',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return     
    }
    //Cantidad de precipitación para actualizar.
    if(!data.cantidad){
        setAlert({
            msg: 'Se requiere precipitación del dia',
            error: true
        }) 
        //console.log({mensajeincludes: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return    
    }    
    setAlert({});                 
    try {              
        const dataPrecipitation = {};      
        dataPrecipitation['cantidad'] = data.cantidad;                                       
        
        const response = await requestHttp(
            {
              method: HTTP_VERBS.PUT,
              endpoint: `/precipitations/${id}`,
              body: dataPrecipitation,
              token: getToken()
            }
        );            
        /*setAlert({
            msg: response.data,
            error: false
        })*/
        await getPrecipitation();
        showAlert(
            'Actualizado', 
            "Correctamente", 
            SW_ICON.SUCCESS,   
            () => { navigate('/addPrecipitation')}               
        );           
                                   
    } catch (error) {
        //console.log(error)
        setAlert({
            msg: error.response.data,
            error: true
        })
        setTimeout(()=>{
            setAlert({})                
        }, 10000) 
    }     
        
  }
  //Delete Precipitation
  const showAlertYN = () =>{
        //console.log({id: id})
        //Vallido que si este dentro de un rango permitido para eliminar
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);      
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                        showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);             
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){                
                showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);  
                return
            }
        }
        swal({
            title: "Esta usted seguro?",
            text: "Una vez eliminado, no podras recuperar este registro!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {   
                precipitationDelete()                
            } else {
            //swal("Su registro esta a salvo!");
            return
            }
            //console.log(willDelete)
        });
  }
  const precipitationDelete = async () =>{             
        try {           
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.DELETE,
                    endpoint: `/precipitations/${id}`,               
                    token: getToken()               
                }
            );
              
            showAlert(
                'precipitation', 
                "Eliminado Correctamente", 
                SW_ICON.SUCCESS,   
                () => { navigate('/addPrecipitation')}                 
            );                              
                
            } catch (error) { 
                if(error.response.status === 401){
                    //hendleCloseSeccion();
                }else{
                    showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
                }            
            }        
  }

  //Validar dia de la semana
  const fechaComoCadena = date.split('T')[0] +  " 00:01:59"; // día semana
  const dias = [
      'DOMINGO',
      'LUNES',
      'MARTES',
      'MIERCOLES',
      'JUEVES',
      'VIERNES',
      'SABADO',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  const nombreDia = dias[numeroDia];
  
  const fecha = new Date(fechaComoCadena);
  //hoy
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  
  const rango = new Date(hoy - fecha);
  const tiempo = new Date(189999999);
  
  //console.log({rango: rango})
  //console.log({tiempo: tiempo})
  //console.log("Fecha actual: ", hoy); 
  //console.log("Calendario: ", fecha); 
  //console.log("NoDia: ", numeroDia);    
  //console.log("nombre dia: ", nombreDia); 
  

  const { msg } = alert;


  return (
    <Page>
        <PrecipitationDetailWrapper_Global>
            <PrecipitationDetailWrapper_Global_DOS>
                <LogoImageWrapper>
                    <PageTitle>
                    EDITAR PRECIPITACIÓN
                    </PageTitle>
                    <img src={"../Logodos.jpg"} alt="imgLogo"/>
                </LogoImageWrapper>
                { msg && <Alert alert = {alert} />}
                <PrecipitationDetailWrapper>
                    <PrecipitationDetailInfoWrapper>
                        <PrecipitationDetailDescriptionWrapper>
                            <PropertyTypesContainerRow>
                                <PropertyTypesContainerColum>
                                    <PrecipitationDetailValueWrapper>
                                        Finca Reporta: 
                                        <h2>{precipitation.strFinca}</h2>
                                        <h3>Ibm: {precipitation.Ibm} </h3>
                                        <PropertyBusinessType
                                            busineesType={`Fecha de la Precipitación: ${precipitation.dtmFecha?precipitation.dtmFecha.split('T')[0]:''} (${nombreDia})`}
                                        />
                                    </PrecipitationDetailValueWrapper>
                                </PropertyTypesContainerColum>
                            </PropertyTypesContainerRow>
                        </PrecipitationDetailDescriptionWrapper>
                        <FormControlAction>
                            <FormControlActionOne>                  
                                <ButtonIcon icon={IoSave} onPress={handleSubmit(onSubmitsave)} />                    
                                <ButtonIcon icon={IoTrashOutline} onPress={showAlertYN} />                   
                            </FormControlActionOne>                  
                            <FormControlActionOne>                   
                                <PropertyTypeLabel 
                                    typeId= {`${precipitation.employeesId?precipitation.employeesId.name:''}`}
                                />&nbsp;&nbsp;               
                                <PropertyTypeLabel 
                                        typeId= {`FC: ${precipitation.dtmFechaCrea?precipitation.dtmFechaCrea:''}`}                                                      
                                />                    
                            </FormControlActionOne>                        
                    </FormControlAction>
                    </PrecipitationDetailInfoWrapper>
                </PrecipitationDetailWrapper>
                <form>                            
                <FormControl>
                    <FormControl>                                                                                                                             
                            <FormControl_Global>
                                <FormControlInputSearch>
                                        <label>MM LLUVIA</label>
                                        <input  type="number"                                    
                                                {...register("cantidad")}
                                                value={cantidad}
                                                onChange={e => setCantidad(e.target.value)}   
                                                tabIndex="15"                                             
                                                id="input5"
                                                                                                                                                                                            
                                        />                                    
                                </FormControlInputSearch>
                            </FormControl_Global>                                                                                                                                                                                         
                    </FormControl>      
                </FormControl>
                <FormControlActionTwo>
                        <ButtonIcon icon={IoArrowBack} onPress={() => { navigate('/addPrecipitation')}} />
                </FormControlActionTwo>
                </form>
            </PrecipitationDetailWrapper_Global_DOS>
        </PrecipitationDetailWrapper_Global>
    </Page>
  )
}

