import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, removeToken } from "../../../../../../utils/TokenLS";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../../../../../utils/HttpRequest";
import { showAlert, SW_ICON } from "../../../../../../utils/swAlert";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ButtonIcon } from "../../../../../../components/ButtonIcon";
import { PropertyBusinessType } from "../../../../../../components/PropertyBusinessType";
import { FormControl, FormControlInput } from "../../../../../../globalStyles";
import { ButtonAplicate, CerrarModal, 
         FormControlModal, 
         FormularioModal, 
         ModalDetailDescriptionWrapper, 
         ModalDetailValueWrapper, 
         ModalPropertyTypesContainerColum, 
         ModalPropertyTypesContainerRow, 
         PropertyTable, 
         PropertyTableCell, 
         PropertyTableHeader, 
         PropertyTableRow, 
         TittleModal } from "./styles"

const suma = 0;

export const ModalAplicateDos = ({setModal, animarModal, setAnimarModal, detail}) => {

  let detailAplicate = {}
  detailAplicate = (detail)   
  //console.log('estoy en modal consultado por finca')
  //console.log({modalModal: setModal})
  console.log({detailAplicate: detailAplicate})
  
  const [ rdts, setRdts ] = useState([]);
  const [ rdtsDia, setRdtsDia ] = useState([]);
  const [ sucursaludps, setSucursaludps ] = useState([]);
  const [ concepto, setConcepto ] = useState([]);
  const [ analysesrdts, setAnalysesrdts ] = useState([]);
  const [ mayores, setMayores ] = useState([]);
  const [ menores, setMenores ] = useState([]);
  const [ precipitation, setPrecipitation ] = useState([]);
  const [ rdtsdiaprecipitacion, setRdtsDiaPrecipitacion ] = useState([]); 

  const [ sumtotal, setSumtotal ] = useState(suma);
  const [ sumcant, setSumcant ] = useState(suma);
  const [ sumcantdos, setSumcantdos ] = useState(suma);
  const [ sumcanttres, setSumcanttres ] = useState(suma);
  const [ sumcantcuatro, setSumcantcuatro ] = useState(suma);
  const [ sumhoras, setSumhoras ] = useState(suma);

  const [ dateselect, setDateselect ] = useState('');

  const navigate = useNavigate();

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                                         
             getUdps();
             getConcepto();             
             detailAplicate.aplicado == 0?console.log(''):getRdts(); 
             detailAplicate.aplicado == 0?console.log(''):getRdtsAgrupadosDia();
             detailAplicate.aplicado == 0?console.log(''):getPrecipitation();                                        
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[detailAplicate.Ibm])
  //Concepts
  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                                              
            onSubmitUdps();                                                              
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[concepto])
  //Rdts
  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                                              
            onSubmitUdps(rdts);                                                              
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[rdts])
  //analysesrdts
  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                               
           await analysesrdts?onSubmitCantidad():console.log('');                                                                        
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[analysesrdts])
  //Rdts
  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                                              
            detailAplicate.aplicado == 0?console.log(''):onSubmitRdtsdiaprecipitacion();                                                              
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[precipitation, rdtsDia])
  //getUdps
  const getUdps = async ()=>{
         
    if(detailAplicate.Ibm === '' || detailAplicate.Ibm === 0 || detailAplicate.Ibm === '90021' || detailAplicate.Ibm === '90020'){
      setSucursaludps([])
      //console.log({nohacerConsulta: detailAplicate.Ibm}) 
      return             
    }else{
      try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/udps',                         
                params: makePropertiesFilters()                                     
            }
        );             
        const {data} = response;                
        data?setSucursaludps(data):console.log(''); 
        //console.log({udps: data})                 
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
          //console.log({ConsultaUdps: sucursaludps})        
    }                   
  }
  //Concept
  const getConcepto = async ()=>{        
    if(detailAplicate.Ibm){
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/conceptossucursal/allconceptos',                         
                    params: makeConceptoFilters()                                     
                }
            );             
            const {data} = response; 
            //console.log({concepto: data})   
            data ? setConcepto(data):setConcepto({}); 
                                                                                                  
        } catch (error) {
            console.log(error)
        }
        
    }else{
        //setConcepto({});
    } 
  }  
  const makeConceptoFilters = ()=>{
    const filters = {};  
    filters['Ibm'] = detailAplicate.Ibm;            
    filters['IntCodigo'] = detailAplicate.Codigo;    
    //console.log({filters: filters});         
    return filters;            
  }
  //getRdts
  const getRdts = async ()=>{

    if(detailAplicate.Ibm === '' || detailAplicate.Ibm === 0 || detailAplicate.Ibm === '90021' || detailAplicate.Ibm === '90020'){
      setRdts([])
      return
      //console.log({nohacerConsulta: sucursalSeleted})        
    }else{
      try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/Rdts',                         
                params: makePropertiesFilters()                                     
            }
        );             
        const {data} = response;                
        data?setRdts(data):console.log('');  
        //console.log({rdts: data})        
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
          //console.log({hacerConsulta: sucursalSeleted})        
    }                   
  }
  const makePropertiesFilters = ()=>{
    const filters = {};                
        filters['Fecha_INI'] = detailAplicate.FINI; 
        filters['Fecha_FIN'] = detailAplicate.FFIN; 
        filters['IbmLaboro'] = detailAplicate.Ibm;   
        //filters['IntCodigo_EMPL'] = codtrabajador;    
        filters['IntCodigo_CONC'] = detailAplicate.Codigo;
        //filters['IntCodigo_UDP'] = codudp;
        filters['Ibm'] = detailAplicate.Ibm; 
        filters['intClase'] = 2;   
      // filters['employeesId'] = auth.data._id;     
      //console.log({fechafin: fechafin});
     
    return filters;       
  }
  //getRdtsAgrupados por Dia
  const getRdtsAgrupadosDia = async ()=>{
  
    if(detailAplicate.Ibm === '' || detailAplicate.Ibm === 0 || detailAplicate.Ibm === '90021' || detailAplicate.Ibm === '90020'){
      setRdts([])
      return
      //console.log({nohacerConsulta: sucursalSeleted})        
    }else{
      try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/Rdts/supplies',                         
                params: makePropertiesFiltersDos()                                     
            }
        );             
        const {data} = response;                
        data?setRdtsDia(data):console.log('');  
        console.log({rdtsDias: data})        
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
          //console.log({hacerConsulta: sucursalSeleted})        
    }                   
  }
  const makePropertiesFiltersDos = ()=>{
    const filters = {};                
        filters['Fecha_INI'] = detailAplicate.FINI; 
        filters['Fecha_FIN'] = detailAplicate.FFIN; 
        filters['IbmLaboro'] = detailAplicate.Ibm;   
        //filters['IntCodigo_EMPL'] = codtrabajador;    
        filters['IntCodigo_CONC'] = detailAplicate.Codigo;
        //filters['IntCodigo_UDP'] = codudp;
        filters['Ibm'] = detailAplicate.Ibm; 
        filters['intCiclo'] = detailAplicate.Ciclo;
        //Para aplicar la consulta correcta de la agrupacion por dia, se debe enviar este parametro.   
        filters['intTrab'] = 1;   
      // filters['employeesId'] = auth.data._id;     
      //console.log({fechafin: fechafin});
      console.log({filters: filters});
     
    return filters;       
  }
  //getPrecipitation
  const getPrecipitation = async ()=>{                
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/precipitations',                         
                params: makePropertiesFiltersPrecipitation()                                     
            }
        );             
        const {data} = response;                
        setPrecipitation(data);
        //console.log({dataPreci: data})                     
    } catch (error) {
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        }
    }    
  }
  const makePropertiesFiltersPrecipitation = ()=>{
      const filters = {};                
          filters['Fecha_INI'] = detailAplicate.FINI; 
          filters['Fecha_FIN'] = detailAplicate.FFIN;         
          filters['Ibm'] = detailAplicate.Ibm; 
          //Se envia el Group 2 para que el orden sea por fecha  
          //filters['Group'] = 2;  
          //filters['employeesId'] = auth.data._id;              
          //console.log(fechaini);
      return filters;       
  } 
  //Unir  con la lluvia
  const onSubmitRdtsdiaprecipitacion = async () =>{
    //Recorro Rdtsagrupados por dia con la precipitacion y encuentro las precipitaciones que correspondene a cada dia 
    const nuevaLista = rdtsDia.map(item1 => {
      const item2 = precipitation.find(item2 => (item2.dtmFecha === item1.Fecha));
      return {
        ...item1,
        lluvia: item2 ? item2.intPrecipitation : 0
      };
    });
    //console.log({nuevalista: nuevaLista})         
    setRdtsDiaPrecipitacion(nuevaLista) 

  }
  //Calculos por lote
  const onSubmitUdps = async (data) =>{         
    //console.log({enOnSumitUdps:data})           
    let products = [];
    if(sucursaludps.length >= 1){ 
      //console.log('Dentro del si')   
        //Recorro solo el concepto filtrado          
        for (let i = 0; i < sucursaludps.length ; i++){  
            
            let acumCant = 0;

            for (let b = 0; b < data.length ; b++){     
                if(sucursaludps[i].IntCodigo === data[b].IntCodigo_UDP){
                    acumCant = acumCant + data[b].intCantidad_UDP
                    //console.log(data[b])  
                    
                }   
                if(sucursaludps[i].IntCodigo === data[b].IntCodigo_UDPdos){
                    acumCant = acumCant + data[b].intCantidad_UDPdos
                    //console.log(data[b])  
                    
                } 
                if(sucursaludps[i].IntCodigo === data[b].IntCodigo_UDPtres){
                    acumCant = acumCant + data[b].intCantidad_UDPtres
                    //console.log(data[b])  
                    
                }           
                if(sucursaludps[i].IntCodigo === data[b].IntCodigo_UDPcuatro){
                    acumCant = acumCant + data[b].intCantidad_UDPcuatro
                    //console.log(data[b])  
                    
                }
                                                                                                                                           
            }
            
            const h = {
                Codigo: detailAplicate.Codigo,
                Labor: detailAplicate.Concepto,
                Vueltas: concepto[0].intVueltas,
                Lote: sucursaludps[i].IntCodigo, 
                A_Neta: sucursaludps[i].intANeta, 
                A_Bruta: sucursaludps[i].intABruta,
                Falta:  acumCant - (sucursaludps[i].intANeta * concepto[0].intVueltas),
                realizado: acumCant
            }
            products.push(h);
            
        } 
        //console.log({products: products})
        setAnalysesrdts(products)
        //console.log({analysesFin: analysesrdts})                       
    }                         
   
  }
  //Sumar cantidad
  const onSubmitCantidad = async (data) =>{    
    if(Object.keys(analysesrdts).length > 0 ){
    //console.log('en submitCant')
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;        
      for (let i = 0; i < analysesrdts.length ; i++){          
          a = a + analysesrdts[i].A_Neta;
          b = b + analysesrdts[i].A_Bruta;
          c = c + analysesrdts[i].realizado;
          d = d + analysesrdts[i].Falta;
          e = e + analysesrdts[i].Falta;          
      }
      setSumtotal(a)
      setSumcant(b)
      setSumcantdos(c)
      setSumcanttres(d)
      setSumcantcuatro(e)
      setSumhoras(0)        
    }else{
      setSumtotal(0);
      setSumcant(0);
      setSumcantdos(0)
      setSumcanttres(0)
      setSumcantcuatro(0)
      setSumhoras(0);
    }        
    //console.log({analysesCant: analysesrdts})   
    await onSubmitMayor();                     
  }
  //Filtrar lotes realizados
  const onSubmitMayor = async (data) =>{        

    if(Object.keys(analysesrdts).length > 0 ){
      //console.log('en submitMAyor')
        
      // Filtrar valor realizado mayores a 0 para determinar donde se realizo aplicacion del lote
      let realizado = analysesrdts.filter(item => item.realizado > 0);
      setMayores(realizado)
      //console.log({mayoresA2: mayores})                           
    }    
  }
  //Seleccionar unicamente por fecha de aplicacion
  const handleRdtsdia = async (data) =>{        
    //Filtro Rdts y encuentro solo la fecha seleccionada         
    const nuevaLista = rdts.filter((item) => item.dtmFecha_RDT === data.Fecha);
    //console.log({nuevalistaRdtdia: nuevaLista})
    onSubmitUdps(nuevaLista);     
    setDateselect(data)
    //console.log({dateselect: dateselect})
  }
  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
    //closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
  }
  //Boton de cerrar Modal
  const ocultarModal = ()=>{      
    //setAnimarModal(false)
    setModal(false)          
  }
  //Validar dia de la semana
  const fechaComoCadena = detailAplicate.FINI; // día semana
  const dias = [      
      'LUNES',
      'MARTES',
      'MIERCOLES',
      'JUEVES',
      'VIERNES',
      'SABADO',
      'DOMINGO',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  const anio = new Date(fechaComoCadena).getFullYear();
  //console.log({numeroAnio: anio})
  const nombreDia = dias[numeroDia];
  //console.log({dateselect: dateselect})
  return (
    
    <FormControlModal>
      <CerrarModal>
        <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarModal} />
      </CerrarModal> 
      {animarModal?
        <FormularioModal>
          <ModalDetailDescriptionWrapper>
            <ModalPropertyTypesContainerRow>
              <ModalPropertyTypesContainerColum>
                  <ModalDetailValueWrapper>
                        <h2>{detailAplicate.Finca} // Ciclo: {detailAplicate.Ciclo} // Estado: {detailAplicate.total - detailAplicate.aplicado == 0?'Completado':
                                                                                       detailAplicate.total - detailAplicate.aplicado == detailAplicate.total?'No Iniciado':
                                                                                       detailAplicate.total - detailAplicate.aplicado > 0?'Incompleto':''}</h2>                                                
                        <PropertyBusinessType
                            busineesType={`Area Neta: ${sumtotal.toFixed(2)}`}
                        />                        
                        <PropertyBusinessType
                            busineesType={`Cant Lotes: ${analysesrdts.length}`}
                        />                        
                        <PropertyBusinessType
                            busineesType={`Bultos Aprobados: ${detailAplicate.total} `}
                        />
                        <PropertyBusinessType
                            busineesType={`Bultos Aplicados: ${detailAplicate.aplicado} ( ${detailAplicate.FINI.split('T')[0]} // ${detailAplicate.FFIN.split('T')[0]} ) `}
                        />
                        <PropertyBusinessType
                            busineesType={`Bultos Pendientes: ${detailAplicate.total - detailAplicate.aplicado}`}
                        />                       
                  </ModalDetailValueWrapper>
              </ModalPropertyTypesContainerColum>
            </ModalPropertyTypesContainerRow>
            <ModalPropertyTypesContainerRow>                                 
              <ModalPropertyTypesContainerColum>
                  <PropertyBusinessType
                      busineesType={`"${rdtsdiaprecipitacion.length}" Días han tomado fertilizar, a continuación se indican estos días:`}
                  />
                  <br/>              
                  <PropertyTable>
                    <thead>
                      <PropertyTableRow>                
                          <PropertyTableHeader>FECHA APLIC</PropertyTableHeader>                                        
                          <PropertyTableHeader>BULT</PropertyTableHeader>
                          <PropertyTableHeader>PERS</PropertyTableHeader> 
                          <PropertyTableHeader>BU/PE</PropertyTableHeader>
                          <PropertyTableHeader>mmPre</PropertyTableHeader>                                                   
                      </PropertyTableRow>
                    </thead>
                    <tbody>
                      {rdtsdiaprecipitacion.map((item) => (
                                <PropertyTableRow key={item}>
                                    <PropertyTableHeader>              
                                        {item.Fecha.split('T')[0]}                   
                                    </PropertyTableHeader>
                                    <PropertyTableCell>                                                             
                                        <ButtonAplicate onClick={()=>handleRdtsdia(item)}>
                                          {item.total} 
                                        </ButtonAplicate>                                                                                              
                                    </PropertyTableCell>                                                                                      
                                    <PropertyTableCell>              
                                        {item.Trab}                   
                                    </PropertyTableCell>                            
                                    <PropertyTableCell>              
                                        {(item.total / item.Trab).toFixed(0)}                   
                                    </PropertyTableCell>
                                    <PropertyTableCell>              
                                        {item.lluvia}                   
                                    </PropertyTableCell>                                                                                      
                                </PropertyTableRow>
                            ))}
                    </tbody>
                  </PropertyTable>
              </ModalPropertyTypesContainerColum>
            </ModalPropertyTypesContainerRow>
            <ModalPropertyTypesContainerRow>                                 
              <ModalPropertyTypesContainerColum>
                  <PropertyBusinessType
                      busineesType={`"${mayores.length}" Lotes se fertilizaron ${dateselect?'en la fecha: ' + dateselect.Fecha.split('T')[0] + 
                                    ', para dicha labor se programo ' + dateselect.Trab + ' Personas, con un rendimiento de ' + (dateselect.total/dateselect.Trab).toFixed(0) +
                                    ' Bultos/Persona, la ' + dateselect.Finca + ' reporta una precipitación de ' + dateselect.lluvia +'mm de lluvia para este día':''}, a continuación se indican estos lotes:`}
                  />
                  <br/>              
                  <PropertyTable>
                    <thead>
                      <PropertyTableRow>                
                          <PropertyTableHeader>LOTE</PropertyTableHeader>
                          <PropertyTableHeader>A.NETA</PropertyTableHeader>                  
                          <PropertyTableHeader>APLICADO</PropertyTableHeader>
                          <PropertyTableHeader>PENDIENTE</PropertyTableHeader>                                                
                      </PropertyTableRow>
                    </thead>
                    <tbody>
                      {mayores.map((item) => (
                                <PropertyTableRow key={item}>
                                    <PropertyTableHeader>              
                                        {item.Lote}                   
                                    </PropertyTableHeader>
                                    <PropertyTableCell>              
                                        {item.A_Neta}                   
                                    </PropertyTableCell>                                                   
                                    <PropertyTableCell>              
                                        {item.realizado.toFixed(0)}                   
                                    </PropertyTableCell>                            
                                    <PropertyTableCell>              
                                        {(item.Falta).toFixed(0)}                   
                                    </PropertyTableCell>                                                                                              
                                </PropertyTableRow>
                            ))}
                    </tbody>
                  </PropertyTable>
              </ModalPropertyTypesContainerColum>
            </ModalPropertyTypesContainerRow>                      
          </ModalDetailDescriptionWrapper>
          
          
        </FormularioModal>        
        :''}
    </FormControlModal>
  )
}