import styled from "styled-components"


export const RdtDetailWrapper_Global = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    justify-content: center;
    
    
`;

export const RdtDetailWrapper_Global_DOS = styled.div`
     //border: 1px solid blue;   
    
     @media (max-width: 1280px){
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    }     
    @media (min-width: 1920px){
            margin-top: 20px;
            padding: 0 20px;            
            justify-content: center;
            width: 60%;
    }
    
`;

export const RdtDetailWrapper = styled.div`

    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px;    
    display: flex;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    margin: 10px 0;

    text-decoration: none;
    

`;

export const RdtDetailImageWrapper = styled.div`
    flex: 30;
    border-radius: 20px;
    img{
        width: 100%;
        border-radius: inherit;
    }

`;

export const RdtDetailInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin-left: 10px;
    font-size: 1em;
    h2{
        font-size: 1.5em;
        margin: 0;
        color:#6262A0;
    }
    h3{
        
        margin: 0;
        color:#6262A0;
    }        
`;

export const RdtDetailDescriptionWrapper = styled.div`    
    color: #6262A0;
    font-weight: bold; 
    font-size: 1.0em;   
    
    p{
        color: #6262A0;
        font-weight: bold; 
        font-size: 1em;   
    }
`;

export const RdtDetailValueWrapper = styled.div`
    //border: 1px solid blue;   
    //width:100%;
    color: #222;
    font-size: 1em;
    padding-bottom: 10px;
    h2{
        font-size: 1.2em;
        margin: 0;
        color:#6262A0;
    }
    h3{
        
        margin: 0;
        color:#6262A0;
    }
    label{
        border: 1px solid red;
        padding: 5px
    }
`
export const RdtDetailValueWrapperdos = styled.div`
    color: #222;
    font-size: 1.5em;  
    border: 1px solid #dfdfdf; 
    border-radius: 10px;
    margin: 0px;  
    p{
        margin: 5px;
        text-align: center;       
        //padding-bottom: 20px;
    }
    span{
        color: #26794f;
    }

`;

export const RdtDetailValueWrapperbutton = styled.div`
    color: #222;
    font-size: 1em;  
    display: flex;
    //border: 1px solid red;
    justify-content: space-between; 
    //align-items: flex-end;   
    margin: 20px;  
    h2{
        font-size: 2em;  
    }
    input{
       
        width: 100%;
        //padding: 10px;
        font-size: 1em;
        color: #fff;
        //background-color: #4A148C;
        background-color: #0d6a3b;//verde osc
        //background-color: #ff8c00;//Naranja
        text-align: center;
        border: none;
        border-radius: 6px;
        padding: 10px;
        opacity: 0.9;
        cursor: pointer;   
    }

`
export const RdtDetailSubTitle = styled.p`

    color: #C0C2D2;
    font-size: 1.2em;
    text-transform: capitalize;
    margin: 0;
    margin-top: 1px;

`;


export const RdtDetailTitle = styled.h1`
    font-size: 1.5em;
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;
    
`;


export const PropertyTypesContainerColum = styled.section`

    //border: 1px solid yellow;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    background-color: white;
    padding: 5px;
    width: 100%;
    label{
        display: block;
        font-size: 0.70em;
        color: blue;
        font-weight: bold;          
        p{
            color: black;              
        }                    
    }
    
    
    

`;
export const PropertyTypesContainerRow = styled.section`

    //border: 1px solid red;
    border: 1px solid #dfdfdf;
    margin: 10px 5px 5px 0px;
    background-color: #F2F2F2;
    border-radius: 6px;
    display: flex;
    flex-direction: row;

    //Para moviles
    @media (max-width: 512px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        flex-direction: column-reverse; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
    }
    

`;

export const FormControl = styled.div`
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;        
`;


export const FormControlSeccionOne = styled.div`
    
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;  
        
    //Para moviles
    @media (max-width: 512px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
    }      
`;

export const FormControlSeccionTwo = styled.div`
    
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;  
        
    //Para moviles
    @media (max-width: 512px){
        border: 1px solid blue;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        //flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
    }      
`;

export const FormControlTitle = styled.div`
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;  
    justify-content: center;
    label{        
        font-size: 1.4em;
        color: blue;
        font-weight: bold;         
        text-align: center;                           
    }
    span{ 
        font-size: 1em;
        color: red;
        font-weight: bold;         
        text-align: center;                           
    }      
`;

export const FormControlInput = styled.div`
    //border: 1px solid yellow;
    flex: 1; 
    //width: 100%   

    label{
        display: block;
        font-size: 0.70em;
        color: #222;
        font-weight: bold;          
                    
    }

    p{
        display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;          
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],
    input[type="float"],input[type="date"]{
        display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;

export const FormControlInputOther = styled.div`
    //border: 1px solid yellow;
    flex: 1; 
    //width: 100%   

    label{
        //display: block;
        font-size: 0.70em;
        color: #222;
        font-weight: bold;   
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],
    input[type="float"],input[type="date"]{
        display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: orange;
        border: none;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;

export const FormControlActionOne = styled.div`
    display: flex;        
    width: 100%;
`;
export const FormControlActionTwo = styled.div`
    display: flex; 
    flex-direction: row-reverse;       
    width: 100%;
`;

export const FormControlAction = styled.div`
    //border: 1px solid red;
    display: flex;
    //justify-content: space-between;
    //justify-content: flex-start;
    align-items: center;
    //padding-left: 5px;
    //flex-direction: column;
    //flex-wrap: wrap;
    //margin-top: 50px;
    //margin-bottom: 20px;
    button{
        margin-right: 30px;
    }
    
`;

export const FormControlInputSearch = styled.div`
    //border: 1px solid red;
    flex: 1;    
    margin: 0 10px 0 10px;

    label{
        display: block;
        font-size: 0.80em;
        color: #26794f;
        font-weight: 500;
        padding-left: 10px ;
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],
    input[type="float"],input[type="date"]{
        //display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 1.1em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border-radius: 15px;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;

export const FormControlInputudp = styled.div`
    //border: 1px solid yellow;
    flex: 1; 
    //width: 100%   

    label{
        //display: block;
        font-size: 0.70em;
        color: #222;
        font-weight: bold;   
                    
    }    
    input[type="text"]{
        display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;    
        text-align:center;

    }
    input[type="number"]{
        display: block;
        width: 50%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;    
        text-align:center;
        font-weight:bold;

    }

    p{
        display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;    
        text-align:center;

        span{
            color: blue; 
            font-size: 0.9em;           
            font-weight:bold;
        }       
    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }
`;

export const FormControlInputSearchudp = styled.div`
    //border: 1px solid red;
    flex: 1;    
    margin: 0 10px 0 10px;
    
    label{
        display: block;
        font-size: 0.80em;
        color: #26794f;
        font-weight: 500;
        padding-left: 10px ;
                    
    }    
    input[type="number"],
    input[type="float"]{
        
        width: 50%;        
        font-size: 0.8em;
        padding: 5px 10px;
        margin: 0px;
        //background-color: #fff;
        border-radius: 5px;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;

export const LogoImageWrapper = styled.div`
        
//border: 1px solid blue;
display: flex;
margin: 0;
align-items: center;
justify-content: space-between;

img{  
    flex: 1 ;
        //border: 1px solid blue;    
        border-radius: 20px;
        width: 10%;
        
    }    

`;

export const EmployeesValueWrapper = styled.div`
    //border: 1px solid blue;    
    align-items: flex-end;
    .box{        
        display: flex;
        //flex-wrap: wrap;
        flex-flow: row wrap;
        //align-items: flex-end;
        
        .subBox{
            border: 1px solid #dfdfdf;
           //justify-content: flex-start;
           //flex-direction: row-reverse; 
           
            width: 10%;
            align-items:right;
            margin: 5px; 
            

            label{
                font-weight: 500;
                font-size: 0.9em;
                background-color: #f6f6f6;                
            }
            button{
                margin-right: 2px;
            }
        }
    }
`;

export const AddRdtsFill = styled.div`
    //border: 1px solid;       
    //padding: 4px;
    background-color: #0d6a3b; 
    color: white;
    border-radius: 10px;
    margin-top:3px;
      
`;

export const AddRdtsFillnofound = styled.div`
    //border: 1px solid;       
    //padding: 4px;
    background-color: #f0a808; 
    color: white;
    border-radius: 10px;
    margin-top:3px;
    display: flex;
         
`;