import { IoCloseCircleOutline } from "react-icons/io5";
import { ButtonIcon } from "../../../../../../components/ButtonIcon";
import { PropertyBusinessType } from "../../../../../../components/PropertyBusinessType";
import { FormControl, FormControlInput } from "../../../../../../globalStyles";
import { CerrarModal, 
         FormControlModal, 
         FormularioModal, 
         ModalDetailDescriptionWrapper, 
         ModalDetailValueWrapper, 
         ModalPropertyTypesContainerColum, 
         ModalPropertyTypesContainerRow, 
         TittleModal } from "./styles"



export const ModalTransport = ({setModal, animarModal, setAnimarModal, detail}) => {
  let detailTransport = {}
  detailTransport = (detail)   
  //console.log('estoy en modal')
  //console.log({modalModal: setModal})
  //console.log(detailTransport)
  //Boton de cerrar
  const ocultarModal = ()=>{      
    //setAnimarModal(false)
    setModal(false)
          
  }
  //Validar dia de la semana
  const fechaComoCadena = detailTransport.dtmFecha; // día semana
  const dias = [      
      'LUNES',
      'MARTES',
      'MIERCOLES',
      'JUEVES',
      'VIERNES',
      'SABADO',
      'DOMINGO',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  const anio = new Date(fechaComoCadena).getFullYear();
  //console.log({numeroAnio: anio})
  const nombreDia = dias[numeroDia];
  return (
    
    <FormControlModal>
      <CerrarModal>
        <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarModal} />
      </CerrarModal> 
      {animarModal?
        <FormularioModal>            
            <form>
              <ModalDetailDescriptionWrapper>
                <ModalPropertyTypesContainerRow>
                  <ModalPropertyTypesContainerColum>
                    <ModalDetailValueWrapper>
                        <h2>{detailTransport.strFinca} // Ciclo: {detailTransport.ciclo}</h2>
                        <h3>Ibm: {detailTransport.Ibm} </h3>
                        <PropertyBusinessType
                            busineesType={`Fecha: ${detailTransport.dtmFecha?detailTransport.dtmFecha.split('T')[0]:''} (${nombreDia})`}
                        />                        
                        <PropertyBusinessType
                            busineesType={`Empresa: ${detailTransport.conductor?detailTransport.conductor:''} - Placa: ${detailTransport.placa?detailTransport.placa:''}`}
                        />
                        <PropertyBusinessType
                            busineesType={`Personas: ${detailTransport.cantidad?detailTransport.cantidad:''}`}
                        />                       
                    </ModalDetailValueWrapper>
                  </ModalPropertyTypesContainerColum>
                </ModalPropertyTypesContainerRow>
                <ModalPropertyTypesContainerRow>
                  <ModalPropertyTypesContainerColum>
                    <ModalDetailValueWrapper>                        
                        <h3>Observación:</h3>                        
                        <PropertyBusinessType
                            busineesType={`${detailTransport.Observacion?detailTransport.Observacion:''}`}
                        />
                    </ModalDetailValueWrapper>
                  </ModalPropertyTypesContainerColum>
                </ModalPropertyTypesContainerRow>
              </ModalDetailDescriptionWrapper>
              
            </form>
        </FormularioModal>
        
        :'no tiene'}
    </FormControlModal>
  )
}


