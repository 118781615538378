import { IoCloseCircleOutline } from "react-icons/io5";
import { ButtonIcon } from "../../../../../../components/ButtonIcon";
import { PropertyBusinessType } from "../../../../../../components/PropertyBusinessType";
import { FormControl, FormControlInput } from "../../../../../../globalStyles";
import { getStaticArcReception } from "../../../../../../utils/staticArc";
import { CerrarModal, 
         FormControlModal, 
         FormularioModal, 
         ModalDetailDescriptionWrapper, 
         ModalDetailValueWrapper, 
         ModalPropertyTypesContainerColum, 
         ModalPropertyTypesContainerRow, 
         ReceptionImageWrapper, 
         TittleModal } from "./styles"

export const ModalReception = ({setModal, animarModal, setAnimarModal, detail}) => {
    let detailReception = {}
    detailReception = (detail)   
    //console.log('estoy en modal')
    //console.log({modalModal: setModal})
    //console.log(detailReception)
    //Boton de cerrar
    const ocultarModal = ()=>{      
        //setAnimarModal(false)
        setModal(false)
            
    }
    //Validar dia de la semana
    const fechaComoCadena = detailReception.dtmFecha; // día semana
    const dias = [      
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
        'DOMINGO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const anio = new Date(fechaComoCadena).getFullYear();
    //console.log({numeroAnio: anio})
    const nombreDia = dias[numeroDia];
  return (
    <FormControlModal>
      <CerrarModal>
        <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarModal} />
      </CerrarModal> 
      {animarModal?
        <FormularioModal>            
            <form>
              <ModalDetailDescriptionWrapper>
                <ModalPropertyTypesContainerRow>
                  <ModalPropertyTypesContainerColum>
                    <ModalDetailValueWrapper>
                        <h2>{detailReception.strFinca} // Ciclo: {detailReception.ciclo}</h2>
                        <h3>Ibm: {detailReception.Ibm} </h3>
                        <PropertyBusinessType
                            busineesType={`Fecha: ${detailReception.dtmFecha?detailReception.dtmFecha.split('T')[0]:''} (${nombreDia})`}
                        />                        
                        <PropertyBusinessType
                            busineesType={`Empresa: ${detailReception.entrega?detailReception.entrega:''} - Remisión: ${detailReception.remision?detailReception.remision:''}`}
                        />
                        <PropertyBusinessType
                            busineesType={`Recibido: ${detailReception.cantidad?detailReception.cantidad:''}`}
                        />
                        <PropertyBusinessType
                            busineesType={`Recibe: ${detailReception.recibe?detailReception.recibe:''}`}
                        />                       
                    </ModalDetailValueWrapper>
                  </ModalPropertyTypesContainerColum>
                </ModalPropertyTypesContainerRow>
                <ModalPropertyTypesContainerRow>
                  <ModalPropertyTypesContainerColum>
                    <ModalDetailValueWrapper>                        
                        <h3>Observación:</h3>                        
                        <PropertyBusinessType
                            busineesType={`${detailReception.Observacion?detailReception.Observacion:''}`}
                        />
                    </ModalDetailValueWrapper>
                  </ModalPropertyTypesContainerColum>
                </ModalPropertyTypesContainerRow>
                <ModalPropertyTypesContainerRow>
                  <ModalPropertyTypesContainerColum>
                    <ReceptionImageWrapper>                        
                      <img alt='Documento' 
                          //src={`Imagenes/${retired.mainImage}`}
                          src={getStaticArcReception(detailReception.mainAdjunto)}
                      />
                    </ReceptionImageWrapper>
                  </ModalPropertyTypesContainerColum>
                </ModalPropertyTypesContainerRow>
              </ModalDetailDescriptionWrapper>
              
            </form>
        </FormularioModal>
        
        :''}
    </FormControlModal>
  )
}


