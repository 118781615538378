import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import Alert from "../../components/Alert/Alert";
import { Page } from "../../components/Page";
import { FileInput, FileName, FormControl, FormControlInput, FormControlInputFile, Label, PageTitle } from "../../globalStyles";
import { Button } from "../../components/Button"
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { getToken, removeToken } from "../../utils/TokenLS";
import { ButtonTransport, 
         PropertyContainerDasboard, 
         PropertyContainerDasboardColum, 
         PropertyContainerDasboardRow, 
         PropertyReceptionDasboard, 
         PropertySectorone, 
         PropertySectortwo, 
         PropertyTitleDasboard, 
         PropertyTotalDasboard, 
         PropertyTypesContainerColum, 
         PropertyTypesContainerRow, 
         ReceptionProductsDetailInfo, 
         ReceptionProductsDetailTitle } from "./styles";
import { ReceptionFarms } from "./Components/ReceptionFarms";
import { AplicateFarms } from "./Components/AplicateFarms";
import { TransportFarms } from "./Components/TransportFarms";
import { PropertyGrafLinealChart } from "../AddPrecipitation/components/PropertyGrafLinealChart";
import { PropertyGrafBarChart } from "../AddPrecipitation/components/PropertyGrafBarChart";
import { PropertyGrafBarChartmes } from "../AddPrecipitation/components/PropertyGrafBarChartmes";
import { PropertyGrafDonaChartanio } from "../AddPrecipitation/components/PropertyGrafDonaChartanio";


const ALL_SUCURSALES = 0;

export const ReceptionProducts  = () => {
    
    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ sucursal, setSucursal ] = useState([]);        
    const [ concepto, setConcepto ] = useState([]);

    const [ dataReception, setDataReception ] = useState({});
    const [ date, setDate ] = useState('');
    const [ selectedFileName, setSelectedFileName ] = useState('');
    const [ receptions, setReceptions ] = useState([]);
    const [ receptionsProduct, setReceptionsproduct ] = useState([]);
    const [ rdts, setRdts ] = useState([]);
    const [ transports, setTransports ] = useState([]);
    const [ calendar, setCalendar ] = useState([]);

    //Para Precipitacion por finca cuando se filtre
    const [ calendarAplicate, setCalendarAplicate ] = useState([]);
    const [ sem, setSem ] = useState(0);     
    const [ anioA, setAnioA ] = useState(0);    
    const [ precipitationUltsem, setPrecipitationUltsem ] = useState([]);
    
    const [ ciclo, setCiclo ] = useState('');
    const [ codproducto, setCodproducto ] = useState('112');
    const [ sucursalSeleted, setSucursalSelected ] = useState(ALL_SUCURSALES);    
    const [ sucursalname, setSucursalname ] = useState('');    
    const [ ucodigos, setUcodigos ] = useState([]);
        
    const [alert, setAlert] = useState({})

    const navigate = useNavigate();

    const { 
                register, 
                handleSubmit,
                reset,
                formState:{
                    errors,
                    isValid
                }  
    } = useForm({ 
            mode: 'onChange',
            defaultValues: {
                checkbox: []
            }
        });   
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
            closeSeccionAuth()
            removeToken()
            showAlert(
                'Inicie Seccion Nuevamente', 
                "Su token puede estar vencido", 
                SW_ICON.INFO,
                () => { navigate('/Login')}    
            );
    }
    //Token valido o no expirado
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await getSucursalesAll();                             
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[auth])
    //Recargar Receptions
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await reload ? getReceptions():null();                 
                await reload ? getReceptionProduct():null();
                await reload ? getTransports():null();   
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[reload])
    //Si estoy editando
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await rdtprov.ibm?setSucursalSelected(rdtprov.ibm):console.log(''); 
                await rdtprov.dateRdt?setDate((rdtprov.dateRdt).split('T')[0]):console.log('');                                
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[rdtprov])
    //Sucursals       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                await  cleanStates();                              
                await date && ciclo ? getReceptions():cleanStates();
                await date && ciclo ? getTransports():cleanStates();
                await date && ciclo && codproducto? getReceptionProduct():cleanStates();
                await date && ciclo ? getRdts():cleanStates();                                         
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();        
          
    },[sucursalSeleted])
    //date       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                              
                await date ? getCalendar():cleanStates();
                await date && ciclo? getReceptions():cleanStates();
                await date && ciclo? getTransports():cleanStates();
                await date && ciclo && codproducto? getReceptionProduct():cleanStates();
                await date && ciclo ? getRdts():cleanStates();                                              
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
            
    },[date])
    //ciclo
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();    
                //console.log('En Ciclo')                                                          
                await date && ciclo? getReceptions():cleanStates(); 
                await date && ciclo? getTransports():cleanStates(); 
                await date && ciclo && codproducto? getReceptionProduct():cleanStates();
                await date && ciclo ? getRdts():cleanStates();                                              
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                   
    },[ciclo])
    //codproduct
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                                              
                await date && ciclo && codproducto? getReceptionProduct():cleanStates();
                await date && ciclo && codproducto? getRdts():cleanStates();
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                   
    },[codproducto])
    //rdts: dispara para consultar por fecha del rdtMax en calendario para consultar precipitacion        
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                                              
                await sucursalSeleted ? getCalendarAplicate():cleanStates();                  
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                   
    },[rdts])
    //calendar: para consultar precipitaciones entre las ultimas semanas  y enviar anioA y sem al grafico
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                                              
                await rdts ? getPrecipitationUltSemanas():cleanStates();                  
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                   
    },[calendarAplicate])
   
    
    //Sucursales
    const handleChange = event => {
        setRdtprov({});
        setSucursalSelected(event.target.value)                 
    }
    const handleChangedate = event => {
        setDate(event.target.value) 
                      
    }
    //Sucursals
    const getSucursalesAll = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/sucursal',                         
                    params: makeSucursalesFilters()                                     
                }
            );             
            const {data} = response;                
            setSucursal(data);                                     
        } catch (error) {
            console.log(error)
        }
    }
    const makeSucursalesFilters = ()=>{
        const filters = {};           
        return filters;       
    }
    //getCalendars para consultar fecha del primer dia de la semana del año consultado
    const getCalendar = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',                         
                    params: makePropertiesFiltersCalendar()                                     
                }
            );             
            const {data} = response;                
            setCalendar(data);             
            setReload(false);
            //console.log({calendar: data})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersCalendar = ()=>{
        const filters = {};                
            filters['anio'] = anio;  
            filters['semana'] = 1;  
            filters['nombreDia'] = 'LUNES';             
            
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }    
    //getReceptions consulta todas las recepciones recibidas del ciclo en el año
    const getReceptions = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/supplies',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;                
            setReceptions(data);             
            setReload(false);
            //console.log({receptions: data})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFilters = ()=>{
        const filters = {};
                           
            if(sucursalSeleted != '90021'){
                filters['Ibm'] = sucursalSeleted; 
                filters['ciclo'] = ciclo;
                filters['Fecha_INI'] = calendar[0].dtmFecha;             
                filters['Fecha_FIN'] = date;  
                //console.log({conReceptions: filters})           
                return filters;
            }else{                
                filters['ciclo'] = ciclo;
                filters['Fecha_INI'] = calendar[0].dtmFecha;             
                filters['Fecha_FIN'] = date;  
                //console.log({sinSucursals: filters})           
                return filters;
            }                
            
                   
    }
    //getReceptions consulta todas las recepciones recibidas del ciclo en el año para el producto especifico
    const getReceptionProduct = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/supplies/analyses',                         
                    params: makePropertiesFiltersProduct()                                     
                }
            );             
            const {data} = response;                
            setReceptionsproduct(data);             
            setReload(false);
            //console.log({receptionsProduct: data})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersProduct = ()=>{
        const filters = {};
        if(sucursalSeleted != '90021'){
            filters['Ibm'] = sucursalSeleted; 
            filters['ciclo'] = ciclo;
            filters['IntCodigo_CONC'] = codproducto;
            filters['Fecha_INI'] = calendar[0].dtmFecha;             
            filters['Fecha_FIN'] = date; 
            //console.log({conReceptionsproduct: filters})           
            return filters;
        }else{                
            
            filters['ciclo'] = ciclo;
            filters['IntCodigo_CONC'] = codproducto;
            filters['Fecha_INI'] = calendar[0].dtmFecha;             
            filters['Fecha_FIN'] = date; 
            //console.log({conReceptionsproduct: filters})            
            return filters;
        }                           
    }
    //getRdts
    const getRdts = async ()=>{
        //console.log({sucursal: sucursalSeleted})
        if(!date){
          setRdts([])
          return
          //console.log('Fecha vacia')
        }
        if(ciclo === '' || ciclo === 0){
          setRdts([])
          return
          //console.log({nohacerConsulta: sucursalSeleted})        
        }else{
          try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/Rdts/supplies',                         
                    params: makePropertiesFiltersrdts()                                     
                }
            );             
            const {data} = response;                
            data?setRdts(data):console.log(''); 
            //console.log({dataRdts: data})       
            } catch (error) {
                if(error.response.status === 401){
                    hendleCloseSeccion();
                }else{
                    console.log(error)
                }
            }
              //console.log({hacerConsulta: sucursalSeleted})        
        }                   
    }
    const makePropertiesFiltersrdts = ()=>{
        const filters = {};
        if(sucursalSeleted != '90021'){
            filters['Fecha_INI'] = calendar[0].dtmFecha; 
            filters['Fecha_FIN'] = date; 
            filters['IbmLaboro'] = sucursalSeleted;                 
            filters['IntCodigo_CONC'] = codproducto;
            filters['intCiclo'] = ciclo; 
            //console.log({conSucu: filters})           
            return filters;
        }else{                         
            filters['Fecha_INI'] = calendar[0].dtmFecha; 
            filters['Fecha_FIN'] = date;                                          
            filters['intCiclo'] = ciclo; 
            //console.log({sinSucu: filters})            
            return filters;
        }                
                        
          // filters['employeesId'] = auth.data._id;     
          //console.log({fechafin: fechafin});
         
        //return filters;       
    }
    //getTransports consulta todas los transportes en el ciclo especifico
    const getTransports = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/transports',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;                
            setTransports(data);             
            setReload(false);
            //console.log({transports: data})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    //Clean states
    const cleanStates = ()=>{

        setDataReception({});
                                   
        if(sucursalSeleted === '20128'){
            setSucursalname('EDEN')
        }else if(sucursalSeleted === '90032'){
            setSucursalname('TULE')
        }else if(sucursalSeleted === '20065'){
            setSucursalname('JUANCA')
        }else if(sucursalSeleted === '80108'){
            setSucursalname('LA MONICA')
        }else if(sucursalSeleted === '23270'){
            setSucursalname('TIKAL')
        }else if(sucursalSeleted === '20947'){
            setSucursalname('BUENOS AIRES')
        }else if(sucursalSeleted === '80132'){
            setSucursalname('AGUA VERDE')
        }else if(sucursalSeleted === '20180'){
            setSucursalname('TOSCANA')
        }else if(sucursalSeleted === '20175'){
            setSucursalname('ASTURIAS')
        }else if(sucursalSeleted === '20185'){
            setSucursalname('LA CEJA')
        }else if(sucursalSeleted === '20186'){
            setSucursalname('ARIZONA')
        }else if(sucursalSeleted === '20188'){
            setSucursalname('ALCATRAZ')
        }else if(sucursalSeleted === '80133'){
            setSucursalname('MANAURE')
        }else if(sucursalSeleted === '90008'){
            setSucursalname('LA AGUADA')
        }else{
            setSucursalname('')
        }
    }
    //Cuando doy Click en el Concepto del Dasboard Grupo
    const handleConcepto = (idConcepto)=>{        
        setCodproducto(idConcepto);
        //console.log({idConcepto: idConcepto})  
        /*setModal(true)
        setTimeout(() => {
          setAnimarModal(true)
        }, 300);*/
    }
    //getCalendarsAplicate
    const getCalendarAplicate = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',                         
                    params: makePropertiesFiltersCalendarAplicate()                                     
                }
            );             
            const {data} = response;                
            setCalendarAplicate(data);                 
            setReload(false);            
            //console.log({calA: data?data:'No hay nada'})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersCalendarAplicate = ()=>{
        const filters = {};                
            filters['Fecha'] = sucursalSeleted && rdts[0]?rdts[0].fechaMax:date;                               
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }
    //getPrecipitation
    const getPrecipitationUltSemanas = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations',                         
                    params: makePropertiesFiltersUltSemanas()                                     
                }
            );             
            const {data} = response;                
            setPrecipitationUltsem(data);             
            setReload(false);
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersUltSemanas = ()=>{
        const filters = {};                                    
            filters['Ibm'] = sucursalSeleted;
            filters['anio'] = calendarAplicate?calendarAplicate[0].anio:console.log('');  
            filters['semana'] = calendarAplicate?calendarAplicate[0].semana:console.log('');   
            //Se envia el Group 2 para que el orden sea por fecha   
            filters['Group'] = 2;  
            setSem(calendarAplicate?calendarAplicate[0].semana:0)
            setAnioA(calendarAplicate?calendarAplicate[0].anio:0)
            //console.log({filterSucu: sucursalSeleted})
            //console.log({filterA: anioA})
            //console.log({filterS: sem})
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }

    //Validar dia de la semana
    const fechaComoCadena = date +  " 00:01:59"; // día semana
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const anio = new Date(fechaComoCadena).getFullYear();
    //console.log({numeroAnio: anio})
    const nombreDia = dias[numeroDia];

    const fecha = new Date(fechaComoCadena);
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    
    const rango = new Date(hoy - fecha);
    // 1. Receptions ------------------------------------------------------------------------------------------------------------------------
    // Crear un objeto para almacenar los totales por ciclo y cod de producto en recepciones
    const totalporCodigo = {};    
    receptions.forEach((itemCod) => {
        if (!totalporCodigo[itemCod.IntCodigo_CONC]) {
            totalporCodigo[itemCod.IntCodigo_CONC] = 0;
        }        
        totalporCodigo[itemCod.IntCodigo_CONC] += itemCod.cantidad;
    });
    //console.log(totalporCodigo) 
    //Crear un objeto para almacenar los codigos recepcionados por ciclo
    const codigosunicos = [...new Set(receptions.map((itemCod) => itemCod.IntCodigo_CONC))];   
    //console.log({codigosunicos: codigosunicos})     
    //Creo objeto nueva lista para almacenar codigo y concepto en un nuevaLista      
    const nuevaLista = codigosunicos.map(item1 => {
        const item2 = receptions.find(item2 => item2.IntCodigo_CONC === item1);            
        return {
            Codigo: item2.IntCodigo_CONC,
            Concepto: item2.strConcepto
        };
    });    
    // Almacenar cod y nombre de producto para mostrarlos en el Dasboard con el index del cod de producto
    const nombreCodigos = {};
    nuevaLista.forEach((itemCod) => {
        if (!nombreCodigos[itemCod.Codigo]) {
            nombreCodigos[itemCod.Codigo] = '';
        }        
        nombreCodigos[itemCod.Codigo] += itemCod.Concepto;
    });
    //console.log({nombreCodigos: nombreCodigos}) 

    // 2.Supplies Aplicated ------------------------------------------------------------------------------------------------------------------------
    // Crear un objeto para almacenar los totales por ciclo y cod de producto en rdts
    const totalporcodigoSuppplies = {};    
    rdts.forEach((itemCod) => {
        if (!totalporcodigoSuppplies[itemCod.Codigo]) {
            totalporcodigoSuppplies[itemCod.Codigo] = 0;
        }        
        totalporcodigoSuppplies[itemCod.Codigo] += itemCod.total;
        
    });
    //console.log({totalSupplies: totalporcodigoSuppplies}) 
    //console.log(rdts)
    //
     /*//Probar verificacion de acumulado por un codigo especifico    
     const item22 = rdts.find(item2 => item2.Codigo == '228' && item2.Ibm == '20185');
     const item23 = rdts.map(item2 => {
        console.log(
            
           item2.Codigo == '228'?item2.total:'')
     });
     //console.log({rdts: item22})
    */
    
    //sucursalSeleted == '' || sucursalSeleted == 0?console.log({sucu: 'No hay nada'}):console.log({sucu: sucursalSeleted})
  return (
    <Page>
        <ReceptionProductsDetailTitle>
            ANALISIS DE FERTILIZANTES
        </ReceptionProductsDetailTitle>
        <form>
            <PropertyTypesContainerRow>                         
                <PropertyTypesContainerColum>
                    <FormControl>
                        <FormControlInput>
                                <label>FINCA:</label>                    
                                <select id="sucursal"
                                            {...register("sucursal", { required: true })}
                                            onChange={handleChange}
                                            value={sucursalSeleted}
                                            disabled={ auth.data.role !== 1 && rdtprov.ibm !== 90021?true:false}>
                                        <option value={""}>-- Todas --</option>
                                        {sucursal.map(item=>(                                    
                                                <option key={item.strCodigo} 
                                                        value={item.strCodigo}                                                                                               
                                                        id={item.strCodigo}
                                            >{item.strNombre}</option>
                                            )
                                            )
                                        }  
                                                                                                                                                                                    
                                </select>
                        </FormControlInput>
                    </FormControl>  
                    <FormControl>
                        <FormControlInput>
                                    <label>FECHA:</label>
                                    <input  type="date"
                                            value={date}
                                            onChange={handleChangedate}                                        
                                    />                            
                                    
                        </FormControlInput>
                    </FormControl> 
                </PropertyTypesContainerColum>
                <PropertyTypesContainerColum>
                    <FormControl>
                        <FormControlInput>
                                    <label>CICLO:</label>
                                    <input  type='text'                                    
                                            {...register("ciclo")}
                                            value={ciclo}
                                            onChange={e => setCiclo(e.target.value)}                                                                                                                
                                    />
                        </FormControlInput>                    
                    </FormControl>
                    <FormControl>
                        <FormControlInput>
                                    <label>COD.LABOR:</label>
                                    <input  type='text'                                    
                                            {...register("labor")}
                                            value={codproducto}
                                            onChange={e => setCodproducto(e.target.value)}                                                                                                                
                                    />
                        </FormControlInput>                    
                    </FormControl>                
                </PropertyTypesContainerColum>
                
                <PropertyTypesContainerColum>
                {sucursalSeleted == '' || sucursalSeleted == 0 || sucursalSeleted == '90021'?'':
                <PropertyTypesContainerRow> 
                <PropertyTypesContainerColum>                    
                    <ReceptionProductsDetailInfo>
                        <p> 
                            <span>{'PRECIPITACIÓN'}</span><br/>
                            (Día, Sem, Mes, Año)                                                                                                                                                                                     
                        </p> 
                    </ReceptionProductsDetailInfo> 
                </PropertyTypesContainerColum>
                <PropertyTypesContainerColum>                    
                    <PropertyGrafLinealChart
                        sucursal={sucursalSeleted}
                        semana={sem}
                        anio={anioA}
                    /> 
                </PropertyTypesContainerColum>
                
                <PropertyTypesContainerColum>                    
                    <PropertyGrafBarChart
                            sucursal={sucursalSeleted}
                            semana={sem}
                            anio={anio}
                    /> 
                </PropertyTypesContainerColum>
                <PropertyTypesContainerColum>                    
                    <PropertyGrafBarChartmes
                            sucursal={sucursalSeleted}
                            semana={sem}
                            anio={anio}
                    /> 
                </PropertyTypesContainerColum>
                <PropertyTypesContainerColum>
                    <PropertyGrafDonaChartanio
                            sucursal={sucursalSeleted}                            
                            anio={anio}
                    />
                </PropertyTypesContainerColum>
                </PropertyTypesContainerRow>} 
                </PropertyTypesContainerColum>                             
            </PropertyTypesContainerRow>  
        </form>
        <PropertyContainerDasboardRow>
        <PropertySectorone>
            <PropertyContainerDasboardColum>
                    <PropertyTitleDasboard>
                        AÑO:&nbsp;{calendar[0]?calendar[0].anio:''}&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;CICLO:&nbsp;{ciclo}
                    </PropertyTitleDasboard>
                    <PropertyContainerDasboard>
                        <PropertyReceptionDasboard>
                            <h2>RECIBIDOS</h2>
                            <PropertyContainerDasboard>
                        
                            {codigosunicos.map((cod) => 
                                    (                        
                                        <PropertyTotalDasboard key={`${cod}`}>
                                            <h2>{totalporCodigo[cod] || 0}</h2><span>{nombreCodigos[cod] || ''}</span>
                                        </PropertyTotalDasboard>          
                                    ))              
                            }
                            </PropertyContainerDasboard>                   
                        </PropertyReceptionDasboard>                                                    
                    </PropertyContainerDasboard>
                    <PropertyContainerDasboard>
                        
                        <PropertyReceptionDasboard>
                            <h2>APLICADOS</h2>
                            <PropertyContainerDasboard>
                        
                            {codigosunicos.map((cod) => 
                                    (
                                        <ButtonTransport onClick={()=>handleConcepto(cod)}>                        
                                            <PropertyTotalDasboard key={`${cod}`}>
                                                <h2>{(totalporcodigoSuppplies[cod]) || 0}</h2><span>{nombreCodigos[cod] || ''}</span>
                                            </PropertyTotalDasboard> 
                                        </ButtonTransport>         
                                    ))              
                            }
                            </PropertyContainerDasboard>                   
                        </PropertyReceptionDasboard>
                                                                        
                    </PropertyContainerDasboard>
                    <PropertyContainerDasboard>
                        <PropertyReceptionDasboard>
                            <h2>DISPONIBLE</h2>
                            <PropertyContainerDasboard>                    
                            {codigosunicos.map((cod) => 
                                    (                        
                                        <PropertyTotalDasboard key={`${cod}`}>
                                            <h2>{(totalporCodigo[cod] - totalporcodigoSuppplies[cod]).toFixed(2) || 0}</h2><span>{nombreCodigos[cod] || ''}</span>
                                        </PropertyTotalDasboard>          
                                    ))              
                            }
                            </PropertyContainerDasboard>                   
                        </PropertyReceptionDasboard>                                                                    
                    </PropertyContainerDasboard>            
                </PropertyContainerDasboardColum>
                
                <PropertyContainerDasboardColum>
                    <PropertyTitleDasboard>
                        REMISIONES RECIBIDAS
                    </PropertyTitleDasboard>
                    <ReceptionFarms
                        receptions={receptions}                    
                    />     
                </PropertyContainerDasboardColum>
            </PropertySectorone>
            <PropertySectortwo>
                <PropertyContainerDasboardColum>
                    <PropertyTitleDasboard>
                        TRANSPORTE CUADRILLA
                    </PropertyTitleDasboard>
                    <TransportFarms
                        transport={transports}                   
                    />     
                </PropertyContainerDasboardColum>
                <PropertyContainerDasboardColum>
                    <PropertyTitleDasboard>
                        FERTILIZACIÓN APLICADA
                    </PropertyTitleDasboard>
                    <AplicateFarms
                        receptions={receptionsProduct}
                        rdts={rdts}
                        sucursals={sucursal}
                    />                    
                </PropertyContainerDasboardColum>
            </PropertySectortwo>            
        </PropertyContainerDasboardRow>      

    </Page>
  )
}