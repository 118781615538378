import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export const PropertyGrafBarChartAniosem = (item) => {
  const [ precipitationaniosem, setPrecipitationaniosem ] = useState([]);
  //console.log({item: item})             

  //anio       
  useEffect(() => {
    const getData = async ()=>{
        try {                                                    
          item.anio ? getPrecipitationaniosem():console.log('');            
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
        
  },[item.anio])
  //semana
  useEffect(() => {  
    const getData = async ()=>{            
        try {           
            item.semana ? getPrecipitationaniosem():console.log('');
            //await getPrecipitationanio();                      
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
    },[item.semana]) 
  //getPrecipitation
  const getPrecipitationaniosem = async ()=>{                
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/precipitations/aniosem',                         
                params: makePropertiesFiltersaniosem()                                     
            }
        );             
        const {data} = response;                
        setPrecipitationaniosem(data);    
        //console.log({dataAniosem: data})  
            
    } catch (error) {
        if(error.response.status === 401){
          console.log('')
        }else{
            console.log(error)
        }
    }    
  }
  const makePropertiesFiltersaniosem = ()=>{
      const filters = {};                                           
          filters['anio'] = item.anio?item.anio:console.log('');  
          filters['semana'] = item.semana;   
          filters['tipo'] = 'Grafico';              
      return filters;       
  }

  const generateChartData = () => {
    const uniqueFincas = [...new Set(precipitationaniosem.map((itemPrec) => itemPrec.Finca))];
    const uniqueSem = [...new Set(precipitationaniosem.map((itemPrec) => itemPrec.semana))];    
    //console.log({Mes: uniqueMes})  
    //console.log({fincas: uniqueFincas})
    const datasets = uniqueSem.map((sem) => {
      const dataForSem = uniqueFincas.map((finca) => {
        const matchingData = precipitationaniosem.find(
          (data) => data.Finca === finca && data.semana === sem
        );
        return matchingData ? matchingData.totalLLuvia: 0;
      });

      return {
        label: `Sem ${sem}`,
        data: dataForSem,
        backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.2)`,
        borderColor: ["#blue"],
        borderWidth: 1,
      };
    });

    return { labels: uniqueFincas, datasets };
  };
  
  const chartData = generateChartData();

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Precipitación (mm)',
        },
      },
    },
  };
  

  /*
  const data = {
    labels: sem,
    datasets:[{
       label: "mm", 
       data: precipitacion, 
       tension: 0.2,
       borderColor: "blue",
       borderWidth: 2,
       fill: true,
       backgroundColor: 'blue'       
    }],
  };

  const options = {
    maintainAspectRatio:false,
    responsive: true,

    plugins:{
      legend:{
        display: false,
        position: 'top',
      },
    },
  */
    /*scales:{
      y:{
        min: 0,
      },
    },

    plugins:{
      legend:{
        display: true,
        position: 'top',
      },
    },
    tooltip:{
      intersect: false,
      mode: 'index',
      callbacks:{
        label: function (context){
          const label = context.dataset.label || '';
          const value = context.formattedValue;
          return `${label}: ${value} mm`
        },
      },
    },  */
  
  //<Bar data={chartData} options={options} />
  return (
    <div>
     <h2>Gráfico de Precipitación de Fincas Ultimas 3 Semanas del año</h2>
     <Bar data={chartData} options={options} />
    </div>
  )
}


