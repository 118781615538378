import { Link } from "react-router-dom";
import styled from "styled-components"

export const PropertyCardWrapper = styled(Link)`
    flex: 1 1 auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px 0 5px 0;    
    //display: flex;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    margin: 1px 0;
    font-size: 0.7em;
    text-decoration: none;
    background-color: #fff;

`;


export const PropertyCardWrapperTr = styled.div`
    display: flex;
    align-items: center;
    //border: 1px solid red;  
    
`;

export const PropertyButtonWrapper = styled.div`
    margin: 3px;
    //border: 1px solid blue;   

`;

export const PropertyInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin: 0 10px 0 10px;
    text-align: left;
    color:#6262A0;
    
    
        
`;

export const PropertyDescriptionWrapper = styled.div`    
    color: #6262A0;
    font-weight: bold; 
    font-size: 0.8em;   
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    p{
        color: #6262A0;
        font-weight: bold; 
        font-size: 1em;
        text-align: right;
    }
`;

export const PropertyValueWrapper = styled.p`
    color: #222;
`
