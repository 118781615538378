import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import Alert from "../../components/Alert/Alert";
import { Page } from "../../components/Page";
import { FormControlInputFile, PageTitle } from "../../globalStyles";
import { Button } from "../../components/Button"
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { getToken, removeToken } from "../../utils/TokenLS";
import { AddTransportWrapper_Global, 
         AddTransportWrapper_Global_DOS, 
         PropertyTypesContainerColum, 
         PropertyTypesContainerRow,
         FormControl,           
         FormControlInput,
         FormControlInputSearch,          
         FormControlTitle,
         FormControl_Global} from "./styles";
import { PropertyTransports } from "./components/PropertyTransports";

const ALL_SUCURSALES = 0;

export const AddTransport = () => {

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ sucursal, setSucursal ] = useState([]);
    const [ concepto, setConcepto ] = useState([]);
    const [ transports, setTransports ] = useState([]);

    const [ dataTransport, setDataTransport ] = useState({});        
    const [ date, setDate ] = useState('');

    const [sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);    
    const [sucursalname, setSucursalname] = useState('');    
    
    
    const [alert, setAlert] = useState({})    

    const navigate = useNavigate();

    const { 
                register, 
                handleSubmit,
                reset,
                formState:{
                    errors,
                    isValid
                }  
    } = useForm({ 
            mode: 'onChange',
            defaultValues: {
                checkbox: []
            }
        });     

    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
            closeSeccionAuth()
            removeToken()
            showAlert(
                'Inicie Seccion Nuevamente', 
                "Su token puede estar vencido", 
                SW_ICON.INFO,
                () => { navigate('/Login')}    
            );
    }
    //Token valido o no expirado
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await getSucursalesAll();                             
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[auth])
    //Recargar Transport cuando elimino
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await reload ? getTransports():null();                    
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[reload])
    //Sucursals       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                await  cleanStates();                              
                await sucursalSeleted?getConcepto():cleanStates();
                await sucursalSeleted && date ? getTransports():cleanStates();                                              
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();        
          
    },[sucursalSeleted])
    //date       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                              
                await sucursalSeleted && date ? getConcepto():cleanStates();  
                await sucursalSeleted && date ? getTransports():cleanStates();                                                          
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
            
    },[date])
    //Sucursales
    const handleChange = event => {
        setRdtprov({});
        setSucursalSelected(event.target.value)                 
    }
    const handleChangedate = event => {
        setDate(event.target.value) 
                      
    }
    //Sucursals
    const getSucursalesAll = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/sucursal',                         
                    params: makeSucursalesFilters()                                     
                }
            );             
            const {data} = response;                
            setSucursal(data);                                     
        } catch (error) {
            console.log(error)
        }
    }
    const makeSucursalesFilters = ()=>{
        const filters = {};           
        return filters;       
    }
    //Concepts
    const getConcepto = async ()=>{        
        if(date){
            try {            
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/conceptossucursal',                         
                        params: makeConceptoFiltersConcept()                                     
                    }
                );             
                const {data} = response; 
                //console.log({concepto: data})   
                data ? setConcepto(data):cleanStates(); 
                //cleanStatesudps();                                                                                              
            } catch (error) {
                console.log(error)
            }
            
        }else{
            cleanStates();
        } 
    }        
    const makeConceptoFiltersConcept = ()=>{
        const filters = {};  
        if(sucursalSeleted !== ALL_SUCURSALES ){
            filters['Ibm'] = sucursalSeleted;            
            filters['IntCodigo'] = '112';
        }
        //console.log(filters);         
        return filters;            
    }
    //getTransports consulta todas los transportes en la fecha y la finca especifica
    const getTransports = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/transports',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;                
            setTransports(data);             
            setReload(false);
            console.log({transports: data})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFilters = ()=>{
        const filters = {};                
            filters['Ibm'] = sucursalSeleted;             
            filters['Fecha_INI'] = date;             
            filters['Fecha_FIN'] = date;             
        return filters;       
    }
    //Clean states
    const cleanStates = ()=>{

        reset();
        setDataTransport({});
               
        if(sucursalSeleted === '20128'){
            setSucursalname('EDEN')
        }else if(sucursalSeleted === '90032'){
            setSucursalname('TULE')
        }else if(sucursalSeleted === '20065'){
            setSucursalname('JUANCA')
        }else if(sucursalSeleted === '80108'){
            setSucursalname('LA MONICA')
        }else if(sucursalSeleted === '23270'){
            setSucursalname('TIKAL')
        }else if(sucursalSeleted === '20947'){
            setSucursalname('BUENOS AIRES')
        }else if(sucursalSeleted === '80132'){
            setSucursalname('AGUA VERDE')
        }else if(sucursalSeleted === '20180'){
            setSucursalname('TOSCANA')
        }else if(sucursalSeleted === '20175'){
            setSucursalname('ASTURIAS')
        }else if(sucursalSeleted === '20185'){
            setSucursalname('LA CEJA')
        }else if(sucursalSeleted === '20186'){
            setSucursalname('ARIZONA')
        }else if(sucursalSeleted === '20188'){
            setSucursalname('ALCATRAZ')
        }else if(sucursalSeleted === '20194'){
            setSucursalname('MANAURE')
        }else if(sucursalSeleted === '90008'){
            setSucursalname('LA AGUADA')
        }else{
            setSucursalname('')
        }
    }
    //Grabar Recepcion
    const onSubmitsave = async (data) =>{
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede registrar transportes para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                    //if(rango > 363599999){ //Festivo dia lunes
                        setAlert({
                            msg: 'Usted ya no puede registrar transportes para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){
            //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede registrar transportes para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }    
        //Valido Fecha
        if(fecha > hoy){
            setAlert({
                msg: 'Fecha no puede ser "mayor" a la fecha actual',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return     
        }
        
        dataTransport['Ibm'] = sucursalSeleted
        dataTransport['strFinca'] = sucursalname
        dataTransport['dtmFecha'] = date
        dataTransport['ciclo'] = concepto.intCiclo?concepto.intCiclo:0      
        dataTransport['cantidad'] = data.personas
        dataTransport['placa'] = data.placa
        dataTransport['conductor'] =  data.conductor          
        dataTransport['origen'] = data.origen      
        dataTransport['Observacion'] = data.observacion
        dataTransport['employeesId'] =  auth.data._id
        dataTransport['email_USUA'] =  auth.data.email
        //console.log({dataTrans: dataTransport})
        //Enviar a grabar
        transportRequest(dataTransport);
    }
    const transportRequest = async (dataTransports) =>{             

        try {           
            const response = await requestHttp(
                {
                    endpoint: '/transports',
                    body: dataTransports,
                    token: getToken()
                }
            );             
            cleanStates();
            getTransports();            
            showAlert(
                'Transporte', 
                "Creado Correctamente", 
                SW_ICON.SUCCESS                
            );                  
        } catch (error) {            
            showAlert('Error', "Transporte Incorrecto", SW_ICON.ERROR);
        }       
       
    }
    //Validar dia de la semana
    const fechaComoCadena = date +  " 00:01:59"; // día semana
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];

    const fecha = new Date(fechaComoCadena);
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    
    const rango = new Date(hoy - fecha);

    const { msg } = alert;  
  return (
    <Page>
        <AddTransportWrapper_Global>
            <AddTransportWrapper_Global_DOS>
                <LogoImageWrapper>                                                
                            <PageTitle>
                                REGISTRO DE TRANSPORTE
                            </PageTitle>
                            <img src={"Logodos.jpg"} alt="imgLogo"/>
                        </LogoImageWrapper>                
                    <br/>
                    { msg && <Alert alert = {alert} />}
                   <FormControl_Global
                        margintop='20px'
                   >              
                        <AddTransportWrapper_Global_DOS
                            basis='50%'
                        >
                            <FormControl>                                                       
                                    <FormControlInputSearch>
                                        <label>FINCA DESTINO</label>                    
                                        <select id="sucursal"
                                                {...register("sucursal")}
                                                onChange={handleChange}
                                                tabIndex="1"
                                                value={sucursalSeleted}
                                                disabled={ auth.data.role != 1 && rdtprov.ibm != 90021?true:false}>
                                            <option value={""}>--Seleccione--</option>
                                            {sucursal.map(item=>(                                    
                                                    <option key={item.strCodigo} 
                                                            value={item.strCodigo}                                                                                               
                                                            id={item.strCodigo}                                                                                                      
                                                    >{item.strNombre}</option>
                                                )
                                                )
                                            }                                                                                                                                               
                                        </select>
                                    </FormControlInputSearch>                            
                                    <FormControlInputSearch>
                                        <label>FECHA TRANSPORTE</label>
                                        <input  type="date"
                                                {...register("fechatransporte", { required: true })}
                                                value={date} 
                                                onChange={handleChangedate}
                                                tabIndex="2"
                                        />
                                        {errors.fechatransporte?.type === 'required' && <span>Este Campo es requerido</span>}
                                    </FormControlInputSearch>
                            </FormControl>
                        </AddTransportWrapper_Global_DOS>
                        <AddTransportWrapper_Global_DOS
                            basis='50%'
                        >
                            <FormControl>
                                <FormControlTitle>
                                    <label>{sucursalname}</label>
                                </FormControlTitle>
                                <FormControlTitle>
                                        <span>
                                            {nombreDia}
                                            <br/>                                     
                                            {date}
                                        </span>
                                </FormControlTitle>                                                             
                            </FormControl>
                        </AddTransportWrapper_Global_DOS>                                               
                    </FormControl_Global>
                    <form>
                        <FormControl_Global
                            margintop='20px'
                        >                            
                            <FormControl>
                                                     
                                <FormControlInputSearch>
                                        <label>CICLO</label>
                                        <input  type="number"                                    
                                                {...register("ciclo")} 
                                                value={(concepto.intCiclo?concepto.intCiclo:0)}                                                                                          
                                                disabled={true}
                                                tabIndex="3"  
                                                id="input3"                                                     
                                        />                                    
                                </FormControlInputSearch>
                           
                          
                                <FormControlInputSearch>
                                        <label>PERSONAS</label>
                                        <input  type="number"                                    
                                                {...register("personas")}                                                                                           
                                                autoFocus
                                                tabIndex="4"  
                                                id="input4"                                                     
                                        />                                    
                                </FormControlInputSearch>
                         
                           
                                <FormControlInputSearch>
                                        <label>PLACA_VEHIC</label>
                                        <input  type="text"                                    
                                                {...register("placa")}                                                                                                                                           
                                                tabIndex="5"  
                                                id="input5"                                                     
                                        />                                    
                                </FormControlInputSearch>
                                                                                                                                                    
                            </FormControl>                            
                            <FormControl>
                                <FormControlInputSearch>
                                        <label>EMPRESA</label>
                                        <input  type="text"                                    
                                                {...register("conductor")}                                                                                                                                           
                                                tabIndex="6"  
                                                id="input6"                                                     
                                        />                                    
                                </FormControlInputSearch>
                            </FormControl>
                            <FormControl>                                
                                <FormControlInputSearch>
                                        <label>ORIGEN</label>
                                        <input  type="text"                                    
                                                {...register("origen")}                                                                                                                                           
                                                tabIndex="7"  
                                                id="input7"                                                     
                                        />                                    
                                </FormControlInputSearch>                     
                            </FormControl>                     
                        
                        </FormControl_Global>                   
                        <FormControl>                                                                                                                                                                                       
                            <FormControl>                                
                                <FormControlInputSearch>
                                        <label>OBSERVACIÓN</label>
                                        <input  type="text"                                    
                                                {...register("observacion")}                                                                                                                   
                                                tabIndex="8"
                                                id="input8"
                                                                                                                                                                                            
                                        />                                    
                                </FormControlInputSearch>                                
                            </FormControl>                            
                        </FormControl>
                        <FormControl>                        
                            <Button label="GRABAR" onPress={handleSubmit(onSubmitsave)} />                               
                        </FormControl>
                    </form>
                    <PropertyTypesContainerRow>
                        <PropertyTypesContainerColum>
                            <label>Ultimos transportes digitados...</label>
                            {transports.map((item, key) => 
                                <PropertyTransports
                                    key={key}                   
                                    {...item} 
                                    propertyTypeSeleted               
                                />
                                )
                            }  
                        </PropertyTypesContainerColum>
                    </PropertyTypesContainerRow>                       
                    
            </AddTransportWrapper_Global_DOS>
        </AddTransportWrapper_Global>      
    </Page>
  )
}


