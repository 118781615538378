import styled, {createGlobalStyle} from "styled-components";
import { MENU_HEIGHT } from "./constants/styles";

export const GlobalStyle = createGlobalStyle`
        *, ::after, ::before {
        box-sizing: border-box;   
        //border: 1px solid red;           
    }     
    
    body{        
        //margin: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;                   
        font-family: "Lato";        
        //border: 1px solid red;
        
        
    }
`;

export const PageTitle = styled.h1`
    font-size: 2.2em;
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;
    
`

export const SubTitle = styled.p`

    color: #C0C2D2;
    font-size: 1em;
    text-transform: capitalize;
    margin: 0;
    margin-top: 3px;
    span{
        color: #6262A0;
        //color: blanchedalmond;
        color: ${props => props.color};
    }

`;

export const FormControl = styled.div`
    //border: 1px solid blue;
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    background-color: ${props => props.color};
    padding: 5px 10px;
    display: flex;  
    button{
        padding: 10px
    }      
`;

export const FormControlInput = styled.div`
    //border: 1px solid blue;
        
    flex: 1;    
    label{
        display: block;
        font-size: 0.80em;
        color: #222;
        color: ${props => props.colortext};
        font-weight: 500;   
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],input[type="date"]{
        //display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`
export const FormControlInputDuplicate = styled.div`
    //border: 1px solid red;
    //flex: 1;    
    flex-direction: row;
    padding: 10px;
    label{
        display: block;
        font-size: 0.80em;
        color: #222;
        font-weight: 500;   
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],input[type="date"]{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 5px;
        margin: 0px;
        //background-color: transparent;
        border: none;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`

export const FormControlInputRadio = styled.div`
    //border: 1px solid blue;   
    //width: 100%;
    display: flex;
    margin: 10px;    
    label{
        display: block;
        font-size: 0.95em;
        color: #222;
        color: ${props => props.colortext};
        font-weight: 500;   
        //width:100%;            
    }
    input[type="radio"]{
        margin-left: 8px;
        margin-right: 24px;
    }    
`
export const FormControlInputFile = styled.div`
    display: flex;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    /*
    label{
        display: block;
        font-size: 0.95em;
        color: #222;
        font-weight: 500;               
    }
    input[type="file"]{
        margin-left: 8px;
    }*/    
`
export const FileInput = styled.input`
  display: none;
  //color: blue;
`;

export const FileName = styled.span`
  margin-top: 10px;
`;

export const Label = styled.label`

  background-color: ${props => props.backcolor};
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block;
  position: relative;

  &:hover {
    background-color: #2980b9;
  }
`;

export const FormControlAction = styled.div`
    //border: 1px solid orange;
    display: flex;
    justify-content: center;
    align-items: center;
    
`

export const FooterFixed = styled.footer`
    position: fixed;
    bottom: ${MENU_HEIGHT + 5}px;
    left: 0;
    width: 100%;
`
export const FormControlInputSearch = styled.div`
    //border: 1px solid blue;
    flex: 1;    
    margin: 0 10px 0 10px;

    label{
        display: block;
        font-size: 0.80em;
        color: #26794f;
        font-weight: 500;
        padding-left: 10px ;
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],input[type="date"]{
        //display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 1.1em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border-radius: 15px;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`
