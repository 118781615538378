import { BsHandThumbsUp } from "react-icons/bs";
import { PropertyTypeWelcomeWrapper } from "./styles";


export const PropertyWelcome = () =>(
    <PropertyTypeWelcomeWrapper>        
       
        <h2>
            Ultimas novedades registradas<span><BsHandThumbsUp /></span>  
        </h2>   
    </PropertyTypeWelcomeWrapper>
    
)
