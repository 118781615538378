import React from 'react'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth"
import { useRdt } from "../../hooks/useRdt";
import { getToken, removeToken } from "../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import swal from 'sweetalert';
import Alert from "../../components/Alert/Alert"
import { FormControl, FormControlInput, SubTitle } from "../../globalStyles";
import { Page } from "../../components/Page";
import { PropertyCardDial } from './PropertyCardDial';
import { PropertyTypesContainerColum, 
         PropertyTypesContainerHead, 
         PropertyTypesContainerRow, 
         EmployeesDialsDetailTitle, 
         EmployeesDialsWrapper,
         EmployeesDialsDetailInfo } from "./styles";
import { PropertyCardWrapper, PropertyCardWrapperTd, PropertyCardWrapperTr, PropertyDescriptionWrapper, PropertyInfoWrapper } from "./PropertyCardDial/styles"
import { PropertyProfile } from "../Home/components/PropertyProfile";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { ButtonIconRemove } from "../../components/ButtonIconRemove";
import { IoTrashOutline, 
  IoRemoveCircleOutline,
  IoRadioButtonOffOutline,
  IoArrowBackCircleOutline,
  IoArrowBack,
  IoDuplicate,
  IoAddCircleOutline,
  IoSave, 
  IoSend} from "react-icons/io5";
import { PropertyCardDialsm } from './PropertyCardDialsm';

const ALL_SUCURSALES = 0;
const fecha = Date.now();
const suma = 0; 

export const EmployeesDial = () => {

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov } = useRdt();
    
    const [ sucursal, setSucursal ] = useState([]);    
    const [ employees, setEmployees ] = useState([]);
    const [ employeesdials, setEmployeesdials ] = useState([]);     
    const [ rdts, setRdts ] = useState([]);
    const [ employeesdialsdayfincaRdt, setEmployeesdialsdayfincaRdt ] = useState([]);       
    const [ employeesdialsdayfincaRdtsort, setEmployeesdialsdayfincaRdtsort ] = useState([]);          
    const [ employeesdialsdaylate, setEmployeesdialsdaylate ] = useState([]);
    const [ employeesdialsdaylatesort, setEmployeesdialsdaylatesort ] = useState([]);

    const [ sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);
    const [ codtrabajador, setCodtrabajador ] = useState('');
    const [ fechaini, setFechaIni ] = useState('');
          
    const navigate = useNavigate();

    const { 
        register,         
        formState:{
            errors,           
        }  
    } = useForm({ mode: 'onChange'});  
    
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return                
                await getSucursalesAll();                                                      
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                 
    },[auth])
    //Si estoy editando    
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return            
                await rdtprov.ibm?setSucursalSelected(rdtprov.ibm):console.log(''); 
                await rdtprov.dateRdt?setFechaIni((rdtprov.dateRdt).split('T')[0]):console.log('');                             
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[rdtprov])
    //Sucursals       
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return                
                sucursalSeleted?setEmployees([]):console.log(''); 
                fechaini?setEmployeesdials([]):console.log('');                                             
                fechaini?setRdts([]):console.log(''); 
                sucursalSeleted?getEmployeesAll():console.log(''); 
                fechaini?getEmployeesdialsAll():console.log('');                                                       
                fechaini?getRdts():console.log(''); 
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                 
    },[sucursalSeleted])
    //Date
    useEffect(() => {  
        const getData = async ()=>{            
            try {
              const token = getToken();
                if(!token) return
                setEmployeesdials([]);
                setRdts([]);
                sucursalSeleted?getEmployeesdialsAll():console.log('');                                                  
                sucursalSeleted?getRdts():console.log('');                          
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                 
    },[fechaini])
    //Employeesdials
    useEffect(() => {  
        const getData = async ()=>{            
            try {
              const token = getToken();
                if(!token) return     
                setEmployeesdialsdayfincaRdt([]);                                         
                setEmployeesdialsdaylate([]);                                                   
                await getEmployeesdialsdayfincaRdt();                      
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                 
    },[employeesdials]) 
    useEffect(() => {  
        const getData = async ()=>{            
            try {
              const token = getToken();
                if(!token) return                               
                setEmployeesdialsdayfincaRdt([]);
                await getEmployeesdialsdayfincaRdt();                     
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                 
    },[rdts]) 
    useEffect(() => {  
        const getData = async ()=>{            
            try {
              const token = getToken();
                if(!token) return                               
                setEmployeesdialsdayfincaRdtsort([]);                
                setEmployeesdialsdaylatesort([]);
                await getEmployeesdialsdayfincaRdtsort('strReportado', 'strFincaReporta', 'strHoraIngreso','strConcepto');                     
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                 
    },[employeesdialsdayfincaRdt])

     //handle Sucursals
    const handleChange = event => {
        setSucursalSelected(event.target.value)             
    }
    const handleChangeDate = event => {
        setFechaIni(event.target.value)             
    }
    const handleChangeCodtrabajador = event => {
        setCodtrabajador(event.target.value)             
    }
    //Sucursals
    const getSucursalesAll = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/sucursal',                         
                    params: makeSucursalesFilters()                                     
                }
            );             
            const {data} = response;                
            setSucursal(data);                                   
        } catch (error) {
            console.log(error)
        }
    }
    const makeSucursalesFilters = ()=>{
        const filters = {};           
        return filters;       
    }
    //EmployeesAllnombre     
    const getEmployeesAll = async ()=>{        
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',
                    params: makeEmployeesFiltersAll()                                      
                }
            );             
            const {data} = response;              
            setEmployees(data);            
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }  
        }        
    }
    const makeEmployeesFiltersAll = ()=>{
        const filters = {}; 
        filters['Ibm'] = sucursalSeleted;
        if((auth.data.role !== 1) && (auth.data.role !== 2)){
            filters['Ibm'] = auth.data.ibm;
        }
        //console.log(filters);         
        return filters;       
    }
    //EmployeesDials
    const getEmployeesdialsAll = async ()=>{ 
        if(fechaini){
            try {            
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/employeesdials',
                        params: makeEmployeesdialsFiltersAll()                                      
                    }
                );             
                const {data} = response;                             
                await setEmployeesdials(data); 
            } catch (error) {
                if(error.response.status === 401){
                    hendleCloseSeccion();
                }else{
                    console.log(error)
                }  
            }            
        }else{            
            return;
        }             
    }
    const makeEmployeesdialsFiltersAll = ()=>{
        const filters = {}; 
        filters['dtFecha'] = fechaini; 
        filters['Ibm'] = sucursalSeleted;      
        //console.log(fechaini,sucursalSeleted)
        return filters;       
    }
    //getRdts
    const getRdts = async ()=>{        
        if(!fechaini){
            setRdts([])
            return
            //console.log('Fecha vacia')
        }
        if(sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020'){
            setRdts([])
            return
            //console.log({nohacerConsulta: sucursalSeleted})        
            }else{
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/Rdts/employeesibm',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;  
            //console.log({query: makePropertiesFilters() })        
            //console.log({rdts: data})               
            data?setRdts(data):console.log('');        
            } catch (error) {
                if(error.response.status === 401){
                    hendleCloseSeccion();
                }else{
                    console.log(error)
                }
            }
                   
        }                   
    }
    const makePropertiesFilters = ()=>{
        const filters = {};                
            filters['Fecha_INI'] = fechaini; 
            filters['Fecha_FIN'] = fechaini; 
            filters['strCodigo_SUCU'] = sucursalSeleted;   
            filters['IntCodigo_EMPL'] = codtrabajador;    
        // filters['employeesId'] = auth.data._id;     
            //console.log(fechaini);
        return filters;       
    }
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }    
    //Comparo y encuentro trabajadores sin marcacion, con marcacion que tienen Rdt y reportados en la misma fincas.
    const getEmployeesdialsdayfincaRdt = async () =>{ 
        if(sucursalSeleted === '90021' || fechaini === ''){
            return
        } 
        //Saco las llegadas tarde
        const later = employeesdials.filter((item) => item.strHoraIngreso !== "06:00");
        setEmployeesdialsdaylate(later);    
        //Recorro y encuentro los trabajadores que les han reportado algún RDT y agrego la columna "strReportado"
        const nuevaLista = employeesdials.map(item1 => {
            const item2 = rdts.find(item2 => item2.IntCodigo_EMPL === item1.IntCodigo);            
            return {
              ...item1,
              strReportado: item2 ? 'SI' : ''
            };
        });
        //Recorro la nueva lista con los rdt reportado, sin reportar y encuentro los trabajadores que los reportarón en una finca diferente
        const nuevaListados = nuevaLista.map(item1 => {
            const item2 = rdts.find(item2 => (item2.IntCodigo_EMPL === item1.IntCodigo) && (item2.IbmLaboro !== sucursalSeleted));
            return {
              ...item1,
              strFincaReporta: item2 ? item2.IbmLaboro : ''
            };
        });         
        setEmployeesdialsdayfincaRdt(nuevaListados)    

    }
    //Ordeno por Rdt reportado y Finca que reporta
    const getEmployeesdialsdayfincaRdtsort = async (keyRdt, keyIbm, keyHora, keyNew) =>{ 
        if(sucursalSeleted === '90021' || fechaini === ''){
            return
        }     
        //Ordeno por finca que reporta
        const sortedList = [...employeesdialsdayfincaRdt].sort((a, b) => a[keyIbm] > b[keyIbm] ? 1 : -1);
        //Ordeno por Trabajador que fue reportado.
        const sortedListdos = [...sortedList].sort((a, b) => a[keyRdt] > b[keyRdt] ? 1 : -1);
        setEmployeesdialsdayfincaRdtsort(sortedListdos)

        //Ordeno por hora que reporta empleados que llegan tarde
        const sortedListcinco = [...employeesdialsdaylate].sort((a, b) => a[keyHora] > b[keyHora] ? 1 : -1);
        setEmployeesdialsdaylatesort(sortedListcinco)

    }  
    //Validar dia de la semana
    const fechaComoCadena = fechaini.split('T')[0] +  " 00:01:59"; // día semana 
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];

    const { msg } = alert;

  return (
    <Page>       
        <EmployeesDialsDetailTitle>
          ANALISIS DE MARCACIONES DE TRABAJADORES
        </EmployeesDialsDetailTitle> 
        { msg && <Alert alert = {alert} />}
        <form>
            <PropertyTypesContainerRow>            
                <PropertyTypesContainerColum>
                    <LogoImageWrapper>                                
                        <img src={"Logodos.jpg"} alt="imgLogo"/>                               
                    </LogoImageWrapper>                   
                </PropertyTypesContainerColum>
                <PropertyTypesContainerColum>
                <FormControl>
                    <FormControlInput>
                            <label>FINCA REPORTADA</label>                    
                            <select id="sucursal"
                                        {...register("sucursal", { required: true })}
                                        onChange={handleChange}
                                        value={sucursalSeleted}
                                        disabled={ auth.data.role !== 1 && rdtprov.ibm !== 90021?true:false}>
                                    <option value={""}>--Seleccione--</option>
                                    {sucursal.map(item=>(                                    
                                            <option key={item.strCodigo} 
                                                    value={item.strCodigo}                                                                                               
                                                    id={item.strCodigo}
                                        >{item.strNombre}</option>
                                        )
                                        )
                                    }  
                                                                                                                                                                                
                            </select>
                    </FormControlInput>
                </FormControl>  
                <FormControl>
                    <FormControlInput>
                                <label>FECHA DEL RDT</label>
                                <input  type="date"
                                        {...register("fechaini")} 
                                        onChange={handleChangeDate}
                                        value={fechaini}
                                />                            
                                {errors.fechaini?.type === 'required' && <span>Este Campo es requerido</span>}
                    </FormControlInput>
                </FormControl> 
                </PropertyTypesContainerColum>
                <PropertyTypesContainerColum>
                <FormControl>
                    <FormControlInput>
                                <label>COD.TRABAJDOR:</label>
                                <input  type='text'                                    
                                        {...register("codtrabajador")}                                                                        
                                        onChange={handleChangeCodtrabajador}
                                />
                    </FormControlInput>
                </FormControl>
                </PropertyTypesContainerColum>
                <PropertyTypesContainerColum>
                <EmployeesDialsDetailInfo>
                    <p> 
                        USU:<span>{auth.data ? auth.data.email : 'Usuario no registrado'}</span><br/>
                        IBM:<span>{sucursalSeleted}</span><br/>
                        FEC:<span>{fechaini}</span><br/><br/>
                        DIA:<span>"{nombreDia}"</span>                                                                                                                             
                    </p> 
                </EmployeesDialsDetailInfo> 
                </PropertyTypesContainerColum>
                <PropertyTypesContainerColum>
                    <LogoImageWrapper>                                
                                                    
                    </LogoImageWrapper> 
                </PropertyTypesContainerColum>
                
            </PropertyTypesContainerRow>  
        </form>
        <PropertyInfoWrapper>
            <h3>TOTAL TRABAJADORES: <span>{employees.length}</span></h3>            
        </PropertyInfoWrapper>
        
        <br/> 
        <PropertyCardWrapperTr>            
            <PropertyCardWrapperTd>
                <h3>CON MARCACIÓN: <span>{employeesdialsdayfincaRdtsort.length}</span></h3>                
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>COD</th>
                            <th>NOMBRE</th>
                            <th>H.INI</th>
                            <th>H.FIN</th>
                            <th>T.LAB</th> 
                            <th>RDT</th>
                            <th>REPORTA</th>                           
                        </tr>
                    </thead>
                    <tbody>
                        {employeesdialsdayfincaRdtsort.map((elemento, key) => (
                        <tr>
                            <th>
                                <PropertyDescriptionWrapper>
                                    {key + 1}
                                </PropertyDescriptionWrapper>
                                
                            </th>
                            <th>
                                <PropertyDescriptionWrapper>
                                    {elemento.IntCodigo}
                                </PropertyDescriptionWrapper>
                                
                            </th>
                            <th>
                                <PropertyDescriptionWrapper>
                                    {elemento.strNombreTrabajador}
                                </PropertyDescriptionWrapper>
                                
                            </th>
                            <th>
                                <PropertyDescriptionWrapper>
                                {elemento.strHoraIngreso}
                                </PropertyDescriptionWrapper>
                                
                            </th>
                            <th>
                                <PropertyDescriptionWrapper>
                                {elemento.strHoraSalida}
                                </PropertyDescriptionWrapper>
                                
                            </th>
                            <th>
                                <PropertyDescriptionWrapper>
                                {elemento.strTiempoLaborado}
                                </PropertyDescriptionWrapper>
                                
                            </th>
                            <th>
                                <PropertyDescriptionWrapper>
                                {elemento.strReportado}
                                </PropertyDescriptionWrapper>                                
                            </th>
                            <th>
                                <PropertyDescriptionWrapper>
                                    {elemento.strFincaReporta}
                                </PropertyDescriptionWrapper>
                                
                            </th>
                        </tr>
                        ))}                
                    </tbody>
                
                </table>                 
            </PropertyCardWrapperTd> 

            <PropertyCardWrapperTd>
                <PropertyCardWrapperTr>                    
                    <PropertyCardWrapperTd>
                        <h3>TRABAJADORES CON LLEGADA TARDE: <span>{employeesdialsdaylatesort.length}</span></h3>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>COD</th>
                                    <th>NOMBRE</th>
                                    <th>H.INI</th>
                                    <th>H.FIN</th>
                                    <th>T.LAB</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employeesdialsdaylatesort.map((elemento, key) => (
                                <tr>
                                    <th>
                                        <PropertyDescriptionWrapper>
                                            {key + 1}
                                        </PropertyDescriptionWrapper>
                                        
                                    </th>
                                    <th>
                                        <PropertyDescriptionWrapper>
                                            <span>{elemento.IntCodigo}</span>
                                        </PropertyDescriptionWrapper>
                                        
                                    </th>
                                    <th>
                                        <PropertyDescriptionWrapper>
                                            {elemento.strNombreTrabajador}
                                        </PropertyDescriptionWrapper>
                                        
                                    </th>
                                    <th>
                                        <PropertyDescriptionWrapper>
                                            <span>{elemento.strHoraIngreso}</span>
                                        </PropertyDescriptionWrapper>
                                        
                                    </th>
                                    <th>
                                        <PropertyDescriptionWrapper>
                                        {elemento.strHoraSalida}
                                        </PropertyDescriptionWrapper>
                                        
                                    </th>
                                    <th>
                                        <PropertyDescriptionWrapper>
                                        {elemento.strTiempoLaborado}
                                        </PropertyDescriptionWrapper>
                                        
                                    </th>

                                </tr>
                                ))}                
                            </tbody>
                        
                        </table> 
                    </PropertyCardWrapperTd>
                </PropertyCardWrapperTr>
                <PropertyCardDial ibm={sucursalSeleted} fecha={fechaini} />
                <PropertyCardDialsm ibm={sucursalSeleted} fecha={fechaini}/>                
            </PropertyCardWrapperTd>                                 
        </PropertyCardWrapperTr>
    </Page>
  )
}
