import { Link } from "react-router-dom";
import styled from "styled-components"

export const PropertyTableContainer = styled.div`
  //max-height: 920px; /* Establece la altura máxima para la tabla y habilita el desplazamiento vertical */
  overflow-y: auto; /* Habilita el desplazamiento vertical si el contenido supera la altura máxima */
  position: relative; /* Necesario para el posicionamiento relativo del encabezado */
`;

export const PropertyTable = styled.table`
  width: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const PropertyTableHeader = styled.th`
  background-color: #b0c2f2;
  text-align: left;
  padding: 6px;
  text-align: right;
  //position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
  &:first-child {
    left: 0; /* Fija el primer encabezado de columna en la parte izquierda */
  }

  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableHeaderRow = styled.tr`
  background-color: #b0c2f2;
  position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
`;

export const PropertyTableRow = styled.tr`
  font-size: 16px;
  &:nth-child(even) {
    background-color: #d8f8e1;
  }
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        font-size: 12px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        font-size: 11px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableCell = styled.td`
  border: 1px solid #ddd;
  padding: 4px;
  text-align: center;
  color: #6262A0;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 3px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;


export const PropertyTableCellAnio = styled.td`
  //border: 1px solid #ddd;
  //padding: 6px;
  text-align: center;
  color: blue;
  //font-size: 20px;
  //background-color: #ecf1f4;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;