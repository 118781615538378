import { useState } from "react";
import { ButtonTransport, PropertyTable, 
         PropertyTableCell, 
         PropertyTableCellTotal, 
         PropertyTableContainer, 
         PropertyTableHeader, 
         PropertyTableHeaderRow, 
         PropertyTableRow } from "./styles"
import { IoAccessibility, IoHomeSharp } from "react-icons/io5";
import { ButtonIcon } from "../../../../components/ButtonIcon";
import { ModalTransport } from "./Components/ModalTransport";
import { Button } from "../../../../components/Button";


export const TransportFarms = (props) => {

    const [modal, setModal] = useState(false);
    const [animarModal, setAnimarModal] = useState(false);
    const [idTransport, setIdTranspor] = useState(null);

    let transport = []
    transport = (props.transport.map((item)=>
        item
    )) 
    //console.log('Hola Transporte')  
    //console.log({transport: transport})
    //console.log({modalTransport: modal})
    //console.log({modalAnimarTransport: animarModal})

    const handleModalTransportFarm = (idDetail)=>{
        setModal(true)
        setIdTranspor(idDetail);
        //console.log({id: idState})  
        setTimeout(() => {
          setAnimarModal(true)
        }, 300);
    }
    
    //Sumar totales
    let totalTransportado = 0;    
    if(Object.keys(transport).length > 0 ){
    //console.log('en submitCant')
        let a = 0;
                    
        for (let i = 0; i < transport.length ; i++){          
            a = a + transport[i].cantidad;
                        
        }
        totalTransportado = a;
        
            
    }else{
    totalTransportado = 0;        
    }
    //console.log(totalTransportado)
return (
<PropertyTableContainer>
    <br/>
    <PropertyTable>
       <thead>
            { modal?'':           
                <PropertyTableHeaderRow>
                    <PropertyTableHeader>DESTINO</PropertyTableHeader>
                    <PropertyTableHeader>FECHA</PropertyTableHeader>
                    <PropertyTableHeader>PERS</PropertyTableHeader>                             
                    <PropertyTableHeader>TRANS</PropertyTableHeader>                              
                </PropertyTableHeaderRow>
            }
       </thead>
       <tbody>
           {transport.map((item)=>
               <PropertyTableRow key={item._id}>
                    <PropertyTableHeader>{item.strFinca}</PropertyTableHeader>                                                    
                    <PropertyTableCell>{item.dtmFecha.split('T')[0]}</PropertyTableCell>
                    <PropertyTableCell>
                        <ButtonTransport onClick={()=>handleModalTransportFarm(item)}>{item.cantidad}</ButtonTransport>
                    </PropertyTableCell>                                     
                    <PropertyTableCell>{item.conductor}</PropertyTableCell>                                                                                                                                                               
               </PropertyTableRow>           
           )}       
       </tbody>
       <tfoot>
           <PropertyTableRow>
               <PropertyTableHeader></PropertyTableHeader>                                 
               <PropertyTableCellTotal>Total:</PropertyTableCellTotal>                                                                                                                                                                                
               <PropertyTableCell>           
               <span>{totalTransportado}</span>                 
               </PropertyTableCell>
           </PropertyTableRow>
       </tfoot>
    </PropertyTable>
    {modal && <ModalTransport
                    setModal={setModal}
                    animarModal={animarModal}
                    detail={idTransport}/>                                            
    }
</PropertyTableContainer>
)
}

