import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../../hooks/useRdt";
import { showAlert, SW_ICON } from "../../../utils/swAlert";
import { HTTP_VERBS, requestHttp } from "../../../utils/HttpRequest";
import { getToken, removeToken } from "../../../utils/TokenLS";
import { PropertyTable, PropertyTableCell, PropertyTableCellAnio, PropertyTableContainer, PropertyTableHeader, PropertyTableHeaderRow, PropertyTableRow } from "./styles";
import Spinner from "../../../utils/Spinner";

export const PrecipitationDetailaniosemana = (item) => {

    //console.log({detailAniosem: item})

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ precipitationanio, setPrecipitationanio ] = useState([]); 
    const [ precipitationaniosem, setPrecipitationaniosem ] = useState([]);
    const [ iterations, setIterations ] = useState(false); 
    const [ estado, setEstado ] = useState(false) 
    
    const [alert, setAlert] = useState({})    

    const navigate = useNavigate();

    const Cargar = ()=>{
        setTimeout(()=>{    
             setIterations(true)                               
             setEstado(true)                        
        }, 200)           
    }

    const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ 
        mode: 'onChange',
        defaultValues: {
            checkbox: []
        }
   }); 

    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
    }
    //Token valido o no expirado
    useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return
            await item.anio ? getPrecipitationaniosem():console.log('');
            await item.anio ? getPrecipitationanio():console.log('');
            //await getPrecipitationanio();                      
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
    },[auth])
    //cargar data
    useEffect(() => {  
        if(!estado){
            Cargar()
        }               
    },[item.info])  
    //semana
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await item.semana ? getPrecipitationaniosem():console.log('');
                //await getPrecipitationanio();                      
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[item.semana]) 
    //anio
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                //await item.semana ? getPrecipitationaniodia():console.log('');
                await item.anio ? getPrecipitationanio():console.log('');
                //await getPrecipitationanio();                      
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[item.anio]) 
    //getPrecipitation
    const getPrecipitationanio = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations/anio',                         
                    params: makePropertiesFiltersanio()                                     
                }
            );             
            const {data} = response;                
            setPrecipitationanio(data);    
            //console.log({dataAnio: data})                          
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersanio = ()=>{
        const filters = {};                
            filters['anio'] = item.anio;                         
            //filters['employeesId'] = auth.data._id;              
            //console.log(anio);
        return filters;       
    }
    // Crear un objeto para almacenar los totales de precipitacion por año
    const precipitacionPorAnio = {};

    // Calcular los totales de precipitacion por año
    precipitationanio.forEach((itemPrec) => {
        if (!precipitacionPorAnio[itemPrec.anio]) {
            precipitacionPorAnio[itemPrec.anio] = {};
        }
        if (!precipitacionPorAnio[itemPrec.anio][itemPrec.Finca]) {
            precipitacionPorAnio[itemPrec.anio][itemPrec.Finca] = 0;
        }
        precipitacionPorAnio[itemPrec.anio][itemPrec.Finca] += itemPrec.totalLLuvia;
    });
    //console.log({precipitacionAnio: precipitacionPorAnio})
    //getPrecipitationaniosem
    const getPrecipitationaniosem = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations/aniosem',                         
                    params: makePropertiesFiltersaniosem()                                     
                }
            );             
            const {data} = response;                
            setPrecipitationaniosem(data);    
            //console.log({dataAniosem: data})                          
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersaniosem = ()=>{
        const filters = {};                
            filters['anio'] = item.anio;   
            filters['semana'] = item.semana;                         
            //filters['employeesId'] = auth.data._id;              
            //console.log(item.semana);
        return filters;       
    }
    // Crear un objeto para almacenar los totales de precipitacion por semana
    const precipitacionPorAniosem = {};
    // Calcular los totales de precipitacion por semana
    precipitationaniosem.forEach((itemPrec) => {
        if (!precipitacionPorAniosem[itemPrec.anio]) {
            precipitacionPorAniosem[itemPrec.anio] = {};
        }
        if (!precipitacionPorAniosem[itemPrec.anio][itemPrec.semana]) {
            precipitacionPorAniosem[itemPrec.anio][itemPrec.semana] = {};
        }
        if (!precipitacionPorAniosem[itemPrec.anio][itemPrec.semana][itemPrec.Finca]) {
            precipitacionPorAniosem[itemPrec.anio][itemPrec.semana][itemPrec.Finca] = 0;
        }
        precipitacionPorAniosem[itemPrec.anio][itemPrec.semana][itemPrec.Finca] += itemPrec.totalLLuvia;
    });

    const años = Object.keys(precipitacionPorAniosem);
    const fincas = [...new Set(precipitationaniosem.map((itemPrec) => itemPrec.Finca))];
    const semanas = [...new Set(precipitationaniosem.map((itemPrec) => itemPrec.semana))];

    //console.log({añosmes: años})
    //console.log({semanas: semanas})
    //console.log({fincassemanas: fincas})    
    //console.log({fincasPrecipitacionmes: precipitacionPorAniomes})
    //<h2>Milimetros de LLuvia x Semana</h2>
  return (     
    <PropertyTableContainer>
      <br/>
      {iterations?      
      <PropertyTable>      
       <thead>      
            <PropertyTableHeaderRow>
            <PropertyTableHeader>AÑO:</PropertyTableHeader>            
                {años.map((año) => (
                    <PropertyTableHeader key={año}>{año}</PropertyTableHeader>
                ))}   
            </PropertyTableHeaderRow>            
            <PropertyTableRow>                
                <PropertyTableHeader>SEM:</PropertyTableHeader>
                <PropertyTableHeader></PropertyTableHeader>
                {años.map((año) =>
                    semanas.map((sem) => (
                    <PropertyTableHeader key={`${año}-${sem}`}>{sem}</PropertyTableHeader>
                    ))
                )}
            </PropertyTableRow>          
        </thead>
        <tbody>
            {fincas.map((finca) => (
            <PropertyTableRow key={finca}>
                <PropertyTableHeader>{finca}</PropertyTableHeader>
                <PropertyTableHeader>                    
                    {estado?años.map((año) =>                         
                                <PropertyTableCellAnio key={`${finca}-${año}`}>{ precipitacionPorAnio[año][finca] || 0 }</PropertyTableCellAnio>
                            )             
                        :'cargando...'}   
                </PropertyTableHeader>
                {años.map((año) => 
                    semanas.map((sem)=> (
                        <PropertyTableCell key={`${finca}-${año}-${sem}`}>
                            {precipitacionPorAniosem[año][sem][finca] || 0}
                        </PropertyTableCell>          
                    ))              
                )}
            </PropertyTableRow>
            ))}
        </tbody>
      
      </PropertyTable>
      :<Spinner/>}
    </PropertyTableContainer>   
    
  )
}


