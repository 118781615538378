import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Page} from "../../components/Page"
import { FormControl, 
         FormControlAction,
         FooterFixed, 
         FormControlInput, 
         FormControlInputRadio, 
         FormControlInputFile, 
         PageTitle, 
         FormControlInputSearch} from "../../globalStyles";
import {Button} from "../../components/Button"
import { ButtonIcon } from "../../components/ButtonIcon";
import {IoEye, IoSearchCircleSharp } from "react-icons/io5";
import { upload } from "@testing-library/user-event/dist/upload";
import { NoBiometricsWrapper,
         NoBiometricsWrapper_Global, 
         NoBiometricsWrapper_Global_DOS } from "./styles";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { getToken, removeToken } from "../../utils/TokenLS";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";


export const NoBiometrics = () =>{   
     
    const { auth, closeSeccionAuth } = useAuth();
    const [ employe, setEmploye ] = useState([]);

    const [employeSeleted, setEmployeSelected] = useState();
    const [codigoSeleted, setCodigoSelected] = useState();

    const navigate = useNavigate();
    
    const { 
            register, 
            handleSubmit,
            formState:{
                errors,
                isValid
            }  
    } = useForm({ mode: 'onChange'});   

     //Close session if token expired
     const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }

    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                //await getSucursalesAll(); 
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                 
    },[auth])
    
    
    //EmployeesDoc
    const handleEmployeDoc = () => { 
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                await  getEmployeesDoc();   
                setCodigoSelected('');
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                
     } 

    const getEmployeesDoc = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',                         
                    params: makeEmployeesFiltersDoc()                                     
                }
            );             
            const {data} = response;              
            setEmploye(data); 
            setEmployeSelected('');           
            setCodigoSelected(''); 
            if(!data.length){
                showAlert('Error', "Identificación no existe", SW_ICON.ERROR);   
            }
                                   
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }             
        }
    }
        
    const makeEmployeesFiltersDoc = ()=>{
        const filters = {};  
        filters['strDocumento'] = employeSeleted;
        return filters;       
    }

    //EmployeesCod
    const handleEmployeCod = () => {   
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                await   getEmployeesCod();       
                setEmployeSelected(''); 
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                                
     } 

    const getEmployeesCod = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employees',                         
                    params: makeEmployeesFiltersCod()                                     
                }
            );             
            const {data} = response;              
            setEmploye(data); 
            setEmployeSelected('');           
            setCodigoSelected('');   
            if(!data.length){
                showAlert('Error', "Empleado no existe", SW_ICON.ERROR);   
            }  
                      
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }    
        }
    }

    const makeEmployeesFiltersCod = ()=>{
        const filters = {};         
            filters['IntCodigo'] = codigoSeleted;     
        return filters;       
    }

    //FileUpload
    const [file, setFile] = useState(null);

    const fileSelectedHandler = (event) =>{
        const fileSelected = event.target.files[0];
        setFile(fileSelected);        
    };

    const uploadFileHandler = (data) =>{
        if(file){
            requestUploadFile(file, data)           
        }else{
            showAlert("Error", "Selecciona un archivo", SW_ICON.ERROR);
        }        
    }

    const requestUploadFile = async (file, data) =>{
        try {
            const formData = new FormData();
            formData.append("propertyAdjunto", file);
            const response = await requestHttp({
                endpoint: '/nobiometrics/upload',
                contentType: CONTENT_TYPES.MULTIPART_FORM_DTA,
                body: formData
            })                                             
            onSubmitNobiometrics(data, response.data.newFileName)                
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }    
        }
    }

    //Nobiometrics
    const onSubmitNobiometrics = async (data, name) =>{   

        const dataNobiometric = {}               
        dataNobiometric['strDocumento'] =  employe[0].strDocumento
        dataNobiometric['IntCodigo'] =  employe[0].IntCodigo        
        dataNobiometric['strNombreTrabajador'] =  employe[0].strNombreTrabajador 
        dataNobiometric['dtFIngreso'] =  employe[0].dtFIngreso       
        dataNobiometric['dtFNovedad'] =  data.fechanovedad                
        dataNobiometric['strCodigoCentro'] =  employe[0].strCodigoCentro
        dataNobiometric['Ibm'] =  employe[0].strCodigoActividad
        dataNobiometric['strObservacion'] =  data.observacion
        //dataNobiometric['mainAdjunto'] =  name  
        dataNobiometric['employeesId'] =  employe[0]._id

        nobiometricsRequest(dataNobiometric); 
    }
    
    const nobiometricsRequest = async (dataNobiometrics) =>{             

        try {           
            const response = await requestHttp(
                {
                    endpoint: '/nobiometrics',
                    body: dataNobiometrics,
                    token: getToken()
                }
            );             
            setEmployeSelected('');           
            setCodigoSelected('');   
            setEmploye('');                                  
            showAlert(
                'Novedad', 
                "Creada Correctamente", 
                SW_ICON.SUCCESS                
            );
            setFile('');            
        } catch (error) {            
            showAlert('Error', "NoBiometrics Incorrecta", SW_ICON.ERROR);
        }     
       
    }
    /*
    <form onSubmit={handleSubmit(uploadFileHandler)}>

    <FormControl>
                        <FormControlInputFile>                            
                            <label>Adjuntar Archivo</label>
                            <input  type='file' 
                                    accept="image/png, image/jpeg, image/jpg"
                                    //multiple
                                    onChange={fileSelectedHandler}                                    
                            />
                        </FormControlInputFile>                                       
                    </FormControl>

    */
    return(
    <Page >
        <NoBiometricsWrapper_Global>
            <NoBiometricsWrapper_Global_DOS>
                <LogoImageWrapper> 
                    <PageTitle>                   
                        SIN BIOMETRÍA            
                    </PageTitle>         
                    <img src={"Logo.jpg"} alt="imgLogo"/>            
                </LogoImageWrapper>
                
                <br />
                <form onSubmit={handleSubmit(onSubmitNobiometrics)}>
                <FormControl>
                        <FormControlInputSearch>
                            <label>IDENTIFICACIÓN</label>
                            <input  type="number"                                    
                                     {...register("identificacionSearch")}
                                      value={employeSeleted} 
                                      onChange={e => setEmployeSelected(e.target.value)}                                                                     
                            />
                            
                        </FormControlInputSearch> 
                        <FormControlAction>
                            <ButtonIcon icon={IoSearchCircleSharp} onPress={handleEmployeDoc}/>                     
                        </FormControlAction>
                        
                        <FormControlInputSearch>
                            <label>CODIGO</label>
                            <input  type="number"                                    
                                     {...register("codigoSearch")}
                                      value={codigoSeleted} 
                                      onChange={e => setCodigoSelected(e.target.value)}                                                                     
                            />
                            
                        </FormControlInputSearch> 
                        <FormControlAction>
                            <ButtonIcon icon={IoSearchCircleSharp} onPress={handleEmployeCod}/>                     
                        </FormControlAction>                                         
                    </FormControl>
                    <br/>
                    <FormControl>
                        <FormControlInput>
                            <label>NOMBRE DEL TRABAJADOR</label>
                            <input  type="text"                                    
                                     {...register("name")}
                                     value={employe[0]?employe[0].strNombreTrabajador:''}                                                                        
                            />                            
                        </FormControlInput>                                          
                    </FormControl>                              
                    <FormControl>
                        <FormControlInput>
                            <label>IDENTIFICACIÓN</label>
                            <input  type="number"                                    
                                     {...register("identificacion")}    
                                     value={employe[0]?employe[0].strDocumento:''}                                                                     
                            />                            
                        </FormControlInput>                                          
                    </FormControl>            
                    <FormControl>
                    <FormControlInput>
                            <label>CODIGO</label>
                            <input  type="number"                                    
                                     {...register("codigo")}  
                                     value={employe[0]?employe[0].IntCodigo:''}                                                                      
                            />                            
                        </FormControlInput>                                                        
                    </FormControl>
                    <FormControl>
                        <FormControlInput>
                            <label>FINCA A LA QUE PERTENECE</label>
                            <input  type="text"                                    
                                     {...register("origen")}    
                                     value={employe[0]?employe[0].strCodigoCentro:''}                                                                    
                            />                            
                        </FormControlInput>                                          
                    </FormControl>                                                                                       
                    <FormControl>
                        <FormControlInput>
                            <label>FECHA DE NOVEDAD</label>
                            <input  type="date"
                                    {...register("fechanovedad", { required: true })} 
                            />
                            {errors.fechanovedad?.type === 'required' && <span>Este Campo es requerido</span>}
                        </FormControlInput>                                       
                    </FormControl>                    
                    <FormControl>
                        <FormControlInput>
                            <label>OBSERVACIÓN</label>
                            <input  type='text'                                    
                                     {...register("observacion")}                                                                        
                            />                            
                        </FormControlInput>                                          
                    </FormControl>
                    
                    <br />
                    <Button disabled={!isValid} label="Informar Biometria" type="submit" onPress={()=>{}} />
                    <br />
                                  
                </form>
                
            </NoBiometricsWrapper_Global_DOS>
        </NoBiometricsWrapper_Global>        
    </Page>
);};