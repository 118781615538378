import { Link } from "react-router-dom";
import styled, {css} from "styled-components";

export const PropertyTableContainer = styled.div`
  //border: 1px solid orange;
  max-height: 920px; /* Establece la altura máxima para la tabla y habilita el desplazamiento vertical */
  max-width: 1440px; /* Establece la altura máxima para la tabla y habilita el desplazamiento vertical */
  overflow-y: auto; /* Habilita el desplazamiento vertical si el contenido supera la altura máxima */
  position: relative; /* Necesario para el posicionamiento relativo del encabezado */
`;

export const PropertyTable = styled.table`
  width: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const PropertyTableHeader = styled.th`
  //border: 1px solid red;
  background-color: #b0c2f2;
  width: 800px;
  text-align: left;
  padding: 6px;
  text-align: right;
  position: sticky; /* Establece el posicionamiento pegajoso */
  top: 1; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
  &:first-child {
    left: 0; /* Fija el primer encabezado de columna en la parte izquierda */
  }
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableHeaderToday = styled.th`
  background-color: #b0c2f2;
  color: blue;
  text-align: left;
  padding: 6px;
  text-align: right;
  //position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
  &:first-child {
    left: 0; /* Fija el primer encabezado de columna en la parte izquierda */
  }
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableHeaderRow = styled.tr`
  background-color: #b0c2f2;
  position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
`;

export const PropertyTableRow = styled.tr`

  //font-size: 13px;
  &:nth-child(even) {
    background-color: #d8f8e1;
  }
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        font-size: 12px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        font-size: 11px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableCell = styled.td`
  border: 1px solid #ddd;
  //padding: 6px;
  text-align: center;
  color: #6262A0;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableCellToday = styled.td`
  border: 1px solid #ddd;
  //padding: 6px;
  text-align: center;
  color: blue;
  //font-size: 20px;
  background-color: lightblue;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableCellAplicate = styled.td`
  
  border: 1px solid #ddd;
  //padding: 6px;
  text-align: center;
  color: blue;
  //font-size: 20px;
  background-color: #f0a808;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableCellAnio = styled.td`
  //border: 1px solid #ddd;
  //padding: 6px;
  text-align: center;
  color: blue;
  position: sticky;
  width: 600px;
  text-align: right;
  
  span{
      color: black;
      font-size: 14px;
  }
  //background-color: #ecf1f4;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
    //border: 1px solid black;
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {      
        padding: 2px;   
        span{           
            font-size: 10px;
        }           
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

const BaseButtonStyle = css`
    width: 100%;
    font-size: 1em;
    color: blue;
    //text-decoration-line: underline;
    font-weight: bold;
    //background-color: #4A148C;
    background-color: transparent;//verde osc
    //background-color: #ff8c00;//Naranja
    text-align: center;
    border: none;
    //border-radius: 6px;
    //padding: 5px 5px;
    //opacity: 0.9;
    cursor: pointer;    
    
    &hover{        
        opacity: 1;
    }   
`;

export const ButtonAplicate= styled.button`
    ${BaseButtonStyle}
    
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }    
`;