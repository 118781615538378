import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export const PropertyGrafLinealChart = (item) => {
  const [ precipitationUltsem, setPrecipitationUltsem ] = useState([]);
  const [dias, setDias]=useState([]);
  const [precipitacion, setPrecipitation]=useState([]); 

  //console.log({item: item})             
  
  //sucursal       
  useEffect(() => {
    const getData = async ()=>{
        try {                                                    
          item.sucursal && item.semana && item.anio ? getPrecipitationUltSemanas():console.log('');            
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
        
  },[item.sucursal])
  //semana       
  useEffect(() => {
    const getData = async ()=>{
        try {                                                    
          item.sucursal && item.semana && item.anio ? getPrecipitationUltSemanas():console.log('');            
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
        
  },[item.semana])
  //anio       
  useEffect(() => {
    const getData = async ()=>{
        try {                                                    
          item.sucursal && item.semana && item.anio ? getPrecipitationUltSemanas():console.log('');            
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
        
  },[item.anio])
  //getPrecipitation
  const getPrecipitationUltSemanas = async ()=>{                
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/precipitations',                         
                params: makePropertiesFiltersUltSemanas()                                     
            }
        );             
        const {data} = response;                
        setPrecipitationUltsem(data);    
        //console.log({semana: precipitationUltsem})  
        let auxDias=[]
        let auxLluvia=[]
        data.map(elemento=>{
          auxDias.push(elemento.nombrediaCorto +' - '+ elemento.diaMes)
          auxLluvia.push(elemento.intPrecipitation)
        });
        setDias(auxDias)
        setPrecipitation(auxLluvia)
        //console.log({arreglo: dias})               
    } catch (error) {
        if(error.response.status === 401){
          console.log('')
        }else{
            console.log(error)
        }
    }    
  }
  const makePropertiesFiltersUltSemanas = ()=>{
      const filters = {};                                    
          filters['Ibm'] = item.sucursal?item.sucursal:console.log(''); 
          filters['anio'] = item.anio?item.anio:console.log('');  
          filters['semana'] = item.semana?item.semana:console.log('');  
          //filters['employeesId'] = auth.data._id;              
          //console.log(fechaini);
          //console.log({semana: item.sucursal})
      return filters;       
  }

  
  //const Dias=["Lunes","Martes","Miercoles","Jueves","Viernes","Sabado","Domingo"]
  //const Milimetros=[5,0,12,15,20,11,30]

  const data = {
    labels: dias,
    datasets:[{
       label: "mm", 
       data: precipitacion, 
       tension: 0.2,
       borderColor: "blue",
       fill: true,
       pintRadius: 6,
       pointBackgroundColor: "red",
       hoverBackgroundColor: 'yellow',
       hoverBorderColor: 'Black'
       
    }],
  };

  const options = {
    maintainAspectRatio:false,
    responsive: true,

    plugins:{
      legend:{
        display: false,
        position: 'top',
      },
    }

    /*scales:{
      y:{
        min: 0,
      },
    },

    plugins:{
      legend:{
        display: true,
        position: 'top',
      },
    },
    tooltip:{
      intersect: false,
      mode: 'index',
      callbacks:{
        label: function (context){
          const label = context.dataset.label || '';
          const value = context.formattedValue;
          return `${label}: ${value} mm`
        },
      },
    },  */
  };
  //<Line data={data} options={options}/>
  return (
    <div>
      <Line data={data} options={options}/>
    </div>
  )
}


