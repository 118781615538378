import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { showAlert, SW_ICON } from "../../../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";
import { getToken, removeToken } from "../../../../utils/TokenLS";
import { getStaticArcRetired } from "../../../../utils/staticArc";
import { RetiredCardWrapper, 
         RetiredDescriptionWrapper, 
         RetiredDetailWrapper_Global, 
         RetiredDetailWrapper_Global_DOS, 
         RetiredImageWrapper, 
         RetiredInfoWrapper, 
         RetiredValueWrapper } from "./styles";
import { FormControl, FormControlAction, FormControlInput, FormControlInputFile, FormControlInputRadio, SubTitle } from "../../../../globalStyles";
import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";
import { PropertyTypeLabel } from "../../../../components/PropertyTypeLabel";
import { TraslateDetailTitle } from "../../../Traslates/Components/TraslateDetail/styles";
import { Button } from "../../../../components/Button";
import { ButtonIcon } from "../../../../components/ButtonIcon";
import { IoTrashOutline } from "react-icons/io5";
import { Page } from "../../../../components/Page";
import swal from 'sweetalert';

export const RetiredDetail = ()=>{
    
   const { auth, closeSeccionAuth } = useAuth();

   const {id} = useParams()

   const navigate = useNavigate();

   const [ retired, setRetired ] = useState([]);
   const [ motivoSeleted, setMotivoSelected ] = useState('');
   const [ cargando, setCargando ] = useState(false);


   const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ mode: 'onChange'});   
    
   useEffect(() => {  
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return   
                getRetired()            
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();   
          
    },[auth])
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }    
    //Motivo
    const handleChange = event => {
        setMotivoSelected(event.target.value)                            
    }     
    //RetiredById
    const getRetired = async ()=>{
        
        try {                      
            const response = await requestHttp(
                {
                        method: HTTP_VERBS.GET,
                        endpoint: `/retiros/${id}`                                                                       
                }
            );             
            const {data} = response;                
            await setRetired(data);                   
            setCargando(true);  
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }    
        }
    
    }
    //Update Retired
    const onSubmitRetired = async (data, name) =>{   

        const dataRetired = {}                          
        dataRetired['dtFRetiro'] =  data.fecharetiro       
        dataRetired['strCausa'] =  motivoSeleted        
        dataRetired['strObservacion'] =  data.observacion
        dataRetired['mainAdjunto'] =  name          

        retiredRequest(dataRetired); 
    }    
    const retiredRequest = async (dataRetired) =>{             

        try {           
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.PUT,
                    endpoint: `/retiros/${id}`,
                    body: dataRetired,
                    token: getToken()
                }
            );                                                
            showAlert(
                'Retired', 
                "Actualizado Correctamente", 
                SW_ICON.SUCCESS                
            );
            setFile('');
            getRetired()            
        } catch (error) {  
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                showAlert('Error', "Update Incorrecta", SW_ICON.ERROR);
            }            
        }       
       
    }
    //Delete retired
    const showAlertYN = () =>{
        swal({
            title: "Esta usted seguro?",
            text: "Una vez eliminado, no podras recuperar este registro!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {   
                retiredDelete()         
             /*   swal("El registro ha sido eliminado correctamente!", {
                icon: "success",
                            
              });*/           
    
            } else {
              //swal("Su registro esta a salvo!");
              return
            }
            //console.log(willDelete)
          });
    }
    const retiredDelete = async () =>{             

    try {           
        const response = await requestHttp(
            {
                method: HTTP_VERBS.DELETE,
                endpoint: `/retiros/${id}`,               
                token: getToken()               
            }
        );   
        showAlert(
            'Retiro', 
            "Eliminado Correctamente", 
            SW_ICON.SUCCESS,   
            () => { navigate('/')}                 
        );                              
            
        } catch (error) { 
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
            }            
        }      
   
    }
    //FileUpload
    const [file, setFile] = useState(null);
    
    const fileSelectedHandler = (event) =>{
        const fileSelected = event.target.files[0];
        setFile(fileSelected);        
    };
    const uploadFileHandler = (data) =>{
        if(file){
            requestUploadFile(file, data)           
        }else{
            showAlert("Error", "Selecciona un archivo", SW_ICON.ERROR);
        }        
    }
    const requestUploadFile = async (file, data) =>{
        try {
            const formData = new FormData();
            formData.append("propertyAdjunto", file);
            const response = await requestHttp({
                endpoint: '/retiros/upload',
                contentType: CONTENT_TYPES.MULTIPART_FORM_DTA,
                body: formData
            })                                             
            onSubmitRetired(data, response.data.newFileName)       
           
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }            
        }
    }
    
    return(
    <Page>
        {cargando?
        <RetiredDetailWrapper_Global>
            <RetiredDetailWrapper_Global_DOS>          

                <RetiredCardWrapper>
                    <RetiredImageWrapper>
                        <img alt='Documento' 
                            //src={`Imagenes/${retired.mainImage}`}
                            src={getStaticArcRetired(retired.mainAdjunto)}
                        />
                    </RetiredImageWrapper>

                    <RetiredInfoWrapper>       
                        
                        <h3>{retired.strNombreTrabajador} Cod: {retired.IntCodigo}</h3>
                                    
                        <SubTitle>
                        {retired.strCodigoCentro}
                        </SubTitle>                      
                        <RetiredDescriptionWrapper>
                        <RetiredValueWrapper>
                            Retiro: {retired.strCausa}       
                            <PropertyBusinessType
                                busineesType = {`FR: ${retired.dtFRetiro.split('T')[0]}`}
                            />      
                        </RetiredValueWrapper> 
                        <RetiredDescriptionWrapper>        
                                Observación: {retired.strObservacion}             
                            </RetiredDescriptionWrapper>                   
                        </RetiredDescriptionWrapper>
                        <PropertyTypeLabel 
                            typeId= {`FC: ${retired.createdAt.split('T')[0]}`}
                        />
                        <br/>
                        <PropertyTypeLabel 
                        typeId= {`${retired.employeesId?retired.employeesId.name:''}`}
                        />
                    </RetiredInfoWrapper>
                    <FormControlAction>
                            <ButtonIcon icon={IoTrashOutline} onPress={showAlertYN} />                     
                    </FormControlAction>        
                </RetiredCardWrapper>  
                <form onSubmit={handleSubmit(uploadFileHandler)}>
                    <TraslateDetailTitle>
                          EDITAR RETIRO                                                                                      
                     </TraslateDetailTitle> 
                     <FormControl>
                        <FormControlInput>
                            <label>MOTIVO DE RETIRO:</label>                        
                            <FormControlInputRadio>                           
                                <label>Voluntario</label>
                                <input {...register("motivo")}  onChange={handleChange} type="radio" value="Voluntario" />
                                <label>Otro</label>
                                <input {...register("motivo")} onChange={handleChange} type="radio" value="Otro" />
                            </FormControlInputRadio>                                        
                        </FormControlInput>                        
                    </FormControl>                             
                    <FormControl>
                        <FormControlInput>
                            <label>FECHA DE RETIRO</label>
                            <input  type="date"
                                    {...register("fecharetiro", { required: true })} 
                            />
                            {errors.fecharetiro?.type === 'required' && <span>Este Campo es requerido</span>}
                        </FormControlInput>                                       
                    </FormControl>
                    
                    <FormControl>
                        <FormControlInput>
                            <label>OBSERVACIÓN</label>
                            <input  type='text'                                    
                                     {...register("observacion")}                                                                        
                            />                            
                        </FormControlInput>                                          
                    </FormControl>
                    <FormControl>
                        <FormControlInputFile>                            
                            <label>Adjuntar Archivo</label>
                            <input  type='file' 
                                    accept="image/png, image/jpeg, image/jpg, PDF/pdf"
                                    onChange={fileSelectedHandler}                                    
                            />
                        </FormControlInputFile>                                       
                    </FormControl>
                    <br />
                    <Button disabled={!isValid} label="Efectuar Cambio" type="submit" onPress={()=>{}} />
                    <br />
                </form>
            </RetiredDetailWrapper_Global_DOS>
        </RetiredDetailWrapper_Global>
        :'cargando...'}
    </Page>
    )
}