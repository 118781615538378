import styled from "styled-components"
import { PRIMARY_COLOR } from "../../constants/styles"

const ButtonIconStyle = styled.button`
    border:1px solid black;
    border-radius: 10px;
    padding: 6px;
    width: 30px;
    height: 30px;
    color: transparent;
    
`
export const ButtonIconRibbon = ({icon: Icon, cinta, onPress }) => {
    return (
        <ButtonIconStyle type="button" onClick={onPress} style={{backgroundColor: cinta}} >
            <Icon />    
        </ButtonIconStyle>
    );
  }
/*
const ButtonIconStyle = styled.button`
    border:1px solid color;
    border-radius: 6px;
    padding: 6px;
    width: 40px;
    height: 40px;
    background-color: cinta;
    

`

export const ButtonIconRibbon = ({icon: Icon, cinta: color, onPress}) =>(
    
    <ButtonIconStyle type="button" onClick={onPress} cinta={color} >
        <Icon />    
        
    </ButtonIconStyle>
    
)*/