import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";
import { PropertyTypeLabel } from "../../../../components/PropertyTypeLabel";
import { SubTitle } from "../../../../globalStyles";
import { getStaticArcRetired } from "../../../../utils/staticArc";
import { PropertyCardWrapper, PropertyDescriptionWrapper, PropertyImageWrapper, PropertyInfoWrapper, PropertyValueWrapper } from "./style";


export const PropertyCardRetired = (props) => {
  
  return (  
    
    <PropertyCardWrapper to={`/RetiredDetail/${props._id}`}>
      <PropertyImageWrapper>
        <img alt='Documento' 
             //src={`Imagenes/${props.mainImage}`}
              src={getStaticArcRetired(props.mainAdjunto)}
        />
      </PropertyImageWrapper>

      <PropertyInfoWrapper>       
         
         <h3>{props.strNombreTrabajador} Cod: {props.IntCodigo}</h3>
                    
         <SubTitle>
          {props.strCodigoCentro}
         </SubTitle>                      
         <PropertyDescriptionWrapper>
          <PropertyValueWrapper>
            Retiro: {props.strCausa}       
            <PropertyBusinessType
                busineesType = {`FR: ${props.dtFRetiro.split('T')[0]}`}
            />      
           </PropertyValueWrapper> 
           <PropertyDescriptionWrapper>        
                Observación: {props.strObservacion}             
            </PropertyDescriptionWrapper>                   
         </PropertyDescriptionWrapper>
         <PropertyTypeLabel 
            typeId= {`FC: ${props.createdAt.split('T')[0]}`}
          />
      </PropertyInfoWrapper>      
    </PropertyCardWrapper>  
    
  )
}