import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css"
import { HTTP_VERBS, requestHttp } from '../../../../utils/HttpRequest'
import { FormControl, FormControlGraft } from './styles'

export const PropertyGraf = (items) => {

    const [ noemployees, setNoemployees] = useState(0);
    const [ digitados, setDigitados ] = useState(0);
    const [ prestados, setPrestados ] = useState(0);
    const [ apoyo, setApoyo ] = useState(0);
    const [ porcentaje, setPorcentaje] = useState(0);
    
    //Sucursals       
    useEffect(() => {
        const getData = async ()=>{
            try { 
                items.ibm && items.fecha ? getEmployees():console.log('');                             
                items.ibm && items.fecha ? getDigitados():console.log(''); 
                items.ibm && items.fecha ? getPrestados():console.log(''); 
                items.ibm && items.fecha ? getApoyo():console.log(''); 
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                      
     },[items.ibm])

    //date       
    useEffect(() => {
            const getData = async ()=>{
            
            try {                                       
                items.ibm && items.fecha ? getEmployees():console.log('');                             
                items.ibm && items.fecha ? getDigitados():console.log(''); 
                items.ibm && items.fecha ? getPrestados():console.log(''); 
                items.ibm && items.fecha ? getApoyo():console.log(''); 
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
              
     },[items.fecha])

     //Porcentaje       
    useEffect(() => {
        //Calcular el porcentaje digitado
        const faltante = (noemployees - digitados)
        const nuevoPorcentaje = (((noemployees - faltante)/noemployees)*100).toFixed(2);
        setPorcentaje(nuevoPorcentaje)   
                
       },[digitados])

    //getEmployees
    const getEmployees = async ()=>{
            if(items.ibm > 100 ){
                try {                      
                    const response = await requestHttp(
                        {
                            method: HTTP_VERBS.GET,
                            endpoint: '/employees',                         
                            params: makePropertiesFiltersemployees()                                     
                        }
                    );             
                    const {data} = response;                
                    setNoemployees(data.length);        
                    
                } catch (error) {
                    if(error.response.status === 401){
                        //hendleCloseSeccion();
                    }else{
                        console.log(error)
                    }
                }
            }                
    }
    const makePropertiesFiltersemployees = ()=>{
            const filters = {};                
                  filters['updatedAt'] = '2022-01-01';                  
                  filters['Ibm'] = items.ibm;      
                  //console.log({filter: filters});
            return filters;       
    }
    //getDigitados
    const getDigitados = async ()=>{
        if(items.ibm > 100 && items.fecha > '2020-01-01'){
            try {                      
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts/dig',                         
                        params: makePropertiesFilters()                                     
                    }
                );             
                const {data} = response;                
                setDigitados(data.length);        
                
            } catch (error) {
                if(error.response.status === 401){
                    //hendleCloseSeccion();
                }else{
                    console.log(error)
                }
            }
        }
    }
    const makePropertiesFilters = ()=>{
        const filters = {};                
              filters['Fecha_INI'] = items.fecha; 
              filters['Fecha_FIN'] = items.fecha; 
              filters['strCodigo_SUCU'] = items.ibm;      
              //console.log({filter: filters});
        return filters;       
    }
    //getPrestados
    const getPrestados = async ()=>{
        if(items.ibm > 100 && items.fecha > '2020-01-01'){
            try {                      
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts/dig',                         
                        params: makePropertiesFiltersPrestados()                                     
                    }
                );             
                const {data} = response;                
                setPrestados(data.length);        
                
            } catch (error) {
                if(error.response.status === 401){
                    //hendleCloseSeccion();
                }else{
                    console.log(error)
                }
            }
        }
    }
    const makePropertiesFiltersPrestados = ()=>{
        const filters = {};                
              filters['Fecha_INI'] = items.fecha; 
              filters['Fecha_FIN'] = items.fecha; 
              filters['strCodigo_SUCU'] = items.ibm; 
              filters['IbmDiferente'] = items.ibm;     
              //console.log({filter: filters});
        return filters;       
    }
    //getPrestados
    const getApoyo = async ()=>{
        if(items.ibm > 100 && items.fecha > '2020-01-01'){
            try {                      
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts/dig',                         
                        params: makePropertiesFiltersApoyo()                                     
                    }
                );             
                const {data} = response;                
                setApoyo(data.length);        
                
            } catch (error) {
                if(error.response.status === 401){
                    //hendleCloseSeccion();
                }else{
                    console.log(error)
                }
            }
        } 
    }
    const makePropertiesFiltersApoyo = ()=>{
        const filters = {};                
              filters['Fecha_INI'] = items.fecha; 
              filters['Fecha_FIN'] = items.fecha; 
              filters['strCodigoDiferente'] = items.ibm; 
              filters['IbmLaboro'] = items.ibm;     
              //console.log({filter: filters});
        return filters;       
    }

  return (
    <>
        <FormControlGraft>
            <CircularProgressbar
                styles={buildStyles({
                    pathColor: '#24784e',
                    textColor: '#24784e',
                    textSize: '1em'
                    
                })}
                value={porcentaje}
                text={`${porcentaje}% Dig`}
            />        
        </FormControlGraft>
        <FormControl>
            <label>Trabajadores:<span>{noemployees}</span></label>
            <br/>
            <label>Digitados_de_la_Finca:<span>{digitados}</span></label>
            <br/>
            <label>Presté_a_O.Fincas:<span>{prestados}</span></label>
            <br/>
            <label>Me_apoyan_de_O.Fincas:<span>{apoyo}</span></label>
        </FormControl>   
    </>
  )
}
