import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import Alert from "../../components/Alert/Alert";
import { Page } from "../../components/Page";
import { FileInput, FileName, FormControlInputFile, Label, PageTitle } from "../../globalStyles";
import { Button } from "../../components/Button"
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { getToken, removeToken } from "../../utils/TokenLS";
import { AddReceptionProductsWrapper_Global, 
         AddReceptionProductsWrapper_Global_DOS, 
         PropertyTypesContainerColum, 
         PropertyTypesContainerRow,
         FormControl,           
         FormControlInput,
         FormControlInputSearch,          
         FormControlTitle,
         FormControlInputOther,
         FormControlSeccionOne} from "./styles";
import { PropertyReceptions } from "./components/PropertyReceptions";
import { PropertyReceptionsProduct } from "./components/PropertyReceptionsProduct";

const ALL_SUCURSALES = 0;

export const AddReceptionProducts = () => {

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ sucursal, setSucursal ] = useState([]);        
    const [ concepto, setConcepto ] = useState([]);

    const [ dataReception, setDataReception ] = useState({});
    const [ date, setDate ] = useState('');
    const [ selectedFileName, setSelectedFileName ] = useState('');
    const [ receptions, setReceptions ] = useState([]);
    const [ receptionsProduct, setReceptionsproduct ] = useState([]);
    const [ rdts, setRdts ] = useState([]);
    const [ calendar, setCalendar ] = useState([]); 
    
    const [ ciclo, setCiclo ] = useState('');
    const [ codproducto, setCodproducto ] = useState('');
    const [ sucursalSeleted, setSucursalSelected ] = useState(ALL_SUCURSALES);    
    const [ sucursalname, setSucursalname ] = useState('');    
        
    const [alert, setAlert] = useState({})    

    const navigate = useNavigate();

    const { 
                register, 
                handleSubmit,
                reset,
                formState:{
                    errors,
                    isValid
                }  
    } = useForm({ 
            mode: 'onChange',
            defaultValues: {
                checkbox: []
            }
        });   
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
            closeSeccionAuth()
            removeToken()
            showAlert(
                'Inicie Seccion Nuevamente', 
                "Su token puede estar vencido", 
                SW_ICON.INFO,
                () => { navigate('/Login')}    
            );
    }
    //Token valido o no expirado
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await getSucursalesAll();                             
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[auth])
    //Recargar Receptions
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await reload ? getReceptions():null();    
                await reload ? getReceptionProduct():null();
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[reload])
    //Si estoy editando
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await rdtprov.ibm?setSucursalSelected(rdtprov.ibm):console.log(''); 
                await rdtprov.dateRdt?setDate((rdtprov.dateRdt).split('T')[0]):console.log('');                                
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[rdtprov])
    //Sucursals       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return
                await  cleanStates();                              
                await sucursalSeleted && date && ciclo ? getReceptions():cleanStates();
                await sucursalSeleted && date && ciclo && codproducto? getReceptionProduct():cleanStates();
                await sucursalSeleted && date && ciclo && codproducto? getRdts():cleanStates();
                await sucursalSeleted && date && ciclo && codproducto? getConcepto():cleanStates();                                                                                                                                          
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();        
          
    },[sucursalSeleted])
    //date       
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                              
                await sucursalSeleted && date ? getCalendar():cleanStates();
                await sucursalSeleted && date && ciclo? getReceptions():cleanStates();
                await sucursalSeleted && date && ciclo && codproducto? getReceptionProduct():cleanStates();
                await sucursalSeleted && date && ciclo && codproducto? getRdts():cleanStates();                                              
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();         
            
    },[date])
    //ciclo
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                                              
                await sucursalSeleted && date && ciclo? getReceptions():cleanStates(); 
                await sucursalSeleted && date && ciclo && codproducto? getReceptionProduct():cleanStates();
                await sucursalSeleted && date && ciclo && codproducto? getRdts():cleanStates();                                              
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                   
    },[ciclo])
    //codproduct
    useEffect(() => {
        const getData = async ()=>{
            try {
                const token = getToken();
                if(!token) return 
                await  cleanStates();                                                              
                await sucursalSeleted && date && ciclo && codproducto? getReceptionProduct():cleanStates();
                await sucursalSeleted && date && ciclo && codproducto? getRdts():cleanStates();
                await sucursalSeleted && date && ciclo && codproducto? getConcepto():cleanStates();                                                                                            
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();                   
    },[codproducto])
    //Sucursales
    const handleChange = event => {
        setRdtprov({});
        setSucursalSelected(event.target.value)                 
    }
    const handleChangedate = event => {
        setDate(event.target.value) 
                      
    }
    //Sucursals
    const getSucursalesAll = async ()=>{
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/sucursal',                         
                    params: makeSucursalesFilters()                                     
                }
            );             
            const {data} = response;                
            setSucursal(data);                                     
        } catch (error) {
            console.log(error)
        }
    }
    const makeSucursalesFilters = ()=>{
        const filters = {};                 
        return filters;       
    }
    //Concepts
    const getConcepto = async ()=>{        
        if(date){
            try {            
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/conceptossucursal',                         
                        params: makeConceptoFilters()                                     
                    }
                );             
                const {data} = response; 
                console.log({concepto: data})   
                data ? setConcepto(data):cleanStates(); 
                //cleanStatesudps();                                                                                              
            } catch (error) {
                console.log(error)
            }
            
        }else{
            cleanStates();
        } 
    }        
    const makeConceptoFilters = ()=>{
        const filters = {};  
        if(sucursalSeleted !== ALL_SUCURSALES && codproducto !== ALL_SUCURSALES){
            filters['Ibm'] = sucursalSeleted;            
            filters['IntCodigo'] = codproducto;
        }
        //console.log(filters);         
        return filters;            
    }
    //getCalendars para consultar fecha del primer dia de la semana del año consultado
    const getCalendar = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',                         
                    params: makePropertiesFiltersCalendar()                                     
                }
            );             
            const {data} = response;                
            setCalendar(data);             
            setReload(false);
            console.log({calendar: data})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersCalendar = ()=>{
        const filters = {};                
            filters['anio'] = anio;  
            filters['semana'] = 1;  
            filters['nombreDia'] = 'LUNES';             
            
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }
    //getReceptions consulta todas las recepciones recibidas del ciclo en el año
    const getReceptions = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/supplies',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;                
            setReceptions(data);             
            setReload(false);
            console.log({receptions: data})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFilters = ()=>{
        const filters = {};                
            filters['Ibm'] = sucursalSeleted; 
            filters['ciclo'] = ciclo;
            filters['Fecha_INI'] = calendar[0].dtmFecha;             
            filters['Fecha_FIN'] = date;             
        return filters;       
    }
    //getReceptions consulta todas las recepciones recibidas del ciclo en el año para el producto especifico
    const getReceptionProduct = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/supplies',                         
                    params: makePropertiesFiltersProduct()                                     
                }
            );             
            const {data} = response;                
            setReceptionsproduct(data);             
            setReload(false);
            console.log({receptionsProduct: data})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersProduct = ()=>{
        const filters = {};                
            filters['Ibm'] = sucursalSeleted; 
            filters['ciclo'] = ciclo;
            filters['IntCodigo_CONC'] = codproducto;
            filters['Fecha_INI'] = calendar[0].dtmFecha;             
            filters['Fecha_FIN'] = date;             
        return filters;       
    }
    //getRdts
    const getRdts = async ()=>{
        //console.log({sucursal: sucursalSeleted})
        if(!date){
          setRdts([])
          return
          //console.log('Fecha vacia')
        }
        if(sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020'){
          setRdts([])
          return
          //console.log({nohacerConsulta: sucursalSeleted})        
        }else{
          try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/Rdts/analyses',                         
                    params: makePropertiesFiltersrdts()                                     
                }
            );             
            const {data} = response;                
            data?setRdts(data):console.log(''); 
            console.log({dataRdts: data})       
            } catch (error) {
                if(error.response.status === 401){
                    hendleCloseSeccion();
                }else{
                    console.log(error)
                }
            }
              //console.log({hacerConsulta: sucursalSeleted})        
        }                   
    }
    const makePropertiesFiltersrdts = ()=>{
        const filters = {};                
            filters['Fecha_INI'] = calendar[0].dtmFecha;; 
            filters['Fecha_FIN'] = date; 
            filters['IbmLaboro'] = sucursalSeleted;                 
            filters['IntCodigo_CONC'] = codproducto;
            filters['intCiclo'] = ciclo;            
          // filters['employeesId'] = auth.data._id;     
          //console.log({fechafin: fechafin});
         
        return filters;       
    }
    //Clean states
    const cleanStates = ()=>{

        reset();
        setDataReception({});
        setFile(null);
        setSelectedFileName('');
                                   
        if(sucursalSeleted === '20128'){
            setSucursalname('EDEN')
        }else if(sucursalSeleted === '90032'){
            setSucursalname('TULE')
        }else if(sucursalSeleted === '20065'){
            setSucursalname('JUANCA')
        }else if(sucursalSeleted === '80108'){
            setSucursalname('LA MONICA')
        }else if(sucursalSeleted === '23270'){
            setSucursalname('TIKAL')
        }else if(sucursalSeleted === '20947'){
            setSucursalname('BUENOS AIRES')
        }else if(sucursalSeleted === '80132'){
            setSucursalname('AGUA VERDE')
        }else if(sucursalSeleted === '20180'){
            setSucursalname('TOSCANA')
        }else if(sucursalSeleted === '20175'){
            setSucursalname('ASTURIAS')
        }else if(sucursalSeleted === '20185'){
            setSucursalname('LA CEJA')
        }else if(sucursalSeleted === '20186'){
            setSucursalname('ARIZONA')
        }else if(sucursalSeleted === '20188'){
            setSucursalname('ALCATRAZ')
        }else if(sucursalSeleted === '20194'){
            setSucursalname('MANAURE')
        }else if(sucursalSeleted === '90008'){
            setSucursalname('LA AGUADA')
        }else{
            setSucursalname('')
        }
    }
    //FileUpload
    const [file, setFile] = useState(null);
    //Si cambia el archivo adjunto
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];        
        if (selectedFile) {            
            setFile(selectedFile);        
            setSelectedFileName(selectedFile.name);
        }

        // Realiza aquí alguna acción con el archivo seleccionado si es necesario
        console.log('Archivo seleccionado:', file);
    };
    const uploadFileHandler = (data) =>{
        if(file){
            requestUploadFile(file, data)           
        }else{
            showAlert("Error", "Selecciona un archivo", SW_ICON.ERROR);
        }        
    }
    const requestUploadFile = async (file, data) =>{
        try {
            const formData = new FormData();
            formData.append("propertyAdjunto", file);
            const response = await requestHttp({
                endpoint: '/supplies/upload',
                contentType: CONTENT_TYPES.MULTIPART_FORM_DTA,
                body: formData
            })                                             
            onSubmitsave(data, response.data.newFileName)       
           
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }            
        }
    }    
    //Grabar Recepcion
    const onSubmitsave = async (data, name) =>{

        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede registrar recepciones para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                    //if(rango > 363599999){ //Festivo dia lunes
                        setAlert({
                            msg: 'Usted ya no puede registrar recepciones para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){
            //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede registrar recepciones para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }    
        //Valido Fecha
        if(fecha > hoy){
            setAlert({
                msg: 'Fecha no puede ser "mayor" a la fecha actual',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return     
        }
        //Valido Ciclo Autorizado para recepcionar
        if(ciclo != concepto.intCiclo){
            setAlert({
                msg: 'Ciclo no autorizado para recepcionar, " Ciclo Autorizado: ' + concepto.intCiclo + ' "',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return     
        }

        //dataReception['idSupplies'] = 1
        dataReception['Ibm'] = sucursalSeleted
        dataReception['strFinca'] = sucursalname
        dataReception['dtmFecha'] = date
        dataReception['ciclo'] = data.ciclo
        dataReception['IntCodigo_CONC'] = data.codproducto
        dataReception['strConcepto'] = concepto.strConcepto
        dataReception['cantidad'] = data.cantidad
        dataReception['remision'] = data.remision
        dataReception['mainAdjunto'] =  name          
        dataReception['entrega'] = data.entrega
        dataReception['recibe'] = data.recibe
        dataReception['Observacion'] = data.observacion
        dataReception['employeesId'] =  auth.data._id
        dataReception['email_USUA'] =  auth.data.email
        //console.log({dataRe: dataReception})

        //Enviar a grabar
        receptionRequest(dataReception); 

    }
    const receptionRequest = async (dataReception) =>{             

        try {           
            const response = await requestHttp(
                {
                    endpoint: '/supplies',
                    body: dataReception,
                    token: getToken()
                }
            );             
            cleanStates();
            getReceptions();
            getReceptionProduct();

            showAlert(
                'Recepción', 
                "Creada Correctamente", 
                SW_ICON.SUCCESS                
            );
            setFile('');            
        } catch (error) {            
            showAlert('Error', "Recepción Incorrecta", SW_ICON.ERROR);
        }       
       
    }
    //Validar dia de la semana
    const fechaComoCadena = date +  " 00:01:59"; // día semana
    const dias = [
        'DOMINGO',
        'LUNES',
        'MARTES',
        'MIERCOLES',
        'JUEVES',
        'VIERNES',
        'SABADO',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const anio = new Date(fechaComoCadena).getFullYear();
    //console.log({numeroAnio: anio})
    const nombreDia = dias[numeroDia];

    const fecha = new Date(fechaComoCadena);
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    
    const rango = new Date(hoy - fecha);

    // Crear un objeto para almacenar los totales de reception por ciclo en el año del producto especifico
    let recptionProduct = 0;
    // Calcular los totales de precipitacion por año
    if(Object.keys(receptionsProduct).length > 0 ){
        let a = 0;
        for (let i = 0; i < receptionsProduct.length ; i++){                           
            a = a + receptionsProduct[i].cantidad;               
        }
        recptionProduct = a
    }
    //console.log({totalProduct: recptionProduct})
    // Crear un objeto para almacenar los totales de producto por ciclo aplicado reportado en los Rdts
    let aplicateProduct = 0;
    // Calcular los totales de precipitacion por año
    if(Object.keys(receptionsProduct).length > 0 ){
        let a = 0;
        for (let i = 0; i < rdts.length ; i++){                           
            a = a + rdts[i].intTotal_RDT;               
        }
        aplicateProduct = a.toFixed(1)
    }
    //console.log({totalAplicate: aplicateProduct})
    // Crear un objeto para realizar la operacion de producto disponible
    let disponible = 0;
    disponible = (recptionProduct - aplicateProduct).toFixed(1);
    //console.log({disponible: disponible})
    const { msg } = alert;  
  return (
    <Page>
        <AddReceptionProductsWrapper_Global>
            <AddReceptionProductsWrapper_Global_DOS>
                <LogoImageWrapper>                                                
                            <PageTitle>
                                RECEPCIÓN DE FERTILIZANTES
                            </PageTitle>
                            <img src={"Logodos.jpg"} alt="imgLogo"/>
                        </LogoImageWrapper>                
                    <br/>
                    { msg && <Alert alert = {alert} />}
                    <FormControlSeccionOne>
                            <FormControl>                                                       
                                <FormControlInputSearch>
                                    <label>FINCA</label>                    
                                    <select id="sucursal"
                                            {...register("sucursal")}
                                            onChange={handleChange}
                                            tabIndex="1"
                                            value={sucursalSeleted}
                                            disabled={ auth.data.role != 1 && rdtprov.ibm != 90021?true:false}>
                                        <option value={""}>--Seleccione--</option>
                                        {sucursal.map(item=>(                                    
                                                <option key={item.strCodigo} 
                                                        value={item.strCodigo}                                                                                               
                                                        id={item.strCodigo}                                                                                                      
                                                >{item.strNombre}</option>
                                            )
                                            )
                                        }                                                                                                                                               
                                    </select>
                                </FormControlInputSearch>                            
                                <FormControlInputSearch>
                                    <label>FECHA DE RECEPCIÓN</label>
                                    <input  type="date"
                                            value={date} 
                                            onChange={handleChangedate}                                           
                                            tabIndex="2"
                                    />
                                    
                                </FormControlInputSearch>
                            </FormControl>                          
                            <FormControl>
                               <FormControlTitle>
                                   <label>{sucursalname}</label>
                               </FormControlTitle>
                               <FormControlTitle>
                                    <span>
                                        {nombreDia}
                                        <br/>                                     
                                        {date}
                                    </span>
                               </FormControlTitle>                                                             
                            </FormControl>
                    </FormControlSeccionOne>
                    <form onSubmit={handleSubmit(uploadFileHandler)}>
                    <FormControl>                                
                                <FormControlInputOther
                                    color={'blue'}
                                    fontw={'bold'}
                                    backg={'transparent'}
                                    justc={'center'}
                                    padit={'3px'}
                                    margr={'7px'}
                                    bordr={'10px'}
                                    bord={'1px solid black'}                                    
                                >
                                        <label>CICLO A RECEPCIONAR</label>
                                        <p {...register("lblciclo")}>{ciclo?ciclo:''}</p>                                                                    
                                </FormControlInputOther>
                                <FormControlInputOther
                                    color={'blue'}
                                    fontw={'bold'}
                                    backg={'transparent'}
                                    justc={'center'}
                                    padit={'3px'}
                                    margr={'7px'}
                                    bordr={'10px'}
                                    bord={'1px solid black'}
                                >
                                        <label>RECIBIDO EN CICLO: {ciclo?ciclo:''}&nbsp;&nbsp;PROD: {codproducto?codproducto:''}</label>
                                        <p {...register("recibido")}>{recptionProduct}</p>                                                                    
                                </FormControlInputOther>
                                <FormControlInputOther
                                    color={'red'}
                                    fontw={'bold'}
                                    backg={'transparent'}
                                    justc={'center'}
                                    padit={'3px'}
                                    margr={'7px'}
                                    bordr={'10px'}
                                    bord={'1px solid black'}
                                >
                                        <label>APLICADO EN CICLO: {ciclo?ciclo:''}&nbsp;&nbsp;PROD: {codproducto?codproducto:''}</label>
                                        <p {...register("aplicado")}>{aplicateProduct}</p>                                                                    
                                </FormControlInputOther>
                                <FormControlInputOther
                                    color={'blue'}
                                    fontw={'bold'}
                                    backg={'transparent'}
                                    justc={'center'}
                                    padit={'3px'}                                    
                                    bordr={'10px'}
                                    bord={'1px solid black'}
                                >
                                        <label>DISPONIBLE CICLO: {ciclo?ciclo:''}&nbsp;&nbsp;PROD: {codproducto?codproducto:''}</label>
                                        <p {...register("disponible")}>{disponible}</p>                                                                    
                                </FormControlInputOther>
                            </FormControl>
                        <FormControlSeccionOne>
                            <FormControl>
                                <FormControlInputSearch>
                                        <label>CICLO</label>
                                        <input  type="number"                                    
                                                {...register("ciclo")}                                                                                           
                                                value={ciclo}
                                                onChange={e => setCiclo(e.target.value)}
                                                autoFocus                                                
                                                tabIndex="3"  
                                                id="input3"                                                     
                                        />                                        
                                </FormControlInputSearch>                                                               
                                <FormControlInputSearch>
                                        <label>COD.PROD</label>
                                        <input  type="number"                                    
                                                {...register("codproducto")} 
                                                value={codproducto}  
                                                onChange={e => setCodproducto(e.target.value)}                                                                                                                                        
                                                tabIndex="4"  
                                                id="input4"                                                     
                                        />                                    
                                </FormControlInputSearch>
                                <FormControlInputSearch>
                                        <label>CANTIDAD</label>
                                        <input  type="number"                                    
                                                {...register("cantidad", { required: true })}                                                                                                                                           
                                                tabIndex="5"  
                                                id="input5"                                                     
                                        /> 
                                        {errors.cantidad?.type === 'required' && <span>Este Campo es requerido</span>}                                                                      
                                </FormControlInputSearch>
                            </FormControl>
                            <FormControl>
                                <FormControlInput>
                                        <label>PRODUCTO</label>
                                        <p {...register("nombreproducto")}>{concepto.strCadena?concepto.strCadena+' - '+concepto.strUMedida:''}</p>                                                                                                            
                                </FormControlInput>
                            </FormControl>
                            <FormControl>                                
                                <FormControlInput
                                    color={'#23774d'}
                                    fontw={'bold'}
                                    backg={'transparent'}
                                    justc={'center'}
                                    padit={'3px'}
                                    margr={'7px'}
                                    bordr={'10px'}                                                                     
                                >
                                        <label>CICLO AUTORIZADO PARA RECEPCIONAR</label>
                                        <span {...register("lblciclo")}>{concepto.intCiclo?concepto.intCiclo:''}</span>                                                                    
                                </FormControlInput>                                
                            </FormControl>                        
                        </FormControlSeccionOne>                                           
                        <FormControlSeccionOne>
                            <FormControl>
                                <FormControlInputSearch>
                                            <label>No REMISIÓN</label>
                                            <input  type="text"                                    
                                                    {...register("remision", { required: true })}                                                                                           
                                                    autoFocus
                                                    tabIndex="6"  
                                                    id="input6"                                                     
                                            />  
                                            {errors.remision?.type === 'required' && <span>Este Campo es requerido</span>}                                  
                                </FormControlInputSearch>
                                <FormControlInputFile> 
                                    <Label
                                        backcolor={selectedFileName?'#23774d':'#f0a808'}
                                        id="input7" 
                                        tabIndex="7"   
                                    >
                                        {selectedFileName?selectedFileName:'Seleccionar remisión'}
                                        <FileInput
                                            type="file"
                                            id="file"                                                                                      
                                            onChange={handleFileChange}                                              
                                        />
                                    </Label>                                                                                                                        
                                </FormControlInputFile>
                            </FormControl>                                                                                                                                                                                       
                           
                            <FormControl>
                                <FormControlInputSearch>
                                            <label>ENTREGA (Nombre Proveedor)</label>
                                            <input  type="text"                                    
                                                    {...register("entrega", { required: true })}                                                                                                    
                                                    tabIndex="8"
                                                    id="input8"
                                                                                                                                                                                                
                                            />
                                            {errors.entrega?.type === 'required' && <span>Este Campo es requerido</span>}                                    
                                </FormControlInputSearch>                                
                            </FormControl>
                        </FormControlSeccionOne>
                        <FormControlSeccionOne>                                                                                                                                                                                       
                            <FormControl>
                                <FormControlInputSearch>
                                        <label>OBSERVACIÓN</label>
                                        <input  type="text"                                    
                                                {...register("observacion")}                                            
                                                tabIndex="9"
                                                id="input9"                                                                                                                                                                                            
                                        />                                    
                                </FormControlInputSearch>                                
                            </FormControl>
                            <FormControl>
                                <FormControlInputSearch>
                                        <label>RECIBE (Nombre del Empleado - Cargo)</label>
                                        <input  type="text"                                    
                                                {...register("recibe", { required: true })}                                                                                                
                                                tabIndex="10"
                                                id="input10"
                                                                                                                                                                                            
                                        />
                                        {errors.recibe?.type === 'required' && <span>Este Campo es requerido</span>}                                                                        
                                </FormControlInputSearch>
                            </FormControl>
                        </FormControlSeccionOne>
                        <FormControl>
                            <Button disabled={!isValid} label="Grabar" type="submit" onPress={()=>{}} />                               
                        </FormControl>
                    </form>
                    <PropertyTypesContainerRow>
                        <PropertyTypesContainerColum>
                            <label>Remisiones digitadas del ciclo No {ciclo}</label>
                            {receptions.map((item, key) => 
                                <PropertyReceptions
                                    key={key}                   
                                    {...item} 
                                    propertyTypeSeleted               
                                />
                                )
                            }  
                        </PropertyTypesContainerColum>
                        <PropertyTypesContainerColum>
                            <label>Remisiones digitadas del ciclo No: {ciclo} Producto: {codproducto}</label>
                            {receptionsProduct.map((item, key) => 
                                <PropertyReceptionsProduct
                                    key={key}                   
                                    {...item} 
                                    propertyTypeSeleted               
                                />
                                )
                            }   
                        </PropertyTypesContainerColum>
                    </PropertyTypesContainerRow>    
            </AddReceptionProductsWrapper_Global_DOS>
        </AddReceptionProductsWrapper_Global>      
    </Page>
  )
}