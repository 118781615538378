import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { showAlert, SW_ICON } from "../../../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../../../utils/HttpRequest";
import { getToken, removeToken } from "../../../../utils/TokenLS";
import { 
    FormControlActionTwo,
    FormControlInputSearch,
    Label,
    PropertyTypesContainerColum,
    PropertyTypesContainerRow,
    TransportDetailDescriptionWrapper,
    TransportDetailSubTitle,
    TransportDetailValueWrapper,
    TransportDetailWrapper,
            TransportDetailWrapper_Global, 
            TransportDetailWrapper_Global_DOS,
            TransportFormControlAction,
            TransportFormControlActionOne,
            TransportImageWrapper,
            TransportInfoWrapper, 
            } from "./styles";
import { FileInput, FormControl, FormControlAction, FormControlInput, FormControlInputFile, FormControlInputRadio, PageTitle, SubTitle } from "../../../../globalStyles";
import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";
import { PropertyTypeLabel } from "../../../../components/PropertyTypeLabel";
import { TraslateDetailTitle } from "../../../Traslates/Components/TraslateDetail/styles";
import { Button } from "../../../../components/Button";
import { ButtonIcon } from "../../../../components/ButtonIcon";
import { IoArrowBack, IoTrashOutline } from "react-icons/io5";
import { Page } from "../../../../components/Page";
import swal from 'sweetalert';
import { LogoImageWrapper } from "../../../../components/Logo/styles";
import Alert from "../../../../components/Alert/Alert";

export const TransportDetail = () => {
  
  const { auth, closeSeccionAuth } = useAuth();
  const {id} = useParams()
  const navigate = useNavigate();

  const [ detailTransport, setDetailTransport ] = useState([]);
  const [ cargando, setCargando ] = useState(false);
  const [ alert, setAlert ] = useState({})    

  const [ cantidad, setCantidad ] = useState(0);    
  const [ placa, setPlaca ] = useState('');
  const [ conductor, setConductor ] = useState('');
  const [ origen, setOrigen ] = useState('');
  const [ observacion, setObservacion ] = useState('');

  const { 
      register, 
      handleSubmit,
      formState:{
          errors,
          isValid
      }  
  } = useForm({ mode: 'onChange'});   

  useEffect(() => {  
      const getData = async ()=>{
          try {
              const token = getToken();
              if(!token) return   
              await getTransport()            
          } catch (error) {
              console.log(error)
          }             
      }    
      getData();   
        
  },[auth])
  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
      closeSeccionAuth()
      removeToken()
      showAlert(
          'Inicie Seccion Nuevamente', 
          "Su token puede estar vencido", 
          SW_ICON.INFO,
          () => { navigate('/Login')}    
      );
  }
  //TransportById
  const getTransport = async ()=>{        
    try {                      
        const response = await requestHttp(
            {
                    method: HTTP_VERBS.GET,
                    endpoint: `/transports/${id}`                                                                       
            }
        );             
        const {data} = response;                
        await setDetailTransport(data);
        //console.log({dataDetail: data})                   
        setCargando(true); 
                
        data?setCantidad(data.cantidad):console.log('')  
        data?setPlaca(data.placa):console.log('')  
        data?setConductor(data.conductor):console.log('')  
        data?setOrigen(data.origen):console.log('')  
        data?setObservacion(data.Observacion):console.log('')  
        
    } catch (error) {
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        }    
    }
  }  
  //Delete Transport
  const showAlertYN = (id) =>{
    //console.log({id: id})
    //Vallido que si este dentro de un rango permitido para eliminar
    //Validaciones
    //Vallido que si este dentro de un rango permitido para registrar
    //valido si dia es viernes o sabado                          
    if(numeroDia >= 5){
        //rango para el viernes de 4 dias.
        if(numeroDia == 5){                
            if(rango > 363599999){
                showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);      
                return
            }
        }else{
            //rango para el sabado de 3 dias.                     
                if(rango > 276999999){
                    showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);             
                    return
                }
        }
    }else{
        //Rango de 2 dias para registrar
        if(rango > 189999999){                
            showAlert('Error', "Registro esta fuera del tiempo permitido para eliminar", SW_ICON.ERROR);  
            return
        }
    }
    swal({
        title: "Esta usted seguro?",
        text: "Una vez eliminado, no podras recuperar este registro!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
        if (willDelete) {   
            transportDelete()                
        } else {
        //swal("Su registro esta a salvo!");
        return
        }
        //console.log(willDelete)
    });
  }
  const transportDelete = async () =>{             
      try {           
          const response = await requestHttp(
              {
                  method: HTTP_VERBS.DELETE,
                  endpoint: `/transports/${id}`,               
                  token: getToken()               
              }
          );          
          showAlert(
              'Transporte', 
              "Eliminada Correctamente", 
              SW_ICON.SUCCESS,   
              () => { navigate('/AddTransport')}                 
          );                              
              
          } catch (error) { 
              if(error.response.status === 401){
                  //hendleCloseSeccion();
              }else{
                  showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
              }            
          }        
  }
  //Actualizar Transport
  const onSubmitsave = async () =>{
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede realizar modificaciones para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                    //if(rango > 363599999){ //Festivo dia lunes
                        setAlert({
                            msg: 'Usted ya no puede realizar modificaciones para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){
            //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede realizar modificaciones para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }    
    //Valido Fecha
    if(fecha > hoy){
        setAlert({
            msg: 'Fecha no puede ser "mayor" a la fecha actual',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return     
    }
    const dataTransport = {}    
    dataTransport['cantidad'] = cantidad?cantidad:detailTransport.cantidad
    dataTransport['placa'] = placa?placa:detailTransport.placa    
    dataTransport['conductor'] = conductor?conductor:detailTransport.conductor
    dataTransport['origen'] = origen?origen:detailTransport.origen
    dataTransport['Observacion'] = observacion?observacion:detailTransport.Observacion
    dataTransport['employeesId'] =  auth.data._id
    
    //console.log({dataReupdate: dataTransport})
    //Enviar a grabar
    transportRequest(dataTransport); 

  }
  const transportRequest = async (dataTransport) =>{             

    try {           
        const response = await requestHttp(
            {
                method: HTTP_VERBS.PUT,
                endpoint: `/transports/${id}`,
                body: dataTransport,
                token: getToken()
            }
        );                                                
        showAlert(
            'Transporte', 
            "Actualizado Correctamente", 
            SW_ICON.SUCCESS                
        );        
        getTransport()            
    } catch (error) {  
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            showAlert('Error', "Update Incorrecta", SW_ICON.ERROR);
        }            
    }       
   
  }
  
  //Validar dia de la semana
  const fechaComoCadena = cargando?detailTransport.dtmFecha.split('T')[0]:''; // día semana
  const dias = [      
      'LUNES',
      'MARTES',
      'MIERCOLES',
      'JUEVES',
      'VIERNES',
      'SABADO',
      'DOMINGO',
  ];
  const numeroDia = new Date(fechaComoCadena).getDay();
  //console.log({numeroDia: numeroDia})
  const nombreDia = dias[numeroDia];

  const fecha = new Date(fechaComoCadena);
  //hoy
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);

  const rango = new Date(hoy - fecha);

  const { msg } = alert;

  return (
    <Page>
        {cargando?
        <TransportDetailWrapper_Global>
            <TransportDetailWrapper_Global_DOS>
                <LogoImageWrapper>
                  <PageTitle>
                    EDITAR TRANSPORTE
                  </PageTitle>
                  <img src={"../Logodos.jpg"} alt="imgLogo"/>
                </LogoImageWrapper>
                { msg && <Alert alert = {alert} />}          
                <TransportDetailWrapper>                  
                  <TransportInfoWrapper>                    
                      <h2>CICLO {detailTransport.ciclo}</h2>
                      <TransportDetailSubTitle>
                        
                      </TransportDetailSubTitle>
                      <TransportDetailDescriptionWrapper>
                        <PropertyTypesContainerRow>
                          <PropertyTypesContainerColum>
                              <TransportDetailValueWrapper>
                                  Destino:                                  
                                  <h2>FINCA {detailTransport.strFinca}</h2>
                                  <h3>Ibm: {detailTransport.Ibm} </h3>
                                  <PropertyBusinessType
                                    busineesType={`Fecha de Transporte: ${detailTransport.dtmFecha?detailTransport.dtmFecha.split('T')[0]:''} (${nombreDia})`}
                                  />
                                  <PropertyBusinessType
                                    busineesType={`Personas: ${detailTransport.cantidad}`}                                    
                                  />
                              </TransportDetailValueWrapper>
                          </PropertyTypesContainerColum>
                          <PropertyTypesContainerColum>
                              <TransportDetailValueWrapper>
                                  Empresa: 
                                  <h2>{detailTransport.conductor}</h2>                                  
                                  <PropertyBusinessType
                                    busineesType={`Placa: ${detailTransport.placa}`}                                    
                                  /> 
                                  <br/>                                  
                                  Origen:
                                  <h2>{detailTransport.origen}</h2>                                                                   
                              </TransportDetailValueWrapper>
                          </PropertyTypesContainerColum>                          
                        </PropertyTypesContainerRow>                        
                      </TransportDetailDescriptionWrapper>
                      <TransportFormControlAction>
                        <TransportFormControlActionOne>
                            <PropertyTypeLabel 
                                typeId= {`${detailTransport.employeesId?detailTransport.employeesId.name:''}`}
                            />&nbsp;&nbsp;               
                            <PropertyTypeLabel 
                                    typeId= {`FC: ${detailTransport.dtmFechaModifica?detailTransport.dtmFechaModifica:''}`}                                                      
                            />
                        </TransportFormControlActionOne>
                        <TransportFormControlActionOne>
                            <PropertyTypesContainerRow>
                                <PropertyTypesContainerColum>
                                    <TransportDetailValueWrapper>                                                                  
                                        <PropertyBusinessType
                                            busineesType={`Observación: ${detailTransport.Observacion}`}                                    
                                        />                                    
                                    </TransportDetailValueWrapper>                                
                                </PropertyTypesContainerColum>                            
                            </PropertyTypesContainerRow>                                                       
                        </TransportFormControlActionOne>
                      </TransportFormControlAction>
                      
                  </TransportInfoWrapper>
                  <FormControlAction>
                    <ButtonIcon icon={IoTrashOutline} onPress={showAlertYN} />
                  </FormControlAction>                  
                </TransportDetailWrapper>
                <form>
                <FormControl>
                            <FormControl>
                                <FormControlInputSearch>
                                        <label>CANTIDAD</label>
                                        <input  type="number"                                    
                                                {...register("cantidad")}
                                                value={cantidad}
                                                onChange={e => setCantidad(e.target.value)}     
                                                autoFocus                                                                                                                                        
                                                tabIndex="5"  
                                                id="input5"                                                     
                                        />                                        
                                </FormControlInputSearch>
                                <FormControlInputSearch>
                                            <label>PLACA</label>
                                            <input  type="text"                                    
                                                    {...register("placa")}
                                                    value={placa}
                                                    onChange={e => setPlaca(e.target.value)}                                                                                                                                             
                                                    tabIndex="6"  
                                                    id="input6"                                                     
                                            />                                              
                                </FormControlInputSearch>                                
                            </FormControl>                                                                                                                                                                                                               
                            <FormControl>
                                <FormControlInputSearch>
                                            <label>ORIGEN</label>
                                            <input  type="text"                                    
                                                    {...register("origen")}
                                                    value={origen}
                                                    onChange={e => setOrigen(e.target.value)}                                                                                                    
                                                    tabIndex="8"
                                                    id="input8"
                                                                                                                                                                                                
                                            />                                                                   
                                </FormControlInputSearch>                                
                            </FormControl>
                        </FormControl>
                        <FormControl>                                                                                                                                                                                       
                            <FormControl>
                                <FormControlInputSearch>
                                        <label>OBSERVACIÓN</label>
                                        <input  type="text"                                    
                                                {...register("observacion")}
                                                value={observacion}
                                                onChange={e => setObservacion(e.target.value)}                                            
                                                tabIndex="9"
                                                id="input9"                                                                                                                                                                                            
                                        />                                    
                                </FormControlInputSearch>                                
                            </FormControl>                            
                        </FormControl>
                        <FormControl>
                            <Button label="Actualizar" onPress={handleSubmit(onSubmitsave)} />                               
                        </FormControl>
                        <FormControlActionTwo>
                                <ButtonIcon icon={IoArrowBack} onPress={() => { navigate('/addTransport')}} />
                        </FormControlActionTwo>
                </form>
            </TransportDetailWrapper_Global_DOS>
        </TransportDetailWrapper_Global>
        :'cargando...'}
    </Page>
  )
}