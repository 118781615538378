export const getStaticArcNobiometric = (path) =>{
    //console.log(process.env.REACT_APP_STATIC_STORAGE + path)
    return process.env.REACT_APP_STATIC_STORAGE_BIOM + path;
    
} 

export const getStaticArcRetired = (path) =>{
    //console.log(process.env.REACT_APP_STATIC_STORAGE + path)
    return process.env.REACT_APP_STATIC_STORAGE_RETI + path;
    
} 

export const getStaticArcReception = (path) =>{
    //console.log(process.env.REACT_APP_STATIC_STORAGE_SUPP + path)
    return process.env.REACT_APP_STATIC_STORAGE_SUPP + path;
    
} 