import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../../hooks/useRdt";
import { showAlert, SW_ICON } from "../../../utils/swAlert";
import { HTTP_VERBS, requestHttp } from "../../../utils/HttpRequest";
import { getToken, removeToken } from "../../../utils/TokenLS";
import { PropertyTable, PropertyTableCell, PropertyTableCellAnio, PropertyTableContainer, PropertyTableHeader, PropertyTableHeaderRow, PropertyTableHeaderTwo, PropertyTableRow } from "./styles";
import Spinner from "../../../utils/Spinner";

export const PrecipitationDetailaniomes = (item) => {
    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ precipitationanio, setPrecipitationanio ] = useState([]); 
    const [ precipitationaniomes, setPrecipitationaniomes ] = useState([]);
    const [ iterations, setIterations ] = useState(false); 
    const [ estado, setEstado ] = useState(false) 
    
    const [alert, setAlert] = useState({})    

    const navigate = useNavigate();

    const Cargar = ()=>{
        setTimeout(()=>{ 
            setIterations(true)                                                                
            setEstado(true)                        
        }, 200)           
    }
    const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ 
        mode: 'onChange',
        defaultValues: {
            checkbox: []
        }
   });
   //Close session if token expired
   const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
   }
   //Token valido o no expirado
   useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return
            await item.anio ? getPrecipitationaniomes():console.log('');
            await item.anio ? getPrecipitationanio():console.log('');
            //await getPrecipitationanio();                      
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
    },[auth])
    //cargar data
    useEffect(() => {  
        if(!estado){
            Cargar()
        }               
    },[item.info])  
    //anio
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                //await item.semana ? getPrecipitationaniodia():console.log('');
                await item.anio ? getPrecipitationanio():console.log('');
                //await getPrecipitationanio();                      
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[item.anio])    
    //getPrecipitation
    const getPrecipitationanio = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations/anio',                         
                    params: makePropertiesFiltersanio()                                     
                }
            );             
            const {data} = response;                
            setPrecipitationanio(data);    
            //console.log({dataAnio: data})                          
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersanio = ()=>{
        const filters = {};                
            filters['anio'] = item.anio;                         
            //filters['employeesId'] = auth.data._id;              
            //console.log(anio);
        return filters;       
    }
    // Crear un objeto para almacenar los totales de precipitacion por año
    const precipitacionPorAnio = {};

    // Calcular los totales de precipitacion por año
    precipitationanio.forEach((itemPrec) => {
        if (!precipitacionPorAnio[itemPrec.anio]) {
            precipitacionPorAnio[itemPrec.anio] = {};
        }
        if (!precipitacionPorAnio[itemPrec.anio][itemPrec.Finca]) {
            precipitacionPorAnio[itemPrec.anio][itemPrec.Finca] = 0;
        }
        precipitacionPorAnio[itemPrec.anio][itemPrec.Finca] += itemPrec.totalLLuvia;
    });
    //console.log({precipitacionAnio: precipitacionPorAnio})
    //getPrecipitationaniomes
    const getPrecipitationaniomes = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations/aniomes',                         
                    params: makePropertiesFiltersaniomes()                                     
                }
            );             
            const {data} = response;                
            setPrecipitationaniomes(data);    
            //console.log({dataAniomes: data})                          
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersaniomes = ()=>{
        const filters = {};                
            filters['anio'] = item.anio;                         
            //filters['employeesId'] = auth.data._id;              
            //console.log(item.anio);
        return filters;       
    }
    // Crear un objeto para almacenar los totales de precipitacion por año
    const precipitacionPorAniomes = {};

    // Calcular los totales de precipitacion por año
    precipitationaniomes.forEach((itemPrec) => {
        if (!precipitacionPorAniomes[itemPrec.anio]) {
            precipitacionPorAniomes[itemPrec.anio] = {};
        }
        if (!precipitacionPorAniomes[itemPrec.anio][itemPrec.mes]) {
            precipitacionPorAniomes[itemPrec.anio][itemPrec.mes] = {};
        }
        if (!precipitacionPorAniomes[itemPrec.anio][itemPrec.mes][itemPrec.Finca]) {
            precipitacionPorAniomes[itemPrec.anio][itemPrec.mes][itemPrec.Finca] = 0;
        }
        precipitacionPorAniomes[itemPrec.anio][itemPrec.mes][itemPrec.Finca] += itemPrec.totalLLuvia;
    });

    const años = Object.keys(precipitacionPorAniomes);
    const fincas = [...new Set(precipitationaniomes.map((itemPrec) => itemPrec.Finca))];
    const meses = [...new Set(precipitationaniomes.map((itemPrec) => itemPrec.mes))];

    //console.log({añosmes: años})
    //console.log({meses: meses})
    //console.log({fincasmes: fincas})    
    //console.log({fincasPrecipitacionmes: precipitacionPorAniomes})
    //<h2>Milimetros de LLuvia x Mes</h2>
  return (
    
    <PropertyTableContainer>
        {iterations?
        <>
      <br/> 
      <PropertyTable>
       <thead>      
            <PropertyTableHeaderRow>
            <PropertyTableHeader>AÑO:</PropertyTableHeader>            
                {años.map((año) => (
                    <PropertyTableHeader key={año}>{año}</PropertyTableHeader>
                ))}
            </PropertyTableHeaderRow>
            <PropertyTableRow>
                <PropertyTableHeader>Mes:</PropertyTableHeader>
                <PropertyTableHeader></PropertyTableHeader>
                {años.map((año) =>
                    meses.map((mes) => (
                    <PropertyTableHeaderTwo key={`${año}-${mes}`}>{mes}</PropertyTableHeaderTwo>
                    ))
                )}
            </PropertyTableRow>          
        </thead>
        <tbody>
            {fincas.map((finca) => (
            <PropertyTableRow key={finca}>
                <PropertyTableHeader>{finca}</PropertyTableHeader>
                <PropertyTableHeader>                    
                    {estado?años.map((año) =>                         
                                <PropertyTableCellAnio key={`${finca}-${año}`}>{ precipitacionPorAnio[año][finca] || 0 }</PropertyTableCellAnio>
                            )             
                        :'cargando...'}   
                </PropertyTableHeader>
                {años.map((año) => 
                    meses.map((mes)=> (
                        <PropertyTableCell key={`${finca}-${año}-${mes}`}>
                            {precipitacionPorAniomes[año][mes][finca] || 0}
                        </PropertyTableCell>          
                    ))              
                )}
            </PropertyTableRow>
            ))}
        </tbody>
      </PropertyTable>   
      </> 
      :<Spinner/>}     
    </PropertyTableContainer>
  )
}


