import React from 'react'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from '../../../hooks/useAuth';
import { getToken, removeToken } from '../../../utils/TokenLS';


import { PropertyCardWrapper, PropertyCardWrapperTd, PropertyCardWrapperTr, PropertyDescriptionWrapper, PropertyImageWrapper, PropertyInfoWrapper, PropertyValueWrapper } from "./styles";
import { SubTitle } from "../../../globalStyles";
import { HTTP_VERBS, requestHttp } from '../../../utils/HttpRequest';
import { showAlert, SW_ICON } from '../../../utils/swAlert';

//Visiualizar trabajadores sin Marcación y con novedades
export const PropertyCardDialsm = ({ibm, fecha}) => {
  
  const { auth, closeSeccionAuth } = useAuth();
  const navigate = useNavigate();
  
  const [ employees, setEmployees ] = useState([]);
  const [ news, setNews ] = useState([]);  
  const [ newssort, setNewssort ] = useState([]);  
  const [ rdts, setRdts ] = useState([]);
  const [ traslate, setTraslate ] = useState([]);
  const [ employeesdials, setEmployeesdials ] = useState([]); 
  const [ employeesdialsdaynofound, setEmployeesdialsdaynofound ] = useState([]);
  const [ employeesdialsdaynofoundsort, setEmployeesdialsdaynofoundsort ] = useState([]);

  const [ codtrabajador, setCodtrabajador ] = useState('');
  
  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                
            await getTraslatesAll();                                         
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[auth])

  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return 
            setEmployeesdials([]);          
            setEmployees([]);
            setNews([]);   
            setTraslate([]);
            setRdts([]);
            await getEmployeesAll();
            await getNews();  
            await getTraslatesAll();  
            await getEmployeesdialsAll();                           
            await getRdts();                                       
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[fecha])

  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                
            setEmployeesdials([]);          
            setEmployees([]);
            setNews([]);  
            setTraslate([]); 
            setRdts([]);
            await getEmployeesAll();
            await getNews();   
            await getTraslatesAll();   
            await getEmployeesdialsAll();                           
            await getRdts();
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[ibm])

  useEffect(() => {  
    const getData = async ()=>{            
        try {
          const token = getToken();
            if(!token) return     
            setEmployeesdialsdaynofound([]);                                          
            await getEmployeesdialsdayfincaRdt();                        
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[employeesdials]) 

  useEffect(() => {  
    const getData = async ()=>{            
        try {
          const token = getToken();
            if(!token) return     
            setEmployeesdialsdaynofound([]);                                          
            await getEmployeesdialsdayfincaRdt();                     
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[rdts]) 

  useEffect(() => {  
    const getData = async ()=>{            
        try {
          const token = getToken();
            if(!token) return                                           
            setEmployeesdialsdaynofoundsort([]); 
            setNewssort([]);          
            await getEmployeesdialsdayfincaRdtsort('strReportado', 'strFincaReporta', 'strHoraIngreso','strConcepto','strMotivo');                     
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
},[employeesdialsdaynofound])

  //getTraslates
  const getTraslatesAll = async ()=>{
    if(!fecha){                
      return
    //console.log('Fecha vacia')
    }    
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/traslates/rdts',                         
                params: makePropertiesFiltersTraslates()                                     
            }
        );             
        const {data} = response;
        //console.log({respuesta: response})                
        setTraslate(data);                   
    } catch (error) {            
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        }            
    }        
  }
  const makePropertiesFiltersTraslates = ()=>{
    const filters = {};                
        filters['dtFInicial_RDT'] = fecha; 
        filters['dtFFinal_RDT'] = fecha;             
    return filters;       
  }
  //EmployeesAllnombre     
  const getEmployeesAll = async ()=>{        
    try {            
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/employees',
                params: makeEmployeesFiltersAll()                                      
            }
        );             
        const {data} = response;              
        setEmployees(data);            
    } catch (error) {
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        }  
    }        
  }
  const makeEmployeesFiltersAll = ()=>{
    const filters = {}; 
    filters['Ibm'] = ibm;
    if((auth.data.role !== 1) && (auth.data.role !== 2)){
        filters['Ibm'] = auth.data.ibm;
    }
    //console.log(filters);         
    return filters;       
  }
  //EmployeesDials
  const getEmployeesdialsAll = async ()=>{ 
    if(fecha){
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employeesdials',
                    params: makeEmployeesdialsFiltersAll()                                      
                }
            );             
            const {data} = response;                             
            await setEmployeesdials(data); 
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }  
        }            
    }else{            
        return;
    }             
  }
  const makeEmployeesdialsFiltersAll = ()=>{
    const filters = {}; 
    filters['dtFecha'] = fecha; 
    filters['Ibm'] = ibm;      
    //console.log(fechaini,sucursalSeleted)
    return filters;       
  }
  //getRdts
  const getRdts = async ()=>{        
    if(!fecha){
        setRdts([])
        return
        //console.log('Fecha vacia')
    }
    if(ibm === '' || ibm === 0 || ibm === '90021' || ibm === '90020'){
        setRdts([])
        return
        //console.log({nohacerConsulta: sucursalSeleted})        
        }else{
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/Rdts/employeesibm',                         
                params: makePropertiesFilters()                                     
            }
        );             
        const {data} = response;  
        //console.log({query: makePropertiesFilters() })        
        //console.log({rdts: data})               
        data?setRdts(data):console.log('');        
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
               
    }                   
  }
  const makePropertiesFilters = ()=>{
    const filters = {};                
        filters['Fecha_INI'] = fecha; 
        filters['Fecha_FIN'] = fecha; 
        filters['strCodigo_SUCU'] = ibm;   
        filters['IntCodigo_EMPL'] = codtrabajador;    
    // filters['employeesId'] = auth.data._id;     
        //console.log(fechaini);
    return filters;       
  }
  //getNews
  const getNews = async ()=>{
    //console.log({sucursal: sucursalSeleted})
    if(!fecha){            
        setNews([])
    return
    //console.log('Fecha vacia')
    }
    
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/rdtsnews',                         
                params: makePropertiesFiltersNews()                                     
            }
        );             
        const {data} = response;                
        data?setNews(data):console.log('');                 
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
        
        //console.log({hacerConsulta: sucursalSeleted})        
            
  }
  const makePropertiesFiltersNews = ()=>{
    const filters = {};                
        filters['Fecha_INI'] = fecha; 
        filters['Fecha_FIN'] = fecha; 
        filters['strCodigo_SUCU'] = ibm;               
    return filters;       
  }
  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
  }
  //Comparo y encuentro trabajadores con marcacion que tienen Rdt.
  const getEmployeesdialsdayfincaRdt = async () =>{ 
    if(ibm === '90021' || fecha === ''){
        return
    } 
    //1.Comparo y encuentro trabajdores sin marcaciones      
    const noFound = employees.filter((item) => !employeesdials.find((item2) => item.IntCodigo === item2.IntCodigo));       
    //2.Saco los que tienen novedades de Sin Marcación.        
    const sinMarcacion = noFound.filter((item) => !news.find((item2) => item.IntCodigo === item2.IntCodigo_EMPL));
    //3.Recorro y encuentro los trabajadores que les han reportado algún RDT y agrego la columna "strReportado"
    const nuevaListatres = sinMarcacion.map(item1 => {
         const item2 = rdts.find(item2 => item2.IntCodigo_EMPL === item1.IntCodigo);            
         return {
         ...item1,
         strReportado: item2 ? 'SI' : ''
         };
     });
    //4.Recorro la nueva lista de empleados que no marcaron con los rdt reportado, sin reportar y encuentro los trabajadores que los reportarón en una finca diferente
    const nuevaListacuatro = nuevaListatres.map(item1 => {
         const item2 = rdts.find(item2 => (item2.IntCodigo_EMPL === item1.IntCodigo) && (item2.IbmLaboro !== ibm));
         //Finca Laboro
         return {
         ...item1,
         strFincaReporta: item2 ? item2.IbmLaboro : ''
         };
     });    
    //5.Recorro la nueva lista de empleados que no marcaron, con los traslados y prestamos reportados; asigno el Motivo: "T"=Traslado ó "P"=Prestamo
    const nuevaListaseis = nuevaListacuatro.map(item1 => {
        const item2 = traslate.find(item2 => item2.IntCodigo === item1.IntCodigo);            
        return {
        ...item1,        
        strMotivo: item2 ? item2.strMotivo : '',
        strPreste: item2 ? item2.intIBM_TRAS : '',
        dtInicia: item2 ? item2.dtFInicial.split('T')[0] : ''
        //dtFinal: item2 ? item2.dtFFinal : ''
        };
    });
   setEmployeesdialsdaynofound(nuevaListaseis)
   //console.log({nueva6: nuevaListaseis})
    //Recorro y encuentro los trabajadores que no estan activos y agrego la columna "strLiquidado" en news con la palabra SI para que no los sigan duplicando
    const nuevaListacinco = news.map(item1 => {
        const item2 = !employees.find(item2 => item2.IntCodigo === item1.IntCodigo_EMPL);            
        return {
          ...item1,
          strLiquidado: item2 ? 'SI' : ''
        };
    });
    setNews(nuevaListacinco)
    //console.log(employees)

  }
  //Ordeno por Rdt reportado y Finca que reporta
  const getEmployeesdialsdayfincaRdtsort = async (keyRdt, keyIbm, keyHora, keyNew, keyMotivo) =>{ 
    if(ibm === '90021' || fecha === ''){
        return
    }     
    //Ordeno por finca que reporta empleados que no marcaron
    const sortedListtres = [...employeesdialsdaynofound].sort((a, b) => a[keyIbm] > b[keyIbm] ? 1 : -1);
    //Ordeno por Trabajador que fue reportado empleados que no marcaron.
    const sortedListcuatro = [...sortedListtres].sort((a, b) => a[keyRdt] > b[keyRdt] ? 1 : -1);
    //Ordeno por motivo
    const sortedListcinco = [...sortedListcuatro].sort((a, b) => a[keyMotivo] > b[keyMotivo] ? -1 : 1);
    setEmployeesdialsdaynofoundsort(sortedListcinco)    

    //Ordeno las novedades por tipo de novedad
    const sortedListseis = [...news].sort((a, b) => a[keyNew] > b[keyNew] ? 1 : -1);
    setNewssort(sortedListseis)
  } 

  return (
    
  <>    
    <PropertyCardWrapperTr>
                <PropertyCardWrapperTd>
                    <h3>TRABAJADORES <b>SIN MARCACIÓN:</b> <span>{employeesdialsdaynofoundsort.length}</span></h3>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>COD</th>
                                <th>NOMBRE</th>                        
                                <th>EQUIPO DE TRABAJO</th>
                                <th>MOTIVO</th>                                                                              
                                <th>FINCA</th>
                                <th>RDT</th>
                                <th>REPORTA</th>                        
                            </tr>
                        </thead>
                        <tbody>
                            {employeesdialsdaynofoundsort.map((elemento, key) => (
                            <tr>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {key + 1}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        <span>{elemento.IntCodigo}</span>
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strNombreTrabajador}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>                        
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strCodigoCentro}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strMotivo}<a>{elemento.dtInicia}</a>
                                    </PropertyDescriptionWrapper>                                
                                </th>                                                         
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strPreste}
                                    </PropertyDescriptionWrapper>                                
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                    {elemento.strReportado}
                                    </PropertyDescriptionWrapper>                                
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strFincaReporta}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>                       
                            </tr>
                            ))}                
                        </tbody>
                    
                    </table>
                </PropertyCardWrapperTd>
    </PropertyCardWrapperTr>
    <PropertyCardWrapperTr>
                <PropertyCardWrapperTd>
                    <h3>NOVEDADES: <span>{newssort.length}</span></h3>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>COD</th>
                                <th>NOMBRE</th>
                                <th>CONCEPTO</th>                        
                                <th>EQUIPO DE TRABAJO</th>   
                                <th>LIQ</th>                        
                            </tr>
                        </thead>
                        <tbody>
                            {newssort.map((elemento, key) => (
                            <tr>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {key + 1}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        <span>{elemento.IntCodigo_EMPL}</span>
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strNombreTrabajador}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>                        
                                <th>
                                    <PropertyDescriptionWrapper>
                                    {elemento.strConcepto}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                    {elemento.strNombre_SUCU}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>     
                                <th>
                                    <PropertyDescriptionWrapper>
                                    {elemento.strLiquidado}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>                  
                            </tr>
                            ))}                
                        </tbody>
                    
                    </table>
                </PropertyCardWrapperTd>
    </PropertyCardWrapperTr> 
      
  </>
  
  )
}
