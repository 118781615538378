import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";
import { PropertyTypeLabel } from "../../../../components/PropertyTypeLabel";
import { SubTitle } from "../../../../globalStyles";
import { getStaticArcNobiometric } from "../../../../utils/staticArc";
import { PropertyCardWrapper, PropertyDescriptionWrapper, PropertyImageWrapper, PropertyInfoWrapper, PropertyValueWrapper } from "./style";


export const PropertyCardNobiometric = (props) => {
  
  return (   
    
    <PropertyCardWrapper>
      <PropertyImageWrapper>
        <img alt='foto biometric' 
             //src={`Imagenes/${props.mainImage}`}
              src={getStaticArcNobiometric(props.mainAdjunto)}
        />
      </PropertyImageWrapper>      
      <PropertyInfoWrapper>     
         <h3>{props.strNombreTrabajador} Cod: {props.IntCodigo}</h3>
         <SubTitle>
          {props.strCodigoCentro}
         </SubTitle>                      
         <PropertyDescriptionWrapper>
          <PropertyValueWrapper>
            Sin Biometria:      
            <PropertyBusinessType
                busineesType = {`FN: ${props.dtFNovedad.split('T')[0]}`}
            />      
           </PropertyValueWrapper> 
           <PropertyDescriptionWrapper>        
                Observación: {props.strObservacion}             
            </PropertyDescriptionWrapper>                   
         </PropertyDescriptionWrapper>
         <PropertyTypeLabel 
            typeId= {`FC: ${props.createdAt.split('T')[0]}`}
          />
      </PropertyInfoWrapper>      
    </PropertyCardWrapper>  
    
  )
}