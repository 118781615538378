import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from '../../../../hooks/useAuth';
import { useRdt } from '../../../../hooks/useRdt';
import { getToken, removeToken } from '../../../../utils/TokenLS';
import { showAlert, SW_ICON } from '../../../../utils/swAlert';
import { IoCloseCircleOutline, IoBookmark, IoArrowBack } from "react-icons/io5";
import { AnalysesPropertyTypesContainerColum, AnalysesPropertyTypesContainerRow, ButtonAplicate, CerrarAnalysesRdt, CerrarAnalysesRdtInt, FormControlAnalysesRdt, FormularioAnalysesRdt, PropertyTable, PropertyTableCell, PropertyTableCellBH, PropertyTableContainer, PropertyTableHeader, PropertyTableRow, TittleAnalysesRdt } from './styles';
import { ButtonIcon } from '../../../../components/ButtonIcon';
import { HTTP_VERBS, requestHttp } from '../../../../utils/HttpRequest';
import { ButtonIconRibbon } from '../../../../components/ButtonIconRibbon';
import { DetailsAnalysesRdts } from '../DetailsAnalysesRdts';
import { DetailAnalysesRdtsInv } from '../DetailAnalysesRdtsInv';
import { ModalLoteEmb } from '../ModalLoteEmb';
import { Buttoninv } from '../../../../components/Buttoninv';
import { Button } from '../../../../components/Button';
import { Buttoninvemb } from '../../../../components/Buttoninvemb';
import { ButtonIconRibbonEmb } from '../../../../components/ButtonIconRibbonEmb';
import styled from 'styled-components';


const colors = [ 
    {id:1, cinta:'brown', name:'CAFE', nameCorto:'CA'},
    {id:2, cinta:'black', name:'NEGRA', nameCorto:'NE'}, 
    {id:3, cinta:'orange', name:'NARANJA', nameCorto:'NA'}, 
    {id:4, cinta:'green', name:'VERDE', nameCorto:'VE'}, 
    {id:5, cinta:'yellow', name:'AMARILLA', nameCorto:'AM'},   
    {id:6, cinta:'white', name:'BLANCA', nameCorto:'BL'},
    {id:7, cinta:'blue', name:'AZUL', nameCorto:'AZ'},
    {id:8, cinta:'gray', name:'GRIS', nameCorto:'GR'},   
    {id:9, cinta:'purple', name:'MORADA', nameCorto:'MO'},    
    {id:10, cinta:'red', name:'ROJA', nameCorto:'RO'},   
];

export const DetailsAnalysesRdtsEmb = ({setModal, animarModal, setAnimarModal, ibm,fechaini,fechafin,codLabor,cintas, global,sucursalName}) => {
    //console.log({ibm: ibm})
    //console.log({fechaini: fechaini})
    //console.log({fechafin: fechafin})
    //console.log({codLabor: codLabor})
    //console.log({cintas: cintas})
    //console.log({global: global})

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, reload, setReload, rdtEmploye  } = useRdt();

    //Buscar en calendario según fecha que se esta trabajando
    const [ calendar, setCalendar ] = useState([]);
    //Buscar analisis de rdts por cinta,lote, si es Prema o Pres, cant
    const [ rdtsudps, setRdtsudps ] = useState([]);
    const [ rdtsudpslote, setRdtsudpslote ] = useState([]);

    //Modal para mostrar el detalle de las personas que realizarón la labor 118 Embolse.
    const [ modalLoteEmb, setModalLoteEmb ] = useState(false);    
    const [ animarModalLoteEmb, setAnimarModalLoteEmb ] = useState(false);
    const [ idSemana, setIdSemana ] = useState(null);
    const [ idLote, setIdLote ] = useState(null);
    const [ embLote, setEmbLote ] = useState(null);    
    const [ embPM, setEmbPM ] = useState(null);    
    const [ embPS, setEmbPS ] = useState(null);
    
    //Para sombrear cuando paso el mouse sobre la celda.
    const [hoveredCell, setHoveredCell] = useState(null);
    
    const handleMouseOver = (rowIndex, colIndex) => {
        setHoveredCell({ rowIndex, colIndex });
        //console.log(rowIndex, colIndex)
    };
    const handleMouseOut = () => {
        setHoveredCell(null);
    };

    const navigate = useNavigate();
        
    const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ mode: 'onChange'});   

    //Token valido o no expirado
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token){
                    hendleCloseSeccion()
                } return                  
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[auth])
    //date       
    useEffect(() => {
            const getData = async ()=>{
                //console.log('obtener calendar');
                try {
                    const token = getToken();
                    if(!token) return                
                    //await  cleanStates();                
                    await getCalendar();
                    await getRdtsUdp();
                } catch (error) {
                    console.log(error)
                }             
            }    
            getData();         
                  
    },[ibm,fechaini,fechafin,codLabor])    
    
    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }
    //Boton de cerrar
    const ocultarAuthorizationsRdt = ()=>{      
        //setAnimarModal(false)
        setModal(false)
              
    }
    //getCalendars para prematuro y presente
    const getCalendar = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',                         
                    params: makePropertiesFiltersCalendar()                                     
                }
            );             
            const {data} = response;                
            setCalendar(data); 
            //console.log({calendar: data});       
            //console.log(calendar[0].cintaPm);    
            //console.log(calendar[0].cintaPs);                 
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersCalendar = ()=>{
        const filters = {};                
            filters['Fecha_INI'] = fechaini;
            filters['Fecha_FIN'] = fechafin; 
            filters['tipo'] = 'udps';                          
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaini);
        return filters;       
    }
    //getRdts x udp x cinta x tipo Pm o Ps
    const getRdtsUdp = async ()=>{
            //console.log({sucursal: sucursalSeleted})
            if(!fechaini){
              setRdtsudps([])
              return
              //console.log('Fecha vacia')
            }
            if(!fechafin){
                setRdtsudps([])
                return
                //console.log('Fecha vacia')
              }
            if(ibm === '' || ibm === 0 || ibm === '90021' || ibm === '90020'){
              setRdtsudps([])
              return
              //console.log({nohacerConsulta: sucursalSeleted})        
            }else{
              try {                      
                const response = await requestHttp(
                    {
                        method: HTTP_VERBS.GET,
                        endpoint: '/Rdts/analysesudps',                         
                        params: makePropertiesFilters()                                     
                    }
                );             
                const {data} = response;                
                data?setRdtsudps(data):console.log('');
                //console.log({rdtsUdps: data})        
                } catch (error) {
                    if(error.response.status === 401){
                        hendleCloseSeccion();
                    }else{
                        console.log(error)
                    }
                }
                  //console.log({hacerConsulta: sucursalSeleted})        
            }                   
    }
    const makePropertiesFilters = ()=>{
        const filters = {};                
            filters['Fecha_INI'] = fechaini; 
            filters['Fecha_FIN'] = fechafin; 
            filters['IbmLaboro'] = ibm;   
            filters['IntCodigo_CONC'] = codLabor;            
          // filters['employeesId'] = auth.data._id;     
          //console.log({fechafin: fechafin});
         
        return filters;       
    }

    //1. Unimos calendar que contiene el anio y semana con rdtsUdps
    //1.1 Función para verificar si una fecha está dentro de un rango
    const fechaDentroDelRango = (fecha, fechaInicio, fechaFin) => {
        return fecha >= fechaInicio && fecha <= fechaFin;
    };    
    //1.2 Iterar sobre rdtsudps y comprobar si las fechas coinciden con alguna semana en calendar
    rdtsudps.forEach(itemDos => {
        const { fmin, fmax } = itemDos;
        //Inicio con el calendario
        calendar.forEach(itemUno => {
            const { fini, ffin, semana, anio } = itemUno;
            if (
                fechaDentroDelRango(fmin, fini, ffin) ||
                fechaDentroDelRango(fmax, fini, ffin)
            ) {
                // Si la fmin o fmax está dentro del rango, agregar anio y semana a rdtsudps
                itemDos.semana = semana;
                itemDos.anio = anio;
            }
        }); 
        //Continuo agregando aneta y abruta de global
        global.forEach(itemTres =>{
            if(Number(itemTres.Lote) === itemDos.Lote){
                itemDos.A_Neta = itemTres.A_Neta
            }
        })       
    });
    //console.log({rdtsudps: rdtsudps});

    //1.3 Eliminamos los objetos con lote igual a cero
    const rdtsudpsuno = rdtsudps.filter(objeto => objeto.Lote !== 0 );    
    //console.log({rdtsudpsuno: rdtsudpsuno })

    //2. Le hago un distinc a global y saco todos los lotes de la finca
    const Lotes = [...new Set(global.map((itemLote) => itemLote.Lote))];
    //console.log({Lotes: Lotes})
    //2.0 Le hago un distinc a global a las semanas
    const Semanas = [...new Set(calendar.map((itemSem) => itemSem.semana))];
    //console.log({Semanas: Semanas})

    //2.1 Organizar el calendario por index de sem
    const semCalendar = {}
    calendar.forEach(item =>{
        //si la sem no esta la inicializo
        if(!semCalendar[item.semana]){
            semCalendar[item.semana]={anio:0, cintaPm: 0, cintaPs: 0, fini: '', ffin: ''};
        }  
        //Agregar items
        semCalendar[item.semana].anio = item.anio;
        semCalendar[item.semana].cintaPm = colors.find(color => color.id == item.cintaPm);
        semCalendar[item.semana].cintaPs = colors.find(color => color.id == item.cintaPs);;
        semCalendar[item.semana].fini = item.fini;
        semCalendar[item.semana].ffin = item.ffin;

        const color = colors.find(color => color.id == item.cintaPm)
        //Recuperar color de cinta para labor 118 embolsePm
        //console.log({color:color})
            
    })    
    //console.log({semCalendar: semCalendar })
        
    //3. Crear un nuevo arreglo para almacenar los resultados de rdtsudpsuno
    const nuevoArreglo = [];
    //3.1 Crear un conjunto para rastrear las combinaciones únicas de año, semana
    const combinacionesUnicas = new Set();
    //3.2 Iterar sobre los datos de rdtsudpsuno originales y guardar un nuevoArreglo 
    for (const cantidadLotes of rdtsudpsuno) {
        const { anio, semana, Lote, cinta, tipoCinta, total, totalCantidad, fmin, fmax } = cantidadLotes; //Campos de rdtsudpsuno
        const combinacion = `${anio}-${semana}`;

        if (!combinacionesUnicas.has(combinacion)) {
            combinacionesUnicas.add(combinacion);//Guardo las combinaciones unicas de anio-semana
        }
        
        //Guardo los datos de rdtsudpsuno solo de las combinaciones encontradas por anio-semana en nuevoArreglo
        nuevoArreglo.push({            
            anio,
            semana,
            Lote,
            cinta,
            tipoCinta,
            total,
            totalCantidad,
            fmin,
            fmax         
        });
        
    }
    //console.log({combinacionesUnicas: combinacionesUnicas})
    //console.log({nuevoArreglo: nuevoArreglo})
        
    //4. Completar con los lotes faltantes por semana para luego ser visualizados 
    for (const combinacion of combinacionesUnicas) {
        const [anio, semana] = combinacion.split('-');
        const lotesRegistrados = new Set();

        //4.1 Identificamos los lotes que ya estan en nuevoArreglo
        for (const identificarLote of nuevoArreglo) {
            if (identificarLote.anio === parseInt(anio) && identificarLote.semana === parseInt(semana)) {
                lotesRegistrados.add(String(identificarLote.Lote));
            }
        }
        //console.log({lotesRegistrados: lotesRegistrados})

        //4.2 Agregamos los lotes faltantes a nuevoArreglo
        for (const codLotes of Lotes) {           
            if (!lotesRegistrados.has(codLotes)) {
                nuevoArreglo.push({                    
                    anio: parseInt(anio),
                    semana: parseInt(semana),
                    Lote: Number(codLotes),
                    cinta: '',
                    tipoCinta: 'PM',
                    total: 0,
                    totalCantidad: 0,
                    fmin:'',
                    fmax:'' 
                });
            }            
        }            
    } 
    //console.log({nuevoArreglo: nuevoArreglo})

    //5. Iterar sobre nuevoArreglo y agregar A_Neta y A_Bruta de Global
    nuevoArreglo.forEach(itemDos => {
        //Continuo agregando aneta y abruta de global
        global.forEach(itemTres =>{
            if(Number(itemTres.Lote) === itemDos.Lote){
                itemDos.A_Neta = itemTres.A_Neta
                itemDos.A_Bruta = itemTres.A_Bruta
                itemDos.Vueltas = itemTres.Vueltas
            }
        })       
    });
    //console.log({nuevoArreglo: nuevoArreglo})

    //Mostrar
    //6. Función para organizar los elementos por semana y lote
    const organizarPorSemanaYLote = () => {
        const analisisOrganizado = {};

        nuevoArreglo.forEach(item => { 
                        
            // Si la semana no está en el objeto, inicializarla
            if (!analisisOrganizado[item.semana]) {
                analisisOrganizado[item.semana] = {};
            }
            // Si el lote no está en la semana, inicializarlo
            if (!analisisOrganizado[item.semana][item.Lote]) {
                analisisOrganizado[item.semana][item.Lote] = { PM: "", PS: "", suma: 0, total: 0, cinta: "", Vueltas: 0, A_Neta: 0, A_Bruta: 0, Pendiente: 0, F_MIN:'', F_MAX:'' };
            }
            // Agregar la descripción al tipo de cinta correspondiente
            if (item.tipoCinta === "PM") {
                analisisOrganizado[item.semana][item.Lote].PM += `${item.totalCantidad} `;             
            } else if (item.tipoCinta === "PS") {
                analisisOrganizado[item.semana][item.Lote].PS += `${item.totalCantidad} `;
                
            }
            // Agregar las Vueltas del lote
            analisisOrganizado[item.semana][item.Lote].Vueltas = item.Vueltas;
            // Agregar la A_Bruta del lote
            analisisOrganizado[item.semana][item.Lote].A_Bruta = item.A_Bruta;
            // Agregar la A_Neta del lote
            analisisOrganizado[item.semana][item.Lote].A_Neta = item.A_Neta;
            // Agregar la suma de cantidad PM + PS a la nueva columna suma
            analisisOrganizado[item.semana][item.Lote].suma += item.totalCantidad;
            // Agregar otros campos
            analisisOrganizado[item.semana][item.Lote].total += item.total;
            // Agregar otros campos
            analisisOrganizado[item.semana][item.Lote].Pendiente = (item.A_Neta * item.Vueltas);
            // Agregar otros campos
            analisisOrganizado[item.semana][item.Lote].F_MIN = item.fmin;
            analisisOrganizado[item.semana][item.Lote].F_MAX = item.fmax;
        });                     
        //console.log({analisisOrganizado:analisisOrganizado})  
        return analisisOrganizado;
    }; 
        
    //7. Sacar los totales
    //6. Función para organizar los elementos por semana del total de Prem, pres, aneta, abruta, realizado
    const totalesAnalisisorganizado = () => {
        const totalanalisisPorsem = organizarPorSemanaYLote();
        //console.log({totalesAnalisisorganizado:totalanalisisPorsem})
       
        // Objeto para almacenar las sumas totales por semana
        let sumasPorSemana = {};        

        // Recorremos las claves del objeto analisis
        for (let semanaKey in totalanalisisPorsem) {
            
            let sumaTotalPM = 0;
            let sumaTotalPS = 0;
            let totalSuma = 0;
            let totalCant = 0;
            let totalA_Bruta = 0;
            let totalA_Neta = 0;
            let totalPendiente = 0;

            const semana = totalanalisisPorsem[semanaKey];
            //console.log({semKey: semana})
            // Recorremos los lotes dentro de cada semana
            for (let loteKey in semana) {
                const lote = semana[loteKey];
                //console.log({loteKey: lote.PM})
                // Sumamos los valores de PM y PS de cada lote
                // Verificamos si tienen un valor antes de sumarlo
                if (lote.PM.trim() !== "") {
                    sumaTotalPM += parseInt(lote.PM);
                }
                if (lote.PS.trim() !== "") {
                    sumaTotalPS += parseInt(lote.PS);
                }
                
                totalSuma += (lote.suma);
                totalCant += (lote.total);
                totalA_Bruta += (lote.A_Bruta);
                totalA_Neta += (lote.A_Neta);
                totalPendiente += (lote.Pendiente);                               
            }
            // Almacenamos las sumas totales por semana en el objeto sumasPorSemana
            sumasPorSemana[semanaKey] = {
                A_Bruta: totalA_Bruta,
                A_Neta: totalA_Neta,
                PM: (sumaTotalPM),
                PS: sumaTotalPS,
                Pendiente: totalPendiente,
                Suma: totalSuma,
                total: totalCant
            };
        }

        // Ahora tenemos la suma total de PM y PS
        //console.log("Suma total de PM:", sumaTotalPM);
        //console.log("Suma total de PS:", sumaTotalPS);
        // Resultado final
        //console.log({sumasPorSemana:sumasPorSemana});
        return sumasPorSemana;
    };  

    // Función para mostrar el modal del detalle del embolse.
    const handleModalEmbLote = (idSemana, idLote, embPM, embPS, embLote)=>{
        //console.log({idDetail: idDetail})
        //console.log({idLote: idLote})
        //console.log({embLote: embLote})
        //console.log({guaLote: guaLote})  
        setModalLoteEmb(true)
        setIdSemana(idSemana);
        setIdLote(idLote);
        setEmbLote(embLote);        
        setEmbPM(embPM);        
        setEmbPS(embPS);        
        //console.log({modal: modalLoteGuan})  
        setTimeout(() => {
          setAnimarModalLoteEmb(true)
        }, 300);
       
    }
    
    // Función para renderizar las tablas por semana
    const renderTablasPorSemana = () => {
        const analisisPorSemanaYLote = organizarPorSemanaYLote(); 
        //console.log({analisisPorSemanaYLote:analisisPorSemanaYLote})               
        const totalesAnalisis = totalesAnalisisorganizado();           
        //console.log({totalesAnalisis:totalesAnalisis})

        return Object.keys(analisisPorSemanaYLote).map(semana => (
        <div key={semana}>
            
            <h4>               
            {sucursalName} &nbsp;&nbsp;{semCalendar[semana].anio} Sem: {semana} ({global[0].Codigo}-{global[0].Labor})<br/>          
            </h4>
            
            <PropertyTable>
            <thead>
                {modalLoteEmb ? '':
                <PropertyTableRow>
                    <PropertyTableHeader>LOTE</PropertyTableHeader>                    
                    <PropertyTableHeader>A_BRUTA</PropertyTableHeader>
                    <PropertyTableHeader>A_NETA</PropertyTableHeader>
                    <PropertyTableHeader>REALIZADO</PropertyTableHeader>
                    <PropertyTableHeader>PENDIENTE</PropertyTableHeader>
                    <PropertyTableHeader>
                        <PropertyTableHeader>SEM {semana}</PropertyTableHeader>
                        PM <ButtonIconRibbon icon={IoBookmark} cinta={semCalendar[semana].cintaPm.cinta} />            
                    </PropertyTableHeader>
                    <PropertyTableHeader>
                        <PropertyTableHeader>SEM {semana}</PropertyTableHeader>
                        PS <ButtonIconRibbon icon={IoBookmark} cinta={semCalendar[semana].cintaPs.cinta} />
                    </PropertyTableHeader>
                    <PropertyTableHeader>TOTAL</PropertyTableHeader>                    
                    <PropertyTableHeader>B/H</PropertyTableHeader>
                    <PropertyTableHeader>FECHA INICIAL</PropertyTableHeader>
                    <PropertyTableHeader>FECHA FINAL</PropertyTableHeader>                                 
                    <PropertyTableHeader>A_N*60</PropertyTableHeader>
                    <PropertyTableHeader>B_EXCE</PropertyTableHeader>                                                                  
                </PropertyTableRow>
                }
            </thead>
            <tbody>
                {Object.keys(analisisPorSemanaYLote[semana]).map(lote => (
                <PropertyTableRow key={`${semana}_${lote}`}>
                    <PropertyTableHeader>{lote}</PropertyTableHeader>                    
                    <PropertyTableCell>{analisisPorSemanaYLote[semana][lote].A_Bruta}</PropertyTableCell>
                    <PropertyTableCell>{analisisPorSemanaYLote[semana][lote].A_Neta}</PropertyTableCell>                    
                    <PropertyTableCell>{(analisisPorSemanaYLote[semana][lote].total).toFixed(2)}</PropertyTableCell> 
                    <PropertyTableCell>{(analisisPorSemanaYLote[semana][lote].total-analisisPorSemanaYLote[semana][lote].Pendiente).toFixed(2)}</PropertyTableCell>                   
                    <PropertyTableCell>{analisisPorSemanaYLote[semana][lote].PM}</PropertyTableCell>
                    <PropertyTableCell>{analisisPorSemanaYLote[semana][lote].PS}</PropertyTableCell>                    
                    <PropertyTableCell
                     onMouseOver={() => handleMouseOver(lote, semana)}
                     onMouseOut={handleMouseOut}
                     style={{
                     
                        backgroundColor: hoveredCell &&
                        hoveredCell.rowIndex === lote &&
                        hoveredCell.colIndex === semana ? '#f0a808' : '',
                        color: hoveredCell &&
                        hoveredCell.rowIndex === lote &&
                        hoveredCell.colIndex === semana ? 'white' : ''
                      
                      }}
                      onClick={()=>handleModalEmbLote(semana,lote, analisisPorSemanaYLote[semana][lote].PM, analisisPorSemanaYLote[semana][lote].PS, analisisPorSemanaYLote[semana][lote].suma)}
                    >
                        {analisisPorSemanaYLote[semana][lote].suma}
                        
                    </PropertyTableCell>
                    <PropertyTableCellBH>{(analisisPorSemanaYLote[semana][lote].suma/analisisPorSemanaYLote[semana][lote].A_Neta).toFixed(0)}</PropertyTableCellBH>
                    <PropertyTableCell>{analisisPorSemanaYLote[semana][lote].F_MIN.split('T')[0]}</PropertyTableCell>
                    <PropertyTableCell>{analisisPorSemanaYLote[semana][lote].F_MAX.split('T')[0]}</PropertyTableCell>
                    <PropertyTableCell>{(analisisPorSemanaYLote[semana][lote].A_Neta*60).toFixed(0)}</PropertyTableCell>                    
                    <PropertyTableCellBH>{(analisisPorSemanaYLote[semana][lote].A_Neta*60)<(analisisPorSemanaYLote[semana][lote].suma)?((analisisPorSemanaYLote[semana][lote].suma)-(analisisPorSemanaYLote[semana][lote].A_Neta*60)).toFixed(0):0}</PropertyTableCellBH>                                        
                </PropertyTableRow>
                ))}
            </tbody>
            <tfoot>
                <PropertyTableRow>
                    <PropertyTableHeader>TOTAL</PropertyTableHeader>                    
                    <PropertyTableHeader>
                       {(totalesAnalisis[semana].A_Bruta).toFixed(2)}
                    </PropertyTableHeader>
                    <PropertyTableHeader>
                        {(totalesAnalisis[semana].A_Neta).toFixed(2)}
                    </PropertyTableHeader>
                    <PropertyTableHeader>
                        {(totalesAnalisis[semana].total).toFixed(2)}    
                    </PropertyTableHeader>
                    <PropertyTableHeader>
                        {(totalesAnalisis[semana].total-totalesAnalisis[semana].Pendiente).toFixed(2)}    
                    </PropertyTableHeader>
                    <PropertyTableHeader>
                        {(totalesAnalisis[semana].PM)}
                    </PropertyTableHeader>
                    <PropertyTableHeader>
                    {(totalesAnalisis[semana].PS)}
                    </PropertyTableHeader>
                    <PropertyTableHeader>
                        {(totalesAnalisis[semana].Suma)}    
                    </PropertyTableHeader>                    
                    <PropertyTableHeader>
                        {(totalesAnalisis[semana].Suma/totalesAnalisis[semana].A_Neta).toFixed(0)}    
                    </PropertyTableHeader>                    
                    <PropertyTableHeader></PropertyTableHeader>
                    <PropertyTableHeader></PropertyTableHeader>                                 
                    <PropertyTableHeader></PropertyTableHeader>
                    <PropertyTableHeader></PropertyTableHeader>                                 
                </PropertyTableRow>
            </tfoot>
            </PropertyTable>
            {modalLoteEmb && <ModalLoteEmb
                    setModalLoteEmb ={setModalLoteEmb}
                    animarModalLoteEmb={animarModalLoteEmb}                    
                    semana={idSemana}
                    lote={idLote}
                    codLabor={codLabor}
                    calendario={calendar}
                    fechaini={fechaini}
                    fechafin={fechafin}
                    embPM={embPM}
                    embPS={embPS}
                    embLote={embLote}                    
                    global={global}
                    ibm={ibm}
                    sucursalName={sucursalName}/>                                            
            }
        </div>
        ));
    };
    

    return(
       <FormControlAnalysesRdt>
            <CerrarAnalysesRdt>
                <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarAuthorizationsRdt} />   
            </CerrarAnalysesRdt>
            {animarModal?
                <FormularioAnalysesRdt>                    
                    <PropertyTableContainer>
                        <AnalysesPropertyTypesContainerRow>
                            <AnalysesPropertyTypesContainerColum>
                                <CerrarAnalysesRdtInt>                           
                                        <ButtonIcon icon={IoArrowBack} onPress={ocultarAuthorizationsRdt} />        
                                </CerrarAnalysesRdtInt>
                                <AnalysesPropertyTypesContainerColum>
                                    {renderTablasPorSemana()} 
                                </AnalysesPropertyTypesContainerColum>
                            </AnalysesPropertyTypesContainerColum>
                            <AnalysesPropertyTypesContainerColum>
                                <>
                                <DetailAnalysesRdtsInv
                                    ibm = {ibm}                                    
                                    fechafin = {fechafin}
                                    codLabor = {codLabor}
                                    global={global}
                                    sucursalName={sucursalName}
                                />
                                </>                                
                            </AnalysesPropertyTypesContainerColum>                                                                                                    
                        </AnalysesPropertyTypesContainerRow>                        
                    </PropertyTableContainer>
                </FormularioAnalysesRdt>
            :''}
       </FormControlAnalysesRdt>
    )
}