import styled from "styled-components";

export const AddRdtsWrapper_Global = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    justify-content: center;
    
`;

export const AddRdtsWrapper_Global_DOS = styled.div`
    //border: 1px solid blue;   
    
    @media (max-width: 1280px){
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    }     
    @media (min-width: 1920px){
            margin-top: 20px;
            padding: 0 20px;            
            justify-content: center;
            width: 60%;
    }
    
`;

export const AddRdtsWrapper = styled.div`
    //border: 1px solid red;
    display: flex;    
    justify-content: right;
    padding: 10px;
    margin: 10px;    

    a{                            
       margin-bottom: 10px;       
    }   
`;

export const PropertyTypesContainerColum = styled.section`
    //border: 1px solid yellow;
    padding-right: 40px;
    label{
        display: block;
        font-size: 0.70em;
        color: blue;
        font-weight: bold;          
        p{
            color: black;              
        }                    
    } 
`;

export const PropertyTypesContainerRow = styled.section`

    //border: 1px solid red;
    border: 1px solid #dfdfdf;
    background-color: #F2F2F2;
    border-radius: 6px;
    display: flex;
    flex-direction: row;

    //Para moviles
    @media (max-width: 512px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
    }
`;

export const FormControl = styled.div`
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;        
`;

export const FormControlSeccionOne = styled.div`
    
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;  
        
    //Para moviles
    @media (max-width: 512px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
    }      
`;

export const FormControlSeccionTwo = styled.div`
    
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;  
        
    //Para moviles
    @media (max-width: 512px){
        //border: 1px solid blue;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        //flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        //width: 100%;
        //margin-top: 10px;
    }      
`;

export const FormControlTitle = styled.div`
    width: 100%;
    margin: 5px 0;
    border-radius: 6px;
    background-color: #F2F2F2;
    padding: 5px 10px;
    border: 1px solid #dfdfdf;
    display: flex;  
    justify-content: center;
    label{        
        font-size: 1.4em;
        color: blue;
        font-weight: bold;         
        text-align: center;                           
    }
    span{ 
        font-size: 1em;
        color: red;
        font-weight: bold;         
        text-align: center;                           
    }      
`;

export const FormControlInput = styled.div`
    //border: 1px solid yellow;
    flex: 1; 
    //width: 100%   

    label{
        display: block;
        font-size: 0.70em;
        color: #222;
        font-weight: bold;          
                    
    }

    p{
        display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;          
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],
    input[type="float"],input[type="date"]{
        display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;

export const FormControlInputOther = styled.div`
    //border: 1px solid yellow;
    flex: 1; 
    //width: 100%   

    label{
        //display: block;
        font-size: 0.70em;
        color: #222;
        font-weight: bold;   
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],
    input[type="float"],input[type="date"]{
        display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: orange;
        border: none;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;


export const FormControlAction = styled.div`
    //border: 1px solid orange;
    display: flex;
    justify-content: center;
    align-items: center;
    
`;

export const FormControlInputSearch = styled.div`
    //border: 1px solid red;
    flex: 1;    
    margin: 0 10px 0 10px;

    label{
        display: block;
        font-size: 0.80em;
        color: #26794f;
        font-weight: 500;
        padding-left: 10px ;
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],
    input[type="float"],input[type="date"]{
        //display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 1.1em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border-radius: 15px;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;

export const FormControlInputudp = styled.div`
    //border: 1px solid yellow;
    flex: 1; 
    //width: 100%   

    label{
        //display: block;
        font-size: 0.70em;
        color: #222;
        font-weight: bold;   
                    
    }    
    input[type="text"]{
        display: block;
        width: 100%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;    
        text-align:center;

    }
    input[type="number"]{
        display: block;
        width: 50%;
        //border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;    
        text-align:center;
        font-weight:bold;

    }

    p{
        display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: transparent;
        border: none;
        outline: none;    
        text-align:center;

        span{
            color: blue; 
            font-size: 0.9em;           
            font-weight:bold;
        }       
    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }
`;

export const FormControlInputSearchudp = styled.div`
    //border: 1px solid red;
    flex: 1;    
    margin: 0 10px 0 10px;
    
    label{
        display: block;
        font-size: 0.80em;
        color: #26794f;
        font-weight: 500;
        padding-left: 10px ;
                    
    }    
    input[type="number"],
    input[type="float"]{
        
        width: 50%;        
        font-size: 0.8em;
        padding: 5px 10px;
        margin: 0px;
        //background-color: #fff;
        border-radius: 5px;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;


export const PropertyTypeLabelWrapper = styled.div`
    background-color: #126EFD;
    color:#fff;
    border-radius: 6px;
    margin: 8px 0;
    display: inline-block;
    p{
        color: #fff;
        margin: 0px;
        font-size: 0.78em;
        font-weight: bold;
        
    }
`