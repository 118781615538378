import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ButtonAplicate, CerrarModal, 
    FormControlModal, 
    FormularioModal, 
    ModalDetailDescriptionWrapper, 
    ModalDetailValueWrapper, 
    ModalPropertyTypesContainerColum, 
    ModalPropertyTypesContainerRow, 
    PropertyTable, 
    PropertyTableCell, 
    PropertyTableHeader, 
    PropertyTableRow, 
    TittleModal } from "./styles"
import { ButtonIcon } from "../../../../components/ButtonIcon";
import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";

export const ModalAplicatePrec = ({setModal, animarModal, setAnimarModal, detail}) => {

  let detailAplicate = {}
  detailAplicate = (detail)
  //console.log({detailAplicate: detailAplicate})
  //Boton de cerrar Modal
  const ocultarModal = ()=>{      
    //setAnimarModal(false)
    setModal(false)          
  }
  return (
    <FormControlModal>
        <CerrarModal>
            <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarModal} />
        </CerrarModal>
        {animarModal?
            <FormularioModal>
                <ModalDetailDescriptionWrapper>
                    <ModalPropertyTypesContainerRow>
                        <ModalPropertyTypesContainerColum>
                            <ModalDetailValueWrapper>
                                <h2>{detailAplicate[0].Finca} // Año: {detailAplicate[0].anio} // Ciclo: {detailAplicate[0].Ciclo}</h2>
                                <PropertyBusinessType
                                    busineesType={`${detailAplicate[0].nombreMes} `}
                                />
                                <PropertyBusinessType
                                    busineesType={`${detailAplicate[0].nombreDia} // SEM ${detailAplicate[0].semana}`}
                                />
                               
                            </ModalDetailValueWrapper>                        
                        </ModalPropertyTypesContainerColum>                    
                    </ModalPropertyTypesContainerRow>
                    <ModalPropertyTypesContainerRow>                                 
              <ModalPropertyTypesContainerColum>
                  <PropertyBusinessType
                      busineesType={detailAplicate[0].Concepto}
                  />
                  <br/>              
                  <PropertyTable>
                    <thead>
                      <PropertyTableRow>                
                          <PropertyTableHeader>FECHA APLIC</PropertyTableHeader>
                          <PropertyTableHeader>MM</PropertyTableHeader>                                        
                          <PropertyTableHeader>BULT</PropertyTableHeader>
                          <PropertyTableHeader>PERS</PropertyTableHeader> 
                          <PropertyTableHeader>BU/PE</PropertyTableHeader>                                                                             
                      </PropertyTableRow>
                    </thead>
                    <tbody>
                      
                                <PropertyTableRow key={1}>
                                    <PropertyTableHeader>              
                                        {detailAplicate[0].Fecha.split('T')[0]}                   
                                    </PropertyTableHeader>
                                    <PropertyTableCell>              
                                        {detailAplicate[0].lluvia}                   
                                    </PropertyTableCell>
                                    <PropertyTableCell>                                                                                                 
                                        {detailAplicate[0].total}                                         
                                    </PropertyTableCell>                                                                                      
                                    <PropertyTableCell>              
                                        {detailAplicate[0].Trab}                   
                                    </PropertyTableCell>                            
                                    <PropertyTableCell>              
                                        {(detailAplicate[0].total / detailAplicate[0].Trab).toFixed(0)}                   
                                    </PropertyTableCell>
                                                                                                                          
                                </PropertyTableRow>
                   
                    </tbody>
                  </PropertyTable>
              </ModalPropertyTypesContainerColum>
            </ModalPropertyTypesContainerRow>
                
                </ModalDetailDescriptionWrapper>
                
            </FormularioModal>
        :''}
    </FormControlModal>
  )
}


