import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import Alert from "../../components/Alert/Alert";
import { Page } from "../../components/Page"
import { PageTitle } from "../../globalStyles";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { Button } from "../../components/Button"
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { getToken, removeToken } from "../../utils/TokenLS";
import { PrecipitationDetailaniomes } from "./PrecipitationDetailaniomes";
import { PrecipitationDetailanio } from "./PrecipitationDetailanio";
import { PrecipitationDetailaniosemana } from "./PrecipitationDetailaniosemana";
import { PrecipitationDetailaniodia } from "./PrecipitationDetailaaniodia";
import { IoBusiness, IoExit, IoPersonCircleOutline, 
         IoLocation, IoRainy } from "react-icons/io5";
import { PrecipitationWrapper_Chart, PrecipitationWrapper_Global, PrecipitationWrapper_Global_DOS, PrecipitationWrapper_Table, PropertyTypesContainer } from "./styles";
import { PropertyTypeButton } from "../Home/components/PropertyTypeButton";
import { PropertyGrafDonaChartanio } from "./Components/PropertyGrafDonaChartanio";
import { PropertyGrafBarChartAnio } from "./Components/PropertyGrafBarChartAnio";
import { PropertyGrafBarChartAniomes } from "./Components/PropertyGrafBarChartAniomes";
import { PropertyGrafBarChartAniosem } from "./Components/PropertyGrafBarChartAniosem";
import { PrecipitationDetailanioanteriordia } from "./PrecipitationDetailaanioanteriordia";

const ALL_SUCURSALES = 0;
const ALL_PROPERTIES_TYPE = 0;

const PropertiesTypes = [
    { id: 5, icon: IoRainy, label:'Año Anterior'},    
    { id: 1, icon: IoRainy, label:'Ultimos Días'},   
    { id: 2, icon: IoRainy, label:'Ultimas 12 Sem'},
    { id: 3, icon: IoRainy, label:'Ultimos Meses Año'},
    { id: 4, icon: IoRainy, label:'Ultimos 3 Años'},    
    
];

export const Precipitations = () => {
    
    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ date, setDate ] = useState('');
    const [ sucursal, setSucursal ] = useState([]);
    const [ sucursalSeleted, setSucursalSelected ] = useState(ALL_SUCURSALES);  
    const [ sucursalname, setSucursalname ] = useState('');
    const [ cantidad, setCantidad ] = useState();    
    const [ precipitationanio, setPrecipitationanio ] = useState([]); 
    const [ precipitationUltsem, setPrecipitationUltsem ] = useState([]);
    const [ calendar, setCalendar ] = useState([]);  
    const [ sem, setSem ] = useState(0);     
    const [ anio, setAnio ] = useState(0); 

    const [propertyTypeSeleted, setPropertyTypeSelected] = useState(ALL_PROPERTIES_TYPE);
    const [ estado, setEstado ] = useState(false)  

    const [alert, setAlert] = useState({})    

    const navigate = useNavigate();

    const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ 
        mode: 'onChange',
        defaultValues: {
            checkbox: []
        }
   }); 

   //Capturo la fecha actual para realizar las consultas y enviarlas a los artefactos correspondientes
   const fechaAct = Date.now();
   const anioActual = new Date(fechaAct).getFullYear();
   const mesActual = new Date(fechaAct).getMonth() + 1;
   const diaActual = new Date(fechaAct).getDate();
   
   let fechaActualizada = '';                               
    mesActual < 10 && diaActual < 10 ? 
        fechaActualizada = new String(anioActual +'-0'+ mesActual +'-0'+ diaActual):            
    mesActual < 10 && diaActual >= 10 ? 
        fechaActualizada = new String(anioActual +'-0'+ mesActual +'-'+ diaActual):            
    mesActual >= 10 && diaActual < 10 ? fechaActualizada = new String(anioActual +'-'+ mesActual +'-0'+ diaActual):
        fechaActualizada = new String(anioActual +'-'+ mesActual +'-'+ diaActual)                
   
   
   const Cargar = ()=>{
    setTimeout(()=>{                                  
         setEstado(true)                        
    }, 300)           
    }

    const propertyTypeHandler = (id)=>{
        setPropertyTypeSelected(propertyTypeSeleted === id 
            ? ALL_PROPERTIES_TYPE : id);        
    }

    useEffect(() => {     
        if(!estado){
            Cargar()
        }else if(auth.data){
            if(propertyTypeSeleted === 1){
                    //console.log({Propiedad: propertyTypeSeleted})  
            }else if(propertyTypeSeleted === 2){
              
            }else if(propertyTypeSeleted === 3){
               
            }else if(propertyTypeSeleted === 4){
                 
            }else if(propertyTypeSeleted === 5){                
                            
            }else{
                              
            }                       
        }

    },[propertyTypeSeleted])

    //Close session if token expired
    const hendleCloseSeccion = async ()=>{
        closeSeccionAuth()
        removeToken()
        showAlert(
            'Inicie Seccion Nuevamente', 
            "Su token puede estar vencido", 
            SW_ICON.INFO,
            () => { navigate('/Login')}    
        );
    }
    //Token valido o no expirado
    useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return            
            await anioActual ? getCalendar():console.log('');
            //await getPrecipitationanio();                      
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
    },[auth])
    //Recargar RDTs
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await reload ? console.log('') :console.log('')                                              
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[reload])
    //Si estoy editando
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await rdtprov.ibm?setSucursalSelected(rdtprov.ibm):console.log(''); 
                await rdtprov.dateRdt?setDate((rdtprov.dateRdt).split('T')[0]):console.log('');                                
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[rdtprov]) 
    //getCalendars
    const getCalendar = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/calendars',                         
                    params: makePropertiesFiltersCalendar()                                     
                }
            );             
            const {data} = response;                
            setCalendar(data);             
            setReload(false);
            //console.log({fechaActualizada: fechaActualizada})
            //console.log({calendar: data})
            //console.log({semana: data[0].semana})
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersCalendar = ()=>{
        const filters = {};                
            filters['Fecha'] = new Date(fechaActualizada);             
            
            //filters['employeesId'] = auth.data._id;              
            //console.log(fechaActualizada);
        return filters;       
    }
    //console.log({calendar: calendar[0]?calendar[0].semana:0})
    
  return (
    <Page> 
        
        <PropertyTypesContainer>             
                {          
                    PropertiesTypes.map((item, key) => 
                        <PropertyTypeButton 
                            selected={propertyTypeSeleted === item.id}
                            icon={item.icon} 
                            label={item.label}
                            id={item.id}
                            key={key}
                            onPress={propertyTypeHandler}

                        /> )
                }
        </PropertyTypesContainer>
        <PrecipitationWrapper_Global>
            
        {propertyTypeSeleted===1?
                <>
                    <PrecipitationWrapper_Table>
                        <PrecipitationDetailaniodia
                            anio={anioActual}
                            semana={calendar[0]?calendar[0].semana:0}
                            diaCorto={calendar[0]?calendar[0].nombrediaCorto:'VACIO'}
                            info={propertyTypeSeleted}
                        />
                    </PrecipitationWrapper_Table>                    
                </>
            :propertyTypeSeleted===2?
                <> 
                    <PrecipitationWrapper_Table>
                        <PrecipitationDetailaniosemana
                                anio={anioActual}
                                semana={calendar[0]?calendar[0].semana:0}
                                info={propertyTypeSeleted}
                        />
                    </PrecipitationWrapper_Table>       
                    <PrecipitationWrapper_Chart>
                        <PropertyGrafBarChartAniosem
                            anio={anioActual}
                            semana={calendar[0]?calendar[0].semana:0}
                        />
                    </PrecipitationWrapper_Chart> 
                </>
            :propertyTypeSeleted===3?
                <>
                    <PrecipitationWrapper_Table>
                        <PrecipitationDetailaniomes
                            anio={anioActual}
                            info={propertyTypeSeleted}
                        />
                    </PrecipitationWrapper_Table>
                    <PrecipitationWrapper_Chart>
                        <PropertyGrafBarChartAniomes
                            anio={anioActual}
                            mes={calendar[0]?calendar[0].mes:0}
                        />
                    </PrecipitationWrapper_Chart> 
                </>
            :propertyTypeSeleted===4?
                <>
                    <PrecipitationWrapper_Table>
                        <PrecipitationDetailanio
                            anio={anioActual}            
                        />
                    </PrecipitationWrapper_Table>
                    <PrecipitationWrapper_Chart>
                        <PropertyGrafBarChartAnio
                            anio={anioActual}
                        />
                    </PrecipitationWrapper_Chart>
                </>
            :propertyTypeSeleted===5?
                <>
                    <PrecipitationWrapper_Table>
                        <PrecipitationDetailanioanteriordia
                            anio={anioActual}
                            semana={calendar[0]?calendar[0].semana:0}
                            diaCorto={calendar[0]?calendar[0].nombrediaCorto:'VACIO'}
                            info={propertyTypeSeleted}
                        />
                    </PrecipitationWrapper_Table>                    
                </>
            :   <>
                    <PrecipitationWrapper_Table>
                        <PrecipitationDetailaniodia
                            anio={anioActual}
                            semana={calendar[0]?calendar[0].semana:0}
                            diaCorto={calendar[0]?calendar[0].nombrediaCorto:'VACIO'}
                            info={propertyTypeSeleted}
                        />
                    </PrecipitationWrapper_Table>                    
                </>
            
        }      
      </PrecipitationWrapper_Global>
    </Page>
  )
}


