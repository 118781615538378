import { Link } from "react-router-dom";
import styled, {css} from "styled-components";

export const PropertyTableContainer = styled.div`
  //max-height: 920px; /* Establece la altura máxima para la tabla y habilita el desplazamiento vertical */
  overflow-y: auto; /* Habilita el desplazamiento vertical si el contenido supera la altura máxima */
  position: relative; /* Necesario para el posicionamiento relativo del encabezado */
  margin-left: 10px;
  height: 800px;
`;

export const PropertyTable = styled.table`
  width: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

export const PropertyTableHeader = styled.th`
  background-color: #26794f;
  text-align: center;
  padding: 8px 8px;
  //position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
  &:first-child {
    left: 0; /* Fija el primer encabezado de columna en la parte izquierda */
  }
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 4px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 3px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableHeaderRow = styled.tr`
  background-color: #b0c2f2;
  position: sticky; /* Establece el posicionamiento pegajoso */
  top: 0; /* Fija el encabezado en la parte superior */
  z-index: 1; /* Asegura que el encabezado esté sobre el contenido desplazado */
  font-size: 14px;
`;

export const PropertyTableRow = styled.tr`
  &:nth-child(even) {
    background-color: white;
  }
  font-size: 14px;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        font-size: 10px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        font-size: 10px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

export const PropertyTableCell = styled.td`
  border: 1px solid #ddd;
  padding: 4px;
  text-align: right;
  color: black;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 3px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    span{
      //color: blue;
      font-weight: bold;
    }
`;

export const PropertyTableCellTotal = styled.td`
  border: 1px solid #ddd;
  padding: 4px;
  text-align: right;
  color: black;
  font-weight: bold;
  /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
  @media (max-width: 1366px) {
        padding: 3px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
    /* Consulta de medios para ajustar el diseño en dispositivos con un ancho máximo de 768px (por ejemplo, tablets y móviles) */
    @media (max-width: 768px) {
        padding: 2px;
        //flex-direction: column; /* Cambia a diseño de columna para dispositivos con un ancho máximo de 768px */
    }
`;

const BaseButtonStyle = css`
    width: 100%;
    font-size: 1em;
    color: blue;
    //text-decoration-line: underline;
    font-weight: bold;
    //background-color: #4A148C;
    background-color: transparent;//verde osc
    //background-color: #ff8c00;//Naranja
    text-align: right;
    border: none;
    //border-radius: 6px;
    //padding: 5px 5px;
    //opacity: 0.9;
    cursor: pointer;    
    
    &hover{        
        opacity: 1;
    }   
`;

export const ButtonTransport= styled.button`
    ${BaseButtonStyle}
    
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }    
`;