import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useRdt } from "../../../hooks/useRdt";
import { showAlert, SW_ICON } from "../../../utils/swAlert";
import { HTTP_VERBS, requestHttp } from "../../../utils/HttpRequest";
import { getToken, removeToken } from "../../../utils/TokenLS";
import Spinner from "../../../utils/Spinner";
import { PropertyTable, 
         PropertyTableCell, 
         PropertyTableCellAnio, 
         PropertyTableCellToday,
         PropertyTableCellAplicate, 
         PropertyTableContainer, 
         PropertyTableHeader, 
         PropertyTableHeaderRow, 
         PropertyTableHeaderToday, 
         PropertyTableRow, 
         ButtonAplicate} from "./styles";
import { ModalAplicatePrec } from "../Components/ModalAplicatePrec";

export const PrecipitationDetailanioanteriordia = (item) => {
    //item me pasan la fecha actual.
    //console.log({detailAniodiaItem: item})

    const { auth, closeSeccionAuth } = useAuth();
    const { rdtprov, setRdtprov, reload, setReload } = useRdt();
    const [ precipitationanio, setPrecipitationanio ] = useState([]); 
    const [ precipitationaniodia, setPrecipitationaniodia ] = useState([]); 
    const [ estado, setEstado ] = useState(false); 
    const [ iterations, setIterations ] = useState(false);
    const [ rdtsDia, setRdtsDia ] = useState([]);

    const [ modal, setModal ] = useState(false);    
    const [ animarModal, setAnimarModal ] = useState(false);
    const [ idAplicate, setIdAplicate ] = useState(null);
    
    const [alert, setAlert] = useState({})    

    const navigate = useNavigate();

    const Cargar = ()=>{
        //console.log(precipitacionPorAniodia>0?'lleno':'Vacio')
        setTimeout(()=>{   
             setIterations(true)                                        
             setEstado(true)  
                         
        }, 200)           
    }

    const { 
        register, 
        handleSubmit,
        formState:{
            errors,
            isValid
        }  
    } = useForm({ 
        mode: 'onChange',
        defaultValues: {
            checkbox: []
        }
   }); 

   //Close session if token expired
   const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
   }
   //Token valido o no expirado
   useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return            
            await item.anio ? getPrecipitationaniodia():console.log('');
            await item.anio ? getPrecipitationanio():console.log('');
            await item.anio ? getRdtsAgrupadosDia():console.log('');                          
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
    },[auth])
    //anio
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                //await item.semana ? getPrecipitationaniodia():console.log('');
                await item.anio ? getPrecipitationanio():console.log('');
                //await getPrecipitationanio();                      
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[item.anio])   
    //semana
    useEffect(() => {  
        const getData = async ()=>{            
            try {
                const token = getToken();
                if(!token) return
                await item.semana ? getPrecipitationaniodia():console.log('');
                //await item.anio ? getPrecipitationanio():console.log('');
                //await getPrecipitationanio();                      
            } catch (error) {
                console.log(error)
            }             
        }    
        getData();               
    },[item.semana])    
    //getPrecipitation
    const getPrecipitationanio = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations/anio',                         
                    params: makePropertiesFiltersanio()                                     
                }
            );             
            const {data} = response;                
            setPrecipitationanio(data);    
            //console.log({dataAnio: data})                          
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersanio = ()=>{
        const filters = {};                
            filters['anio'] = item.anio;                         
            //filters['employeesId'] = auth.data._id;              
            //console.log(anio);
        return filters;       
    }
    // Crear un objeto para almacenar los totales de precipitacion por año
    const precipitacionPorAnio = {};

    // Calcular los totales de precipitacion por año
    precipitationanio.forEach((itemPrec) => {
        if (!precipitacionPorAnio[itemPrec.anio]) {
            precipitacionPorAnio[itemPrec.anio] = {};
        }
        if (!precipitacionPorAnio[itemPrec.anio][itemPrec.Finca]) {
            precipitacionPorAnio[itemPrec.anio][itemPrec.Finca] = 0;
        }
        precipitacionPorAnio[itemPrec.anio][itemPrec.Finca] += itemPrec.totalLLuvia;
    });
    //console.log({precipitacionAnio: precipitacionPorAnio})

    //getPrecipitation por anio y dia en las ultimas doce semanas
    const getPrecipitationaniodia = async ()=>{                
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/precipitations',                         
                    params: makePropertiesFiltersaniodia()                                     
                }
            );             
            const {data} = response;                
            setPrecipitationaniodia(data); 
            //await data?getPrecipitationanio():console.log('')   
            //console.log({precipitationaniodia: data})                          
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }    
    }
    const makePropertiesFiltersaniodia = ()=>{
        const filters = {};                
            filters['anio'] = item.anio-1;
            //Se muestra solo los tres meses finales del año anterior   
            filters['semana'] = 52;
            //Se envia el Group 2 para que el orden sea por orden de finca   
            filters['Group'] = 1;                               
            //filters['employeesId'] = auth.data._id;              
            //console.log(item.semana);
        return filters;       
    }
    //getRdtsAgrupados por Dia
    const getRdtsAgrupadosDia = async ()=>{
    
        if(item.Ibm === '' || item.Ibm === 0 || item.Ibm === '90021' || item.Ibm === '90020'){
            setRdtsDia([])
        return
        //console.log({nohacerConsulta: sucursalSeleted})        
        }else{
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/Rdts/supplies',                         
                    params: makePropertiesFiltersDos()                                     
                }
            );             
            const {data} = response;                
            data?setRdtsDia(data):console.log('');  
            //console.log({rdtsDias: data})        
            } catch (error) {
                if(error.response.status === 401){
                    hendleCloseSeccion();
                }else{
                    console.log(error)
                }
            }
            //console.log({hacerConsulta: sucursalSeleted})        
        }                   
    }
    const makePropertiesFiltersDos = ()=>{
        const filters = {};                
            filters['Fecha_INI'] = String( (item.anio-1) + "-01-01"); 
            filters['Fecha_FIN'] = String( (item.anio-1) +  "-12-31" ); ; 
            //filters['IbmLaboro'] = item.Ibm;   
            //filters['IntCodigo_EMPL'] = codtrabajador;    
            //filters['IntCodigo_CONC'] = item.Codigo;
            //Envio este parametro y lo recibe el endpoint la consulta se ejecuta con un array con el cod:112 y 228     ¿que son los de fertilizante
            filters['IntCodigo_CONC_Fer'] = '112';
            //filters['Ibm'] = item.Ibm; 
            //Se envia parametro para consulta de ciclo mayor o igual al actual -1
            filters['intCicloMayor'] = Number(1);
            //Para aplicar la consulta correcta de la agrupacion por dia, se debe enviar este parametro.   
            filters['intTrab'] = 2;   
        // filters['employeesId'] = auth.data._id;     
        //console.log({fechafin: fechafin});
        //console.log({filters: filters});
        
        return filters;       
    }
    //const dias = [...new Set(precipitationaniodia.map((itemPrec) => itemPrec.nombrediaCorto))];
    //const semanasUnicas = [...new Set(precipitationaniodia.map(venta => ({ año: venta.anio, sem: venta.semana })))]; 
    //console.log({semanasunicas: semanasUnicas})

    //Recorro las precipitaciones diarias originales y encuentro las aplicaciones realizadas e incluyo el campo aplicado con el total de la aplicacion por Ibm y Codigo 
    const nuevaListados = precipitationaniodia.map(item1 => {
        const item2 = rdtsDia.find(item2 => item2.Ibm === item1.Ibm && item2.Fecha === item1.dtmFecha);            
        return {
        ...item1,
        aplicado: item2 ? item2.total : 0,
        Trab: item2 ? item2.Trab : 0,        
        Ciclo: item2 ? item2.Ciclo : '',
        Codigo: item2 ? item2.Codigo : '',
        Concepto: item2 ? item2.Concepto : ''
        //Dias: item2 ? Date.now() : 0
        
        };
    });
    //console.log({nuevaListados: nuevaListados})
    // Crear un nuevo arreglo para almacenar los resultados
    const nuevoArreglo = [];

    // Crear un conjunto para rastrear las combinaciones únicas de finca, año y semana
    const combinacionesUnicas = new Set();

    // Iterar sobre los datos de precipitacion originales
    for (const cantidadPrecipitacion of nuevaListados) {
        const { strFinca, anio, semana, nombrediaCorto, intPrecipitation, Ciclo, aplicado, Trab, Codigo, Concepto, dtmFecha, Ibm } = cantidadPrecipitacion;
        const combinacion = `${strFinca}-${anio}-${semana}`;

        if (!combinacionesUnicas.has(combinacion)) {
            combinacionesUnicas.add(combinacion);
        }

        nuevoArreglo.push({
            strFinca,
            anio,
            semana,
            nombrediaCorto,
            intPrecipitation,
            Ciclo,
            aplicado,
            Trab,
            Codigo,
            Concepto,
            dtmFecha,
            Ibm
        });
        
    }
    //console.log({combinacionesUnicas: combinacionesUnicas})
    //console.log({nuevoArreglo: nuevoArreglo})
    // Completar con precipitacion en 0 para los días que faltan
    for (const combinacion of combinacionesUnicas) {
        const [strFinca, anio, semana] = combinacion.split('-');
        const diasRegistrados = new Set();

        for (const cantidadPrecipitacion of nuevoArreglo) {
            if (cantidadPrecipitacion.strFinca === strFinca && cantidadPrecipitacion.anio === parseInt(anio) && cantidadPrecipitacion.semana === parseInt(semana)) {
                diasRegistrados.add(cantidadPrecipitacion.nombrediaCorto);
            }
        }

        for (const nombrediaCorto of ['LU', 'MA', 'MI', 'JU', 'VI', 'SA', 'DO']) {
           
            if (!diasRegistrados.has(nombrediaCorto)) {
            nuevoArreglo.push({
                strFinca,
                anio: parseInt(anio),
                semana: parseInt(semana),
                nombrediaCorto,
                intPrecipitation: 0,
                aplicado: 0
            });
            }            
        }    
          
    } 
    //console.log(nuevoArreglo[0]?setIterations(true):'llenando...');
    
    //console.log({iterations: iterations})

    //variable para indicar cuando se muestra la inf en la tabla
    let terminado = 0;
    // Crear un objeto para almacenar los totales de precipitacion por semana
    const precipitacionPorAniodia = {};

    // Calcular los totales de precipitacion por semana
    nuevoArreglo.forEach((itemPrec) => {
        
        if (!precipitacionPorAniodia[itemPrec.anio]) {
            precipitacionPorAniodia[itemPrec.anio] = {};
        }
        if (!precipitacionPorAniodia[itemPrec.anio][itemPrec.semana]) {
            precipitacionPorAniodia[itemPrec.anio][itemPrec.semana] = {};
        }
        if (!precipitacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto]) {
            precipitacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto] = {};
        }
        if (!precipitacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto][itemPrec.strFinca]) {
            precipitacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto][itemPrec.strFinca] = 0;
        }
        precipitacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto][itemPrec.strFinca] += itemPrec.intPrecipitation;
        //console.log({presi: precipitacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto][itemPrec.strFinca]});

        //Espero que termine de realizar todas las operaciones y muestro inf
        terminado = terminado + 1; 
        if (terminado === nuevoArreglo.length){
            Cargar()
        }             
    });
    //console.log({precipitacionPorAniodia: precipitacionPorAniodia})
    
    // Crear un objeto para almacenar los totales de aplicacion por semana
    const aplicacionPorAniodia = {};
    // Identificar si hubo Aplicación de fertilizante
    nuevoArreglo.forEach((itemPrec) => {
        
        if (!aplicacionPorAniodia[itemPrec.anio]) {
            aplicacionPorAniodia[itemPrec.anio] = {};
        }
        if (!aplicacionPorAniodia[itemPrec.anio][itemPrec.semana]) {
            aplicacionPorAniodia[itemPrec.anio][itemPrec.semana] = {};
        }
        if (!aplicacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto]) {
            aplicacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto] = {};
        }
        if (!aplicacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto][itemPrec.strFinca]) {
            aplicacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto][itemPrec.strFinca] = 0;
        }
        aplicacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto][itemPrec.strFinca] += itemPrec.aplicado;
        //console.log({presi: precipitacionPorAniodia[itemPrec.anio][itemPrec.semana][itemPrec.nombrediaCorto][itemPrec.strFinca]});

        //Espero que termine de realizar todas las operaciones y muestro inf
        terminado = terminado + 1; 
        if (terminado === nuevoArreglo.length){
            Cargar()
        }             
    });
    //console.log({aplicacionPorAniodia: aplicacionPorAniodia})
       

    const años = Object.keys(precipitacionPorAniodia);
    //Le hago un distinc a precipitationaniodia por finca y sacar el nombre de las fincas
    const fincas = [...new Set(precipitationaniodia.map((itemPrec) => itemPrec.strFinca))];
    //Le hago un distinc a precipitationaniodia por semana y sacar las semanas consultadas
    const semanas = [...new Set(precipitationaniodia.map((itemPrec) => itemPrec.semana))];
    //const dias = ['LU','MA','MI','JU','VI','SA','DO'];
    const dias = ['DO','SA','VI','JU','MI','MA','LU'];

    //Ordenar las semanas en forma descendente
    semanas.sort((a, b) => b - a);
    //Modal para visualizar detalle de cant de fertilización y personas que la aplicaron
    const handleModalAplicateFarm = (idDetail)=>{
        //Filtro datos de la precipitacion origianal seleccionada
        const selectPrec = precipitationaniodia.filter((item) => item.strFinca === idDetail.split(',')[0] && item.anio === Number(idDetail.split(',')[1]) && 
                                                            item.semana === Number(idDetail.split(',')[2]) && item.nombrediaCorto === idDetail.split(',')[3] );            
        //console.log({selectPrec: selectPrec}) 
        //console.log({selectPrec: selectPrec[0].Ibm}) 

        //Filtro los Rdts del dia seleccionado
        const selectRdt = rdtsDia.filter((item) => item.Ibm === selectPrec[0].Ibm && item.Fecha === selectPrec[0].dtmFecha);            
        //console.log({selectRdt: selectRdt}) 
        //console.log({selectRdt: selectRdt[0].Ibm}) 

        //Recorro la precipitacion y aplicaciones seleccionada e incluyo los campos faltantes        
        const nuevaListaPrecApli = selectRdt.map(item1 => {
            const item2 = selectPrec.find(item2 => item2.Ibm === item1.Ibm && item2.dtmFecha === item1.Fecha);            
            return {
            ...item1,
            semana: item2 ? item2.semana : 0,
            anio: item2 ? item2.anio : 0,        
            lluvia: item2 ? item2.intPrecipitation : '',
            nombreDia: item2 ? item2.nombreDia : '',
            nombreMes: item2 ? item2.nombreMes : ''
            //Dias: item2 ? Date.now() : 0
            
            };
        });
        setModal(true)
        setIdAplicate(nuevaListaPrecApli);
        //console.log({id: idAplicate})  
        setTimeout(() => {
          setAnimarModal(true)
          //console.log({modalActive:nuevaListaPrecApli})
          /*console.log({modalActive:idDetail.split(',')[0]})
          console.log({modalActive:idDetail.split(',')[1]})
          console.log({modalActive:idDetail.split(',')[2]})
          console.log({modalActive:idDetail.split(',')[3]})
          console.log({modalActive:idDetail.split(',')[4]})*/

        }, 300);
    }

    // Filtrar elementos para mostrarlos en la tabla
    //const elementosFiltrados = precipitationaniodia.filter((elemento) =>elemento.semana == 38);                                                            
    //console.log(precipitationaniodia)   
    /*
    {fincas.map((finca) => (                 
            años.map((año) =>
                semanas.map((sem)=>
                    dias.map((dia)=> (                       
                            console.log({Resultado:  precipitacionPorAniodia[año][sem][dia][finca] || 0})
                )))              
            )      
    ))}
   */

    //console.log({añosdias: años})
    //console.log({semanasdias: semanas})
    //console.log({fincasdias: fincas})  
    //console.log({diasdias: dias})    
    
    //console.log({elementosFiltrados: elementosFiltrados})    
    
    //<h2>Milimetros de LLuvia x Dia en las ultimas 2 Semanas</h2>
    /*
    {precipitacionPorAnio?años.map((año) =>                         
                                <PropertyTableCellAnio key={`${finca}-${año}`}>{ precipitacionPorAnio[año][finca] || 0 }</PropertyTableCellAnio>
                            )             
                        :''}
    */
  return (
    
    <PropertyTableContainer> 
         
      <br/> 
      {iterations?modal?'':           
      <PropertyTable>
      
       <thead>
                             
            <PropertyTableRow>
                <PropertyTableHeader>FINCA</PropertyTableHeader>
                
                {años.map((año) =>
                    semanas.map((sem) =>                        
                        dias.map((dia) => (
                            dia === 'MI'?
                                <PropertyTableHeader key={`${año}-${sem}-${dia}`}>SM</PropertyTableHeader>
                            :    dia === 'JU'?
                               <PropertyTableHeader key={`${año}-${sem}-${dia}`}>{sem}</PropertyTableHeader>
                            :  <PropertyTableHeader key={`${año}-${sem}-${dia}`}></PropertyTableHeader>
                    )))                    
                )}
            </PropertyTableRow>
            <PropertyTableRow>
                
                {años.map((año) => (
                    <PropertyTableHeader key={año}>Acumulado_Año_{año}</PropertyTableHeader>
                ))}
                {años.map((año) =>
                    semanas.map((sem) =>                        
                        dias.map((dia) => (
                            sem === item.semana && dia === item.diaCorto?
                                <PropertyTableHeaderToday key={`${año}-${sem}-${dia}`}>{dia}</PropertyTableHeaderToday>
                            :   <PropertyTableHeader key={`${año}-${sem}-${dia}`}>{dia}</PropertyTableHeader>
                    )))                    
                )}
                
            </PropertyTableRow>       
        </thead>
  
       
        <tbody>
            {fincas.map((finca) => (                        
                    <PropertyTableRow key={finca}> 
                                           
                        <PropertyTableHeader>                    
                        {estado?años.map((año) =>                         
                                    <PropertyTableCellAnio key={`${finca}-${año}`}><span>{finca}</span>{'('+ precipitacionPorAnio[año][finca]+ ')' || 0 }</PropertyTableCellAnio>
                                    
                                )             
                            :''}   
                        </PropertyTableHeader>
                                        
                        {años.map((año) =>    
                            semanas.map((sem)=>                                              
                                dias.map((dia)=> (
                                    sem === item.semana && dia === item.diaCorto?                                        
                                        <PropertyTableCellToday key={`${finca}-${año}-${sem}-${dia}`}>{ precipitacionPorAniodia[año][sem][dia][finca] || 0 }</PropertyTableCellToday>
                                    :aplicacionPorAniodia[año][sem][dia][finca]>0? 
                                        
                                            <PropertyTableCellAplicate key={`${finca}-${año}-${sem}-${dia}`}>
                                                <ButtonAplicate onClick={()=>handleModalAplicateFarm(`${[finca,año,sem,dia,precipitacionPorAniodia[año][sem][dia][finca] || 0]}`)}>
                                                    { precipitacionPorAniodia[año][sem][dia][finca] || 0 }
                                                </ButtonAplicate>
                                            </PropertyTableCellAplicate>
                                                                             
                                    :  <PropertyTableCell key={`${finca}-${año}-${sem}-${dia}`}>{ precipitacionPorAniodia[año][sem][dia][finca] || 0 }</PropertyTableCell>
                                )))                               
                        )}                      
                    </PropertyTableRow>        
            ))}  
        </tbody>
       
      </PropertyTable>  
      
      :<Spinner/>} 
    {modal && <ModalAplicatePrec
                setModal={setModal}
                animarModal={animarModal}
                detail={idAplicate}/>}                                          
      
    </PropertyTableContainer>
  )
}

