import { Link } from "react-router-dom";
import styled from "styled-components"

export const PropertyCardWrapper = styled(Link)`
    //border: 1px solid red;  
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 1px; 
    flex: 1 1 auto;   
    //display: flex;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    margin: 1px 0;
    font-size: 0.8em;
    text-decoration: none;

    
    

`;

export const PropertyCardWrapperTr = styled.div`
    display: flex;
    align-items: flex-start;
    //border: 1px solid red;  

        //Para moviles y Tablets
        @media (max-width: 1024px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        width: 100%;
        //margin-top: 10px;
        }
    
`;

export const PropertyCardWrapperTd = styled.div`
    //border: 1px solid red;  
    flex-grow: 1;    
    margin: 5px 5px; 
    padding-bottom: 10px;   
    border: 1px solid #ccc;
    
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);

    h3{
        //border: 1px solid orange;
        text-align: center;
        color: #26794f;
        span{
            color: black;
        }     
        b{
            color: red;
        }  
    }
    span{
        color: red;
    }

    //Para moviles y Tablets
    @media (max-width: 1024px){
        //border: 1px solid red;
        //border: 1px solid orange; 
        //margin-bottom:10px;  
        flex-direction: column; 
        //margin: 0px; 
        //border-radius: 5px;
        //align-items: center;
        //justify-content: center;
        width: 100%;
        //margin-top: 10px;
    }

    
`;

export const PropertyButtonWrapper = styled.div`
    margin: 3px;
    //border: 1px solid blue;   

`;


export const PropertyImageWrapper = styled.div`
    flex: 30;
    border-radius: 20px;
    img{
        width: 100%;
        border-radius: inherit;
    }

`;

export const PropertyInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin: 0 10px 0 10px;
    text-align: left;
    color:#6262A0;

    h3{
        color: #26794f;
        span{
            color: black;
        }
    }
    
        
`;

export const PropertyDescriptionWrapper = styled.div`    
    color: #6262A0;
    font-weight: bold; 
    font-size: 0.8em;   
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    p{
        color: #6262A0;
        font-weight: bold; 
        font-size: 1em;
        text-align: right;
    }
    a{
        padding-left: 3px;
        color: #26794f;
    }
`;

export const PropertyValueWrapper = styled.p`
    color: #222;
`;


