
import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";
import { PropertyTypeLabel } from "../../../../components/PropertyTypeLabel";
import { SubTitle } from "../../../../globalStyles";
import { PropertyCardWrapper,         
         PropertyDescriptionWrapper, 
         PropertyInfoWrapper, 
         PropertyValueWrapper } from "./style";


export const PropertyCard = (props) => {
  
  return (  
    
        <PropertyCardWrapper to={`/TraslateDetail/${props._id}`}>
          
          <PropertyInfoWrapper>       
            
              <h3>{props.strNombreTrabajador} Cod: {props.IntCodigo}</h3>
                        
            <SubTitle>
              {props.strCodigoCentro_EMPL}
            </SubTitle>
            <PropertyDescriptionWrapper>        
              
          
            </PropertyDescriptionWrapper>                       

            <PropertyDescriptionWrapper>
              <PropertyValueWrapper>
                {props.strMotivo}: <br/> {props.strCodigoCentro} - {props.intIBM_TRAS}        
                <PropertyBusinessType
                    busineesType = {`FPT: ${props.dtFInicial.split('T')[0]} ${props.dtFFinal === null?'':'//'} ${props.dtFFinal === null?'':props.dtFFinal.split('T')[0]}  `}
                />      
              </PropertyValueWrapper>                    
            </PropertyDescriptionWrapper>
            <PropertyTypeLabel 
                typeId= {`FC: ${props.updatedAt.split('T')[0]}`}
              />
              <PropertyBusinessType
                busineesType = {`${props.employeesId?props.employeesId.name:''}`}
              />
          </PropertyInfoWrapper>      
        </PropertyCardWrapper>  
    
  )
}