
import styled from 'styled-components';

export const PropertyTypesContainerHead = styled.section`
    
    //border: 1px solid red;
    display: flex;  
    margin: 10px 0 ;    
    
`;
export const PropertyTypesContainerWelcome = styled.section`

    //border: 1px solid yellow;
    display: flex;    
    flex-direction: column ;  
    padding: 0 0 30px;

`;
export const PropertyTypesContainer = styled.section`
    //border: 1px solid orange;
    display: flex;
    overflow-x: scroll; 
    
`;

export const Home_Global_DOS = styled.div`
    //border: 1px solid blue;  
    flex-basis: ${props => props.basis};   
    padding: 3px; 
   
    @media (max-width: 1440px){
            margin-top: 20px;
            padding:0 5px;            
            justify-content: center;
            flex-basis: 33.3%;
    }
    @media (max-width: 820px){
            margin-top: 20px;
            padding:0 5px;            
            justify-content: center;
            flex-basis: 50%;
    }

    
`;

export const Home_Global_TRES = styled.div`
    //border: 1px solid orange;   
    display:flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    @media (max-width: 520px){
            flex-direction:column;
    }
`;