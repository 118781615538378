import { useState, useEffect, createContext } from "react";
import { requestHttp } from "../utils/HttpRequest";
import { getToken } from "../utils/TokenLS";

const initialState = {
    role: 0,
    name: '',
    email: '',    
    isAuthenticated: false
};

const AuthContext = createContext(initialState);

const AuthProvider = ({children}) =>{

   const [ auth, setAuth ] = useState({})
   const [ loading, setLoading ] = useState(true)

   useEffect(()=>{
        const autenticateUser = async() => {
            const token = getToken()
            if(!token){
                setLoading(false)
                return               
            }
            try {
                const response = await requestHttp(
                    {
                        method: 'get',
                        endpoint: '/users/info',
                        token: token
                    }
                );   
                setAuth(response)                
            } catch (error) {                
                setAuth({})                
            } 
            setLoading(false)                        
        }        
        autenticateUser();
   }, [])

   const closeSeccionAuth = () =>{
        setAuth({})
   }

    return(
        <AuthContext.Provider
            value={{
                auth,
                setAuth,    
                closeSeccionAuth,             
                loading
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export {
    AuthProvider
}

export default AuthContext;