import styled from "styled-components";

export const FormControlAuthorizationsRdt = styled.div` 
    //border: 1px solid orange;   
    display: flex;
    justify-content: center;
    position: absolute;
    background-color: rgb(0 0 0 / 0.92);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const CerrarAuthorizationsRdt = styled.div`
    position: absolute;
    right: 3rem;
    top: 3rem;
    width: 3rem;
    height: 3rem;
`;

export const FormularioAuthorizationsRdt = styled.div`
    //border: 1px solid blue;      
    max-width: 80rem;
    width: 40rem;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in;     
    opacity: 1;

    @media (max-width: 1024px){
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    } 
    @media (min-width: 1024px){
            margin-top: 20px;
            padding: 0 20px;            
            justify-content: center;
            width: 50%;
    }
    
`;

export const AnimarAuthorizationsRdt = styled.div` 
    opacity: 1;
`;

export const TittleAuthorizationsRdt = styled.h1`
    font-size: 2.2em;
    //color: #222;
    color: #fff;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;

    @media (max-width: 820px){
            font-size: 1.6em;
            margin-top: 100px;
            margin-bottom: 0px;
            padding: 0 2px;            
            justify-content: center;
            width: 100%;
    }    
`;

export const AuthorizationsRdtDetailWrapper = styled.div`
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px;    
    display: flex;
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    margin: 10px 0;
    background-color:white;
    text-decoration: none;    
`;

export const AuthorizationsRdtDetailInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin-left: 10px;
    font-size: 1em;
    h2{
        font-size: 1.5em;
        margin: 0;
        color:#6262A0;
    }
    h3{
        
        margin: 0;
        color:#6262A0;
    }        
`;

export const AuthorizationsRdtDetailSubTitle = styled.div`
    //border: 1px solid red;
    color: #C0C2D2;
    font-size: 1.2em;
    text-transform: capitalize;
    margin: 0;
    margin-top: 1px;
`;

export const AuthorizationsRdtDetailDescriptionWrapper = styled.div` 
    border-radius: 20px;
    color: #6262A0;
    font-weight: bold; 
    font-size: 1.0em;   
    padding-bottom: 10px;    
    p{
        color: #6262A0;
        font-weight: bold; 
        font-size: 1em;   
    }
`;

export const AuthorizationsPropertyTypesContainerRow = styled.section`
    //border: 1px solid red;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin: 10px 0;
    background-color: #F2F2F2;
    display: flex;
    flex-direction: row;   
    box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -webkit-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);
    -moz-box-shadow: 5px 2px 7px 0px rgba(194,194,194,0.75);   
`;

export const AuthorizationsPropertyTypesContainerColum = styled.section`
    //border: 1px solid yellow;
    
    border-radius: 20px;
    background-color: white;
    padding: 15px;
    width: 100%;
    label{
        display: block;
        font-size: 0.70em;
        color: blue;
        font-weight: bold;          
        p{
            color: black;              
        }                    
    }
`;

export const AuthorizationsRdtDetailValueWrapper = styled.div`
    color: #222;
    font-size: 1em;
    h2{
        font-size: 1.2em;
        margin: 0;
        color:#6262A0;
    }
    h3{        
        margin: 0;
        color:#6262A0;
    }
`;

