import styled from "styled-components";

export const SincronizationsWrapper_Global = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    justify-content: center;
    
    
`;

export const SincronizationsWrapper_Global_DOS = styled.div`
    //border: 1px solid blue;   
    
    @media (max-width: 1024px){
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    } 
    @media (min-width: 1024px){
            margin-top: 0px;
            padding: 0 20px;            
            justify-content: center;
            width: 50%;
    }
    
`;


export const SincronizationsWrapper = styled.div`
    //border: 1px solid red;
         
    padding: 10px;
    margin: 10px;
    
    
    a{                            
       margin-bottom: 10px;
       
    }

    span{
        //color: blueviolet;
        color: #f0a808;
        font-weight: 700;
       
    }

    
   
`;

export const SincronizationsWrapperEmployees = styled.div`
    //border: 1px solid blue;   
    font-size: 1.8em;
    
        
    h3{
        //border: 1px solid red;
        text-align: center;
        font-weight: 700;


    }

    span{
        //color: blueviolet;
        color: #f0a808;
        font-weight: 700;
        text-transform: capitalize;
    }
`;

export const SincronizationsWrapper_Items = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    //justify-content: center;
    button{
        margin: 5px;
    }
    
    
`;
