import styled from "styled-components"
import { UPDATEALL_COLOR } from "../../constants/styles"

const ButtonIconStyle = styled.button`
    border:1px solid ${UPDATEALL_COLOR};
    border-radius: 6px;
    padding: 6px;
    width: 40px;
    height: 40px;
    svg{
        color: ${UPDATEALL_COLOR};
        font-size: 1.4em;
    }
    &:hover{
        cursor: pointer;
        background-color: ${UPDATEALL_COLOR};        
        svg{
            color: #fff;
        } 
        
    }
    

`

export const ButtonIconUpdateAll = ({icon: Icon, onPress}) =>(
    <ButtonIconStyle type="button" onClick={onPress}>
        <Icon />
    </ButtonIconStyle>
    
)