import styled from "styled-components";
import { PRIMARY_COLOR } from "../../constants/styles";

export const AddNewsWrapper_Global = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    justify-content: center;
    
`;

export const AddNewsWrapper_Global_DOS = styled.div`
    //border: 1px solid blue;  
    flex-basis: ${props => props.basis};    
    @media (max-width: 1024px){
            margin-top: 5px;
            padding: 5px;            
            justify-content: center;
            width: 100%;            
    } 
    @media (min-width: 1024px){
            margin-top: 20px;
            padding:0 5px;            
            justify-content: center;
            width: 50%;
    }
    
`;

export const AddNewsWrapper_Global_TRES = styled.div`
    //border: 1px solid black;   
    display:flex;
    @media (max-width: 520px){
            flex-direction:column;
    }
`;



export const AddNewsWrapper = styled.div`
    //border: 1px solid red;
    display: flex;    
    justify-content: right;
    padding: 10px;
    margin: 10px;    

    a{                            
       margin-bottom: 10px;       
    }   
`;

export const AddNewsBusqueda = styled.div`
    //border: 1px solid;       
    padding: 6px;
    font-size: 0.8em;
    border-radius: 5px;
    :hover{
        cursor: pointer;
        background-color: ${PRIMARY_COLOR};
    }       
`;

export const AddNewsCombo = styled.div`
    //border: 1px solid;       
    //padding: 4px;
    font-size: 0.8em;               
`;
