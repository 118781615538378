
import styled from 'styled-components';

export const PropertyTypesContainerHead = styled.section`
    
    //border: 1px solid red;
    display: flex;  
    margin: 10px 0 ;    
    
`
export const PropertyTypesContainerColum = styled.section`

    //border: 1px solid yellow;
    padding-right: 20px;
    

`
export const PropertyTypesContainerRow = styled.section`

    //border: 1px solid red;
    display: flex;

`
export const PropertyTypesContainer = styled.section`
    //border: 1px solid orange;
    display: flex;
    overflow-x: scroll; 
    
`

export const RdtsDetailTitle = styled.h1`
    font-size: 1.5em;
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;
    
`

export const RdtsWrapper = styled.section`
    border: 1px solid red;
    display: flex;    
    //justify-content: center; 

    a{                            
       margin-bottom: 10px;       
    }   
`;


export const RdtsDetailInfo = styled.div`
    span{
        font-size: 1.5em;
        font-weight: bold;        
    }
    p{
        text-align: left; 
        span{
            font-size: 1.1em;
            font-weight: bold;
        }
    }
    
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    
    //border: 1px solid blue;
    width: 100%;
    
`;