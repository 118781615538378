import styled from "styled-components";



export const SincronizationsWrapperEmployees = styled.div`
    //border: 1px solid blue;   
    font-size: 1.8em;
    
        
    h3{
        //border: 1px solid red;
        text-align: center;
        font-weight: 700;


    }

    span{
        //color: blueviolet;
        color: #f0a808;
        font-weight: 700;
        text-transform: capitalize;
    }
`;

export const SincronizationsWrapper_Items = styled.div`
    //border: 1px solid blue;   
    
    display: flex;
    //justify-content: center;
    button{
        margin: 5px;
    }
    
    
`;